/**
 * We are keeping a manual list of targets for the purposes of type safety.
 */
export var Targets = {
  CONFLUENCE: 'confluence.ondemand',
  JIRA_CORE: 'jira-core.ondemand',
  JIRA_SERVICE_DESK: 'jira-servicedesk.ondemand',
  JIRA_SOFTWARE: 'jira-software.ondemand',
  JIRA_PRODUCT_DISCOVERY: 'jira-product-discovery',
  BITBUCKET: 'bitbucket.ondemand',
  TRELLO: 'trello',
  OPSGENIE: 'opsgenie',
  STATUSPAGE: 'statuspage',
  /**
   * @private
   * @deprecated Use ATLAS instead
   */
  TEAM_CENTRAL: 'townsquare',
  ATLAS: 'townsquare',
  /**
   * @private
   * @deprecated
   */
  AVOCADO: 'avocado',
  ANALYTICS: 'atlassian-analytics-free',
  COMPASS: 'compass'
};

/**
 * The list of supported targets in Cross-flow.
 *
 * While TypeScript does match this to the list of Targets provided in this library,
 * in essence any string value can be used to accomodate this type through the use of
 * type casting.
 */