// @ts-ignore TODO: fix noImplicitAny error here
import ConnectHostPrev from 'atlassian-connect-js';
// @ts-ignore TODO: fix noImplicitAny error here
import ConnectHistory from 'atlassian-connect-js-history/dist/connect-host-history';
// @ts-ignore TODO: fix noImplicitAny error here
import ConnectHostNext from 'atlassian-connect-js-next';

import { getInitialOrBucketState } from '../utils';

import AddonManager from './addonManager';
import * as AnalyitcsAPI from './analytics/api';
import * as ConnectAPI from './api';
import * as ContentReplacerAPI from './contentReplacer';
import * as EditorAPI from './editor';
import * as LinksAPI from './links';

let isACJSNextEnabled = false;

try {
  const acjsNextFeature =
    !!getInitialOrBucketState().global.features['acjs-next'];
  if (acjsNextFeature) {
    isACJSNextEnabled = true;
  }
} catch (_error) {
  // TODO: BBCEEP-3380 Implement error monitoring
}

let ConnectHost: any;

if (isACJSNextEnabled) {
  ConnectHost = ConnectHostNext;
} else {
  ConnectHost = ConnectHostPrev;
}

// set env from ConnectAPI
ConnectAPI.getHostEnv((err, env) => !err && AddonManager.setEnv(env));

// set current user and isAuthenticated FLAG
ConnectAPI.getCurrentUser(
  (err, user) => !err && AddonManager.setCurrentUser(user)
);

// add ConnectAPI module to ConnectHost
ConnectHost.defineModule('bitbucket', {
  ...ConnectAPI,
  ...EditorAPI,
  ...ContentReplacerAPI, // This API is for Atlassian only (site-addons)
});

// add LinkersAPI module to ConnectHost
ConnectHost.defineModule('links', LinksAPI);

// add analytics API to connect
ConnectHost.defineModule('analytics', AnalyitcsAPI);

// add history API to connect
ConnectHost.defineModule('history', ConnectHistory);

// register content resolver
// required, but not currently used by bitbucket-connect-js
// is used to do async data fetching before rendering an iframe
ConnectHost.registerContentResolver.resolveByExtension(() => {
  return {
    fail: () => {},
    done: () => {},
  };
});

// required by history API
const globalWindow: any = window;
globalWindow.connectHost = ConnectHost;

export default ConnectHost;
