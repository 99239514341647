import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from 'react';

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default function useOverflowable(truncationWidth, buttonRef) {
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    hasOverflow = _useState2[0],
    setOverflow = _useState2[1];
  // Recalculate hasOverflow on every render cycle
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(function () {
    if (truncationWidth && buttonRef.current) {
      var shouldOverflow = buttonRef.current.clientWidth >= truncationWidth;
      if (shouldOverflow !== hasOverflow) {
        setOverflow(shouldOverflow);
      }
    }
  });
  return hasOverflow;
}