import React from 'react';

import { defineMessages, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { ModalTransition } from '@atlaskit/modal-dialog-next';

import { getCurrentUser } from 'src/selectors/user-selectors';

import { LoadingState } from './constants';
import Modals, { isModalType } from './modals';
import {
  useActions,
  useActiveModal,
  useAddUsersAndGroups,
  useCheckedRows,
  useLoadingState,
  usePermissionsContext,
  useRemoveAccess,
  useSearchUsersAndGroups,
  useUpdateAccess,
} from './provider';
import { PermissionsAPIProps } from './types';
import { bulkShowRemoveOwnAccessWarning } from './utils';

const messages = defineMessages({
  removeAccessContent: {
    id: 'frontbucket.settings.permissions.modals.removeAccessContent',
    description: 'Remove access modal content message',
    defaultMessage: 'You are about to remove access for {entityName}.',
  },
  areYouSure: {
    id: 'frontbucket.settings.permissions.modals.areYouSure',
    description:
      'Question confirming the user is sure about the action they are taking',
    defaultMessage: 'Are you sure?',
  },
  removeOwnAccessWarning: {
    id: 'frontbucket.settings.permissions.modals.removeOwnAccessWarning',
    description: 'Remove access modal warning about removing your own access',
    defaultMessage:
      'You might be revoking your own admin access to this {contextType}.',
  },
  removeOwnAccessWarningQuestion: {
    id: 'frontbucket.settings.permissions.modals.removeOwnAccessWarningQuestion',
    description:
      'Remove access modal warning about removing your own access question',
    defaultMessage:
      'Are you sure? You might be revoking your own admin access to this {contextType}.',
  },
  workspace: {
    id: 'frontbucket.settings.permissions.modals.workspace',
    description: 'Workspace text',
    defaultMessage: 'workspace',
  },
  project: {
    id: 'frontbucket.settings.permissions.modals.project',
    description: 'Project text',
    defaultMessage: 'project',
  },
  repository: {
    id: 'frontbucket.settings.permissions.modals.repository',
    description: 'Repository text',
    defaultMessage: 'repository',
  },
});

const ActiveModal: React.FC<
  PermissionsAPIProps & {
    refreshInvitations: () => void;
  }
> = ({ api, refreshInvitations }) => {
  const [{ loadingState }] = useLoadingState();
  const [{ checkedRows }] = useCheckedRows();
  const [{ activeModal }] = useActiveModal();
  const [{ context }] = usePermissionsContext();
  const { setActiveModal } = useActions();
  const clearActiveModal = () => setActiveModal();
  const updateAccess = useUpdateAccess(api);
  const removeAccess = useRemoveAccess(api);
  const searchUsersAndGroups = useSearchUsersAndGroups(
    api.searchUsersAndGroups
  );
  const addUsersAndGroups = useAddUsersAndGroups(api, refreshInvitations);
  const currentUser = useSelector(getCurrentUser);
  const isLoading = loadingState === LoadingState.MODAL_LOADING;
  return (
    <ModalTransition>
      {isModalType('AddAccessModal', activeModal) && (
        <Modals.AddAccessModal
          isLoading={isLoading}
          onAdd={addUsersAndGroups}
          onClose={clearActiveModal}
          searchUsersAndGroups={searchUsersAndGroups}
          context={context}
        />
      )}
      {isModalType('BulkChangePrivilegesModal', activeModal) && (
        <Modals.BulkChangePrivilegesModal
          isLoading={isLoading}
          onBulkChange={async privilege => {
            await updateAccess(privilege, checkedRows);
            setActiveModal();
          }}
          onClose={clearActiveModal}
          itemNames={checkedRows.map(p => p.name)}
          removeOwnAccessWarning={
            bulkShowRemoveOwnAccessWarning(checkedRows, currentUser) && (
              <FormattedMessage
                tagName="p"
                {...messages.removeOwnAccessWarningQuestion}
                values={{
                  contextType: <FormattedMessage {...messages[context]} />,
                }}
              />
            )
          }
          context={context}
        />
      )}
      {isModalType('RemoveAccessModal', activeModal) && (
        <Modals.RemoveAccessModal
          isLoading={isLoading}
          onRemove={async () => {
            await removeAccess(
              ...(activeModal.props ? [activeModal.props] : checkedRows)
            );
            setActiveModal();
          }}
          onClose={clearActiveModal}
          removeMessage={
            <>
              {bulkShowRemoveOwnAccessWarning(
                activeModal.props ? [activeModal.props] : checkedRows,
                currentUser
              ) && (
                <FormattedMessage
                  tagName="p"
                  {...messages.removeOwnAccessWarningQuestion}
                  values={{
                    contextType: <FormattedMessage {...messages[context]} />,
                  }}
                />
              )}
              <FormattedMessage
                tagName="p"
                {...messages.removeAccessContent}
                values={{
                  entityName: (
                    <b>
                      {activeModal.props
                        ? activeModal.props.name
                        : `"${checkedRows
                            .map(entity => entity.name)
                            .join('", "')}"`}
                    </b>
                  ),
                }}
              />
            </>
          }
        />
      )}
      {isModalType('RemoveOwnAccessWarning', activeModal) && (
        <Modals.RemoveOwnAccessWarning
          isLoading={isLoading}
          onConfirm={() => {
            if (activeModal.props[0] && activeModal.props[1]) {
              updateAccess(activeModal.props[1], [activeModal.props[0]]);
            }
          }}
          onCancel={clearActiveModal}
          header={messages.areYouSure}
          body={
            <FormattedMessage
              tagName="p"
              {...messages.removeOwnAccessWarning}
              values={{
                contextType: <FormattedMessage {...messages[context]} />,
              }}
            />
          }
        />
      )}
      {isModalType('PartialInvitationMessageModal', activeModal) && (
        <Modals.PartialInvitationMessageModal
          onClose={() => {
            clearActiveModal();
          }}
          emails={activeModal.props}
        />
      )}
    </ModalTransition>
  );
};

export default ActiveModal;
