import { N200, N300 } from '@atlaskit/theme/colors';
var colorMap = {
  light: {
    textColor: "var(--ds-text-subtlest, ".concat(N200, ")"),
    separatorColor: "var(--ds-text-subtlest, ".concat(N200, ")")
  },
  dark: {
    textColor: "var(--ds-text-subtlest, ".concat(N300, ")"),
    separatorColor: "var(--ds-text-subtlest, ".concat(N300, ")")
  }
};
export var getColors = function getColors(mode) {
  return colorMap[mode];
};