/**
 * @jsxRuntime classic
 */
/** @jsx jsx */

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { useTheme } from '../../theme';
var paddingAll = 4;
var buttonHeight = 32;
var skeletonStyles = css({
  display: 'inline-flex',
  width: '68px',
  height: "".concat(buttonHeight - paddingAll * 2.5, "px"),
  borderRadius: "var(--ds-border-radius, 4px)",
  opacity: 0.15
});

// Not exported to consumers, only used in NavigationSkeleton
// eslint-disable-next-line @repo/internal/react/require-jsdoc
export var PrimaryButtonSkeleton = function PrimaryButtonSkeleton(props) {
  var theme = useTheme();
  return jsx("div", {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
    style: theme.mode.skeleton,
    css: skeletonStyles
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
    ,
    className: props.className
  });
};