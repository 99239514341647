import React, { useMemo, ComponentProps } from 'react';

import { FeatureKeys, useFeature } from '@atlassian/bitbucket-features';
import { ProductIds } from '@atlassian/ipm-choreographer';
import { EngagementProvider as BaseEngagementProvider } from '@atlassiansox/engagekit-ts';

import { useAppData } from 'src/app/data';
import locale from 'src/locale';
import { analyticsClient } from 'src/utils/analytics/client';
import { getBbEnv } from 'src/utils/bb-env';
import { getAnalyticsEnvironment } from 'src/utils/coordination-client';
import { IS_SERVER } from 'src/utils/ssr';

type Config = ComponentProps<typeof BaseEngagementProvider>['config'];
const getEngagementProviderConfig = (
  cloudId: string,
  currentUserAccountId: string | undefined | null,
  workspaceId: string | undefined
): Config => {
  return {
    cloudId,
    aaid: currentUserAccountId || '',
    workspaceId: workspaceId || '',
    locale: locale.locale,
    product: 'bitbucket',
    stargateUrl: '/gateway/api',
  };
};

export const EngagementProvider: React.FC<{}> = ({ children }) => {
  const appData = useAppData();

  // We don't have cloudIds, we use the environment instead.
  const cloudId = getBbEnv();
  const currentUserAccountId = appData.user?.aaid;
  const workspaceId = appData.tenantId;

  const config: Config = useMemo(
    () =>
      getEngagementProviderConfig(cloudId, currentUserAccountId, workspaceId),
    [cloudId, currentUserAccountId, workspaceId]
  );

  const enableChoreographer = !!useFeature(
    FeatureKeys.enableChoreographer,
    false
  );

  const analyticsEnv = getAnalyticsEnvironment();

  const coordinationOptions = useMemo(
    () => ({
      enableChoreographer,
      envType: analyticsEnv,
      additionalData: { sourceProductId: ProductIds.BITBUCKET },
    }),
    [enableChoreographer, analyticsEnv]
  );

  if (IS_SERVER || !currentUserAccountId) {
    return <>{children}</>;
  }

  return (
    <BaseEngagementProvider
      config={config}
      analyticsClientInstance={analyticsClient()}
      coordinationOptions={coordinationOptions}
    >
      <>{children}</>
    </BaseEngagementProvider>
  );
};
