import React from 'react';
import createReactContext from 'create-react-context';
import PropTypes from 'prop-types';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var StateContext = createReactContext(null);

var Container = function () {
  function Container() {
    _classCallCheck(this, Container);

    this._listeners = [];
  }

  Container.prototype.setState = function setState(state) {
    this.state = Object.assign({}, this.state, state);
    this._listeners.forEach(function (fn) {
      return fn();
    });
  };

  Container.prototype.subscribe = function subscribe(fn) {
    this._listeners.push(fn);
  };

  Container.prototype.unsubscribe = function unsubscribe(fn) {
    this._listeners = this._listeners.filter(function (f) {
      return f !== fn;
    });
  };

  return Container;
}();

var DUMMY_STATE = {};

var Subscribe = function (_React$Component) {
  _inherits(Subscribe, _React$Component);

  function Subscribe() {
    var _temp, _this, _ret;

    _classCallCheck(this, Subscribe);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _React$Component.call.apply(_React$Component, [this].concat(args))), _this), _this.state = {}, _this.instances = [], _this.onUpdate = function () {
      _this.setState(DUMMY_STATE);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  Subscribe.prototype.componentWillUnmount = function componentWillUnmount() {
    this._unsubscribe();
  };

  Subscribe.prototype._unsubscribe = function _unsubscribe() {
    var _this2 = this;

    this.instances.forEach(function (container) {
      container.unsubscribe(_this2.onUpdate);
    });
  };

  Subscribe.prototype._createInstances = function _createInstances(map, containers) {
    var _this3 = this;

    this._unsubscribe();

    if (map === null) {
      throw new Error('You must wrap your <Subscribe> components with a <Provider>');
    }

    var safeMap = map;
    var instances = containers.map(function (ContainerItem) {
      var instance = void 0;

      if ((typeof ContainerItem === 'undefined' ? 'undefined' : _typeof(ContainerItem)) === 'object' && ContainerItem instanceof Container) {
        instance = ContainerItem;
      } else {
        instance = safeMap.get(ContainerItem);

        if (!instance) {
          instance = new ContainerItem();
          safeMap.set(ContainerItem, instance);
        }
      }

      instance.unsubscribe(_this3.onUpdate);
      instance.subscribe(_this3.onUpdate);

      return instance;
    });

    this.instances = instances;
    return instances;
  };

  Subscribe.prototype.render = function render() {
    var _this4 = this;

    return React.createElement(
      StateContext.Consumer,
      null,
      function (map) {
        return _this4.props.children.apply(null, _this4._createInstances(map, _this4.props.to));
      }
    );
  };

  return Subscribe;
}(React.Component);

Subscribe.propTypes = {
  to: PropTypes.array.isRequired,
  children: PropTypes.func.isRequired
};


function Provider(props) {
  return React.createElement(
    StateContext.Consumer,
    null,
    function (parentMap) {
      var childMap = new Map(parentMap);

      if (props.inject) {
        props.inject.forEach(function (instance) {
          childMap.set(instance.constructor, instance);
        });
      }

      return React.createElement(
        StateContext.Provider,
        { value: childMap },
        props.children
      );
    }
  );
}

export { Container, Subscribe, Provider };
