import { createResource } from 'react-resource-router';

import { FeatureKeys } from '@atlassian/bitbucket-features';

import {
  getPipelineRunsUrl,
  getRunsFromResponse,
  sortRuns,
} from 'src/components/pipelines/hooks/pipeline-runs';
import { ResourceContext } from 'src/router/types';

import { fetchData } from './utils';

export type Params = {
  pipelineUuid: string;
  stepUuid: string;
  repositoryFullSlug: string;
  trigger: string;
};

export const getUrl = (params: Params): string => {
  return getPipelineRunsUrl(params.repositoryFullSlug, params.pipelineUuid);
};

export const loadPipelineRunsResource = createResource({
  type: 'pipeline-runs',
  getKey: ({ match: { params } }) => getUrl(params as Params),
  maxAge: 60000,
  getData: async ({ match: { params } }, { appData }: ResourceContext) => {
    if (!appData?.features[FeatureKeys.isPipelinesPageLoadOptimisationEnabled])
      return null;

    const data = await fetchData(
      getUrl(params as Params),
      'pipeline_runs_resource_exception'
    );
    const runs = getRunsFromResponse(data);
    return sortRuns(runs);
  },
});
