import URL from 'url';

import React, { useCallback, Fragment, ComponentType } from 'react';

import { injectIntl, InjectedIntl, InjectedIntlProps } from 'react-intl';

import ArrowRightCircleIcon from '@atlaskit/icon/glyph/arrow-right-circle';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import { BitbucketWordmark } from '@atlaskit/logo';
import Lozenge from '@atlaskit/lozenge';
// eslint-disable-next-line @atlaskit/design-system/no-deprecated-imports
import {
  ContainerHeader,
  HeaderSection,
  BackItem,
  Item,
  ItemAvatar,
  GroupHeading,
  Section,
  MenuSection,
  Wordmark,
  // @ts-ignore TODO: fix noImplicitAny error here
} from '@atlaskit/navigation-next';
import { gridSize } from '@atlaskit/theme';

import { MenuItem, MenuItemGroup, UiEvent } from '../types';
import {
  isNestedMenu,
  findNestedMenuItemParent,
  MENU_ITEM_GROUP_TYPE,
  getFirstMenuItem,
} from '../utils/create-nested-menu';

import messages from './container-navigation-next.i18n';
import {
  BetaLozenge,
  GroupHeadingNoTopMargin,
  MenuItemWrapper,
} from './container-navigation-next.style';
import { renderMenuItemIcon } from './icons-map';
import PremiumIcon from './premium-icon';

const linkComponentProps = (
  menuItem: MenuItem,
  linkComponent?: ComponentType<any>
) => {
  return menuItem.is_client_link ? { menuItem, component: linkComponent } : {};
};

type ContainerHeaderProps =
  | {
      isGlobalContext: true;
      isPrivate?: false;
      containerHref?: undefined;
      containerLogo?: undefined;
      containerName?: undefined;
      containerType?: undefined;
    }
  | {
      isGlobalContext: false;
      isPrivate: boolean;
      containerHref: string;
      containerLogo: string;
      containerName: string;
      containerType: string;
    };

export type historyPushType = {
  push: (pathname: string) => void;
};

export type ContainerNavigationProps = {
  headerLinkComponent?: React.ComponentType<any>;
  history?: historyPushType; // This property is not passed in bitbucket-core.
  linkComponent?: ComponentType<any>;
  menuItems: MenuItem[];
  selectedMenuItem?: MenuItem;
  publishUiEvent: (event: UiEvent) => void;
};

type ContainerNavigationComponentProps = ContainerNavigationProps &
  ContainerHeaderProps &
  InjectedIntlProps;

export const ContainerMenuItem = ({
  item,
  index,
  publishUiEvent,
  intl,
  selectedMenuItem,
  linkComponent,
  isNested,
  history,
}: {
  item: MenuItem | MenuItemGroup;
  index: number;
  history?: historyPushType;
  publishUiEvent: (event: UiEvent) => void;
  intl: InjectedIntl;
  selectedMenuItem?: MenuItem;
  linkComponent?: ComponentType<any>;
  isNested?: boolean;
}): JSX.Element => {
  const publishEvent = useCallback(() => {
    if (item.type !== MENU_ITEM_GROUP_TYPE) {
      if (item.label === 'Runners') {
        publishUiEvent({
          action: 'clicked',
          actionSubject: 'button',
          actionSubjectId: 'runners',
          source: 'repositorySettingsNavigation',
        });
      } else if (item.label === 'Workspace runners') {
        publishUiEvent({
          action: 'clicked',
          actionSubject: 'button',
          actionSubjectId: 'runners',
          source: 'workspaceSettingsNavigation',
        });
      }
    }
  }, [item, publishUiEvent]);

  if (item.type === MENU_ITEM_GROUP_TYPE) {
    const heading = (
      <GroupHeading key={item.key} id={item.key}>
        {item.title}
      </GroupHeading>
    );
    // If the first item, then remove top margin from heading
    return (
      <>
        {index === 0 ? (
          <GroupHeadingNoTopMargin key={item.key}>
            {heading}
          </GroupHeadingNoTopMargin>
        ) : (
          heading
        )}
        {item.children.map((child, i) => (
          <ContainerMenuItem
            key={child.id}
            history={history}
            item={child}
            index={i}
            selectedMenuItem={selectedMenuItem}
            linkComponent={linkComponent}
            publishUiEvent={publishUiEvent}
            intl={intl}
            isNested
          />
        ))}
      </>
    );
  }

  if (Array.isArray(item.children) && item.children.length) {
    // TODO: Only use the item and item.url
    // We shouldn't need to get the first menuItem child
    // We are doing this because is_client_link is false on "item"
    const firstMenuItem: MenuItem = getFirstMenuItem(item);

    return (
      <Item
        id={item.id}
        before={() => renderMenuItemIcon(item)}
        // @ts-ignore TODO: fix noImplicitAny error here
        after={({ isHover }) =>
          isHover && (
            <ArrowRightCircleIcon label={item.label} secondaryColor="inherit" />
          )
        }
        text={item.label}
        href={firstMenuItem.url}
        {...linkComponentProps(firstMenuItem, linkComponent)}
      />
    );
  }

  const icon = isNested ? null : renderMenuItemIcon(item);

  const renderMenuItem = () => {
    if (item.is_new) {
      return (
        <MenuItemWrapper>
          <span>{item.label}</span>
          <BetaLozenge>
            <Lozenge appearance="new">
              {intl.formatMessage(messages.new)}
            </Lozenge>
          </BetaLozenge>
        </MenuItemWrapper>
      );
    }
    if (item.is_beta) {
      return (
        <MenuItemWrapper>
          <span>{item.label}</span>
          <BetaLozenge>
            <Lozenge appearance="new">Beta</Lozenge>
          </BetaLozenge>
        </MenuItemWrapper>
      );
    }
    if (item.is_premium) {
      return (
        <MenuItemWrapper>
          <span>{item.label}</span>
          <PremiumIcon label="premium icon" />
        </MenuItemWrapper>
      );
    }
    return item.label;
  };

  return (
    <Item
      id={item.id}
      href={item.url}
      text={renderMenuItem()}
      isSelected={selectedMenuItem && selectedMenuItem.id === item.id}
      {...(icon ? { before: () => icon } : {})}
      onClick={publishEvent}
      {...linkComponentProps(item, linkComponent)}
      {...(item.is_external_link
        ? {
            after: () => <ShortcutIcon size="small" label="external icon" />,
            target: item.target,
          }
        : {})}
    />
  );
};

export const ContainerMenuSection = ({
  menuItems,
  publishUiEvent,
  intl,
  selectedMenuItem,
  linkComponent,
  history,
  containerHref,
}: {
  menuItems: MenuItem[];
  publishUiEvent: (event: UiEvent) => void;
  intl: InjectedIntl;
  selectedMenuItem?: MenuItem;
  linkComponent?: ComponentType<any>;
  containerHref: string;
  history?: historyPushType;
}): JSX.Element | null => {
  if (!selectedMenuItem) {
    return null;
  }

  const parentItem = findNestedMenuItemParent(menuItems, selectedMenuItem);
  const items = parentItem ? parentItem.children : menuItems;

  return (
    <>
      {parentItem?.parentId && (
        <Section>
          {({ className }: any) => (
            <div className={className}>
              <BackItem
                onClick={() => {
                  const containerHrefPathname =
                    URL.parse(containerHref).pathname || '/';
                  if (history) {
                    history.push(containerHrefPathname);
                  } else {
                    window.location.assign(containerHrefPathname);
                  }
                }}
              />
            </div>
          )}
        </Section>
      )}
      <MenuSection
        key="nested-section"
        id={parentItem?.id}
        parentId={parentItem?.parentId}
        shouldGrow
      >
        {({ className }: any) => (
          <div className={className}>
            {items.map((item: MenuItem, index: number) => (
              <ContainerMenuItem
                key={item.id}
                history={history}
                index={index}
                item={item}
                selectedMenuItem={selectedMenuItem}
                linkComponent={linkComponent}
                publishUiEvent={publishUiEvent}
                intl={intl}
              />
            ))}
          </div>
        )}
      </MenuSection>
    </>
  );
};

const ContainerNavigation = ({
  containerHref,
  containerLogo,
  containerName,
  isGlobalContext,
  isPrivate,
  linkComponent,
  menuItems,
  selectedMenuItem,
  publishUiEvent,
  intl,
  headerLinkComponent,
  history,
  containerType,
}: ContainerNavigationComponentProps) => {
  const Avatar = React.useMemo(
    () => (itemState: any) =>
      (
        <ItemAvatar
          itemState={itemState}
          appearance="square"
          size="large"
          src={containerLogo}
          status={isPrivate ? 'locked' : undefined}
        />
      ),
    [containerLogo, isPrivate]
  );

  return (
    <Fragment>
      <HeaderSection>
        {({ css }: any) =>
          isGlobalContext ? (
            <div style={{ ...css }}>
              <Wordmark wordmark={BitbucketWordmark} />
            </div>
          ) : (
            <div
              style={{ ...css, paddingBottom: gridSize() * 2.5 }}
              id="bitbucket-navigation"
            >
              <ContainerHeader
                before={Avatar}
                component={headerLinkComponent}
                id={`${containerType}HeaderTab`}
                href={containerHref}
                text={containerName}
              />
            </div>
          )
        }
      </HeaderSection>
      {isNestedMenu(menuItems) ? (
        <ContainerMenuSection
          history={history}
          containerHref={containerHref as string}
          menuItems={menuItems}
          selectedMenuItem={selectedMenuItem}
          linkComponent={linkComponent}
          publishUiEvent={publishUiEvent}
          intl={intl}
        />
      ) : (
        <MenuSection>
          {({ className }: any) => (
            <div className={className}>
              {menuItems.map(item => (
                <Item
                  href={item.url}
                  id={item.id}
                  before={() => renderMenuItemIcon(item)}
                  key={item.id}
                  text={item.label}
                  isSelected={item === selectedMenuItem}
                  {...linkComponentProps(item, linkComponent)}
                  {...(item.is_external_link
                    ? {
                        after: () => (
                          <ShortcutIcon size="small" label="external icon" />
                        ),
                        target: item.target,
                      }
                    : {})}
                />
              ))}
            </div>
          )}
        </MenuSection>
      )}
    </Fragment>
  );
};
export default injectIntl(ContainerNavigation);
