/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { useModal } from './hooks';
import { iconColor, titleIconMargin } from './internal/constants';
var fontSize = 20;
var lineHeight = 1;
var adjustedLineHeight = 1.2;
var titleStyles = css({
  display: 'flex',
  minWidth: 0,
  margin: "var(--ds-space-0, 0px)",
  alignItems: 'center',
  fontSize: "var(--ds-font-size-300, 20px)",
  fontStyle: 'inherit',
  fontWeight: "var(--ds-font-weight-medium, 500)",
  letterSpacing: "-0.008em",
  lineHeight: lineHeight
});
var textStyles = css({
  minWidth: 0,
  /**
   * This ensures that the element fills the whole header space
   * and its content does not overflow (since flex items don't
   * shrink past its content size by default).
   */
  flex: '1 1 auto',
  wordWrap: 'break-word'
});
var iconStyles = css({
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
  marginRight: "".concat(titleIconMargin, "px"),
  flex: '0 0 auto',
  alignSelf: 'start'
});

/**
 * When the title is truncated (not multi-line), we adjust the
 * line height to avoid cropping the descenders. This removes
 * the extra spacing that we get from that adjustment.
 */
var lineHeightOffset = fontSize - fontSize * adjustedLineHeight;
var truncatedTextStyles = css({
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage, @atlaskit/design-system/ensure-design-token-usage/preview
  marginTop: "".concat(lineHeightOffset / 2, "px"),
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage, @atlaskit/design-system/ensure-design-token-usage/preview
  marginBottom: "".concat(lineHeightOffset / 2, "px"),
  lineHeight: adjustedLineHeight,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
});
var truncatedTextIconStyles = css({
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage, @atlaskit/design-system/ensure-design-token-usage/preview
  marginBottom: "".concat(lineHeightOffset / 2, "px"),
  lineHeight: 1.2
});
var TitleIcon = function TitleIcon(_ref) {
  var appearance = _ref.appearance,
    isMultiline = _ref.isMultiline;
  var Icon = appearance === 'danger' ? ErrorIcon : WarningIcon;
  return jsx("span", {
    css: [iconStyles, !isMultiline && truncatedTextIconStyles]
  }, jsx(Icon, {
    label: appearance,
    primaryColor: iconColor[appearance]
  }));
};
/**
 * __Modal title__
 *
 * A modal title is used to display a title within a modal.
 *
 * - [Examples](https://atlassian.design/components/modal-dialog/examples)
 * - [Code](https://atlassian.design/components/modal-dialog/code)
 * - [Usage](https://atlassian.design/components/modal-dialog/usage)
 */
var ModalTitle = function ModalTitle(props) {
  var appearance = props.appearance,
    children = props.children,
    _props$isMultiline = props.isMultiline,
    isMultiline = _props$isMultiline === void 0 ? true : _props$isMultiline,
    userDefinedTestId = props.testId;
  var _useModal = useModal(),
    titleId = _useModal.titleId,
    modalTestId = _useModal.testId;
  var testId = userDefinedTestId || modalTestId && "".concat(modalTestId, "--title");
  return jsx("h1", {
    css: titleStyles,
    "data-testid": testId
  }, appearance && jsx(TitleIcon, {
    appearance: appearance,
    isMultiline: isMultiline
  }), jsx("span", {
    id: titleId,
    css: [textStyles, !isMultiline && truncatedTextStyles],
    "data-testid": testId && "".concat(testId, "-text")
  }, children));
};
export default ModalTitle;