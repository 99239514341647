import RemotePostOfficePlugin, { type PluginEnv, type PluginVariant } from './plugin';

class PluginRegistry {
	public static getRegistry(): PluginRegistry {
		if (window.postOfficePluginRegistry === undefined) {
			window.postOfficePluginRegistry = new PluginRegistry();
		}

		return window.postOfficePluginRegistry as PluginRegistry;
	}

	private readonly registry: Map<string, RemotePostOfficePlugin> = new Map();

	public getPluginForPlacement(
		placementId: string,
		env: PluginEnv,
		variant: PluginVariant,
		overrides?: {
			moduleUri: string;
		},
	): RemotePostOfficePlugin {
		const pluginKey = `${placementId}--${env}--${variant}`;

		if (this.registry.has(pluginKey)) {
			return this.registry.get(pluginKey) as RemotePostOfficePlugin;
		}

		const plugin = new RemotePostOfficePlugin(
			{
				placementId,
				env,
				variant,
			},
			overrides,
		);

		this.registry.set(pluginKey, plugin);

		return plugin;
	}
}

export default PluginRegistry;

declare global {
	interface Window {
		postOfficePluginRegistry?: PluginRegistry;
	}
}
