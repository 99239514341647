import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  header: {
    id: 'frontbucket.settings.merge-checks.header.title',
    description: 'Header for the merge checks settings page',
    defaultMessage: 'Custom merge checks',
  },
  beta: {
    id: 'frontbucket.settings.merge-checks.header.beta',
    description: 'Beta label for the merge checks settings page',
    defaultMessage: 'Beta',
  },
});
