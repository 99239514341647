import { createResource } from 'react-resource-router';

import { FeatureKeys } from '@atlassian/bitbucket-features';

import { setPipelineDefinitions } from 'src/components/pipelines/redux/actions/pipelines';
import { getPipelineDefinitionsUrl } from 'src/components/pipelines/redux/sagas/pipelines';
import { ResourceContext } from 'src/router/types';

import { fetchData } from './utils';

export type Params = {
  repositoryFullSlug: string;
  branch: string;
  page: string;
};

export const getBranchUrl = (params: Params): string => {
  return `/!api/2.0/repositories/${params.repositoryFullSlug}/refs/branches/${params.branch}`;
};

export const getDefinitionsUrl = (params: Params, ref: string): string => {
  return getPipelineDefinitionsUrl(
    params.repositoryFullSlug as Params['repositoryFullSlug'],
    ref
  );
};

export const loadPipelineDefinitionsResource = createResource({
  type: 'pipeline-definitions',
  getKey: ({ match: { params } }) => getBranchUrl(params as Params),
  maxAge: 0,
  getData: async (
    { match: { params } },
    { reduxStore, appData }: ResourceContext
  ) => {
    if (!appData?.features[FeatureKeys.isPipelinesPageLoadOptimisationEnabled])
      return null;

    if (!params.branch) return { status: 'success' };

    const { dispatch } = reduxStore;
    const branchData = await fetchData(
      getBranchUrl(params as Params),
      'pipeline_definitions_resource_exception'
    );

    const definitionData = await fetchData(
      getDefinitionsUrl(
        params as Params,
        branchData?.target?.hash || branchData.name
      ),
      'pipeline_definitions_resource_exception'
    );
    dispatch(setPipelineDefinitions(definitionData));

    return { status: 'success' };
  },
});
