import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  preMerge: {
    id: 'frontbucket.settings.merge-checks.preMerge',
    description: 'Label for a custom merge check that runs pre-merge',
    defaultMessage: 'pre-merge',
  },
  onMerge: {
    id: 'frontbucket.settings.merge-checks.onMerge',
    description: 'Label for a custom merge check that runs on-merge',
    defaultMessage: 'on-merge',
  },
  enableErrorMessage: {
    id: 'frontbucket.settings.merge-checks.enableErrorMessage',
    description: 'Error message for flag when enable check failed',
    defaultMessage: 'Something went wrong while trying to enable "{name}"',
  },
  disableErrorMessage: {
    id: 'frontbucket.settings.merge-checks.disableErrorMessage',
    description: 'Error message for flag when disable check failed',
    defaultMessage: 'Something went wrong while trying to disable "{name}"',
  },
  checkDisabledLabel: {
    id: 'frontbucket.settings.merge-checks.checkDisabledLabel',
    description: 'Label for custom merge check when it is disabled',
    defaultMessage: 'Off',
  },
  checkRecommendedLabel: {
    id: 'frontbucket.settings.merge-checks.checkRecommendedLabel',
    description:
      'Label for custom merge check when it is recommended, i.e. cannot block a merge',
    defaultMessage: 'Recommended',
  },
  checkRequiredLabel: {
    id: 'frontbucket.settings.merge-checks.checkRequiredLabel',
    description:
      'Label for custom merge check when it is required, i.e. can block a merge',
    defaultMessage: 'Required',
  },
  requiredPremiumOnly: {
    id: 'frontbucket.settings.merge-checks.premiumRequired',
    description:
      'Explains that the "Required" option for checks is only available to premium workspaces',
    defaultMessage: 'Required is only available to premium workspaces',
  },
  premiumIconLabel: {
    id: 'frontbucket.settings.merge-checks.premiumIconLabel',
    description:
      'Accessibility label for Premium icon that indicates a feature is only available to premium workspaces',
    defaultMessage: 'Premium workspaces only',
  },
});
