/**
 * @name mediaGroup_node
 */

// Temporary due to an existing issue in validator below:
// https://product-fabric.atlassian.net/jira/servicedesk/projects/DTR/queues/issue/DTR-1429
// TODO: Remove border and link marks from white list
export var mediaGroup = {
  inline: false,
  group: 'block',
  content: '(media|unsupportedBlock)+',
  attrs: {},
  marks: 'unsupportedMark unsupportedNodeAttribute annotation border link',
  selectable: false,
  parseDOM: [{
    tag: 'div[data-node-type="mediaGroup"]'
  }, {
    tag: 'div[class="MediaGroup"]'
  }],
  toDOM: function toDOM() {
    return ['div', {
      'data-node-type': 'mediaGroup'
    }, 0];
  }
};