import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { getConfig as getConfigUFO } from '@atlassian/ufo-config';
var defaultAllowedParams = ['operationName', 'operation', 'q'];
var getAllowedParams = function getAllowedParams() {
  var config = getConfigUFO();
  return (config === null || config === void 0 ? void 0 : config.allowedResourcesParams) || defaultAllowedParams;
};
var handleQueryParams = function handleQueryParams(urlString) {
  try {
    if (typeof urlString !== 'string') {
      return urlString;
    }
    var url = new URL(urlString);
    var params = new URLSearchParams(url.search);
    var allowedParams = getAllowedParams();
    var _iterator = _createForOfIteratorHelper(params),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var _step$value = _slicedToArray(_step.value, 1),
          key = _step$value[0];
        if (!allowedParams.includes(key)) {
          url.searchParams.delete(key);
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    return url.toString();
  } catch (e) {
    return urlString;
  }
};
var config = {
  mapResources: function mapResources(url) {
    return url;
  },
  sanitiseEndpoints: function sanitiseEndpoints(url) {
    return handleQueryParams(url);
  }
};
export function configure(resourceTimingConfig) {
  var newConfig = {
    mapResources: resourceTimingConfig.mapResources,
    sanitiseEndpoints: function sanitiseEndpoints(url) {
      var sanitised = resourceTimingConfig.sanitiseEndpoints(url);
      if (sanitised) {
        return handleQueryParams(sanitised);
      }
      return sanitised;
    }
  };
  config = newConfig;
}
export var getConfig = function getConfig() {
  return config;
};