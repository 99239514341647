import React from 'react';

import { FocusedPageHeader } from 'src/components/accessibility';
import { SharedProps } from 'src/components/settings/create-settings-hoc';
import { useParams } from 'src/router/hooks';
import urls from 'src/sections/repository/urls';

import Breadcrumbs from './breadcrumbs';

export type Props = SharedProps;

const ConnectIframeHeader: React.FC<Props> = props => {
  const { repositoryOwner, repositorySlug } = useParams<{
    repositoryOwner: string;
    repositorySlug: string;
  }>();
  const settingsHref = urls.ui.admin(
    repositoryOwner || '',
    repositorySlug || ''
  );
  return (
    <FocusedPageHeader
      breadcrumbs={<Breadcrumbs settingsHref={settingsHref} {...props} />}
    />
  );
};

export default ConnectIframeHeader;
