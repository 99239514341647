import {
  Environment,
  Step,
  StepGroup,
  StepGroupType,
  Tasks,
  ExecutionPhase,
  StepCommand,
  CommandToggleState,
  CommandToggleOption,
  StepData,
  RunnerDetailsCommand,
  SetupCommand,
  TeardownCommand,
  AfterScriptCommand,
  ScriptCommand,
  ExecutionPhaseType,
  StepTaskData,
  LogRangeTaskData,
  LogRequestMeta,
} from 'src/components/pipelines/models';
import { LoadingStatus } from 'src/constants/loading-status';
import { Action } from 'src/types/state';
import createReducer from 'src/utils/create-reducer';

import { BITBUCKET_PIPELINES_FEATURE_FLAG_KEYS } from '../../constants';
import {
  CLEAR_CURRENT_PIPELINE,
  COLLAPSE_ALL_COMMANDS,
  COLLAPSE_COMMAND,
  RESET_DOWNLOADED_COMMANDS,
  EXPAND_COMMAND,
  REQUEST_DELETE_LOG,
  REQUEST_LOG,
  REQUEST_REDEPLOY_STEP,
  REQUEST_RERUN_STEPS,
  REQUEST_RESUME_STAGE_REDEPLOY,
  REQUEST_START_STEP,
  REQUEST_STEP_BRANCH_RESTRICTIONS,
  REQUEST_STEPS,
  REQUEST_UPDATE_FAILURE_REASON,
  RESET_UPDATE_FAILURE_REASON,
  SET_STEP,
  SET_STEPS,
  REQUEST_LOG_RANGES,
  CLEAR_STEPS,
  SET_STEPS_FOR_PREVIOUS_RUN,
  REQUEST_FLAT_NESTED_LOGS,
} from '../actions/pipelines';

export type StepsState = {
  currentStepUuid: string;
  stepForPreviousRun: boolean;
  downloadedCommands: { [key: string]: boolean[] };
  environments: { [key: string]: Environment };
  erroredCommands: { [key: string]: boolean[] };
  expandedCommands: { [key: string]: boolean[] };
  groups: StepGroup[];
  map: Map<string, Step> | [];
  streamingCommands: { [key: string]: boolean[] };
  commands: { [key: string]: StepCommand[] };
  runningManualStep: boolean | string;
  stageActionInProgress: undefined | number;
  fetchedBranchRestrictions: { [key: string]: LoadingStatus };
  fetchedStepRerunStatus: LoadingStatus;
  failureReasonUpdated: string;
  fetchedStatus: LoadingStatus;
};

export const initialState: StepsState = {
  currentStepUuid: '',
  stepForPreviousRun: false,
  downloadedCommands: {},
  environments: {},
  erroredCommands: {},
  expandedCommands: {},
  groups: [],
  map: [],
  streamingCommands: {},
  commands: {},
  runningManualStep: false,
  stageActionInProgress: undefined,
  fetchedBranchRestrictions: {},
  fetchedStepRerunStatus: LoadingStatus.Before,
  failureReasonUpdated: 'DEFAULT',
  fetchedStatus: LoadingStatus.Before,
};

function isParallelStep(step: any) {
  return !!step.parallel_group;
}

function isStageStep(step: any) {
  return !!step?.stage;
}

function isStepLogV2Enabled(step: StepData) {
  return (step.feature_flags || []).find(
    ff => ff.name === BITBUCKET_PIPELINES_FEATURE_FLAG_KEYS.LOG_V2
  )?.value as boolean;
}

function createStepGroups(stepsData: any[]) {
  const stepGroups: StepGroup[] = [];

  let i = 0;
  while (i < stepsData.length) {
    let step = stepsData[i];
    if (!isParallelStep(step) && !isStageStep(step)) {
      stepGroups.push(
        new StepGroup({ steps: [step.uuid], type: StepGroupType.SINGLE })
      );
      i++;
    } else if (isParallelStep(step)) {
      // find all consecutive steps in same parallel group
      const currentParallelGroupName = step.parallel_group.group_name;
      const currentParallelSteps: any[] = [];
      while (
        i < stepsData.length &&
        isParallelStep(step) &&
        step.parallel_group.group_name === currentParallelGroupName
      ) {
        currentParallelSteps.push(step);
        i++;
        step = stepsData[i];
      }
      if (currentParallelSteps.length > 0) {
        stepGroups.push(
          new StepGroup({
            steps: currentParallelSteps
              .sort(
                (left, right) =>
                  left.parallel_group.step_index -
                  right.parallel_group.step_index
              )
              .map(s => s.uuid),
            type: StepGroupType.PARALLEL,
          })
        );
      }
    } else if (isStageStep(step)) {
      const currentStageGroupIndex = step.stage.index;
      const currentStageSteps: any[] = [];
      while (
        i < stepsData.length &&
        isStageStep(step) &&
        step.stage.index === currentStageGroupIndex
      ) {
        currentStageSteps.push(step);
        i++;
        step = stepsData[i];
      }
      if (currentStageSteps.length > 0) {
        stepGroups.push(
          new StepGroup({
            steps: currentStageSteps.map(s => s.uuid),
            type: StepGroupType.STAGE,
          })
        );
      }
    }
  }
  return stepGroups;
}

const createCommands = (data: {
  execution_phases: {
    [key in ExecutionPhaseType]: StepTaskData[] | LogRangeTaskData[];
  };
}) => {
  const tasks = new Tasks(data);
  return tasks.convertToCommands();
};

const createCommandsWithMergingLogRanges = (
  existingCommands: StepCommand[] | undefined,
  stepData: StepData
) => {
  const commands = createCommands(stepData.tasks);

  return !existingCommands || existingCommands.length === 0
    ? commands
    : commands.map((command, index) => {
        const existingCommand = existingCommands[index];
        if (!existingCommand) {
          return command;
        }
        if (!command) {
          return existingCommand;
        }
        return {
          ...existingCommand,
          ...(existingCommand.log_range.byte_count === 0
            ? { log_range: command.log_range }
            : {}),
        };
      });
};

const updateIndex = (array: boolean[], index: number, value: boolean) => {
  // eslint-disable-next-line no-param-reassign
  array = (array || []).slice(); // copy of array needed to trigger redux updates
  if (index === -1) {
    // eslint-disable-next-line no-param-reassign
    array = array.map(() => false);
  } else {
    array[index] = value;
  }
  return array;
};

export const getFailedCommandForStep = (commands: StepCommand[]) => {
  const filteredCommands =
    commands?.filter(
      cmd =>
        cmd.execution_phase === ExecutionPhase.MAIN &&
        cmd.log_range.byte_count > 0
    ) || [];

  if (!filteredCommands.length) {
    return [];
  }
  const failedCommandIndex = commands.findIndex(
    cmd => cmd.name === filteredCommands[filteredCommands.length - 1].name
  );
  return new Array(failedCommandIndex + 1).fill(false).fill(true, -1);
};

export const resetNonRunningCommands = (
  commandType: CommandToggleState,
  oldCommands: { [key: string]: boolean[] },
  stepsMaps: { [key: string]: Step },
  commands: { [key: string]: StepCommand[] }
) => {
  const entries = Object.entries(oldCommands)
    .filter(([key]) =>
      ['IN_PROGRESS', 'PENDING'].includes(stepsMaps[key]?.state?.name)
    )
    .map(([key, value]) => [
      key,
      new Array(value.length).fill(false).fill(true, -1),
    ]);
  Object.keys(stepsMaps).forEach(key => {
    if (
      commandType === CommandToggleOption.EXPANDED &&
      stepsMaps[key]?.state?.result?.name === 'FAILED'
    ) {
      const failedCommand = getFailedCommandForStep(commands[key]);
      if (failedCommand.length > 0) {
        entries.push([key, failedCommand]);
      }
    }
  });
  return Object.fromEntries(entries);
};

const getStreamingCommands = (
  step: Step,
  data: {
    execution_phases: {
      [key in ExecutionPhaseType]: StepTaskData[] | LogRangeTaskData[];
    };
  }
): boolean[] => {
  if (!step.isSyncing) {
    return [];
  }

  const tasks = new Tasks(data);
  const runnerDetailsCommand = true;
  const setupCommand =
    tasks.runnerDetailsCommand === undefined ||
    (tasks.setupCommand.log_range &&
      tasks.setupCommand.log_range.byte_count !== 0);
  const mainCommands = tasks.mainCommands.map(
    (scriptCommand: StepCommand) =>
      scriptCommand.log_range && scriptCommand.log_range.byte_count !== 0
  );
  const afterMainCommands = tasks.afterMainCommands.map(
    (scriptCommand: StepCommand) =>
      scriptCommand.log_range && scriptCommand.log_range.byte_count !== 0
  );
  const teardownCommand =
    tasks.teardownCommand.log_range &&
    tasks.teardownCommand.log_range.byte_count !== 0;
  const streamingCommands =
    tasks.runnerDetailsCommand === undefined
      ? [setupCommand, ...mainCommands, ...afterMainCommands, teardownCommand]
      : [
          runnerDetailsCommand,
          setupCommand,
          ...mainCommands,
          ...afterMainCommands,
          teardownCommand,
        ];
  const streamingCommandIndex = streamingCommands.lastIndexOf(true);
  const commands: boolean[] = [];
  commands[streamingCommandIndex] = true;
  return commands;
};

const reduceSteps = (
  state: StepsState,
  action: Action<{ values: StepData[] }> & { meta: { stepUuid?: string } }
) => {
  // Ignore any in-flight Websocket step updates if switched to a previous run to avoid them incorrectly rewriting step state
  if (state.stepForPreviousRun && !action.meta?.pipelineRunUuid) {
    return state;
  }

  if (!action.payload?.values) {
    return state;
  }

  let { currentStepUuid } = state;
  if (!currentStepUuid) {
    if (
      (action.payload.values || []).filter(
        step => step?.uuid === action.meta?.stepUuid
      ).length
    ) {
      currentStepUuid = action.meta?.stepUuid || '';
    } else {
      currentStepUuid = action.payload.values?.[0]?.uuid || '';
    }
  }

  const map = new Map();
  action.payload.values?.forEach(data => {
    const step = new Step(data);
    map.set(step.uuid, step);
  });

  const commands = action.payload.values?.reduce((obj, stepData: StepData) => {
    const currentStepCommands = isStepLogV2Enabled(stepData)
      ? createCommandsWithMergingLogRanges(
          state.commands[stepData.uuid],
          stepData
        )
      : createCommands(stepData.tasks);

    return {
      ...obj,
      [stepData.uuid]: currentStepCommands,
    };
  }, {});
  const streamingCommands = action.payload.values?.reduce(
    (obj, stepData: StepData) => ({
      ...obj,
      [stepData.uuid]: isStepLogV2Enabled(stepData)
        ? state.streamingCommands[stepData.uuid]
        : getStreamingCommands(map.get(stepData.uuid), stepData.tasks),
    }),
    {}
  );

  const environments = action.payload.values
    .filter(s => s.environment)
    .reduce(
      (reducer, step) => {
        reducer[step.uuid] = new Environment({
          ...step.environment,
          branchRestrictions:
            state.environments[step.uuid]?.branchRestrictions || [],
        });
        return reducer;
      },
      { ...state.environments }
    );

  return {
    ...state,
    currentStepUuid,
    map,
    groups: createStepGroups(action.payload.values),
    environments,
    commands: {
      ...state.commands,
      ...commands,
    },
    streamingCommands: {
      ...state.streamingCommands,
      ...streamingCommands,
    },
    fetchedStatus: LoadingStatus.Success,
  };
};

export const steps = createReducer(initialState, {
  [CLEAR_CURRENT_PIPELINE]() {
    return { ...initialState };
  },
  [CLEAR_STEPS](_state: StepsState) {
    return {
      ...initialState,
    };
  },
  [SET_STEPS_FOR_PREVIOUS_RUN](state: StepsState) {
    return {
      ...state,
      stepForPreviousRun: true,
    };
  },
  [SET_STEP](state: StepsState, action: Action<string>) {
    if (!action?.payload) {
      return state;
    }
    return {
      ...state,
      currentStepUuid: action.payload,
    };
  },
  [REQUEST_STEPS.REQUEST](state: StepsState) {
    return {
      ...state,
      fetchedStatus: LoadingStatus.Fetching,
    };
  },
  [REQUEST_STEPS.ERROR](state: StepsState) {
    return {
      ...state,
      fetchedStatus: LoadingStatus.Failed,
    };
  },
  [SET_STEPS]: reduceSteps,
  [REQUEST_STEPS.SUCCESS]: reduceSteps,
  [EXPAND_COMMAND](
    state: StepsState,
    action: Action & { meta: { stepUuid: string; index: number } }
  ) {
    if (!action.meta?.stepUuid) {
      return state;
    }
    const expandedCommands = {
      [action.meta.stepUuid]: updateIndex(
        [...(state.expandedCommands[action.meta.stepUuid] || [])],
        action.meta.index,
        true
      ),
    };

    return {
      ...state,
      expandedCommands: { ...state.expandedCommands, ...expandedCommands },
    };
  },
  [COLLAPSE_COMMAND](
    state: StepsState,
    action: Action & { meta: { stepUuid: string; index: number } }
  ) {
    if (!action.meta?.stepUuid) {
      return state;
    }
    const expandedCommands = {
      [action.meta.stepUuid]: updateIndex(
        [...(state.expandedCommands[action.meta.stepUuid] || [])],
        action.meta.index,
        false
      ),
    };

    return {
      ...state,
      expandedCommands: { ...state.expandedCommands, ...expandedCommands },
    };
  },
  [RESET_DOWNLOADED_COMMANDS](state: StepsState) {
    const stepsMaps = Object.fromEntries(state.map);

    return {
      ...state,
      downloadedCommands: resetNonRunningCommands(
        CommandToggleOption.DOWNLOADED,
        state.downloadedCommands,
        stepsMaps,
        state.commands
      ),
      expandedCommands: resetNonRunningCommands(
        CommandToggleOption.EXPANDED,
        state.expandedCommands,
        stepsMaps,
        state.commands
      ),
    };
  },
  [COLLAPSE_ALL_COMMANDS](
    state: StepsState,
    action: Action & { meta: { stepUuid: string } }
  ) {
    if (!action.meta?.stepUuid) {
      return state;
    }
    const expandedCommands = {
      [action.meta.stepUuid]: updateIndex(
        [...(state.expandedCommands[action.meta.stepUuid] || [])],
        -1,
        false
      ),
    };

    return {
      ...state,
      expandedCommands: { ...state.expandedCommands, ...expandedCommands },
    };
  },

  [REQUEST_LOG.REQUEST](
    state: StepsState,
    action: Action & { meta: { stepUuid: string; index: number } }
  ) {
    if (!action.meta?.stepUuid) {
      return state;
    }
    const erroredCommands = {
      [action.meta.stepUuid]: updateIndex(
        [...(state.erroredCommands[action.meta.stepUuid] || [])],
        action.meta.index,
        false
      ),
    };

    return {
      ...state,
      erroredCommands: {
        ...state.erroredCommands,
        ...erroredCommands,
      },
    };
  },
  [REQUEST_FLAT_NESTED_LOGS.SUCCESS](
    state: StepsState,
    action: Action & { meta: LogRequestMeta }
  ) {
    if (!action.meta?.stepUuid || action.meta?.index === undefined) {
      return state;
    }

    const downloadedCommands = {
      [action.meta.stepUuid]: updateIndex(
        [...(state.downloadedCommands[action.meta.stepUuid] || [])],
        action.meta.index,
        true
      ),
    };
    const erroredCommands = {
      [action.meta.stepUuid]: updateIndex(
        [...(state.erroredCommands[action.meta.stepUuid] || [])],
        action.meta.index,
        false
      ),
    };

    return {
      ...state,
      downloadedCommands: {
        ...state.downloadedCommands,
        ...downloadedCommands,
      },
      erroredCommands: {
        ...state.erroredCommands,
        ...erroredCommands,
      },
    };
  },
  [REQUEST_LOG.SUCCESS](
    state: StepsState,
    action: Action & { meta: LogRequestMeta }
  ) {
    if (
      action.meta?.incrementalRequestEnabled ||
      !action.meta?.stepUuid ||
      action.meta?.index === undefined
    ) {
      return state;
    }

    const downloadedCommands = {
      [action.meta.stepUuid]: updateIndex(
        [...(state.downloadedCommands[action.meta.stepUuid] || [])],
        action.meta.index,
        true
      ),
    };
    const erroredCommands = {
      [action.meta.stepUuid]: updateIndex(
        [...(state.erroredCommands[action.meta.stepUuid] || [])],
        action.meta.index,
        false
      ),
    };

    return {
      ...state,
      downloadedCommands: {
        ...state.downloadedCommands,
        ...downloadedCommands,
      },
      erroredCommands: {
        ...state.erroredCommands,
        ...erroredCommands,
      },
    };
  },
  [REQUEST_LOG.ERROR](
    state: StepsState,
    action: Action & { meta: { stepUuid: string; index: number } }
  ) {
    if (!action.meta?.stepUuid) {
      return state;
    }
    const erroredCommands = {
      [action.meta.stepUuid]: updateIndex(
        [...(state.erroredCommands[action.meta.stepUuid] || [])],
        action.meta.index,
        true
      ),
    };
    const expandedCommands = {
      [action.meta.stepUuid]: updateIndex(
        [...(state.expandedCommands[action.meta.stepUuid] || [])],
        action.meta.index,
        false
      ),
    };

    return {
      ...state,
      erroredCommands: {
        ...state.erroredCommands,
        ...erroredCommands,
      },
      expandedCommands: {
        ...state.expandedCommands,
        ...expandedCommands,
      },
    };
  },
  [REQUEST_DELETE_LOG.SUCCESS](
    state: StepsState,
    action: Action & { meta: { stepUuid: string; serviceUuid?: string } }
  ) {
    const serviceUuid = action.meta?.serviceUuid;
    const stepUuid = action.meta?.stepUuid;
    if (serviceUuid) {
      return state;
    }

    const map = new Map(state.map);
    map.set(stepUuid, new Step({ ...map.get(stepUuid), log_byte_count: 0 }));

    return { ...state, map };
  },
  [REQUEST_START_STEP.REQUEST](
    state: StepsState,
    action: Action & { meta: { stepUuid: string; stageIndex?: number } }
  ) {
    if (!action.meta?.stepUuid) {
      return state;
    }
    return {
      ...state,
      runningManualStep: action.meta.stepUuid,
      stageActionInProgress: action.meta.stageIndex,
    };
  },
  [REQUEST_REDEPLOY_STEP.REQUEST](
    state: StepsState,
    action: Action & { meta: { stepUuid: string; stageIndex?: number } }
  ) {
    if (!action.meta?.stepUuid) {
      return state;
    }
    return {
      ...state,
      runningManualStep: action.meta.stepUuid,
      stageActionInProgress: action.meta.stageIndex,
    };
  },
  [REQUEST_RESUME_STAGE_REDEPLOY.REQUEST](
    state: StepsState,
    action: Action & { meta: { stageIndex: number } }
  ) {
    if (!action.meta?.stageIndex) {
      return state;
    }
    return { ...state, stageActionInProgress: action.meta.stageIndex };
  },
  [REQUEST_RERUN_STEPS.REQUEST](state: StepsState) {
    return {
      ...state,
      currentStepUuid: '',
      fetchedStepRerunStatus: LoadingStatus.Fetching,
    };
  },
  [REQUEST_START_STEP.SUCCESS](state: StepsState) {
    return {
      ...state,
      runningManualStep: false,
      stageActionInProgress: undefined,
    };
  },
  [REQUEST_REDEPLOY_STEP.SUCCESS](state: StepsState) {
    return {
      ...state,
      runningManualStep: false,
      stageActionInProgress: undefined,
    };
  },
  [REQUEST_RESUME_STAGE_REDEPLOY.SUCCESS]: (state: StepsState) => {
    return { ...state, stageActionInProgress: undefined };
  },
  [REQUEST_RERUN_STEPS.SUCCESS]: (state: StepsState) => {
    return { ...state, fetchedStepRerunStatus: LoadingStatus.Success };
  },
  [REQUEST_START_STEP.ERROR](state: StepsState) {
    return {
      ...state,
      runningManualStep: false,
      stageActionInProgress: undefined,
    };
  },
  [REQUEST_REDEPLOY_STEP.ERROR](state: StepsState) {
    return {
      ...state,
      runningManualStep: false,
      stageActionInProgress: undefined,
    };
  },
  [REQUEST_RESUME_STAGE_REDEPLOY.ERROR]: (state: StepsState) => {
    return { ...state, stageActionInProgress: undefined };
  },
  [REQUEST_RERUN_STEPS.ERROR]: (state: StepsState) => {
    return { ...state, fetchedStepRerunStatus: LoadingStatus.Failed };
  },
  [REQUEST_STEP_BRANCH_RESTRICTIONS.REQUEST](
    state: StepsState,
    action: Action & { meta: { environmentUuids: string[] } }
  ) {
    if (!action.meta?.environmentUuids) {
      return state;
    }

    const fetchedBranchRestrictions = action.meta.environmentUuids.reduce(
      (reducer, uuid) => {
        reducer[uuid] = LoadingStatus.Fetching;
        return reducer;
      },
      { ...state.fetchedBranchRestrictions }
    );

    return { ...state, fetchedBranchRestrictions };
  },
  [REQUEST_STEP_BRANCH_RESTRICTIONS.SUCCESS](
    state: StepsState,
    action: Action<{ values: any[] }> & { meta: { environmentUuids: string[] } }
  ) {
    if (!action.meta?.environmentUuids || !action.payload?.values) {
      return state;
    }

    const environments = action.meta.environmentUuids.reduce(
      (reducer, uuid) => {
        const stepUuid = Object.keys(reducer).find(
          key => reducer[key]?.uuid === uuid
        );
        if (stepUuid) {
          reducer[stepUuid] = new Environment({
            ...reducer[stepUuid]?.toJS?.(),
            branchRestrictions: action.payload?.values.filter(
              r => r.environmentUuid === uuid
            ),
          });
        }
        return reducer;
      },
      { ...state.environments }
    );

    const fetchedBranchRestrictions = action.meta.environmentUuids.reduce(
      (reducer, uuid) => {
        reducer[uuid] = LoadingStatus.Success;
        return reducer;
      },
      { ...state.fetchedBranchRestrictions }
    );

    return { ...state, environments, fetchedBranchRestrictions };
  },
  [REQUEST_UPDATE_FAILURE_REASON.SUCCESS](state: StepsState) {
    return { ...state, failureReasonUpdated: 'SUCCESS' };
  },
  [REQUEST_UPDATE_FAILURE_REASON.ERROR](state: StepsState) {
    return { ...state, failureReasonUpdated: 'FAILURE' };
  },
  [RESET_UPDATE_FAILURE_REASON.REQUEST](state: StepsState) {
    return { ...state, failureReasonUpdated: 'DEFAULT' };
  },
  [REQUEST_STEP_BRANCH_RESTRICTIONS.ERROR](
    state: StepsState,
    action: Action & { meta: { environmentUuids: string[] } }
  ) {
    if (!action.meta?.environmentUuids) {
      return state;
    }

    const fetchedBranchRestrictions = action.meta.environmentUuids.reduce(
      (reducer, uuid) => {
        reducer[uuid] = LoadingStatus.Failed;
        return reducer;
      },
      { ...state.fetchedBranchRestrictions }
    );

    return { ...state, fetchedBranchRestrictions };
  },
  [REQUEST_LOG_RANGES.SUCCESS](
    state: StepsState,
    action: Action & { meta: {} }
  ) {
    // sanity check
    if (!action.meta?.stepUuid) {
      return state;
    }

    // from log ranges, perform the merge
    const { stepUuid } = action.meta;
    const payload: {
      execution_phases: {
        [key in ExecutionPhaseType]: LogRangeTaskData[];
      };
      log_byte_count: number;
    } = action?.payload;

    // if no records of steps, return.
    const map = new Map(state.map);
    const step = map.get(stepUuid);
    if (!step) {
      return state;
    }

    const streamingCommands = getStreamingCommands(step, payload);

    map.set(
      stepUuid,
      new Step({ ...step, log_byte_count: payload.log_byte_count })
    );

    const streamingCommandsMap = state.streamingCommands;
    streamingCommandsMap[stepUuid] = streamingCommands;

    const commandsMap = state.commands;

    const commands = createCommands(payload);
    const existingCommands = commandsMap[stepUuid];

    const newCommands = existingCommands.map((command, index) => {
      const { log_range, ...currentCommand } = command;
      const updatedCommand = commands[index];
      if (!updatedCommand) {
        return command;
      }

      const commandToUpdate = {
        ...currentCommand,
        execution_duration: updatedCommand.execution_duration,
      };

      if (command instanceof RunnerDetailsCommand) {
        return new RunnerDetailsCommand(
          [commandToUpdate],
          updatedCommand.log_range,
          index
        );
      } else if (command instanceof SetupCommand) {
        return new SetupCommand(
          [commandToUpdate],
          updatedCommand.log_range,
          index
        );
      } else if (command instanceof TeardownCommand) {
        return new TeardownCommand(
          [commandToUpdate],
          updatedCommand.log_range,
          index
        );
      } else if (command instanceof AfterScriptCommand) {
        return new AfterScriptCommand({
          ...commandToUpdate,
          log_range: updatedCommand.log_range,
          index,
        });
      } else {
        return new ScriptCommand({
          ...commandToUpdate,
          log_range: updatedCommand.log_range,
          index,
        });
      }
    });
    return {
      ...state,
      map,
      commands: {
        ...state.commands,
        [stepUuid]: newCommands,
      },
      streamingCommands: streamingCommandsMap,
    };
  },
});
