/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import SelectClearIcon from '@atlaskit/icon/glyph/select-clear';
import { components } from 'react-select';
export { ClearIndicator, DropdownIndicator, LoadingIndicator } from './indicators';
var disabledStyles = css({
  display: 'none'
});
var enabledStyles = css({
  display: 'inherit'
});
export var MultiValueRemove = function MultiValueRemove(props) {
  var isDisabled = props.selectProps.isDisabled;
  return jsx(components.MultiValueRemove, props, jsx("div", {
    css: isDisabled ? disabledStyles : enabledStyles,
    "data-testid": isDisabled ? 'hide-clear-icon' : 'show-clear-icon'
  }, jsx(SelectClearIcon, {
    label: "Clear",
    size: "small",
    primaryColor: "transparent",
    secondaryColor: "inherit"
  })));
};
export var IndicatorSeparator = null;