import { getModuleToLoad, getScopedModules, getSystemModule } from './utils';
/**
 * Here we import what we need to make SystemJS work as a global.
 * This file is marked as containing a sideEffect in the package.json
 *
 *   "sideEffects": [
 *     "./src/services/remote-setup/index.ts"
 *   ],
 *
 * The import extension is needed for those environments (eg. Mocha)
 * that can't map the path to the file correctly.
 */
// eslint-disable-next-line import/extensions
require('systemjs/dist/system.js');

/**
 * This function loops through the external defined modules and adds them into the System registry
 * by requiring them at runtime from the product
 *
 * @param modulesToLoad
 */
export var setupSystemModules = function setupSystemModules(modulesToLoad, pluginName) {
  if (!modulesToLoad || modulesToLoad.length === 0) {
    console.error('No modules found to set in System registry');
    return null;
  }
  var systemModulesToSet = modulesToLoad.map(getModuleToLoad);
  var systemModules = systemModulesToSet.map(function (module) {
    return getSystemModule(module, pluginName);
  });
  systemModules.forEach(function (entry) {
    if (!entry.import) {
      console.error("Unable to set import for module [".concat(entry.module, "]. Please ensure it has been installed and is available."));
      return;
    }
    System.set(entry.scopedModuleId, entry.import);
  });
  return getScopedModules(systemModules);
};