/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import qs from 'qs';
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { getCurrentRepository } from 'src/selectors/repository-selectors';
import authRequest, { jsonHeaders } from 'src/utils/fetch';

import { SCHEDULES_PAGE_SIZE } from '../../constants';
import envBaseUrl from '../../utils/env-base-url';
import {
  capturePipelinesExceptionWithTags,
  createErrorMessage,
  isIgnoredErrorForSentry,
} from '../../utils/sentry';
import {
  REQUEST_SCHEDULES,
  REQUEST_DELETE_SCHEDULE,
  REQUEST_CREATE_SCHEDULE,
  REQUEST_TOGGLE_SCHEDULE,
} from '../actions/pipelines';

export const getSchedulesUrl = (
  repositoryFullSlug: string,
  scheduleUuid = '',
  queryParams: any = {}
) => {
  return `${envBaseUrl(
    '/!api/2.0'
  )}/repositories/${repositoryFullSlug}/pipelines_config/schedules/${scheduleUuid}${qs.stringify(
    queryParams,
    { addQueryPrefix: true }
  )}`;
};

export function* requestSchedulesSaga(): any {
  const { full_name } = yield select(getCurrentRepository);
  try {
    const url = getSchedulesUrl(full_name, '', {
      page: 1,
      pagelen: SCHEDULES_PAGE_SIZE,
    });
    const res: Response = yield call(fetch, authRequest(url));

    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const schedulesPayload = yield call([res, 'json']);
    const executionsRes = yield all(
      schedulesPayload.values.map((schedule: any) =>
        call(
          fetch,
          authRequest(
            getSchedulesUrl(full_name, `${schedule.uuid}/executions/`, {
              page: 1,
              pagelen: 10,
              sort: '-triggeredOn',
            })
          )
        )
      )
    );
    const executionsPayload = yield all(
      executionsRes.map((r: any) => call([r, 'json']))
    );

    yield put({
      type: REQUEST_SCHEDULES.SUCCESS,
      payload: schedulesPayload.values.map((schedule: any, key: number) => ({
        ...schedule,
        executions: executionsPayload[key].values,
      })),
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_SCHEDULES.ERROR,
    });
    yield put({
      type: REQUEST_SCHEDULES.ERROR,
      payload: e,
    });
  }
}

export function* requestDeleteScheduleSaga(action: {
  meta: { schedule: any };
}): any {
  const { full_name } = yield select(getCurrentRepository);
  try {
    const url = getSchedulesUrl(full_name, action.meta.schedule.uuid);
    const res: Response = yield call(
      fetch,
      authRequest(url, { method: 'DELETE' })
    );

    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    yield put({
      type: REQUEST_DELETE_SCHEDULE.SUCCESS,
      meta: action.meta,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_DELETE_SCHEDULE.ERROR,
    });
    yield put({
      type: REQUEST_DELETE_SCHEDULE.ERROR,
      meta: action.meta,
      payload: e,
    });
  }
}

export function* requestCreateScheduleSaga(action: {
  meta: { schedule: any };
}): any {
  const { full_name } = yield select(getCurrentRepository);
  try {
    const url = getSchedulesUrl(full_name);
    const res: Response = yield call(
      fetch,
      authRequest(url, {
        method: 'POST',
        headers: jsonHeaders,
        body: JSON.stringify(action.meta.schedule),
      })
    );

    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const response = yield call([res, 'json']);
    yield put({
      type: REQUEST_CREATE_SCHEDULE.SUCCESS,
      payload: response,
    });
  } catch (e) {
    if (!isIgnoredErrorForSentry(e)) {
      capturePipelinesExceptionWithTags(e, {
        segment: REQUEST_CREATE_SCHEDULE.ERROR,
      });
    }
    yield put({
      type: REQUEST_CREATE_SCHEDULE.ERROR,
      meta: action.meta,
      payload: e,
    });
  }
}

export function* requestToggleScheduleSaga(action: {
  meta: { schedule: any };
}): any {
  const { full_name } = yield select(getCurrentRepository);
  try {
    const url = getSchedulesUrl(full_name, action.meta.schedule.uuid);
    const res: Response = yield call(
      fetch,
      authRequest(url, {
        method: 'PUT',
        headers: jsonHeaders,
        body: JSON.stringify({ enabled: !action.meta.schedule.enabled }),
      })
    );

    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    yield put({
      type: REQUEST_TOGGLE_SCHEDULE.SUCCESS,
      meta: action.meta,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_TOGGLE_SCHEDULE.ERROR,
    });
    yield put({
      type: REQUEST_TOGGLE_SCHEDULE.ERROR,
      meta: action.meta,
      payload: e,
    });
  }
}

export default function* () {
  yield all([
    takeLatest(REQUEST_SCHEDULES.REQUEST, requestSchedulesSaga),
    takeEvery(REQUEST_CREATE_SCHEDULE.REQUEST, requestCreateScheduleSaga),
    takeEvery(REQUEST_DELETE_SCHEDULE.REQUEST, requestDeleteScheduleSaga),
    takeEvery(REQUEST_TOGGLE_SCHEDULE.REQUEST, requestToggleScheduleSaga),
  ]);
}
