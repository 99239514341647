var allowedTypes = ['wide', 'full-width'];

/**
 * @name breakout_mark
 */

export var breakout = {
  spanning: false,
  inclusive: false,
  parseDOM: [{
    tag: 'div.fabric-editor-breakout-mark',
    getAttrs: function getAttrs(dom) {
      var mode = dom.getAttribute('data-mode');
      return {
        mode: allowedTypes.indexOf(mode || '') === -1 ? 'wide' : mode
      };
    }
  }],
  attrs: {
    mode: {
      default: 'wide'
    }
  },
  toDOM: function toDOM(mark) {
    return ['div', {
      class: 'fabric-editor-breakout-mark',
      'data-mode': mark.attrs.mode
    }, 0];
  }
};