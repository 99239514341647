import { createSelector } from 'reselect';

import {
  isNotCommentActivity,
  ActivityApi,
  isTaskActivity,
  isUpdateActivity,
  isAttachmentActivity,
  isApprovalActivity,
  isChangesRequestedActivity,
} from 'src/components/activity/types';
import { getFabricConversations } from 'src/selectors/conversation-selectors';
import { getCurrentUserKey } from 'src/selectors/user-selectors';

import {
  breakoutCommentEntries,
  transformActivities,
  collapseAttachments,
  collapseCommits,
  breakoutOverviewCommentEntries,
  collapseRichActivities,
  transformOverviewActivities,
} from '../utils/activity-utils';

import { getCommentTree, getPullRequestSlice } from './index';

export const getPullRequestActivitySlice = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.activity
);

export const getCurrentPullRequestActivityNextUrl = createSelector(
  getPullRequestActivitySlice,
  activity => activity.nextUrl
);

export const getCurrentPullRequestActivityError = createSelector(
  getPullRequestActivitySlice,
  activity => activity.hasError
);

export const getCurrentPullRequestActivityLoadingState = createSelector(
  getPullRequestActivitySlice,
  activity => activity.isActivityLoading
);

export const getCurrentPullRequestActivityHasNext = createSelector(
  getPullRequestActivitySlice,
  activity => !!activity.nextUrl
);

export const getCurrentPullRequestActivityEvents = createSelector(
  getPullRequestActivitySlice,
  activity => activity.activityEvents
);

export const getPullRequestActivityFeed = createSelector(
  getPullRequestActivitySlice,
  getFabricConversations,
  getCurrentPullRequestActivityHasNext,
  /**
   * This is the transformation of activity API events and conversations (data) into
   * Activity Feed Entries (visual representations).
   */
  (activitySlice, fabricConversations, hasNext) => {
    // Event - a single happening as reported by the backend in endpoint data structures
    // Entry - a single visual representation in the Activity Feed of the UI
    const { activityEvents } = activitySlice;

    // We have all comments already so we ignore the ones in Activity Endpoint
    const nonCommentEvents: Array<
      | ActivityApi['Approval']
      | ActivityApi['Update']
      | ActivityApi['TaskActivity']
      | ActivityApi['AttachmentActivity']
      | ActivityApi['ChangesRequested']
    > = activityEvents.filter(isNotCommentActivity);

    const getTime = (
      activityEvent:
        | ActivityApi['Approval']
        | ActivityApi['Update']
        | ActivityApi['TaskActivity']
        | ActivityApi['AttachmentActivity']
        | ActivityApi['ChangesRequested']
    ): string => {
      if (isUpdateActivity(activityEvent)) {
        return activityEvent.update.date;
      } else if (isTaskActivity(activityEvent)) {
        return activityEvent.task.action_on;
      } else if (isAttachmentActivity(activityEvent)) {
        return activityEvent.attachment.created_on;
      } else if (isChangesRequestedActivity(activityEvent)) {
        return activityEvent.changes_requested.date;
      }
      return activityEvent.approval?.date;
    };

    const chronologicalEvents = nonCommentEvents.sort((a, b) => {
      const aTime = getTime(a);
      const bTime = getTime(b);
      const aMilliseconds = new Date(aTime).getTime();
      const bMilliseconds = new Date(bTime).getTime();

      // For entries with the same timestamp, preserve original order
      // by sorting `a` to a lower index than `b`.
      // Returning 0 does not guarantee order will be unchanged.
      // (Added to preserve the ordering of bulk uploaded attachments)
      if (aMilliseconds === bMilliseconds) {
        return -1;
      }
      return aMilliseconds - bMilliseconds;
    });

    // Build the two classes of entries, activities + comments
    const commentEntries = breakoutCommentEntries(fabricConversations);
    const activityEntries = transformActivities(chronologicalEvents, !hasNext);
    // Collapse attachment updates into single entries
    const collapsedActivityEntries = collapseAttachments(activityEntries);

    // Combine them
    const combinedEntries = [...collapsedActivityEntries, ...commentEntries];
    // Put them in oldestToNewest order for commits collapsing, so
    // duplicate commits are only rendered at their oldest point, not most recent.
    const oldestToNewest = combinedEntries.sort(
      (a, b) => a.timestamp.getTime() - b.timestamp.getTime()
    );
    // Collapse commit updates into single entries
    const condensedEntries = collapseCommits(oldestToNewest);
    // Reverse so that the feed renders newestToOldest
    return condensedEntries.reverse();
  }
);

// This is copied from the above getPullRequestActivityFeed selector
// We are using this for the new rich activity feed and should consolidate
// to this one long term
export const getPullRequestRichActivityFeed = createSelector(
  getPullRequestActivitySlice,
  getCommentTree,
  getCurrentPullRequestActivityHasNext,
  /**
   * This is the transformation of activity API events and conversations (data) into
   * Activity Feed Entries (visual representations).
   */
  (activitySlice, commentTree, hasNext) => {
    // Event - a single happening as reported by the backend in endpoint data structures
    // Entry - a single visual representation in the Activity Feed of the UI
    const { activityEvents } = activitySlice;

    // We have all comments already so we ignore the ones in Activity Endpoint
    const nonCommentEvents: Array<
      | ActivityApi['Approval']
      | ActivityApi['Update']
      | ActivityApi['TaskActivity']
      | ActivityApi['AttachmentActivity']
      | ActivityApi['ChangesRequested']
    > = activityEvents.filter(isNotCommentActivity);

    const getTime = (
      activityEvent:
        | ActivityApi['Approval']
        | ActivityApi['Update']
        | ActivityApi['TaskActivity']
        | ActivityApi['AttachmentActivity']
        | ActivityApi['ChangesRequested']
    ): string => {
      if (isUpdateActivity(activityEvent)) {
        return activityEvent.update.date;
      } else if (isTaskActivity(activityEvent)) {
        return activityEvent.task.action_on;
      } else if (isAttachmentActivity(activityEvent)) {
        return activityEvent.attachment.created_on;
      } else if (isChangesRequestedActivity(activityEvent)) {
        return activityEvent.changes_requested.date;
      }
      return activityEvent.approval?.date;
    };

    const chronologicalEvents = nonCommentEvents.sort((a, b) => {
      const aTime = getTime(a);
      const bTime = getTime(b);
      const aMilliseconds = new Date(aTime).getTime();
      const bMilliseconds = new Date(bTime).getTime();

      // For entries with the same timestamp, preserve original order
      // by sorting `a` to a lower index than `b`.
      // Returning 0 does not guarantee order will be unchanged.
      // (Added to preserve the ordering of bulk uploaded attachments)
      if (aMilliseconds === bMilliseconds) {
        return -1;
      }
      return aMilliseconds - bMilliseconds;
    });

    // Build the two classes of entries, activities + comments
    const commentEntries = breakoutOverviewCommentEntries(commentTree);
    const activityEntries = transformOverviewActivities(
      chronologicalEvents,
      !hasNext
    );
    // Collapse attachment updates into single entries
    const collapsedActivityEntries = collapseAttachments(activityEntries);

    // Combine them
    const combinedEntries = [...collapsedActivityEntries, ...commentEntries];
    // Put them in oldestToNewest order for commits collapsing, so
    // duplicate commits are only rendered at their oldest point, not most recent.
    const oldestToNewest = combinedEntries.sort(
      (a, b) => a.timestamp.getTime() - b.timestamp.getTime()
    );
    // Collapse commit updates into single entries
    const condensedEntries = collapseCommits(oldestToNewest);

    // collapse down the reviewers and task entries
    const moreCondensedEntries = collapseRichActivities(condensedEntries);

    // Reverse so that the feed renders newestToOldest
    return moreCondensedEntries.reverse();
  }
);

export const getCurrentPullRequestActivityApprovalEvent = createSelector(
  getCurrentPullRequestActivityEvents,
  getCurrentUserKey,
  (activityEvents, userUuid) => {
    const activityEvent = activityEvents.find(
      event =>
        isApprovalActivity(event) && event.approval.user.uuid === userUuid
    );
    if (activityEvent && isApprovalActivity(activityEvent)) {
      return activityEvent.approval;
    }

    return null;
  }
);

export const getCurrentPullRequestActivityChangesRequestedEvent =
  createSelector(
    getCurrentPullRequestActivityEvents,
    getCurrentUserKey,
    (activityEvents, userUuid) => {
      const activityEvent = activityEvents.find(
        event =>
          isChangesRequestedActivity(event) &&
          event.changes_requested.user.uuid === userUuid
      );
      if (activityEvent && isChangesRequestedActivity(activityEvent)) {
        return activityEvent.changes_requested;
      }

      return null;
    }
  );
