import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { ExternalModuleName } from '../../types';

/**
 * Get the configuration for each module to be used for setting System registry.
 *
 * !!Warning!! Set isDefault to true for modules with default exports. It won't affect modules without defaults.
 * !!Warning!! require() cannot be dynamic. It needs to be a known value at build time.
 * @param {ExternalModuleName} moduleName
 * @returns {ExternalModuleConfiguration | undefined}
 */
export var getExternalModuleConfiguration = function getExternalModuleConfiguration(moduleName) {
  try {
    switch (moduleName) {
      case ExternalModuleName.React:
        return {
          isDefault: true,
          import: require('react')
        };
      case ExternalModuleName.ReactDOM:
        return {
          isDefault: true,
          import: require('react-dom')
        };
      case ExternalModuleName.ReactIntlNext:
        return {
          isDefault: true,
          import: require('react-intl-next')
        };
      case ExternalModuleName.EmotionReact:
        return {
          isDefault: false,
          import: require('@emotion/react')
        };
      case ExternalModuleName.FeatureGateJsClientPublic:
        return {
          isDefault: false,
          import: require('@atlaskit/feature-gate-js-client')
        };
      default:
        return;
    }
  } catch (error) {
    console.error("Module [".concat(moduleName, "] is possibly not installed."));
    return;
  }
};

/**
 * Get system module from the list of external modules using the configuration.
 *
 * @param {ExternalModuleManifest} module
 * @returns {ExcludedSystemModule}
 */
export var getModuleToLoad = function getModuleToLoad(module) {
  if ('module' in module) {
    return module;
  }
  var fallbackModule = {
    module: module.name,
    import: undefined,
    isDefault: false
  };
  var isValidExternalModule = Object.values(ExternalModuleName).find(function (moduleName) {
    return moduleName === module.name;
  });
  if (!isValidExternalModule) {
    console.error("Excluded system module [".concat(module.name, "] must be setup inside '/remote-setup/config.ts'. Modules cannot be excluded and required dynamically."));
    return fallbackModule;
  }
  var config = getExternalModuleConfiguration(module.name);
  if (!config) {
    return fallbackModule;
  }
  var excludedSystemModule = _objectSpread({
    module: module.name
  }, config);
  return excludedSystemModule;
};

/**
 * Prepare system module to be set in System registry.
 *
 * @param {ExcludedSystemModule} entry
 * @param {string} source
 * @returns {ExcludedSystemModuleForRegistry}
 */
export var getSystemModule = function getSystemModule(entry, source) {
  if (entry.isDefault) {
    return {
      module: entry.module,
      import: _objectSpread(_objectSpread({}, entry.import), {}, {
        default: entry.import,
        __useDefault: true
      }),
      scopedModuleId: "".concat(source, ":").concat(entry.module)
    };
  }
  return {
    module: entry.module,
    import: entry.import,
    scopedModuleId: "".concat(source, ":").concat(entry.module)
  };
};

/**
 * Build each module with a scope determined by each plugin.
 *
 * @param {ExcludedSystemModuleForRegistry[]} entries
 * @returns {Record<string, string>} e.g. { react: 'app-switcher:react' }
 *
 */
export var getScopedModules = function getScopedModules(entries) {
  try {
    var scopedModules = entries.map(function (entry) {
      return entry.import && _defineProperty({}, entry.module, entry.scopedModuleId);
    });
    return Object.assign.apply(Object, [{}].concat(_toConsumableArray(scopedModules)));
  } catch (error) {
    console.error("Could not set scoped modules with error message: ".concat(error));
    return null;
  }
};