import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15;

import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { actionMargin, dangerColor, keylineHeight, modalPadding, titleMargin, warningColor } from '../constants';
// Wrapper
// ==============================
export var wrapperStyles = css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n\n  /**\n  * This ensures that the element fills the whole modal dialog space\n  * and its content does not overflow (since flex items don't\n  * shrink past its content size by default). */\n  flex: 1 1 auto;\n  min-height: 0;\n"]))); // Header
// ==============================

export var headerStyles = css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  position: relative;\n  padding: ", "px;\n  padding-bottom: ", "px;\n"])), modalPadding, modalPadding - keylineHeight); // TODO: This is a public API, so should remove during breaking change later.

export var Header = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", "\n"])), headerStyles);
export var titleStyles = css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n\n  font-size: 20px;\n  font-style: inherit;\n  font-weight: 500;\n  letter-spacing: -0.008em;\n  line-height: 1;\n\n  min-width: 0;\n  margin: 0;\n"]))); // TODO: This is a public API, so should remove during breaking change later.

export var Title = styled.h1(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", ";\n"])), titleStyles);

/** 20 = font size, 1.2 = adjusted line height.
 * When the heading is truncated (not multi-line), we adjust the
 * line height to avoid cropping the descenders. This removes
 * the extra spacing that we get from that adjustment.
 */
var lineHeightOffset = 20 - 20 * 1.2;
export var getTitleTextStyles = function getTitleTextStyles(isHeadingMultiline) {
  return css(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  word-wrap: break-word;\n\n  /**\n  * This ensures that the element fills the whole header space\n  * and its content does not overflow (since flex items don't\n  * shrink past its content size by default). */\n  flex: 1 1 auto;\n  min-width: 0;\n\n  ", ";\n"])), isHeadingMultiline !== true && css(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n    line-height: 1.2;\n    margin-top: ", "px;\n    margin-bottom: ", "px;\n\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  "])), lineHeightOffset / 2, lineHeightOffset / 2));
};
var iconColor = {
  danger: dangerColor,
  warning: warningColor
};
export var getTitleIconStyles = function getTitleIconStyles(_ref) {
  var appearance = _ref.appearance,
      isHeadingMultiline = _ref.isHeadingMultiline;
  return css(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  flex: 0 0 auto; // Keeps the size of the icon the same, in case the text element grows in width.\n  color: ", ";\n  margin-right: ", "px;\n\n  ", ";\n"])), iconColor[appearance], titleMargin, isHeadingMultiline !== true && css(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n    line-height: 1.2;\n    margin-bottom: ", "px;\n  "])), lineHeightOffset / 2));
}; // Body
// ==============================

/**
 * Adding the padding here avoids cropping the keyline on its sides.
 * The combined vertical spacing is maintained by subtracting the
 * keyline height from header and footer.
 */
export var bodyStyles = css(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  flex: 1 1 auto; // Ensures the body fills the whole space between header and footer.\n  padding: ", "px ", "px;\n"])), keylineHeight, modalPadding); // TODO: This is a public API, so should remove during breaking change later.

export var Body = styled.div(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  ", ";\n"])), bodyStyles); // Footer
// ==============================

var baseFooterStyles = css(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n\n  position: relative;\n  padding: ", "px;\n  padding-top: ", "px;\n\n  & [data-ds--modal-dialog--action] {\n    margin-left: ", "px;\n  }\n"])), modalPadding, modalPadding - keylineHeight, actionMargin);
/** This is the styles we use in our public Footer component,
 *  used when users build their own custom footer. */

var externalFooterStyles = css(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  ", "\n\n  /**\n   * Some of our users rely on this behavior\n   * to put actions on the left-hand side.\n   * e.g. https://atlaskit.atlassian.com/examples/editor/editor-core/full-page-with-x-extensions\n   * (Click '+' -> 'View more' to see the element browser in a modal).\n   */\n  justify-content: space-between;\n"])), baseFooterStyles);
/** This is the styles we use in our internal Footer component,
 *  used when users opt into our default footer + actions API. */

export var internalFooterStyles = css(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n  ", "\n\n  /**\n  * When we're /not/ using a custom footer, we place\n  * all actions on the right-hand side by default.\n  */\n  justify-content: flex-end;\n"])), baseFooterStyles); // TODO: This is a public API, so should remove during breaking change later.

export var Footer = styled.div(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n  ", ";\n"])), externalFooterStyles);