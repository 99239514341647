import { useSelector } from 'react-redux';

import { getFeature, getFeatures } from 'src/selectors/feature-selectors';

/*
 * This exposes hooks for using django features. There is also a
 * useFeature hook that is being worked on to expose LaunchDarkly
 * feature flags.
 */

export function useDjangoFeatures(...keys: string[]) {
  const features = useSelector(getFeatures);
  return keys.map(key => !!features[key]);
}

export const useDjangoFeature = (key: string) => useSelector(getFeature(key));
