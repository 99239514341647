'use strict';

function getText(rootChild) {
  var res = '';
  var rr = function rr(child) {
    if (typeof child === 'string' || typeof child === 'number') {
      res += child;
    } else if (Array.isArray(child)) {
      child.forEach(function (c) {
        return rr(c);
      });
    } else if (child && child.props) {
      var children = child.props.children;


      if (Array.isArray(children)) {
        children.forEach(function (c) {
          return rr(c);
        });
      } else {
        rr(children);
      }
    }
  };

  rr(rootChild);

  return res;
}

module.exports = getText;