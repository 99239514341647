import { simpleHash } from '@atlaskit/frontend-utilities/simple-hash';
/**
 * We generate a hash from a specified name, and optional list of dependencies
 * to be used for a computed filename.
 *
 * This is done so that a specific bundle can be hashed according to specific dependencies so that when they
 * are updated. We deliver a specific file for that combination.
 *
 * @param name
 * @param dependencyMap
 */
var generateHash = function generateHash(name) {
  var dependencyMap = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var stringToHash = name + JSON.stringify(dependencyMap.sort(function (a, b) {
    return (a.name + a.version).localeCompare(b.name + b.version);
  }));
  return simpleHash(stringToHash);
};
export default generateHash;