import React, { ComponentType, useEffect } from 'react';

import { shallowEqual, useSelector } from 'react-redux';

import { BucketState } from 'src/types/state';

import {
  PageLoadMetricOptions,
  sendPageLoadMetric,
} from './send-page-load-metric';

const displayName = (c: ComponentType) =>
  c.displayName || c.name || 'Component';

type FeatureFlagMap = { [flagName: string]: boolean };

type Options = {
  customEndEvent?: string;
  customStartEvent?: keyof PerformanceTiming;
  featureFlagSelectors?: Array<{
    name: string;
    selector: (initialState: BucketState) => boolean;
  }>;
};

// This exists separately to allow us to use the hook in contexts outside of the redux provider (e.g., <App />)
export const useStatsdMeasuredRender = (
  metricName: string,
  options: PageLoadMetricOptions = {}
) => {
  useEffect(() => {
    sendPageLoadMetric(metricName, options);
    // `sendPageLoadMetric` is only meant to be called on the initial mount of the component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const useStatsdMeasuredRenderWithFeatureFlags = (
  metricName: string,
  options: Options = {}
) => {
  const {
    customEndEvent,
    customStartEvent,
    featureFlagSelectors = [],
  } = options;

  // Using `shallowEqual` here is *very* important, otherwise this will force
  // the consuming component (usually page-level) to re-render on every change
  // to the redux store
  const featureFlagMap = useSelector<BucketState, FeatureFlagMap>(
    state =>
      featureFlagSelectors.reduce(
        (acc, { name, selector }) => ({
          ...acc,
          ...{ [name]: selector(state) },
        }),
        {}
      ),
    shallowEqual
  );

  const tags = Object.keys(featureFlagMap).map(
    flagName => `${flagName}:${featureFlagMap[flagName]}`
  );

  useStatsdMeasuredRender(metricName, {
    customEndEvent,
    customStartEvent,
    tags,
  });
};

export const withStatsd =
  (metricName: string, options: Options = {}) =>
  <P extends object>(Comp: ComponentType<P>): React.FC<P> => {
    function WithStatsd(props: P) {
      useStatsdMeasuredRenderWithFeatureFlags(metricName, options);
      return <Comp {...props} />;
    }
    WithStatsd.displayName = `withStatsd(${displayName(Comp)})`;
    WithStatsd.WrappedComponent = Comp;
    return WithStatsd;
  };
