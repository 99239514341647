import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @repo/internal/fs/filename-pattern-match
import { useEffect, useState } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import PersonIcon from '@atlaskit/icon/core/person';
import ReleaseIcon from '@atlaskit/icon/core/release';
import PersonIconLegacy from '@atlaskit/icon/glyph/person';
import ShipIcon from '@atlaskit/icon/glyph/ship';
import { fg } from '@atlaskit/platform-feature-flags';
import { N0, N90 } from '@atlaskit/theme/colors';
import { AVATAR_RADIUS, AVATAR_SIZES } from './constants';
var avatarDefaultIconStyles = css({
  display: 'block',
  width: '100%',
  height: '100%',
  backgroundColor: "var(--ds-icon-subtle, ".concat(N90, ")")
});
var avatarDefaultIconVisualRefreshStyles = css({
  display: 'block',
  width: '100%',
  height: '100%',
  backgroundColor: "var(--ds-background-accent-gray-subtler, #DCDFE4)"
});
var nestedAvatarStyles = Object.entries(AVATAR_SIZES).reduce(function (styles, _ref) {
  var _ref2 = _slicedToArray(_ref, 2),
    key = _ref2[0],
    size = _ref2[1];
  return _objectSpread(_objectSpread({}, styles), {}, _defineProperty({}, key, css({
    // eslint-disable-next-line @atlaskit/design-system/no-nested-styles, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
    '& svg': {
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      width: "".concat(size, "px"),
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      height: "".concat(size, "px")
    }
  })));
}, {});
var avatarImageStyles = css({
  display: 'flex',
  width: '100%',
  height: '100%',
  flex: '1 1 100%'
});

/**
 * __Avatar image__
 *
 * An avatar image is an internal component used to control the rendering phases of an image.
 */
var AvatarImage = function AvatarImage(_ref3) {
  var _ref3$alt = _ref3.alt,
    alt = _ref3$alt === void 0 ? '' : _ref3$alt,
    src = _ref3.src,
    appearance = _ref3.appearance,
    size = _ref3.size,
    testId = _ref3.testId;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    hasImageErrored = _useState2[0],
    setHasImageErrored = _useState2[1];
  var borderRadius = appearance === 'circle' ? '50%' : "".concat(AVATAR_RADIUS[size], "px");
  var isHidden = !alt ? true : undefined;

  // If src changes, reset state
  useEffect(function () {
    setHasImageErrored(false);
  }, [src]);
  if (!src || hasImageErrored) {
    return jsx("span", {
      css: [fg('platform-component-visual-refresh') ? avatarDefaultIconVisualRefreshStyles : avatarDefaultIconStyles, nestedAvatarStyles[size]]
    }, appearance === 'circle' ? fg('platform-component-visual-refresh') ? jsx(PersonIcon, {
      label: alt,
      color: "var(--ds-icon-subtle, #626F86)",
      testId: testId && "".concat(testId, "--person"),
      spacing: "spacious"
    }) : jsx(PersonIconLegacy, {
      label: alt,
      primaryColor: "var(--ds-icon-inverse, ".concat(N0, ")"),
      secondaryColor: "var(--ds-icon-subtle, ".concat(N90, ")"),
      testId: testId && "".concat(testId, "--person"),
      "aria-hidden": isHidden
    }) : fg('platform-component-visual-refresh') ? jsx(ReleaseIcon, {
      label: alt,
      color: "var(--ds-icon-subtle, #626F86)",
      testId: testId && "".concat(testId, "--ship"),
      spacing: "spacious"
    }) : jsx(ShipIcon, {
      label: alt,
      primaryColor: "var(--ds-icon-inverse, ".concat(N0, ")"),
      secondaryColor: "var(--ds-icon-subtle, ".concat(N90, ")"),
      testId: testId && "".concat(testId, "--ship"),
      "aria-hidden": isHidden
    }));
  }
  return jsx("img", {
    src: src,
    alt: alt,
    "data-testid": testId && "".concat(testId, "--image"),
    css: avatarImageStyles,
    style: {
      // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
      borderRadius: borderRadius
    },
    onError: function onError() {
      return setHasImageErrored(true);
    },
    "aria-hidden": isHidden,
    "data-vc": testId ? "".concat(testId, "--image") : 'avatar-image'
  });
};
export default AvatarImage;