import React from 'react';

import { FormattedMessage, injectIntl, InjectedIntl } from 'react-intl';

import urls from 'src/urls/global';

import globalErrorImageUrl from './global-error-image.svg';
import messages from './global-error.i18n';
import * as styles from './global-error.style';

type Props = {
  intl: InjectedIntl;
  sentryEventId?: string;
};

export const GlobalError = injectIntl(({ intl, sentryEventId }: Props) => (
  <>
    <styles.Center>
      <styles.ErrorImage>
        <img
          alt={intl.formatMessage(messages.alertSymbol)}
          src={globalErrorImageUrl}
        />
      </styles.ErrorImage>

      <FormattedMessage {...messages.notice} tagName="h3" />

      <FormattedMessage
        {...messages.keepsHappening}
        values={{
          statusPageLink: (
            <a href={urls.external.statusPage} target="_blank">
              bitbucket.status.atlassian.com
            </a>
          ),
        }}
        tagName="p"
      />

      {sentryEventId && (
        <>
          <p>
            <code>{sentryEventId}</code>
          </p>
        </>
      )}

      <FormattedMessage {...messages.contactSupport} tagName="p" />
    </styles.Center>
  </>
));

export const GlobalErrorPage: React.FC<{ sentryEventId?: string }> = props => {
  return (
    <styles.GlobalErrorPageWrapper>
      <GlobalError sentryEventId={props.sentryEventId || undefined} />
    </styles.GlobalErrorPageWrapper>
  );
};
