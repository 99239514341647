import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function getCurrentLoggingLevel() {
  return window.choreographerLoggingLevel;
}
function logMessageIfValidLoggingLevel(loggingLevel, data) {
  if (getCurrentLoggingLevel() === loggingLevel) {
    // eslint-disable-next-line no-console
    console.table(data);
  }
}
function traceMessageIfValidLoggingLevel(loggingLevel, productId, messageId, message) {
  if (getCurrentLoggingLevel() === loggingLevel) {
    // eslint-disable-next-line no-console
    console.log(`CHOREOGRAPHER - ${productId} - ${messageId} - ${message}`);
  }
}
export class DebugLogger {
  constructor(productId, messageId) {
    /**
     * A function that takes an object of properties for each logging level
     * name, e.g. log.info and log.verbose, which are functions that take an
     * object of any key/value pairs, which will be merged with the productId
     * and the messageId values passed to the constructor, and output via
     * console.table.
     *
     * Example: debugLogger.log({
     *   info: { foo: true },
     *   verbose: {
     *     foo: true,
     *     bar: 123,
     *     baz 'abc',
     *     timestamp: performance.now()
     *   },
     * });
     *
     * @param messages Record<LoggingLevels, Record<string, unknown>> An object
     * keyed by the desired log-level, with each value being an object of any
     * key/value pairs to be output to console.table.
     */
    _defineProperty(this, "log", messages => {
      [...Object.keys(messages)].forEach(loggingLevel => {
        var _messages;
        logMessageIfValidLoggingLevel(loggingLevel, _objectSpread(_objectSpread({
          productId: this.state.productId,
          messageId: this.state.messageId
        }, (_messages = messages[loggingLevel]) !== null && _messages !== void 0 ? _messages : {}), loggingLevel === 'verbose' ? {
          timestamp: performance.now()
        } : {}));
      });
    });
    /**
     * A function that takes an object of properties for each logging level
     * name, e.g. trace.info and trace.verbose, which are functions that take
     * a string to be used as the label for a stack trace via console.trace.
     *
     * Example: debugLogger.trace({
     *   info: 'This is a stack trace',
     *   verbose: `This is a stack trace with a much more verbose message which
     * contains data about the ${productId} and ${messageId} within my function,
     * invoked at ${performance.now()}ms`,
     * });
     *
     * @param messages Record<LoggingLevels, string> An object keyed by the
     * desired log-level, with each value being a string to be output as the
     * label to console.trace.
     */
    _defineProperty(this, "trace", messages => {
      [...Object.keys(messages)].forEach(loggingLevel => {
        traceMessageIfValidLoggingLevel(loggingLevel, this.state.productId, this.state.messageId, `${messages[loggingLevel]}${loggingLevel === 'verbose' ? ` (${performance.now()})` : ''}`);
      });
    });
    this.state = {
      productId,
      messageId
    };
  }
}