import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useEffect, useMemo } from 'react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { STABLE_EMPTY_OBJECT } from '../../common/utils';
import AtlassianSystemRemoteFrontendSdk from '../remote-sdk';
import useRemoteModuleLoader from '../use-remote-module-loader';
var preloadedModuleMap = new WeakMap();

/**
 * Create an SDK instance for a given plugin aand cache it in the WeakMap so that if a plugin is no longer referenced
 * we clean up the sdk from the cache
 *
 * @param remotePlugin
 */
var cacheAndGetSdkForPlugin = function cacheAndGetSdkForPlugin(remotePlugin) {
  var cachedSdk = preloadedModuleMap.get(remotePlugin);
  if (cachedSdk) {
    return cachedSdk;
  }
  var sdk = new AtlassianSystemRemoteFrontendSdk(remotePlugin);
  preloadedModuleMap.set(remotePlugin, sdk);
  return sdk;
};

/**
 * Preload a plugin by instantiating it and firing off the request for the module in the registry
 *
 * @param remotePlugin
 * @param options
 */
var useRemoteModule = function useRemoteModule(remotePlugin) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : STABLE_EMPTY_OBJECT;
  var remoteFrontendSdk = cacheAndGetSdkForPlugin(remotePlugin);
  var experience = remoteFrontendSdk.renderUfoExperience;
  var moduleName = remoteFrontendSdk.getModuleName();
  var optionsWithError = useMemo(function () {
    var onError = function onError(error) {
      var _options$onError;
      void experience.failure({
        metadata: {
          moduleName: moduleName
        }
      });
      options === null || options === void 0 || (_options$onError = options.onError) === null || _options$onError === void 0 || _options$onError.call(options, error);
    };
    return _objectSpread(_objectSpread({}, options), {}, {
      onError: onError
    });
  }, [experience, moduleName, options]);
  useRemoteModuleLoader(remoteFrontendSdk, optionsWithError);
  var _useAnalyticsEvents = useAnalyticsEvents(),
    createAnalyticsEvent = _useAnalyticsEvents.createAnalyticsEvent;
  useEffect(function () {
    experience.start();
    var analyticsEvent = createAnalyticsEvent({
      action: 'rendered',
      actionSubject: 'remoteModule',
      actionSubjectId: 'useRemoteModule',
      attributes: {
        dimensions: remotePlugin.dimensions,
        plugin: remotePlugin.constructor.name
      }
    });
    analyticsEvent.fire(options === null || options === void 0 ? void 0 : options.analyticsChannel);
  }, [createAnalyticsEvent, remotePlugin, options.analyticsChannel, experience]);
  return remoteFrontendSdk;
};
export default useRemoteModule;