// Prevent a focus ring if clicked
export var onMouseDownBlur = function onMouseDownBlur(e) {
  var currentTarget = e.currentTarget;
  var focusedDuringMouseDown = currentTarget === document.activeElement;
  requestAnimationFrame(function () {
    if (focusedDuringMouseDown && currentTarget !== document.activeElement && document.body.contains(currentTarget)) {
      currentTarget.focus();
      return;
    }
    if (!focusedDuringMouseDown && document.body.contains(currentTarget)) {
      currentTarget.blur();
    }
  });
};