import * as Sentry from '@sentry/browser';
import { createResource } from 'react-resource-router';

import { FeatureKeys } from '@atlassian/bitbucket-features';

import { ResourceContext } from 'src/router/types';
import { getFeatures } from 'src/selectors/feature-selectors';
import { createHttpRequest } from 'src/utils/http-request';

import { ForgeMergeCheckResult } from './types';
import { urls } from './urls';

export const loadForgeMergeChecksResultsResource = createResource({
  type: 'pull-request-forge-merge-checks-results',
  getKey: ({ match: { params } }) => {
    const { pullRequestId, repositoryOwner, repositorySlug } = params;
    return `${repositoryOwner}/${repositorySlug}/${pullRequestId}`;
  },
  // we need to ensure the value is cached, otherwise getData resets to null upon every refresh call which causes the UI
  // to reset every time
  maxAge: 60 * 1000 * 1, // 1 minute in ms
  getData: async ({ match: { params } }, { reduxStore }: ResourceContext) => {
    const { pullRequestId, repositoryOwner, repositorySlug } = params;
    const lastPolled = Date.now();
    const emptyResults = {
      results: [],
      lastPolled,
    };

    if (!repositoryOwner || !repositorySlug || !pullRequestId) {
      throw new Error(
        'This resource must be rendered on a route that includes all :repositoryOwner, :repositorySlug and :pullRequestId'
      );
    }

    const state = reduxStore.getState();
    if (!getFeatures(state)[FeatureKeys.extensibleMergeChecksEnabled]) {
      return emptyResults;
    }

    try {
      const results = await createHttpRequest<ForgeMergeCheckResult[]>('GET', {
        throwErrorType: 'errorObject',
      })(urls.results(repositoryOwner, repositorySlug, pullRequestId));

      return {
        results,
        lastPolled,
      };
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
});
