import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _toArray from "@babel/runtime/helpers/toArray";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/** @jsx jsx */
import { Children, Fragment, useCallback, useRef, useState } from 'react';
import { css, jsx } from '@emotion/react';
import { usePlatformLeafEventHandler } from '@atlaskit/analytics-next/usePlatformLeafEventHandler';
import { TabListContext, TabPanelContext } from '../internal/context';
import { getTabsStyles } from '../internal/styles';
import { onMouseDownBlur } from '../internal/utils';
var baseStyles = css({
  display: 'flex',
  maxWidth: '100%',
  minHeight: '0%',
  flexBasis: '100%',
  flexDirection: 'column',
  flexGrow: 1
});

// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
var tabsStyles = getTabsStyles();
var analyticsAttributes = {
  componentName: 'tabs',
  packageName: "@atlaskit/tabs",
  packageVersion: "14.1.0"
};
var getTabPanelWithContext = function getTabPanelWithContext(_ref) {
  var tabPanel = _ref.tabPanel,
    index = _ref.index,
    isSelected = _ref.isSelected,
    tabsId = _ref.tabsId;
  return (
    // Ensure tabPanel exists in case it has been removed
    tabPanel && jsx(TabPanelContext.Provider, {
      value: {
        role: 'tabpanel',
        id: "".concat(tabsId, "-").concat(index, "-tab"),
        hidden: isSelected ? undefined : true,
        'aria-labelledby': "".concat(tabsId, "-").concat(index),
        onMouseDown: onMouseDownBlur,
        tabIndex: isSelected ? 0 : -1
      },
      key: index
    }, tabPanel)
  );
};

/**
 * __Tabs__
 *
 * Tabs acts as a container for all Tab components.
 *
 * - [Examples](https://atlassian.design/components/tabs/examples)
 * - [Code](https://atlassian.design/components/tabs/code)
 * - [Usage](https://atlassian.design/components/tabs/usage)
 */
var Tabs = function Tabs(props) {
  var _props$shouldUnmountT = props.shouldUnmountTabPanelOnChange,
    shouldUnmountTabPanelOnChange = _props$shouldUnmountT === void 0 ? false : _props$shouldUnmountT,
    SelectedType = props.selected,
    defaultSelected = props.defaultSelected,
    onChangeProp = props.onChange,
    id = props.id,
    analyticsContext = props.analyticsContext,
    children = props.children,
    testId = props.testId;
  var _useState = useState(SelectedType || defaultSelected || 0),
    _useState2 = _slicedToArray(_useState, 2),
    selectedState = _useState2[0],
    setSelected = _useState2[1];
  var selected = SelectedType === undefined ? selectedState : SelectedType;
  var childrenArray = Children.toArray(children)
  // Don't include any conditional children
  .filter(function (child) {
    return Boolean(child);
  });
  // First child should be a tabList followed by tab panels
  var _childrenArray = _toArray(childrenArray),
    tabList = _childrenArray[0],
    tabPanels = _childrenArray.slice(1);

  // Keep track of visited and add to a set
  var visited = useRef(new Set([selected]));
  if (!visited.current.has(selected)) {
    visited.current.add(selected);
  }
  var onChange = useCallback(function (index, analyticsEvent) {
    if (onChangeProp) {
      onChangeProp(index, analyticsEvent);
    }
    setSelected(index);
  }, [onChangeProp]);
  var onChangeAnalytics = usePlatformLeafEventHandler(_objectSpread({
    fn: onChange,
    action: 'clicked',
    analyticsData: analyticsContext
  }, analyticsAttributes));
  var tabPanelsWithContext = shouldUnmountTabPanelOnChange ? getTabPanelWithContext({
    tabPanel: tabPanels[selected],
    index: selected,
    isSelected: true,
    tabsId: id
  }) :
  // If a panel has already been visited, don't unmount it
  Array.from(visited.current).map(function (tabIndex) {
    return getTabPanelWithContext({
      tabPanel: tabPanels[tabIndex],
      index: tabIndex,
      isSelected: tabIndex === selected,
      tabsId: id
    });
  });
  return (
    // Only styles that affect the Tabs container itself have been applied via primitives.
    // The other styles applied through the CSS prop are there for styling children
    // through inheritance. This is important for custom cases that use the useTabPanel(),
    // which applies accessibility attributes that we use as a styling hook.
    jsx("div", {
      "data-testid": testId
      // eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
      ,
      css: [baseStyles, tabsStyles]
    }, jsx(TabListContext.Provider, {
      value: {
        selected: selected,
        onChange: onChangeAnalytics,
        tabsId: id
      }
    }, tabList), jsx(Fragment, null, tabPanelsWithContext))
  );
};
export default Tabs;