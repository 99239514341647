function abort(event) {
  event.preventDefault();
  event.stopPropagation();
}
var tabKeyCode = 9;
function onKey(event) {
  // Allowing tab so that a user can move focus away
  if (event.keyCode === tabKeyCode) {
    return;
  }
  abort(event);
}
export default function blockEvents(shouldBlockEvents, events) {
  return shouldBlockEvents ? {
    onMouseDownCapture: abort,
    onMouseUpCapture: abort,
    onKeyDownCapture: onKey,
    onKeyUpCapture: onKey,
    onTouchStartCapture: abort,
    onTouchEndCapture: abort,
    onPointerDownCapture: abort,
    onPointerUpCapture: abort,
    onClickCapture: abort,
    // Just smashing the existing onClick for good measure
    onClick: abort
  } : events;
}