import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { N40 } from '@atlaskit/theme/colors';
import { gridSize as gridSizeFn } from '@atlaskit/theme/constants';
var gridSize = gridSizeFn(); // These are the styles which are consistent regardless of theme

var baseStyles = {
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    height: "".concat(gridSize * 5, "px"),
    paddingLeft: "".concat(gridSize * 1.5, "px"),
    paddingRight: "".concat(gridSize * 1.5, "px"),
    opacity: 0.5
  },
  before: {
    backgroundColor: 'red',
    borderRadius: '50%',
    flexShrink: 0,
    height: "".concat(gridSize * 3, "px"),
    marginRight: "".concat(gridSize * 2, "px"),
    width: "".concat(gridSize * 3, "px")
  },
  content: {
    borderRadius: "".concat(gridSize / 2, "px"),
    flexGrow: 1,
    height: "".concat(gridSize * 2.5, "px")
  }
}; // Light theme

export default (function (_ref) {
  var product = _ref.product;
  return function () {
    return {
      container: {
        wrapper: baseStyles.wrapper,
        before: _objectSpread(_objectSpread({}, baseStyles.before), {}, {
          backgroundColor: N40
        }),
        content: _objectSpread(_objectSpread({}, baseStyles.content), {}, {
          backgroundColor: N40
        })
      },
      product: {
        wrapper: baseStyles.wrapper,
        before: _objectSpread(_objectSpread({}, baseStyles.before), {}, {
          backgroundColor: product.background.static
        }),
        content: _objectSpread(_objectSpread({}, baseStyles.content), {}, {
          backgroundColor: product.background.static
        })
      }
    };
  };
});