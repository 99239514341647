import { createHttpRequest } from 'src/utils/http-request';

import {
  CustomMergeChecksConfigApi,
  BranchConfigsResponse,
  CustomMergeChecksConfigurationResponse,
  CustomMergeChecksConfigureApi,
  CustomMergeCheck,
} from './types';

export const customMergeChecksConfigApi = (
  url: string
): CustomMergeChecksConfigApi => {
  return {
    getBranchConfigs: () =>
      createHttpRequest<BranchConfigsResponse>('GET')(`${url}/branch-configs`),
    getCustomMergeChecks: () =>
      createHttpRequest<CustomMergeChecksConfigurationResponse>('GET')(url),
  };
};

export const customMergeChecksConfigureApi = (
  url: string
): CustomMergeChecksConfigureApi => {
  return {
    configureCustomMergeCheck: (extensionId, branch, state) =>
      createHttpRequest<CustomMergeCheck>('PUT')(`${url}/configure`, {
        extensionId,
        branch,
        state,
      }),
  };
};
