import { ExternalModuleName } from '../types';

/**
 * If we request a `latest` dimension, but only have `stable` in our map then we use it.
 * If we request a `stable` dimension, but only have `latest` an error will be thrown due to the variant order.
 */
export var VARIANT_ORDER = ['latest', 'stable'];
export var EXTERNALS = [{
  name: ExternalModuleName.React
}, {
  name: ExternalModuleName.ReactDOM
}, {
  name: ExternalModuleName.ReactIntlNext
}, {
  name: ExternalModuleName.EmotionReact
}];