import React, { ReactNode, MouseEventHandler } from 'react';

import ApdexMeasuredLink from 'src/components/apdex-measured-link';
import { Repository, Link } from 'src/components/types';
import urls from 'src/sections/repository/urls';
import { ApdexTask } from 'src/types/apdex';

type LandingPage = {
  task: ApdexTask;
  getHref: (owner: string, slug: string) => string;
};

const LANDING_PAGES: { [K: string]: LandingPage } = {
  source: {
    task: ApdexTask.Source,
    getHref: (owner: string, slug: string) => `/${owner}/${slug}/`,
  },
  commits: {
    task: ApdexTask.Commits,
    getHref: (owner: string, slug: string) =>
      urls.ui.commits(`${owner}/${slug}`),
  },
  branches: {
    task: ApdexTask.Branches,
    getHref: urls.ui.branches,
  },
  pull_requests: {
    task: ApdexTask.PullRequests,
    getHref: urls.ui.pullRequests,
  },
};

export type RepositoryLinkData = {
  full_name: string;
  name: string;
  landing_page?: string;
  links: {
    html: Link;
  };
};

export type RepositoryLinkProps = {
  children?: ReactNode | null;
  // Allow styled-component style overrides
  className?: string;
  repository: Repository | RepositoryLinkData;
  onClick?: MouseEventHandler;
  innerRef?: React.Ref<HTMLAnchorElement>;
};

const RepositoryLink: React.FC<RepositoryLinkProps> = React.memo(
  React.forwardRef(function BaseRepositoryLink(
    props,
    ref: React.Ref<HTMLAnchorElement>
  ) {
    const { children, className = '', innerRef, repository } = props;

    // Some HOCs (like Emotion) will forward refs as `ref` while other parts of our code explicitly pass `innerRef`
    const trueRef = ref || innerRef;
    const content = children || repository.name;
    const [owner, slug] = repository.full_name.split('/');
    const { href } = repository.links.html;

    const { landing_page: landingPage } = repository;
    // If the landing page is null, source (or overview), or not in the map, redirect
    // to the source view. Otherwise, check the landing pages map and redirect to the
    // appropiate page.
    const clientLandingPage =
      landingPage && landingPage in LANDING_PAGES
        ? LANDING_PAGES[landingPage]
        : LANDING_PAGES.source;

    return clientLandingPage ? (
      <ApdexMeasuredLink
        {...props}
        to={clientLandingPage.getHref(owner, slug)}
        className={className}
        innerRef={trueRef}
        task={clientLandingPage.task}
      >
        {content}
      </ApdexMeasuredLink>
    ) : (
      <a href={href} className={className} ref={trueRef}>
        {content}
      </a>
    );
  })
);

export default RepositoryLink;
