import React, { ReactNode, MouseEventHandler } from 'react';

import { ApdexTask } from 'src/types/apdex';
import { startApdex } from 'src/utils/analytics/apdex';

import { ComponentLinkProxy as Link } from './component-link';

export type ApdexMeasuredLinkProps = {
  children?: ReactNode;
  className?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  title?: string;
  qaId?: string;
  task: ApdexTask;
  to: string;
  'aria-label'?: string;
  innerRef?: React.Ref<HTMLAnchorElement>;
  target?: '_blank' | '_self' | '_parent' | '_top' | undefined;
};

export default class ApdexMeasuredLink extends React.PureComponent<ApdexMeasuredLinkProps> {
  handleClick = (event: React.MouseEvent<HTMLAnchorElement>): void => {
    const { task, onClick } = this.props;
    startApdex({
      task,
      type: 'transition',
    });
    if (onClick) {
      onClick(event);
    }
  };

  render(): ReactNode {
    const { children, to } = this.props;

    return (
      <Link {...this.props} href={to} onClick={this.handleClick}>
        {children}
      </Link>
    );
  }
}
