import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2;

import { css } from '@emotion/core';
import { focusOutlineColor, keylineColor, keylineHeight } from '../constants';
var outline = css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  outline-offset: -1px;\n  outline-style: dotted;\n  outline-color: ", ";\n  outline-width: thin;\n"])), focusOutlineColor);

var keyline = function keyline(showKeyline) {
  return showKeyline ? "".concat(keylineHeight, "px solid ").concat(keylineColor) : 'unset';
};

export var scrollableStyles = function scrollableStyles(_ref) {
  var showTopKeyline = _ref.showTopKeyline,
      showBottomKeyline = _ref.showBottomKeyline,
      shouldScroll = _ref.shouldScroll;
  return css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  overflow-y: auto;\n  overflow-x: ", ";\n\n  border-top: ", ";\n  border-bottom: ", ";\n\n  /**\n  * We need to inherit flex styles from its parent here\n  * in case they're set because we're essentially being a proxy container\n  * between the original flex parent and its children (the modal body).\n  */\n  display: inherit;\n  flex: inherit;\n  flex-direction: inherit;\n\n  /**\n  * This margin is to match original behavior where we set extra\n  * padding on the modal body, depending on the scroll behavior,\n  * to offset the keylines.\n  *\n  * In this conversion, we always add the padding in the body, and\n  * remove the extra spacing here if scroll behavior is 'outside'.\n  *\n  * This logic needs to stay until we consolidate\n  * the scroll behaviors.\n  */\n  margin: -", "px 0px;\n\n  &:focus {\n    ", "\n  }\n\n  @supports selector(:focus-visible) {\n    &:focus {\n      outline: unset;\n    }\n\n    &:focus-visible {\n      ", "\n    }\n  }\n\n  @media (min-width: 480px) {\n    overflow-y: ", ";\n    height: unset;\n  }\n"])), shouldScroll ? 'hidden' : 'inherit', keyline(showTopKeyline), keyline(showBottomKeyline), shouldScroll ? 0 : keylineHeight, outline, outline, shouldScroll ? 'auto' : 'inherit');
};