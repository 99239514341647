import React from 'react';

import { FormattedMessage } from 'react-intl';

import Modal from 'src/components/modal-dialog-actions';
import { useIntl } from 'src/hooks/intl';

import messages from './remove-access.i18n';

export type RemoveAccessModalProps = {
  onClose: () => void;
  onRemove: () => void;
  isLoading?: boolean;
  removeMessage: React.ReactElement<FormattedMessage>;
  heading?: React.ReactNode;
};

const RemoveAccessModal: React.FC<RemoveAccessModalProps> = ({
  onClose,
  onRemove,
  isLoading,
  removeMessage,
  heading,
}) => {
  const { formatMessage } = useIntl();

  const actions = [
    {
      text: formatMessage(messages.cancel),
      onClick: onClose,
      appearance: 'subtle',
    },
    {
      text: formatMessage(messages.remove),
      onClick: onRemove,
      appearance: 'warning',
      autoFocus: true,
      isLoading,
      testId: 'remove-access-modal--remove-btn',
    },
  ];

  return (
    <Modal
      actions={actions as any}
      heading={heading || formatMessage(messages.removeAccessHeader)}
      width="small"
      onClose={onClose}
      appearance="warning"
    >
      {removeMessage}
    </Modal>
  );
};

export default RemoveAccessModal;
