import { ufowarn } from '../logger';
import { UFOGlobalEventStreamEventType } from '../types';
var MAX_EARLY_QUEUE_LENGTH = 250;
export var getGlobalEventStream = function getGlobalEventStream() {
  if (!globalThis.__UFO_GLOBAL_EVENT_STREAM__) {
    var earlyStream = function () {
      var stream = [];
      return {
        __buffer_only__: true,
        push: function push(event) {
          if (stream.length < MAX_EARLY_QUEUE_LENGTH) {
            stream.push(event);
          }
        },
        getStream: function getStream() {
          return stream;
        }
      };
    }();
    globalThis.__UFO_GLOBAL_EVENT_STREAM__ = earlyStream;
  }
  return globalThis.__UFO_GLOBAL_EVENT_STREAM__;
};
export var setGlobalEventStream = function setGlobalEventStream(eventStream) {
  var _globalThis$__UFO_GLO, _globalThis$__UFO_GLO2;
  if (globalThis.__UFO_GLOBAL_EVENT_STREAM__ && !((_globalThis$__UFO_GLO = globalThis.__UFO_GLOBAL_EVENT_STREAM__) !== null && _globalThis$__UFO_GLO !== void 0 && _globalThis$__UFO_GLO.__buffer_only__)) {
    ufowarn('Global Event Stream already initialised. Ignoring new event stream setup');
    return;
  }
  if ((_globalThis$__UFO_GLO2 = globalThis.__UFO_GLOBAL_EVENT_STREAM__) !== null && _globalThis$__UFO_GLO2 !== void 0 && _globalThis$__UFO_GLO2.__buffer_only__) {
    globalThis.__UFO_GLOBAL_EVENT_STREAM__.getStream().forEach(eventStream.push.bind(eventStream));
  }
  globalThis.__UFO_GLOBAL_EVENT_STREAM__ = eventStream;
};
export var subscribeEvent = function subscribeEvent(experienceId, callback) {
  return {
    type: UFOGlobalEventStreamEventType.SUBSCRIBE,
    payload: {
      experienceId: experienceId,
      callback: callback
    }
  };
};
export var unsubscribeEvent = function unsubscribeEvent(experienceId, callback) {
  return {
    type: UFOGlobalEventStreamEventType.UNSUBSCRIBE,
    payload: {
      experienceId: experienceId,
      callback: callback
    }
  };
};
export var experiencePayloadEvent = function experiencePayloadEvent(data) {
  return {
    type: UFOGlobalEventStreamEventType.EXPERIENCE_PAYLOAD,
    payload: data
  };
};