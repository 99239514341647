/**
 * @name placeholder_node
 */

export var placeholder = {
  inline: true,
  group: 'inline',
  selectable: false,
  marks: '',
  attrs: {
    text: {
      default: ''
    }
  },
  parseDOM: [{
    tag: 'span[data-placeholder]',
    getAttrs: function getAttrs(dom) {
      return {
        text: dom.getAttribute('data-placeholder') || placeholder.attrs.text.default
      };
    }
  }],
  toDOM: function toDOM(node) {
    var text = node.attrs.text;
    var attrs = {
      'data-placeholder': text,
      // Needs to be edtiable for mobile to not close keyboard
      contenteditable: 'true'
    };
    return ['span', attrs, text];
  }
};