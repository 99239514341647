import React from 'react';

import { FormattedMessage, injectIntl, InjectedIntl } from 'react-intl';
import { connect } from 'react-redux';

import Flag from '@atlaskit/flag';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { showNewCommits } from 'src/redux/commit-list';
import { ComponentFlagId } from 'src/redux/flags/types';
import { ApdexTask } from 'src/types/apdex';
import { startApdex } from 'src/utils/analytics/apdex';

import i18n from './i18n';

type Props = {
  id: ComponentFlagId | string;
  showNewCommits: typeof showNewCommits;
  intl: InjectedIntl;
};

export class UpdatedCommitsListFlag extends React.PureComponent<Props> {
  showNewCommits = () => {
    startApdex({
      task: ApdexTask.Commits,
      type: 'transition',
    });

    this.props.showNewCommits();
  };

  render() {
    const actions = [
      {
        content: <FormattedMessage {...i18n.showUpdatedCommits} />,
        onClick: this.showNewCommits,
      },
    ];

    return (
      <Flag
        actions={actions}
        icon={
          <InfoIcon
            primaryColor={token('color.icon.information', colors.B400)}
            label="Info"
          />
        }
        title={<FormattedMessage {...i18n.title} />}
        {...this.props}
      />
    );
  }
}

const mapDispatchToProps = {
  showNewCommits,
};

export default connect(
  null,
  mapDispatchToProps
)(injectIntl(UpdatedCommitsListFlag));
