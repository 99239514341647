import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import uuid from 'uuid/v4';
import getBatchableQueue from '../resilienceQueue';
import getMetricsCollector from '../resilienceQueue/Metrics';
import { attachXidToMultipleEvents } from '../xid';
import { DEFAULT_REQUEST_TIMEOUT } from './defaults';
import { sendEvents } from './sendEvents';
import { SegmentEventTypes } from './types';
import User from './user';
import { buildContext, prepareEventProperties } from './util';
export default class EventProcessor {
  constructor(options) {
    var _this$options;
    // Using anonymous function so it can have the BatchFlushCallback type associated with it
    // And to allow it to not need bind when passing through to ResilieceQueue.
    _defineProperty(this, "sendEvents", async (items, callback) => {
      const httpRetryCount = this.resilienceQueue.getGlobalRetryCount();
      const metricsPayload = this.metrics.getMetricsPayload();
      const metadata = _objectSpread(_objectSpread({}, metricsPayload), {}, {
        httpRetryCount
      });
      for (let key in metadata) {
        // @ts-ignore Some keys maybe a string, but these will never equal 0
        if (metadata[key] === 0) {
          // @ts-ignore Save space in requests by removing metrics with no impact
          delete metadata[key];
        }
      }
      const eventsWithXID = await this.attachXIDs(items);

      // Calculating sentAt after the XID generation as this may take some time.
      const sentAt = new Date().toISOString();
      const unpackagedEvents = eventsWithXID.map(item => {
        item.msg.sentAt = sentAt;
        return item.msg;
      });
      const batchBody = {
        batch: unpackagedEvents,
        sentAt,
        metadata
      };
      try {
        const response = await sendEvents({
          url: this.gasv3BatchUrl,
          batch: batchBody,
          timeout: this.options.requestTimeout
        });
        this.metrics.subtractFromMetrics(metricsPayload);
        callback(null, response);
      } catch (error) {
        callback(error, null);
      }
    });
    this.options = _objectSpread(_objectSpread({}, options), {}, {
      requestTimeout: options.requestTimeout || DEFAULT_REQUEST_TIMEOUT,
      retryQueueOptions: options.retryQueueOptions || {},
      logger: options.logger || console,
      disableCookiePersistence: options.disableCookiePersistence || false
    });
    this.user = new User((_this$options = this.options) === null || _this$options === void 0 ? void 0 : _this$options.disableCookiePersistence);
    this.xidPromiseCallback = options.xidPromiseGetter();
    this.gasv3BatchUrl = `${options.apiHostProtocol}://${options.apiHost}/batch`;
    this.metrics = getMetricsCollector();
    this.resilienceQueue = getBatchableQueue(options.retryQueuePrefix, options.product, this.options.retryQueueOptions, this.options.logger);
    this.resilienceQueue.start(this.sendEvents);
  }
  getUser() {
    return this.user;
  }
  async track(eventName, builtEvent, context, callback) {
    const baseEvent = this.buildBaseEvent(context, SegmentEventTypes.TRACK, builtEvent);
    const eventWithoutMessageId = _objectSpread(_objectSpread({}, baseEvent), {}, {
      type: SegmentEventTypes.TRACK,
      properties: prepareEventProperties(builtEvent),
      event: eventName
    });
    const event = _objectSpread(_objectSpread({}, eventWithoutMessageId), {}, {
      messageId: this.createMessageId()
    });
    const packagedEvent = this.packageEvent(event);
    await this.resilienceQueue.addItem(packagedEvent);
    if (callback) {
      callback();
    }
  }
  async page(eventName, builtEvent, context, callback) {
    const baseEvent = this.buildBaseEvent(context, SegmentEventTypes.PAGE, builtEvent);
    const eventWithoutMessageId = _objectSpread(_objectSpread({}, baseEvent), {}, {
      type: SegmentEventTypes.PAGE,
      properties: prepareEventProperties(builtEvent),
      name: eventName
    });
    const event = _objectSpread(_objectSpread({}, eventWithoutMessageId), {}, {
      messageId: this.createMessageId()
    });
    const packagedEvent = this.packageEvent(event);
    await this.resilienceQueue.addItem(packagedEvent);
    if (callback) {
      callback();
    }
  }

  // Segment uses the identifier to update user id which we have already done in the analyticsWebClient.ts
  async identify(_identifier, builtEvent, context, callback) {
    const baseEvent = this.buildBaseEvent(context, SegmentEventTypes.IDENTIFY, builtEvent);
    const eventWithoutMessageId = _objectSpread(_objectSpread({}, baseEvent), {}, {
      type: SegmentEventTypes.IDENTIFY,
      traits: prepareEventProperties(builtEvent)
    });
    const event = _objectSpread(_objectSpread({}, eventWithoutMessageId), {}, {
      messageId: this.createMessageId()
    });
    const packagedEvent = this.packageEvent(event);
    await this.resilienceQueue.addItem(packagedEvent);
    if (callback) {
      callback();
    }
  }
  buildBaseEvent(context, type, overrides) {
    const clonedContext = prepareEventProperties(context);
    const segmentContext = buildContext(clonedContext);
    return {
      context: segmentContext,
      timestamp: new Date().toISOString(),
      type,
      userId: this.user.getUserId(),
      anonymousId: overrides.anonymousId || this.user.getAnonymousId()
    };
  }
  createMessageId() {
    return `ajs-${uuid()}`;
  }
  packageEvent(event) {
    const {
      apiHost,
      apiHostProtocol
    } = this.options;
    return {
      headers: {
        'Content-Type': 'text/plain'
      },
      msg: event,
      url: `${apiHostProtocol}://${apiHost}/${event.type.charAt(0)}`
    };
  }
  async attachXIDs(items) {
    if (this.xidPromiseCallback) {
      return attachXidToMultipleEvents(items, this.xidPromiseCallback);
    }
    return Promise.resolve(items);
  }
}