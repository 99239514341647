import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  checksNotEnabled: {
    id: 'frontbucket.settings.merge-checks-tabs.checksNotEnabled',
    description: 'Message when no checks are enabled',
    defaultMessage:
      'Merge checks are not enabled in this workspace and requires a workspace admin to enable them in workspace settings.',
  },
  installExampleMergeCheckApps: {
    id: 'frontbucket.settings.merge-checks-tabs.installExampleMergeCheckApps',
    description: 'Message for installing example merge check apps',
    defaultMessage:
      'Looking for ideas? Install one of our {exampleAppsLink} that use custom merge checks to get started.',
  },
  exampleAppsLinkText: {
    id: 'frontbucket.settings.merge-checks-tabs.exampleAppsLinkText',
    description: 'Link to example apps',
    defaultMessage: 'example apps',
  },
});
