import { v4 as createUUID } from 'uuid';
import coinflip from '@atlassian/ufo-coinflip';
import { getInteractionRate } from '@atlassian/ufo-config';
import { getActiveTrace } from '@atlassian/ufo-experience-trace-id-context';
import { DefaultInteractionID } from '@atlassian/ufo-interaction-id-context';
import { abort, addHoldByID, addNewInteraction, getActiveInteraction, removeHoldByID, updatePageLoadInteractionName } from '@atlassian/ufo-interaction-metrics';
import UFORouteName from '@atlassian/ufo-route-name-context';
var AWAITING_PAGELOAD_NAME = 'awaiting_pageload_name';
export default function traceUFOPageLoad(ufoName) {
  var routeName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ufoName;
  var activeInteraction = getActiveInteraction();
  if (activeInteraction && !ufoName) {
    return;
  }
  UFORouteName.current = routeName || null;
  var rate = ufoName ? getInteractionRate(ufoName, 'page_load') : 1;
  var enabledBySamplingRate = coinflip(rate);
  if (enabledBySamplingRate && !activeInteraction) {
    var newId = createUUID();
    DefaultInteractionID.current = newId;
    addNewInteraction(newId, ufoName || '', 'page_load', 0, rate, null, routeName, getActiveTrace());
    if (!ufoName) {
      // if no name is provided we add a hold to the interaction, it is removed if the name is provided while the interaction is holding
      addHoldByID(newId, [], AWAITING_PAGELOAD_NAME, AWAITING_PAGELOAD_NAME, true);
    }
  } else if (!enabledBySamplingRate && activeInteraction && activeInteraction.type === 'page_load') {
    // if there is an active interaction it will be aborted without initialising a new one because the coinflip returned false
    abort(activeInteraction.id, 'excluded_by_sampling');
  } else if (ufoName && activeInteraction && !activeInteraction.ufoName && activeInteraction.type === 'page_load') {
    updatePageLoadInteractionName(ufoName, routeName);
    removeHoldByID(activeInteraction.id, AWAITING_PAGELOAD_NAME);
  }
}
export function updatePageloadName(ufoName) {
  var routeName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ufoName;
  var interaction = getActiveInteraction();
  if (!interaction || interaction.type !== 'page_load' && interaction.type !== 'transition') {
    return;
  }
  if (ufoName) {
    var rate = getInteractionRate(ufoName, 'page_load');
    updatePageLoadInteractionName(ufoName, routeName);
    if (coinflip(rate)) {
      UFORouteName.current = ufoName;
    } else {
      abort(interaction.id, 'excluded_by_sampling');
    }
  }
  removeHoldByID(interaction.id, AWAITING_PAGELOAD_NAME);
}