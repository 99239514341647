import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/**
 * @jsxRuntime classic
 */
/** @jsx jsx */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { useTheme } from '../../theme';
var buttonHeight = "var(--ds-space-400, 32px)";
var skeletonStyles = css({
  borderRadius: "var(--ds-border-radius-circle, 50%)",
  opacity: 0.15
});

// Not exported to consumers, only used in NavigationSkeleton
// eslint-disable-next-line @repo/internal/react/require-jsdoc
export var IconButtonSkeleton = function IconButtonSkeleton(_ref) {
  var className = _ref.className,
    marginLeft = _ref.marginLeft,
    marginRight = _ref.marginRight,
    size = _ref.size;
  var theme = useTheme();
  var dynamicStyles = _objectSpread({
    marginLeft: typeof marginLeft === 'number' ? marginLeft : "var(--ds-space-050, 4px)",
    marginRight: typeof marginRight === 'number' ? marginRight : 0,
    width: typeof size === 'number' ? size : buttonHeight,
    height: typeof size === 'number' ? size : buttonHeight
  }, theme.mode.skeleton);
  return jsx("div", {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
    className: className
    // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
    ,
    style: dynamicStyles,
    css: skeletonStyles
  });
};