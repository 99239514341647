import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import defaultTheme, { DEFAULT_THEME_NAME } from './default-theme';
import { generateTextColor, getBoxShadow, getContrastColor, hexToRGBA } from './theme-helpers';
var generateOpacityValue = function generateOpacityValue(color) {
  return color === '#000000' ? 0.3 : 0.6;
};

// eslint-disable-next-line @repo/internal/react/consistent-types-definitions

var generateButtonCSSStates = function generateButtonCSSStates(colors, buttonType) {
  var backgroundColor = colors.backgroundColor,
    color = colors.color,
    highlightColor = colors.highlightColor;

  // Add less opacity for white text so it is still legible.
  var opacityValue = generateOpacityValue(color);
  var isCreateButton = buttonType === 'create';
  return {
    active: {
      backgroundColor: isCreateButton ? hexToRGBA(backgroundColor, 0.65) : getContrastColor(0.3, opacityValue, backgroundColor),
      boxShadow: getBoxShadow('transparent'),
      color: color
    },
    default: {
      backgroundColor: backgroundColor,
      boxShadow: getBoxShadow('transparent'),
      color: color
    },
    focus: {
      boxShadow: 'none',
      color: color,
      backgroundColor: backgroundColor
    },
    hover: {
      backgroundColor: isCreateButton ? hexToRGBA(backgroundColor, 0.8) : getContrastColor(0.1, opacityValue, backgroundColor),
      boxShadow: getBoxShadow('transparent'),
      color: color
    },
    selected: {
      color: color,
      backgroundColor: getContrastColor(0.3, opacityValue, backgroundColor),
      borderColor: highlightColor,
      boxShadow: getBoxShadow('transparent')
    },
    selectedHover: {
      color: color,
      backgroundColor: getContrastColor(0.3, opacityValue, backgroundColor),
      borderColor: highlightColor,
      boxShadow: getBoxShadow('transparent')
    }
  };
};
var generateCreateButtonColors = function generateCreateButtonColors(themeBackground, themeHighlight) {
  return {
    backgroundColor: themeHighlight,
    color: generateTextColor(themeHighlight),
    highlightColor: themeHighlight
  };
};
export var generateTheme = function generateTheme(themeColors) {
  var backgroundColor = themeColors.backgroundColor,
    highlightColor = themeColors.highlightColor,
    name = themeColors.name;
  var color = generateTextColor(backgroundColor);
  var colors = _objectSpread(_objectSpread({}, themeColors), {}, {
    color: color
  });
  if (name === DEFAULT_THEME_NAME) {
    return defaultTheme;
  }
  return {
    mode: {
      create: generateButtonCSSStates(generateCreateButtonColors(backgroundColor, highlightColor), 'create'),
      iconButton: generateButtonCSSStates(colors, 'iconButton'),
      primaryButton: generateButtonCSSStates(colors, 'primaryButton'),
      navigation: {
        backgroundColor: backgroundColor,
        color: color
      },
      productHome: {
        color: color,
        backgroundColor: highlightColor,
        borderRight: "1px solid ".concat(hexToRGBA(color, 0.5))
      },
      search: {
        default: {
          backgroundColor: backgroundColor,
          color: color,
          borderColor: hexToRGBA(color, 0.5)
        },
        focus: {
          borderColor: hexToRGBA(highlightColor, 0.8),
          boxShadow: getBoxShadow(hexToRGBA(highlightColor, 0.5))
        },
        hover: {
          backgroundColor: getContrastColor(0.1, generateOpacityValue(color), backgroundColor),
          color: color
        }
      },
      skeleton: {
        backgroundColor: color,
        opacity: 0.08
      }
    }
  };
};
export default generateTheme;