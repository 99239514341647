import { PipelineDefinition } from 'src/components/pipelines/models';
import { Action } from 'src/types/state';
import createReducer from 'src/utils/create-reducer';

import {
  REQUEST_PIPELINE_DEFINITIONS,
  SET_PIPELINE_DEFINITIONS,
} from '../actions/pipelines';

export type PipelineDefinitionsState = PipelineDefinition[];

export const initialState: PipelineDefinitionsState = [];

const reducePipelineDefintions = (
  state: PipelineDefinitionsState,
  action: Action<{ values: any[] }>
) => {
  if (!action.payload?.values) {
    return state;
  }
  return action.payload?.values?.map(data => new PipelineDefinition(data));
};
export default createReducer([], {
  [REQUEST_PIPELINE_DEFINITIONS.SUCCESS]: reducePipelineDefintions,
  [SET_PIPELINE_DEFINITIONS]: reducePipelineDefintions,
});
