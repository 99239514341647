import { uuid } from '../../utils/uuid';

/**
 * @name status_node
 */

export var status = {
  inline: true,
  group: 'inline',
  selectable: true,
  attrs: {
    text: {
      default: ''
    },
    color: {
      default: ''
    },
    localId: {
      default: uuid.generate()
    },
    style: {
      default: ''
    }
  },
  parseDOM: [{
    tag: 'span[data-node-type="status"]',
    getAttrs: function getAttrs(domNode) {
      var dom = domNode;
      return {
        text: dom.textContent.replace(/\n/, '').trim(),
        color: dom.getAttribute('data-color'),
        localId: uuid.generate(),
        style: dom.getAttribute('data-style')
      };
    }
  }],
  toDOM: function toDOM(node) {
    var _node$attrs = node.attrs,
      text = _node$attrs.text,
      color = _node$attrs.color,
      localId = _node$attrs.localId,
      style = _node$attrs.style;
    var attrs = {
      'data-node-type': 'status',
      'data-color': color,
      'data-local-id': localId,
      'data-style': style,
      contenteditable: 'false'
    };
    return ['span', attrs, text];
  }
};