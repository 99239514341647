import _extends from "@babel/runtime/helpers/extends";
import _typeof from "@babel/runtime/helpers/typeof";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

/** @jsx jsx */
import { useCallback, useMemo } from 'react';
import { jsx } from '@emotion/core';
import FocusLock from 'react-focus-lock';
import { useUID } from 'react-uid';
import { usePlatformLeafEventHandler } from '@atlaskit/analytics-next';
import Blanket from '@atlaskit/blanket';
import mergeRefs from '@atlaskit/ds-lib/merge-refs';
import useAutoFocus from '@atlaskit/ds-lib/use-auto-focus';
import useCloseOnEscapePress from '@atlaskit/ds-lib/use-close-on-escape-press';
import FadeIn from '@atlaskit/motion/fade-in';
import useOnMotionFinish from '../hooks/use-on-motion-finish';
import usePreventProgrammaticScroll from '../hooks/use-prevent-programmatic-scroll';
import { getDialogStyles, getFillScreenStyles } from '../styles/modal';
import Content from './content';
import Positioner from './positioner';

function ModalDialogInner(props) {
  var actions = props.actions,
      appearance = props.appearance,
      autoFocus = props.autoFocus,
      body = props.body,
      children = props.children,
      components = props.components,
      footer = props.footer,
      header = props.header,
      height = props.height,
      isBlanketHidden = props.isBlanketHidden,
      isChromeless = props.isChromeless,
      isHeadingMultiline = props.isHeadingMultiline,
      onClose = props.onClose,
      onCloseComplete = props.onCloseComplete,
      onOpenComplete = props.onOpenComplete,
      shouldCloseOnEscapePress = props.shouldCloseOnEscapePress,
      shouldCloseOnOverlayClick = props.shouldCloseOnOverlayClick,
      stackIndex = props.stackIndex,
      heading = props.heading,
      width = props.width,
      scrollBehavior = props.scrollBehavior,
      testId = props.testId;
  var id = useUID();
  var scrollDistance = usePreventProgrammaticScroll();
  var isForeground = stackIndex === 0;
  var onCloseHandler = usePlatformLeafEventHandler({
    fn: onClose,
    action: 'closed',
    componentName: 'modalDialog',
    packageName: "@atlaskit/modal-dialog",
    packageVersion: "11.7.4"
  });
  useCloseOnEscapePress({
    onClose: onCloseHandler,
    isDisabled: !shouldCloseOnEscapePress || !isForeground
  });

  var _useOnMotionFinish = useOnMotionFinish({
    onOpenComplete: onOpenComplete,
    onCloseComplete: onCloseComplete
  }),
      _useOnMotionFinish2 = _slicedToArray(_useOnMotionFinish, 2),
      motionRef = _useOnMotionFinish2[0],
      onMotionFinish = _useOnMotionFinish2[1];

  var onBlanketClicked = useCallback(function (e) {
    if (shouldCloseOnOverlayClick) {
      onCloseHandler(e);
    }
  }, [shouldCloseOnOverlayClick, onCloseHandler]);
  useAutoFocus(_typeof(autoFocus) === 'object' ? autoFocus : undefined, // When a user supplies  a ref to focus we enable this hook
  _typeof(autoFocus) === 'object');
  var fillScreenStyles = useMemo(function () {
    return getFillScreenStyles(scrollDistance);
  }, [scrollDistance]);
  var dialogStyles = useMemo(function () {
    return getDialogStyles({
      isChromeless: isChromeless,
      height: height,
      width: width
    });
  }, [isChromeless, height, width]);
  return jsx(FadeIn, null, function (fadeInProps) {
    return jsx("div", _extends({}, fadeInProps, {
      css: fillScreenStyles,
      "aria-hidden": !isForeground
    }), jsx(FocusLock, {
      autoFocus: // When a user supplies a ref to focus we skip focusing automatically
      typeof autoFocus === 'boolean' ? autoFocus : false,
      disabled: !isForeground,
      returnFocus: true
    }, jsx(Blanket, {
      isTinted: !isBlanketHidden,
      onBlanketClicked: onBlanketClicked,
      testId: testId && "".concat(testId, "--blanket")
    }), jsx(Positioner, {
      scrollBehavior: scrollBehavior,
      stackIndex: stackIndex,
      testId: testId
    }, jsx(FadeIn, {
      entranceDirection: "bottom",
      onFinish: onMotionFinish
    }, function (bottomFadeInProps) {
      return jsx("section", _extends({}, bottomFadeInProps, {
        ref: mergeRefs([bottomFadeInProps.ref, motionRef]),
        css: dialogStyles,
        role: "dialog",
        "aria-labelledby": "dialog-heading-".concat(id),
        "data-testid": testId,
        tabIndex: -1,
        "aria-modal": true
      }), jsx(Content, {
        actions: actions,
        appearance: appearance,
        components: components,
        header: header,
        body: body,
        footer: footer,
        heading: heading,
        headingId: "dialog-heading-".concat(id),
        testId: testId && "".concat(testId, "-dialog-content"),
        isChromeless: isChromeless,
        isHeadingMultiline: isHeadingMultiline,
        onClose: onCloseHandler,
        stackIndex: stackIndex,
        shouldScroll: scrollBehavior === 'inside' || scrollBehavior === 'inside-wide'
      }, children));
    }))));
  });
}

export default ModalDialogInner;