import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useMemo, useState } from 'react';
import { STABLE_EMPTY_OBJECT } from '../../common/utils';
import { getUfoExperience } from './utils';
/**
 * This hook is responsible for fetching the module from it's remote address.
 * Note it won't reload if it is already in the registry.
 *
 * @param remoteFrontendSdk
 * @param options
 */
var useRemoteModuleLoader = function useRemoteModuleLoader(remoteFrontendSdk) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : STABLE_EMPTY_OBJECT;
  var _useState = useState({
      status: 'initial'
    }),
    _useState2 = _slicedToArray(_useState, 2),
    state = _useState2[0],
    setStatus = _useState2[1];
  var status = state.status,
    error = state.error;
  var onError = options.onError,
    onEvent = options.onEvent;
  var moduleName = remoteFrontendSdk.getModuleName();
  var experience = useMemo(function () {
    return getUfoExperience(moduleName, 'fetch');
  }, [moduleName]);
  useEffect(function () {
    return function () {
      void experience.abort({
        metadata: {
          moduleName: moduleName
        }
      });
    };
  }, [experience, moduleName]);
  useEffect(function () {
    if (status === 'error' && typeof onError === 'function') {
      onError(error);
    }
  }, [error, status, onError]);
  useEffect(function () {
    // we don't want to re-run the experience if the module is already in the registry and has been loaded
    var isModuleInRegistry = remoteFrontendSdk.isModuleRegisteredAndValid();
    if (isModuleInRegistry && status !== 'done') {
      setStatus({
        status: 'done'
      });
    } else if (status === 'initial') {
      void experience.start();
      setStatus({
        status: 'loading'
      });
      remoteFrontendSdk.fetchModule().then(function () {
        onEvent === null || onEvent === void 0 || onEvent({
          type: 'fetch-complete'
        });
        void experience.success({
          metadata: {
            moduleName: moduleName
          }
        });
        setStatus({
          status: 'done'
        });
      }).catch(function (error) {
        onEvent === null || onEvent === void 0 || onEvent({
          type: 'fetch-error'
        });
        void experience.failure({
          metadata: {
            error: error,
            moduleName: moduleName
          }
        });
        setStatus({
          error: error,
          status: 'error'
        });
      });
    }
  }, [status, remoteFrontendSdk, onError, experience, moduleName, onEvent]);
  return state;
};
export default useRemoteModuleLoader;