import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from 'styled-components';
export default styled.div(_templateObject(), function (_ref) {
  var shouldFitContainer = _ref.shouldFitContainer;
  return shouldFitContainer ? '' : 'max-width: 300px;';
});