import { createResource } from 'react-resource-router';

import authRequest from 'src/utils/fetch';

import {
  getRepositoryDynamicPipelinesProvidersUrl,
  getWorkspaceDynamicPipelinesProvidersUrl,
} from '../urls';

export type DynamicPipelinesProvidersBody = {
  appAri: string;
  inheritedWorkspaceAPPAri: string;
};

type DynamicPipelinesResourceOptions = {
  errorMessage: string;
  getRequestUrl: (params: {}) => string;
  type: string;
};

export type DynamicPipelinesResource = {
  appAri: string;
  inheritedWorkspaceAPPAri: string;
};

async function fetchData(url: string, errorMessage: string) {
  try {
    const req = authRequest(url);
    const resp = await fetch(req);

    if (!resp.ok) {
      if (resp.status === 404) {
        return { appAri: '' };
      }
      throw new Error(errorMessage);
    }

    const body = await resp.json();
    return { appAri: body.appAri || '' };
  } catch (e) {
    throw new Error(errorMessage);
  }
}

function createDynamicPipelinesResource({
  errorMessage,
  getRequestUrl,
  type,
}: DynamicPipelinesResourceOptions) {
  return createResource<DynamicPipelinesResource>({
    type,
    getKey: ({ match }) => getRequestUrl(match.params),
    maxAge: 60000,
    getData: async ({ match }) => {
      const initialData = await fetchData(
        getRequestUrl(match.params),
        errorMessage
      );

      // If the type is 'pipelinesRepositoryDynamicPipelines', fetch additional data
      if (type === 'pipelinesRepositoryDynamicPipelines') {
        const workspaceData = await fetchData(
          getWorkspaceDynamicPipelinesProvidersUrl({
            workspaceSlug: match.params.repositoryOwner || '',
          }),
          'Could not fetch inherited workspace dynamic pipelines'
        );
        return {
          ...initialData,
          inheritedWorkspaceAPPAri: workspaceData.appAri,
        };
      }

      return { ...initialData, inheritedWorkspaceAPPAri: '' };
    },
  });
}

export const loadWorkspaceDynamicPipelinesResource =
  createDynamicPipelinesResource({
    errorMessage: 'Could not fetch workspace dynamic pipelines',
    getRequestUrl: getWorkspaceDynamicPipelinesProvidersUrl,
    type: 'pipelinesWorkspaceDynamicPipelines',
  });

export const loadRepositoryDynamicPipelinesResource =
  createDynamicPipelinesResource({
    errorMessage: 'Could not fetch repository dynamic pipelines',
    getRequestUrl: getRepositoryDynamicPipelinesProvidersUrl,
    type: 'pipelinesRepositoryDynamicPipelines',
  });
