import { SEARCH_QUERY } from '../groups';
import { B400 } from '../../utils/colors';
export var typeAheadQuery = {
  inclusive: true,
  group: SEARCH_QUERY,
  parseDOM: [{
    tag: 'span[data-type-ahead-query]'
  }],
  toDOM: function toDOM(node) {
    return ['span', {
      'data-type-ahead-query': 'true',
      'data-trigger': node.attrs.trigger,
      style: "color: ".concat(B400)
    }];
  },
  attrs: {
    trigger: {
      default: ''
    }
  }
};