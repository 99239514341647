import { getExtensionAttrs } from '../../utils/extensions';

/**
 * @name extension_node
 */

/**
 * @name extension_with_marks_node
 */

var createExtensionNodeSpec = function createExtensionNodeSpec() {
  var nodeSpec = {
    inline: false,
    group: 'block',
    atom: true,
    selectable: true,
    attrs: {
      extensionType: {
        default: ''
      },
      extensionKey: {
        default: ''
      },
      parameters: {
        default: null
      },
      text: {
        default: null
      },
      layout: {
        default: 'default'
      },
      localId: {
        default: null
      }
    },
    parseDOM: [{
      tag: '[data-node-type="extension"]',
      getAttrs: function getAttrs(domNode) {
        return getExtensionAttrs(domNode);
      }
    }],
    toDOM: function toDOM(node) {
      var attrs = {
        'data-node-type': 'extension',
        'data-extension-type': node.attrs.extensionType,
        'data-extension-key': node.attrs.extensionKey,
        'data-text': node.attrs.text,
        'data-parameters': JSON.stringify(node.attrs.parameters),
        'data-layout': node.attrs.layout,
        'data-local-id:': node.attrs.localId
      };
      return ['div', attrs];
    }
  };
  return nodeSpec;
};
export var extension = createExtensionNodeSpec();