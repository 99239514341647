/**
 * @name layoutSection_node
 */

/**
 * Need duplicate `type` and `marks` to make both validator and json-schema satisfied
 */

/**
 * @name layoutSection_full_node
 */

/**
 * @stage 0
 * @name layoutSection_with_single_column_node
 */

export var layoutSection = {
  content: '(layoutColumn | unsupportedBlock){1,3} unsupportedBlock* | unsupportedBlock+',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  isolating: true,
  parseDOM: [{
    context: 'layoutSection//|layoutColumn//',
    tag: 'div[data-layout-section]',
    skip: true
  }, {
    tag: 'div[data-layout-section]'
  }],
  toDOM: function toDOM() {
    var attrs = {
      'data-layout-section': 'true'
    };
    return ['div', attrs, 0];
  }
};
export var layoutSectionWithSingleColumn = {
  content: '(layoutColumn | unsupportedBlock){1,3} unsupportedBlock* | unsupportedBlock+',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  isolating: true,
  parseDOM: [{
    context: 'layoutSection//|layoutColumn//',
    tag: 'div[data-layout-section]',
    skip: true
  }, {
    tag: 'div[data-layout-section]'
  }],
  toDOM: function toDOM() {
    var attrs = {
      'data-layout-section': 'true'
    };
    return ['div', attrs, 0];
  }
};