import { createResource } from 'react-resource-router';

import { FeatureKeys, getFlagValue } from '@atlassian/bitbucket-features';

import { ResourceContext } from 'src/router/types';
import authRequest from 'src/utils/fetch';

import { NudgedReviewers } from '../types';
import urls from '../urls';

const fetchRecentlyNudgedReviewers = async (
  owner: string,
  slug: string,
  id: string
): Promise<NudgedReviewers[]> => {
  const requestUrl = urls.api.internal.nudgeReviewer(owner, slug, id);
  const resp = await fetch(authRequest(requestUrl));

  if (!resp.ok) {
    throw new Error('Could not fetch recently nudged reviewers');
  }
  const body = await resp.json();
  return body;
};

export const loadNudgedReviewersResource = createResource({
  type: 'pullrequest-nudged-reviewers',
  getKey: routerStoreContext => {
    const {
      pullRequestId: id,
      repositoryOwner: owner,
      repositorySlug: slug,
    } = routerStoreContext.match.params;
    return `${owner}/${slug}/${id}/nudged-reviewers`;
  },
  maxAge: 0,
  getData: async (routerStoreContext, { appData }: ResourceContext) => {
    const {
      pullRequestId: id,
      repositoryOwner: owner,
      repositorySlug: slug,
    } = routerStoreContext.match.params;

    const isNudgeReviewersEnabled = getFlagValue(
      FeatureKeys.isNudgeReviewersEnabled,
      false
    );

    // don't trigger unnecessary requests unless the feature flag is turned on
    if (!isNudgeReviewersEnabled) {
      return [];
    }

    if (!id || !owner || !slug) {
      throw new Error(
        'Could not fetch nudged reviewers, URL params were not available'
      );
    }
    // Return empty list if user is not logged in and don't fetch nudged reviewers
    if (!appData.user?.uuid) {
      return [];
    }
    const recentlyNudgedReviewersResult = await fetchRecentlyNudgedReviewers(
      owner,
      slug,
      id
    );
    return recentlyNudgedReviewersResult;
  },
});
