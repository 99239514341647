import { COLOR, FONT_STYLE, LINK, SEARCH_QUERY } from '../groups';

/**
 * @name code_mark
 */

export var code = {
  excludes: "".concat(FONT_STYLE, " ").concat(LINK, " ").concat(SEARCH_QUERY, " ").concat(COLOR),
  inclusive: true,
  parseDOM: [{
    tag: 'span.code',
    preserveWhitespace: true
  }, {
    tag: 'code',
    preserveWhitespace: true
  }, {
    tag: 'tt',
    preserveWhitespace: true
  }, {
    tag: 'span',
    preserveWhitespace: true,
    getAttrs: function getAttrs(domNode) {
      var dom = domNode;
      if (dom.style.whiteSpace === 'pre') {
        return {};
      }
      if (dom.style.fontFamily && dom.style.fontFamily.toLowerCase().indexOf('monospace') >= 0) {
        return {};
      }
      return false;
    }
  }],
  toDOM: function toDOM() {
    return ['span', {
      class: 'code',
      spellcheck: 'false'
    }];
  }
};