/**
 * @name embedCard_node
 */

export var embedCard = {
  inline: false,
  group: 'block',
  selectable: true,
  attrs: {
    url: {
      default: ''
    },
    layout: {
      default: 'center'
    },
    width: {
      default: 100
    },
    originalWidth: {
      default: null
    },
    originalHeight: {
      default: null
    }
  },
  parseDOM: [{
    tag: 'div[data-embed-card]',
    getAttrs: function getAttrs(dom) {
      return {
        url: dom.getAttribute('data-card-url'),
        layout: dom.getAttribute('data-layout') || 'center',
        width: Number(dom.getAttribute('data-width')) || null,
        originalWidth: Number(dom.getAttribute('data-card-original-width')) || null,
        originalHeight: Number(dom.getAttribute('data-card-original-height')) || null
      };
    }
  }],
  toDOM: function toDOM(node) {
    var _node$attrs = node.attrs,
      url = _node$attrs.url,
      layout = _node$attrs.layout,
      width = _node$attrs.width,
      originalWidth = _node$attrs.originalWidth,
      originalHeight = _node$attrs.originalHeight;
    var attrs = {
      'data-embed-card': '',
      'data-card-url': url,
      'data-layout': layout,
      'data-width': width,
      'data-original-width': originalWidth,
      'data-original-height': originalHeight
    };
    return ['div', attrs];
  }
};