import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var version = "3.12.1";
export var crossFlowSupportAttributes = {
  version: version
};
export var enrichWithPackageDetails = function enrichWithPackageDetails(event) {
  var attributes = _objectSpread(_objectSpread({}, event.attributes || {}), {}, {
    crossFlowSupport: crossFlowSupportAttributes
  });
  return _objectSpread(_objectSpread({}, event), {}, {
    attributes: attributes
  });
};