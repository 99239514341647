import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import Button from '@atlaskit/button/custom-theme-button';
import { N200 } from '@atlaskit/theme/colors';
import Tooltip from '@atlaskit/tooltip';
var tooltipMessage = ['Strongly disagree', 'Disagree', 'Slightly disagree', 'Neutral', 'Slightly agree', 'Agree', 'Strongly agree'];
export default (function (_ref) {
  var onChange = _ref.onChange,
    value = _ref.value;
  return jsx("div", null, jsx("div", {
    css: css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n        display: flex;\n        justify-content: space-between;\n\n        & > * + * {\n          margin-left: ", ";\n        }\n\n        & > * {\n          flex: 1;\n\n          & > button {\n            justify-content: center;\n          }\n        }\n      "])), "var(--ds-space-100, 8px)")
  }, Array.from({
    length: 7
  }, function (_, i) {
    var score = i + 1;
    var isSelected = value === score;
    return jsx(Tooltip, {
      content: tooltipMessage[i],
      key: score,
      hideTooltipOnClick: true
    }, jsx(Button, {
      onClick: function onClick() {
        return onChange(score);
      },
      isSelected: isSelected,
      "aria-pressed": isSelected,
      "aria-describedby": "contextualSurveyStatement",
      "aria-label": tooltipMessage[i],
      shouldFitContainer: true
    }, score));
  })), jsx("div", {
    css: css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n        font-size: 12px;\n        font-weight: 600;\n        color: ", ";\n        display: flex;\n        margin-top: ", ";\n        margin-bottom: ", ";\n\n        & > span {\n          width: ", ";\n        }\n      "])), "var(--ds-text-subtlest, ".concat(N200, ")"), "var(--ds-space-100, 8px)", "var(--ds-space-300, 24px)", "var(--ds-space-1000, 80px)"),
    "aria-hidden": true
  }, jsx("span", null, "Strongly disagree"), jsx("span", {
    css: css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n          text-align: center;\n          margin: 0 auto;\n          padding: 0 ", ";\n        "])), "var(--ds-space-600, 48px)")
  }, "Neutral"), jsx("span", {
    css: css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n          text-align: right;\n        "])))
  }, "Strongly agree")));
});