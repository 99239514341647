import qs from 'qs';

import { COMMIT_MESSAGE_LENGTH } from 'src/redux/commit-list';
import escapeBbqlString from 'src/utils/escape-bbql-string';

import {
  qsDefaultOptions,
  buildCommentUrl,
  normalizeDiffParams,
  normalizeDiffStatParams,
} from './url-utils';

type MergeRequestProps = {
  owner: string;
  repoSlug: string;
  pullRequestId: string | number;
};

const baseV2Url = `/!api/2.0/repositories`;

export const v20 = {
  approval: (owner: string, repoSlug: string, pullRequestId: string | number) =>
    `${baseV2Url}/${owner}/${repoSlug}/pullrequests/${pullRequestId}/approve`,
  requestChanges: (
    owner: string,
    repoSlug: string,
    pullRequestId: string | number
  ) =>
    `${baseV2Url}/${owner}/${repoSlug}/pullrequests/${pullRequestId}/request-changes`,

  commentSave: buildCommentUrl,

  commentUpdate: buildCommentUrl,

  commentDelete: buildCommentUrl,

  commentResolve: (
    owner: string,
    repoSlug: string,
    pullRequestId: string | number,
    commentId: string | number
  ) =>
    `${baseV2Url}/${owner}/${repoSlug}/pullrequests/${pullRequestId}/comments/${commentId}/resolve`,

  commits: (
    owner: string,
    slug: string,
    id: string | number,
    pagelen?: string
  ) => {
    const url = `${baseV2Url}/${owner}/${slug}/pullrequests/${id}/commits`;
    const query_params = {
      truncate_to: COMMIT_MESSAGE_LENGTH,
      fields: '+*.participants.approved,-*.participants.*',
      ...(pagelen && { pagelen }),
    };

    const query = qs.stringify(query_params);
    return `${url}?${query}`;
  },

  decline: (owner: string, repoSlug: string, pullRequestId: string | number) =>
    `${baseV2Url}/${owner}/${repoSlug}/pullrequests/${pullRequestId}/decline`,

  diff: (
    user: string,
    repoSlug: string,
    pullRequestId: string | number,
    params: {
      topicDiff?: boolean | null | undefined;
      ignoreWhitespace?: boolean | null | undefined;
    }
  ) => {
    const url = `${baseV2Url}/${user}/${repoSlug}/pullrequests/${pullRequestId}/diff`;

    const query =
      Object.keys(params).length !== 0
        ? qs.stringify(normalizeDiffParams(params), qsDefaultOptions)
        : '';

    return `${url}${query}`;
  },

  diffstat: (
    user: string,
    repoSlug: string,
    pullRequestId: string | number,
    params: {
      topicDiff?: boolean | null | undefined;
      ignoreWhitespace?: boolean | null | undefined;
    }
  ) => {
    const url = `${baseV2Url}/${user}/${repoSlug}/pullrequests/${pullRequestId}/diffstat`;

    const qsParams = normalizeDiffStatParams(params);
    const query = qs.stringify(qsParams, qsDefaultOptions);

    return `${url}${query}`;
  },

  merge_async: ({ owner, repoSlug, pullRequestId }: MergeRequestProps) =>
    `${baseV2Url}/${owner}/${repoSlug}/pullrequests/${pullRequestId}/merge?async=true`,

  participants: (
    owner: string,
    repoSlug: string,
    pullRequestId: string | number
  ) =>
    `${baseV2Url}/${owner}/${repoSlug}/pullrequests/${pullRequestId}?fields=participants`,

  pullRequest: (
    owner: string,
    repoSlug: string,
    pullRequestId: string | number
  ) => `${baseV2Url}/${owner}/${repoSlug}/pullrequests/${pullRequestId}`,

  pullRequests: (
    owner: string,
    repoSlug: string,
    destinationBranch: string
  ) => {
    const bbql = encodeURIComponent(
      `destination.branch.name="${escapeBbqlString(
        destinationBranch
      )}" AND source.branch.name!="${escapeBbqlString(
        destinationBranch
      )}" AND state="OPEN"`
    );
    return `/!api/2.0/repositories/${owner}/${repoSlug}/pullrequests?fields=size&q=${bbql}`;
  },

  statusesFetch: (
    owner: string,
    repoSlug: string,
    pullRequestId: string | number
  ) =>
    `${baseV2Url}/${owner}/${repoSlug}/pullrequests/${pullRequestId}/statuses?pagelen=100`,

  members: (owner: string, userAaid: string) =>
    `/!api/2.0/workspaces/${owner}/members/${userAaid}`,

  repositoryPermissions: (owner: string, repo: string, aid: string) =>
    `/!api/2.0/workspaces/${owner}/permissions/repositories/${repo}?q=user.account_id=%22${aid}%22`,

  sourceBranchPullRequests: (
    destRepoFullSlug: string,
    sourceRepoFullSlug: string,
    sourceBranchName: string
  ) => {
    const bbqlQuery = `source.branch.name="${escapeBbqlString(
      sourceBranchName
    )}" AND source.repository.full_name="${escapeBbqlString(
      sourceRepoFullSlug
    )}" AND state="OPEN"`;

    const bbql = encodeURIComponent(bbqlQuery);
    return `/!api/2.0/repositories/${destRepoFullSlug}/pullrequests?fields=size&q=${bbql}`;
  },

  tasksFetch: (
    workspaceSlug: string,
    repoSlug: string,
    pullRequestId: string | number
  ) =>
    `/!api/2.0/repositories/${workspaceSlug}/${repoSlug}/pullrequests/${pullRequestId}/tasks?pagelen=1000`,

  tasks: (
    workspaceSlug: string,
    repoSlug: string,
    pullRequestId: string | number
  ) =>
    `/!api/2.0/repositories/${workspaceSlug}/${repoSlug}/pullrequests/${pullRequestId}/tasks`,

  task: (
    workspaceSlug: string,
    repoSlug: string,
    pullRequestId: string | number,
    taskId: string | number
  ) =>
    `/!api/2.0/repositories/${workspaceSlug}/${repoSlug}/pullrequests/${pullRequestId}/tasks/${taskId}`,
};
