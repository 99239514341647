import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { isDOMElement } from '../../utils/parseDOM';

/**
 * This annotation is purely for reference, & does _nothing_ given annotating
 * `minLength` on an array of strings is not supported with our schema+spec
 * generator.
 *
 * We're keeping it to signal that data consumer `sources` shouldn't be empty
 * strings
 *
 * // @minLength 1
 */

/**
 * @name dataConsumer_mark
 * @description This mark is used for metadata surrounding a node consuming data
 * from a given source node
 */

var parseDataConsumer = function parseDataConsumer(maybeValue) {
  var sources = isDOMElement(maybeValue) && maybeValue.getAttribute('data-sources');
  try {
    return sources ? {
      sources: JSON.parse(sources)
    } : false;
  } catch (_unused) {
    return false;
  }
};
export var dataConsumer = {
  attrs: {
    sources: {
      default: []
    }
  },
  parseDOM: [{
    tag: '[data-mark-type="dataConsumer"]',
    getAttrs: function getAttrs(maybeValue) {
      return parseDataConsumer(maybeValue);
    }
  }],
  toDOM: function toDOM(mark, inline) {
    var wrapperStyle = inline ? 'span' : 'div';
    return [wrapperStyle, {
      'data-mark-type': 'dataConsumer',
      'data-sources': JSON.stringify(mark.attrs.sources)
    }];
  }
};

/**
 * We want to ensure any "invalid ADF" doesn't get serialised, but the entire
 * mark itself is not valid without a non-empty `sources`.
 *
 * We _almost could_ simply return `null` if sources length is < 0 & would fit
 * the type signature of prosemirror-model's `fragment` but not `mark`'s toJSON.
 *
 * So we'll leave any extra transformation checks in
 * `editor-json-transformer`(?)
 */
export var toJSON = function toJSON(mark) {
  // // Remove intemediary state if we don't have any sources on data consumer
  // if (mark.attrs?.sources?.length < 1) {
  //   return null;
  // }

  return {
    type: mark.type.name,
    attrs: Object.keys(mark.attrs).filter(function (key) {
      return key === 'sources' && mark.attrs[key].length > 0 && mark.attrs[key] !== null;
    }).reduce(function (acc, key) {
      return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, key, mark.attrs[key]));
    }, {})
  };
};