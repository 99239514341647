import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from '@emotion/react';
import { CURRENT_SURFACE_CSS_VAR } from '@atlaskit/tokens';

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 * @codegen <<SignedSource::c98c944b8a999cfc194a2cfe851d2433>>
 * @codegenId dimensions
 * @codegenCommand yarn workspace @atlaskit/primitives codegen-styles
 * @codegenParams ["dimensions"]
 * @codegenDependency ../../scripts/codegen-file-templates/dimensions.tsx <<SignedSource::cc9b3f12104c6ede803da6a42daac0b0>>
 */
export var dimensionMap = {
  '100%': '100%',
  'size.100': '1rem',
  'size.200': '1.5rem',
  'size.300': '2rem',
  'size.400': '2.5rem',
  'size.500': '3rem',
  'size.600': '6rem',
  'size.1000': '12rem'
};
/**
 * @codegenEnd
 */

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 * @codegen <<SignedSource::0d3c08d703b8431712d7e238a3f7229b>>
 * @codegenId spacing
 * @codegenCommand yarn workspace @atlaskit/primitives codegen-styles
 * @codegenDependency ../../../tokens/src/artifacts/tokens-raw/atlassian-spacing.tsx <<SignedSource::55622b91aca9b3afac4bce440f222b71>>
 */
export var positiveSpaceMap = {
  'space.0': "var(--ds-space-0, 0px)",
  'space.025': "var(--ds-space-025, 2px)",
  'space.050': "var(--ds-space-050, 4px)",
  'space.075': "var(--ds-space-075, 6px)",
  'space.100': "var(--ds-space-100, 8px)",
  'space.150': "var(--ds-space-150, 12px)",
  'space.200': "var(--ds-space-200, 16px)",
  'space.250': "var(--ds-space-250, 20px)",
  'space.300': "var(--ds-space-300, 24px)",
  'space.400': "var(--ds-space-400, 32px)",
  'space.500': "var(--ds-space-500, 40px)",
  'space.600': "var(--ds-space-600, 48px)",
  'space.800': "var(--ds-space-800, 64px)",
  'space.1000': "var(--ds-space-1000, 80px)"
};
export var negativeSpaceMap = {
  'space.negative.025': "var(--ds-space-negative-025, -2px)",
  'space.negative.050': "var(--ds-space-negative-050, -4px)",
  'space.negative.075': "var(--ds-space-negative-075, -6px)",
  'space.negative.100': "var(--ds-space-negative-100, -8px)",
  'space.negative.150': "var(--ds-space-negative-150, -12px)",
  'space.negative.200': "var(--ds-space-negative-200, -16px)",
  'space.negative.250': "var(--ds-space-negative-250, -20px)",
  'space.negative.300': "var(--ds-space-negative-300, -24px)",
  'space.negative.400': "var(--ds-space-negative-400, -32px)"
};
export var allSpaceMap = _objectSpread(_objectSpread({}, positiveSpaceMap), negativeSpaceMap);
/**
 * @codegenEnd
 */

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 * @codegen <<SignedSource::dd33c75b34fe7374656f10b21ba9b161>>
 * @codegenId inverse-colors
 * @codegenCommand yarn workspace @atlaskit/primitives codegen-styles
 * @codegenDependency ../../../tokens/src/artifacts/tokens-raw/atlassian-light.tsx <<SignedSource::f27d1519d51cc4a85383a00907847774>>
 */
export var inverseColorMap = {
  'color.background.neutral.bold': 'color.text.inverse',
  'color.background.neutral.bold.hovered': 'color.text.inverse',
  'color.background.neutral.bold.pressed': 'color.text.inverse',
  'color.background.selected.bold': 'color.text.inverse',
  'color.background.selected.bold.hovered': 'color.text.inverse',
  'color.background.selected.bold.pressed': 'color.text.inverse',
  'color.background.brand.bold': 'color.text.inverse',
  'color.background.brand.bold.hovered': 'color.text.inverse',
  'color.background.brand.bold.pressed': 'color.text.inverse',
  'color.background.brand.boldest': 'color.text.inverse',
  'color.background.brand.boldest.hovered': 'color.text.inverse',
  'color.background.brand.boldest.pressed': 'color.text.inverse',
  'color.background.danger.bold': 'color.text.inverse',
  'color.background.danger.bold.hovered': 'color.text.inverse',
  'color.background.danger.bold.pressed': 'color.text.inverse',
  'color.background.warning.bold': 'color.text.warning.inverse',
  'color.background.warning.bold.hovered': 'color.text.warning.inverse',
  'color.background.warning.bold.pressed': 'color.text.warning.inverse',
  'color.background.success.bold': 'color.text.inverse',
  'color.background.success.bold.hovered': 'color.text.inverse',
  'color.background.success.bold.pressed': 'color.text.inverse',
  'color.background.discovery.bold': 'color.text.inverse',
  'color.background.discovery.bold.hovered': 'color.text.inverse',
  'color.background.discovery.bold.pressed': 'color.text.inverse',
  'color.background.information.bold': 'color.text.inverse',
  'color.background.information.bold.hovered': 'color.text.inverse',
  'color.background.information.bold.pressed': 'color.text.inverse'
};

/**
 * @codegenEnd
 */

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 * @codegen <<SignedSource::fde38a9999e3a081afa4a517da815ede>>
 * @codegenId elevation
 * @codegenCommand yarn workspace @atlaskit/primitives codegen-styles
 * @codegenParams ["opacity", "shadow", "surface"]
 * @codegenDependency ../../../tokens/src/artifacts/tokens-raw/atlassian-light.tsx <<SignedSource::f27d1519d51cc4a85383a00907847774>>
 */
export var opacityMap = {
  'opacity.disabled': "var(--ds-opacity-disabled, 0.4)",
  'opacity.loading': "var(--ds-opacity-loading, 0.2)"
};
export var shadowMap = {
  'elevation.shadow.overflow': "var(--ds-shadow-overflow, 0px 0px 8px #091e423f, 0px 0px 1px #091e424f)",
  'elevation.shadow.overflow.perimeter': "var(--ds-shadow-overflow-perimeter, #091e421f)",
  'elevation.shadow.overflow.spread': "var(--ds-shadow-overflow-spread, #091e4229)",
  'elevation.shadow.overlay': "var(--ds-shadow-overlay, 0px 8px 12px #091e423f, 0px 0px 1px #091e424f)",
  'elevation.shadow.raised': "var(--ds-shadow-raised, 0px 1px 1px #091e423f, 0px 0px 1px #091e4221)"
};
export var surfaceColorMap = {
  'elevation.surface': "var(--ds-surface, #FFFFFF)",
  'elevation.surface.hovered': "var(--ds-surface-hovered, #FAFBFC)",
  'elevation.surface.pressed': "var(--ds-surface-pressed, #F4F5F7)",
  'elevation.surface.overlay': "var(--ds-surface-overlay, #FFFFFF)",
  'elevation.surface.overlay.hovered': "var(--ds-surface-overlay-hovered, #FAFBFC)",
  'elevation.surface.overlay.pressed': "var(--ds-surface-overlay-pressed, #F4F5F7)",
  'elevation.surface.raised': "var(--ds-surface-raised, #FFFFFF)",
  'elevation.surface.raised.hovered': "var(--ds-surface-raised-hovered, #FAFBFC)",
  'elevation.surface.raised.pressed': "var(--ds-surface-raised-pressed, #F4F5F7)",
  'elevation.surface.sunken': "var(--ds-surface-sunken, #F4F5F7)"
};
/**
 * @codegenEnd
 */

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 * @codegen <<SignedSource::b0d40448c552e91658e4f2267d9f40b3>>
 * @codegenId colors
 * @codegenCommand yarn workspace @atlaskit/primitives codegen-styles
 * @codegenParams ["border", "background", "text", "fill"]
 * @codegenDependency ../../../tokens/src/artifacts/tokens-raw/atlassian-light.tsx <<SignedSource::f27d1519d51cc4a85383a00907847774>>
 */
export var borderColorMap = {
  'color.border': "var(--ds-border, #091e4221)",
  'color.border.accent.lime': "var(--ds-border-accent-lime, #6A9A23)",
  'color.border.accent.red': "var(--ds-border-accent-red, #FF5630)",
  'color.border.accent.orange': "var(--ds-border-accent-orange, #D94008)",
  'color.border.accent.yellow': "var(--ds-border-accent-yellow, #FFAB00)",
  'color.border.accent.green': "var(--ds-border-accent-green, #36B37E)",
  'color.border.accent.teal': "var(--ds-border-accent-teal, #00B8D9)",
  'color.border.accent.blue': "var(--ds-border-accent-blue, #0065FF)",
  'color.border.accent.purple': "var(--ds-border-accent-purple, #6554C0)",
  'color.border.accent.magenta': "var(--ds-border-accent-magenta, #CD519D)",
  'color.border.accent.gray': "var(--ds-border-accent-gray, #5E6C84)",
  'color.border.disabled': "var(--ds-border-disabled, #FAFBFC)",
  'color.border.focused': "var(--ds-border-focused, #2684FF)",
  'color.border.input': "var(--ds-border-input, #FAFBFC)",
  'color.border.inverse': "var(--ds-border-inverse, #FFFFFF)",
  'color.border.selected': "var(--ds-border-selected, #0052CC)",
  'color.border.brand': "var(--ds-border-brand, #0052CC)",
  'color.border.danger': "var(--ds-border-danger, #FF5630)",
  'color.border.warning': "var(--ds-border-warning, #FFC400)",
  'color.border.success': "var(--ds-border-success, #00875A)",
  'color.border.discovery': "var(--ds-border-discovery, #998DD9)",
  'color.border.information': "var(--ds-border-information, #0065FF)",
  'color.border.bold': "var(--ds-border-bold, #344563)"
};
export var backgroundColorMap = {
  'color.background.accent.lime.subtlest': "var(--ds-background-accent-lime-subtlest, #EEFBDA)",
  'color.background.accent.lime.subtlest.hovered': "var(--ds-background-accent-lime-subtlest-hovered, #D3F1A7)",
  'color.background.accent.lime.subtlest.pressed': "var(--ds-background-accent-lime-subtlest-pressed, #B3DF72)",
  'color.background.accent.lime.subtler': "var(--ds-background-accent-lime-subtler, #D3F1A7)",
  'color.background.accent.lime.subtler.hovered': "var(--ds-background-accent-lime-subtler-hovered, #B3DF72)",
  'color.background.accent.lime.subtler.pressed': "var(--ds-background-accent-lime-subtler-pressed, #94C748)",
  'color.background.accent.lime.subtle': "var(--ds-background-accent-lime-subtle, #94C748)",
  'color.background.accent.lime.subtle.hovered': "var(--ds-background-accent-lime-subtle-hovered, #B3DF72)",
  'color.background.accent.lime.subtle.pressed': "var(--ds-background-accent-lime-subtle-pressed, #D3F1A7)",
  'color.background.accent.lime.bolder': "var(--ds-background-accent-lime-bolder, #5B7F24)",
  'color.background.accent.lime.bolder.hovered': "var(--ds-background-accent-lime-bolder-hovered, #37471F)",
  'color.background.accent.lime.bolder.pressed': "var(--ds-background-accent-lime-bolder-pressed, #37471F)",
  'color.background.accent.red.subtlest': "var(--ds-background-accent-red-subtlest, #FF8F73)",
  'color.background.accent.red.subtlest.hovered': "var(--ds-background-accent-red-subtlest-hovered, #FF7452)",
  'color.background.accent.red.subtlest.pressed': "var(--ds-background-accent-red-subtlest-pressed, #FF5630)",
  'color.background.accent.red.subtler': "var(--ds-background-accent-red-subtler, #FF7452)",
  'color.background.accent.red.subtler.hovered': "var(--ds-background-accent-red-subtler-hovered, #FF5630)",
  'color.background.accent.red.subtler.pressed': "var(--ds-background-accent-red-subtler-pressed, #DE350B)",
  'color.background.accent.red.subtle': "var(--ds-background-accent-red-subtle, #DE350B)",
  'color.background.accent.red.subtle.hovered': "var(--ds-background-accent-red-subtle-hovered, #FF5630)",
  'color.background.accent.red.subtle.pressed': "var(--ds-background-accent-red-subtle-pressed, #FF7452)",
  'color.background.accent.red.bolder': "var(--ds-background-accent-red-bolder, #DE350B)",
  'color.background.accent.red.bolder.hovered': "var(--ds-background-accent-red-bolder-hovered, #FF5630)",
  'color.background.accent.red.bolder.pressed': "var(--ds-background-accent-red-bolder-pressed, #FF7452)",
  'color.background.accent.orange.subtlest': "var(--ds-background-accent-orange-subtlest, #F18D13)",
  'color.background.accent.orange.subtlest.hovered': "var(--ds-background-accent-orange-subtlest-hovered, #FEC57B)",
  'color.background.accent.orange.subtlest.pressed': "var(--ds-background-accent-orange-subtlest-pressed, #FFE2BD)",
  'color.background.accent.orange.subtler': "var(--ds-background-accent-orange-subtler, #B65C02)",
  'color.background.accent.orange.subtler.hovered': "var(--ds-background-accent-orange-subtler-hovered, #F18D13)",
  'color.background.accent.orange.subtler.pressed': "var(--ds-background-accent-orange-subtler-pressed, #FEC57B)",
  'color.background.accent.orange.subtle': "var(--ds-background-accent-orange-subtle, #5F3811)",
  'color.background.accent.orange.subtle.hovered': "var(--ds-background-accent-orange-subtle-hovered, #974F0C)",
  'color.background.accent.orange.subtle.pressed': "var(--ds-background-accent-orange-subtle-pressed, #B65C02)",
  'color.background.accent.orange.bolder': "var(--ds-background-accent-orange-bolder, #43290F)",
  'color.background.accent.orange.bolder.hovered': "var(--ds-background-accent-orange-bolder-hovered, #5F3811)",
  'color.background.accent.orange.bolder.pressed': "var(--ds-background-accent-orange-bolder-pressed, #974F0C)",
  'color.background.accent.yellow.subtlest': "var(--ds-background-accent-yellow-subtlest, #FFE380)",
  'color.background.accent.yellow.subtlest.hovered': "var(--ds-background-accent-yellow-subtlest-hovered, #FFC400)",
  'color.background.accent.yellow.subtlest.pressed': "var(--ds-background-accent-yellow-subtlest-pressed, #FFAB00)",
  'color.background.accent.yellow.subtler': "var(--ds-background-accent-yellow-subtler, #FFC400)",
  'color.background.accent.yellow.subtler.hovered': "var(--ds-background-accent-yellow-subtler-hovered, #FFAB00)",
  'color.background.accent.yellow.subtler.pressed': "var(--ds-background-accent-yellow-subtler-pressed, #FF991F)",
  'color.background.accent.yellow.subtle': "var(--ds-background-accent-yellow-subtle, #FF991F)",
  'color.background.accent.yellow.subtle.hovered': "var(--ds-background-accent-yellow-subtle-hovered, #FFAB00)",
  'color.background.accent.yellow.subtle.pressed': "var(--ds-background-accent-yellow-subtle-pressed, #FFC400)",
  'color.background.accent.yellow.bolder': "var(--ds-background-accent-yellow-bolder, #FF991F)",
  'color.background.accent.yellow.bolder.hovered': "var(--ds-background-accent-yellow-bolder-hovered, #FFAB00)",
  'color.background.accent.yellow.bolder.pressed': "var(--ds-background-accent-yellow-bolder-pressed, #FFC400)",
  'color.background.accent.green.subtlest': "var(--ds-background-accent-green-subtlest, #79F2C0)",
  'color.background.accent.green.subtlest.hovered': "var(--ds-background-accent-green-subtlest-hovered, #57D9A3)",
  'color.background.accent.green.subtlest.pressed': "var(--ds-background-accent-green-subtlest-pressed, #36B37E)",
  'color.background.accent.green.subtler': "var(--ds-background-accent-green-subtler, #57D9A3)",
  'color.background.accent.green.subtler.hovered': "var(--ds-background-accent-green-subtler-hovered, #36B37E)",
  'color.background.accent.green.subtler.pressed': "var(--ds-background-accent-green-subtler-pressed, #00875A)",
  'color.background.accent.green.subtle': "var(--ds-background-accent-green-subtle, #00875A)",
  'color.background.accent.green.subtle.hovered': "var(--ds-background-accent-green-subtle-hovered, #36B37E)",
  'color.background.accent.green.subtle.pressed': "var(--ds-background-accent-green-subtle-pressed, #57D9A3)",
  'color.background.accent.green.bolder': "var(--ds-background-accent-green-bolder, #00875A)",
  'color.background.accent.green.bolder.hovered': "var(--ds-background-accent-green-bolder-hovered, #36B37E)",
  'color.background.accent.green.bolder.pressed': "var(--ds-background-accent-green-bolder-pressed, #57D9A3)",
  'color.background.accent.teal.subtlest': "var(--ds-background-accent-teal-subtlest, #79E2F2)",
  'color.background.accent.teal.subtlest.hovered': "var(--ds-background-accent-teal-subtlest-hovered, #00C7E6)",
  'color.background.accent.teal.subtlest.pressed': "var(--ds-background-accent-teal-subtlest-pressed, #00B8D9)",
  'color.background.accent.teal.subtler': "var(--ds-background-accent-teal-subtler, #00C7E6)",
  'color.background.accent.teal.subtler.hovered': "var(--ds-background-accent-teal-subtler-hovered, #00B8D9)",
  'color.background.accent.teal.subtler.pressed': "var(--ds-background-accent-teal-subtler-pressed, #00A3BF)",
  'color.background.accent.teal.subtle': "var(--ds-background-accent-teal-subtle, #00A3BF)",
  'color.background.accent.teal.subtle.hovered': "var(--ds-background-accent-teal-subtle-hovered, #00B8D9)",
  'color.background.accent.teal.subtle.pressed': "var(--ds-background-accent-teal-subtle-pressed, #00C7E6)",
  'color.background.accent.teal.bolder': "var(--ds-background-accent-teal-bolder, #00A3BF)",
  'color.background.accent.teal.bolder.hovered': "var(--ds-background-accent-teal-bolder-hovered, #00B8D9)",
  'color.background.accent.teal.bolder.pressed': "var(--ds-background-accent-teal-bolder-pressed, #00C7E6)",
  'color.background.accent.blue.subtlest': "var(--ds-background-accent-blue-subtlest, #4C9AFF)",
  'color.background.accent.blue.subtlest.hovered': "var(--ds-background-accent-blue-subtlest-hovered, #2684FF)",
  'color.background.accent.blue.subtlest.pressed': "var(--ds-background-accent-blue-subtlest-pressed, #0065FF)",
  'color.background.accent.blue.subtler': "var(--ds-background-accent-blue-subtler, #2684FF)",
  'color.background.accent.blue.subtler.hovered': "var(--ds-background-accent-blue-subtler-hovered, #0065FF)",
  'color.background.accent.blue.subtler.pressed': "var(--ds-background-accent-blue-subtler-pressed, #0052CC)",
  'color.background.accent.blue.subtle': "var(--ds-background-accent-blue-subtle, #0052CC)",
  'color.background.accent.blue.subtle.hovered': "var(--ds-background-accent-blue-subtle-hovered, #0065FF)",
  'color.background.accent.blue.subtle.pressed': "var(--ds-background-accent-blue-subtle-pressed, #2684FF)",
  'color.background.accent.blue.bolder': "var(--ds-background-accent-blue-bolder, #0052CC)",
  'color.background.accent.blue.bolder.hovered': "var(--ds-background-accent-blue-bolder-hovered, #0065FF)",
  'color.background.accent.blue.bolder.pressed': "var(--ds-background-accent-blue-bolder-pressed, #2684FF)",
  'color.background.accent.purple.subtlest': "var(--ds-background-accent-purple-subtlest, #998DD9)",
  'color.background.accent.purple.subtlest.hovered': "var(--ds-background-accent-purple-subtlest-hovered, #8777D9)",
  'color.background.accent.purple.subtlest.pressed': "var(--ds-background-accent-purple-subtlest-pressed, #6554C0)",
  'color.background.accent.purple.subtler': "var(--ds-background-accent-purple-subtler, #8777D9)",
  'color.background.accent.purple.subtler.hovered': "var(--ds-background-accent-purple-subtler-hovered, #6554C0)",
  'color.background.accent.purple.subtler.pressed': "var(--ds-background-accent-purple-subtler-pressed, #5243AA)",
  'color.background.accent.purple.subtle': "var(--ds-background-accent-purple-subtle, #5243AA)",
  'color.background.accent.purple.subtle.hovered': "var(--ds-background-accent-purple-subtle-hovered, #6554C0)",
  'color.background.accent.purple.subtle.pressed': "var(--ds-background-accent-purple-subtle-pressed, #8777D9)",
  'color.background.accent.purple.bolder': "var(--ds-background-accent-purple-bolder, #5243AA)",
  'color.background.accent.purple.bolder.hovered': "var(--ds-background-accent-purple-bolder-hovered, #6554C0)",
  'color.background.accent.purple.bolder.pressed': "var(--ds-background-accent-purple-bolder-pressed, #8777D9)",
  'color.background.accent.magenta.subtlest': "var(--ds-background-accent-magenta-subtlest, #FFECF8)",
  'color.background.accent.magenta.subtlest.hovered': "var(--ds-background-accent-magenta-subtlest-hovered, #FDD0EC)",
  'color.background.accent.magenta.subtlest.pressed': "var(--ds-background-accent-magenta-subtlest-pressed, #F797D2)",
  'color.background.accent.magenta.subtler': "var(--ds-background-accent-magenta-subtler, #FDD0EC)",
  'color.background.accent.magenta.subtler.hovered': "var(--ds-background-accent-magenta-subtler-hovered, #F797D2)",
  'color.background.accent.magenta.subtler.pressed': "var(--ds-background-accent-magenta-subtler-pressed, #E774BB)",
  'color.background.accent.magenta.subtle': "var(--ds-background-accent-magenta-subtle, #E774BB)",
  'color.background.accent.magenta.subtle.hovered': "var(--ds-background-accent-magenta-subtle-hovered, #F797D2)",
  'color.background.accent.magenta.subtle.pressed': "var(--ds-background-accent-magenta-subtle-pressed, #FDD0EC)",
  'color.background.accent.magenta.bolder': "var(--ds-background-accent-magenta-bolder, #AE4787)",
  'color.background.accent.magenta.bolder.hovered': "var(--ds-background-accent-magenta-bolder-hovered, #943D73)",
  'color.background.accent.magenta.bolder.pressed': "var(--ds-background-accent-magenta-bolder-pressed, #50253F)",
  'color.background.accent.gray.subtlest': "var(--ds-background-accent-gray-subtlest, #6B778C)",
  'color.background.accent.gray.subtlest.hovered': "var(--ds-background-accent-gray-subtlest-hovered, #5E6C84)",
  'color.background.accent.gray.subtlest.pressed': "var(--ds-background-accent-gray-subtlest-pressed, #505F79)",
  'color.background.accent.gray.subtler': "var(--ds-background-accent-gray-subtler, #5E6C84)",
  'color.background.accent.gray.subtler.hovered': "var(--ds-background-accent-gray-subtler-hovered, #505F79)",
  'color.background.accent.gray.subtler.pressed': "var(--ds-background-accent-gray-subtler-pressed, #42526E)",
  'color.background.accent.gray.subtle': "var(--ds-background-accent-gray-subtle, #505F79)",
  'color.background.accent.gray.subtle.hovered': "var(--ds-background-accent-gray-subtle-hovered, #5E6C84)",
  'color.background.accent.gray.subtle.pressed': "var(--ds-background-accent-gray-subtle-pressed, #6B778C)",
  'color.background.accent.gray.bolder': "var(--ds-background-accent-gray-bolder, #42526E)",
  'color.background.accent.gray.bolder.hovered': "var(--ds-background-accent-gray-bolder-hovered, #344563)",
  'color.background.accent.gray.bolder.pressed': "var(--ds-background-accent-gray-bolder-pressed, #253858)",
  'color.background.disabled': "var(--ds-background-disabled, #091e4289)",
  'color.background.input': "var(--ds-background-input, #FAFBFC)",
  'color.background.input.hovered': "var(--ds-background-input-hovered, #EBECF0)",
  'color.background.input.pressed': "var(--ds-background-input-pressed, #FFFFFF)",
  'color.background.inverse.subtle': "var(--ds-background-inverse-subtle, #00000029)",
  'color.background.inverse.subtle.hovered': "var(--ds-background-inverse-subtle-hovered, #0000003D)",
  'color.background.inverse.subtle.pressed': "var(--ds-background-inverse-subtle-pressed, #00000052)",
  'color.background.neutral': "var(--ds-background-neutral, #DFE1E6)",
  'color.background.neutral.hovered': "var(--ds-background-neutral-hovered, #091e4214)",
  'color.background.neutral.pressed': "var(--ds-background-neutral-pressed, #B3D4FF)",
  'color.background.neutral.subtle': "var(--ds-background-neutral-subtle, transparent)",
  'color.background.neutral.subtle.hovered': "var(--ds-background-neutral-subtle-hovered, #091e4214)",
  'color.background.neutral.subtle.pressed': "var(--ds-background-neutral-subtle-pressed, #B3D4FF)",
  'color.background.neutral.bold': "var(--ds-background-neutral-bold, #42526E)",
  'color.background.neutral.bold.hovered': "var(--ds-background-neutral-bold-hovered, #505F79)",
  'color.background.neutral.bold.pressed': "var(--ds-background-neutral-bold-pressed, #344563)",
  'color.background.selected': "var(--ds-background-selected, #DEEBFF)",
  'color.background.selected.hovered': "var(--ds-background-selected-hovered, #B3D4FF)",
  'color.background.selected.pressed': "var(--ds-background-selected-pressed, #4C9AFF)",
  'color.background.selected.bold': "var(--ds-background-selected-bold, #0052CC)",
  'color.background.selected.bold.hovered': "var(--ds-background-selected-bold-hovered, #2684FF)",
  'color.background.selected.bold.pressed': "var(--ds-background-selected-bold-pressed, #0052CC)",
  'color.background.brand.subtlest': "var(--ds-background-brand-subtlest, #B3D4FF)",
  'color.background.brand.subtlest.hovered': "var(--ds-background-brand-subtlest-hovered, #DEEBFF)",
  'color.background.brand.subtlest.pressed': "var(--ds-background-brand-subtlest-pressed, #4C9AFF)",
  'color.background.brand.bold': "var(--ds-background-brand-bold, #0052CC)",
  'color.background.brand.bold.hovered': "var(--ds-background-brand-bold-hovered, #0065FF)",
  'color.background.brand.bold.pressed': "var(--ds-background-brand-bold-pressed, #0747A6)",
  'color.background.brand.boldest': "var(--ds-background-brand-boldest, #0747A6)",
  'color.background.brand.boldest.hovered': "var(--ds-background-brand-boldest-hovered, #0052CC)",
  'color.background.brand.boldest.pressed': "var(--ds-background-brand-boldest-pressed, #0747A6)",
  'color.background.danger': "var(--ds-background-danger, #FFEBE6)",
  'color.background.danger.hovered': "var(--ds-background-danger-hovered, #FFBDAD)",
  'color.background.danger.pressed': "var(--ds-background-danger-pressed, #FF8F73)",
  'color.background.danger.bold': "var(--ds-background-danger-bold, #DE350B)",
  'color.background.danger.bold.hovered': "var(--ds-background-danger-bold-hovered, #FF5630)",
  'color.background.danger.bold.pressed': "var(--ds-background-danger-bold-pressed, #BF2600)",
  'color.background.warning': "var(--ds-background-warning, #FFFAE6)",
  'color.background.warning.hovered': "var(--ds-background-warning-hovered, #FFF0B3)",
  'color.background.warning.pressed': "var(--ds-background-warning-pressed, #FFE380)",
  'color.background.warning.bold': "var(--ds-background-warning-bold, #FFAB00)",
  'color.background.warning.bold.hovered': "var(--ds-background-warning-bold-hovered, #FFC400)",
  'color.background.warning.bold.pressed': "var(--ds-background-warning-bold-pressed, #FF991F)",
  'color.background.success': "var(--ds-background-success, #E3FCEF)",
  'color.background.success.hovered': "var(--ds-background-success-hovered, #ABF5D1)",
  'color.background.success.pressed': "var(--ds-background-success-pressed, #79F2C0)",
  'color.background.success.bold': "var(--ds-background-success-bold, #00875A)",
  'color.background.success.bold.hovered': "var(--ds-background-success-bold-hovered, #57D9A3)",
  'color.background.success.bold.pressed': "var(--ds-background-success-bold-pressed, #00875A)",
  'color.background.discovery': "var(--ds-background-discovery, #EAE6FF)",
  'color.background.discovery.hovered': "var(--ds-background-discovery-hovered, #C0B6F2)",
  'color.background.discovery.pressed': "var(--ds-background-discovery-pressed, #998DD9)",
  'color.background.discovery.bold': "var(--ds-background-discovery-bold, #5243AA)",
  'color.background.discovery.bold.hovered': "var(--ds-background-discovery-bold-hovered, #8777D9)",
  'color.background.discovery.bold.pressed': "var(--ds-background-discovery-bold-pressed, #5243AA)",
  'color.background.information': "var(--ds-background-information, #DEEBFF)",
  'color.background.information.hovered': "var(--ds-background-information-hovered, #B3D4FF)",
  'color.background.information.pressed': "var(--ds-background-information-pressed, #4C9AFF)",
  'color.background.information.bold': "var(--ds-background-information-bold, #0052CC)",
  'color.background.information.bold.hovered': "var(--ds-background-information-bold-hovered, #2684FF)",
  'color.background.information.bold.pressed': "var(--ds-background-information-bold-pressed, #0052CC)",
  'color.blanket': "var(--ds-blanket, #091e4289)",
  'color.blanket.selected': "var(--ds-blanket-selected, #388BFF14)",
  'color.blanket.danger': "var(--ds-blanket-danger, #EF5C4814)",
  'color.skeleton': "var(--ds-skeleton, #F4F5F7)",
  'color.skeleton.subtle': "var(--ds-skeleton-subtle, #091e420a)",
  'elevation.surface': "var(--ds-surface, #FFFFFF)",
  'elevation.surface.hovered': "var(--ds-surface-hovered, #FAFBFC)",
  'elevation.surface.pressed': "var(--ds-surface-pressed, #F4F5F7)",
  'elevation.surface.overlay': "var(--ds-surface-overlay, #FFFFFF)",
  'elevation.surface.overlay.hovered': "var(--ds-surface-overlay-hovered, #FAFBFC)",
  'elevation.surface.overlay.pressed': "var(--ds-surface-overlay-pressed, #F4F5F7)",
  'elevation.surface.raised': "var(--ds-surface-raised, #FFFFFF)",
  'elevation.surface.raised.hovered': "var(--ds-surface-raised-hovered, #FAFBFC)",
  'elevation.surface.raised.pressed': "var(--ds-surface-raised-pressed, #F4F5F7)",
  'elevation.surface.sunken': "var(--ds-surface-sunken, #F4F5F7)",
  'utility.elevation.surface.current': "var(--ds-elevation-surface-current, #FFFFFF)"
};
export var textColorMap = {
  'color.text': "var(--ds-text, #172B4D)",
  'color.text.accent.lime': "var(--ds-text-accent-lime, #4C6B1F)",
  'color.text.accent.lime.bolder': "var(--ds-text-accent-lime-bolder, #37471F)",
  'color.text.accent.red': "var(--ds-text-accent-red, #DE350B)",
  'color.text.accent.red.bolder': "var(--ds-text-accent-red-bolder, #BF2600)",
  'color.text.accent.orange': "var(--ds-text-accent-orange, #F18D13)",
  'color.text.accent.orange.bolder': "var(--ds-text-accent-orange-bolder, #B65C02)",
  'color.text.accent.yellow': "var(--ds-text-accent-yellow, #FF991F)",
  'color.text.accent.yellow.bolder': "var(--ds-text-accent-yellow-bolder, #FF8B00)",
  'color.text.accent.green': "var(--ds-text-accent-green, #00875A)",
  'color.text.accent.green.bolder': "var(--ds-text-accent-green-bolder, #006644)",
  'color.text.accent.teal': "var(--ds-text-accent-teal, #00A3BF)",
  'color.text.accent.teal.bolder': "var(--ds-text-accent-teal-bolder, #008DA6)",
  'color.text.accent.blue': "var(--ds-text-accent-blue, #0052CC)",
  'color.text.accent.blue.bolder': "var(--ds-text-accent-blue-bolder, #0747A6)",
  'color.text.accent.purple': "var(--ds-text-accent-purple, #5243AA)",
  'color.text.accent.purple.bolder': "var(--ds-text-accent-purple-bolder, #403294)",
  'color.text.accent.magenta': "var(--ds-text-accent-magenta, #E774BB)",
  'color.text.accent.magenta.bolder': "var(--ds-text-accent-magenta-bolder, #DA62AC)",
  'color.text.accent.gray': "var(--ds-text-accent-gray, #505F79)",
  'color.text.accent.gray.bolder': "var(--ds-text-accent-gray-bolder, #172B4D)",
  'color.text.disabled': "var(--ds-text-disabled, #A5ADBA)",
  'color.text.inverse': "var(--ds-text-inverse, #FFFFFF)",
  'color.text.selected': "var(--ds-text-selected, #0052CC)",
  'color.text.brand': "var(--ds-text-brand, #0065FF)",
  'color.text.danger': "var(--ds-text-danger, #DE350B)",
  'color.text.warning': "var(--ds-text-warning, #974F0C)",
  'color.text.warning.inverse': "var(--ds-text-warning-inverse, #172B4D)",
  'color.text.success': "var(--ds-text-success, #006644)",
  'color.text.discovery': "var(--ds-text-discovery, #403294)",
  'color.text.information': "var(--ds-text-information, #0052CC)",
  'color.text.subtlest': "var(--ds-text-subtlest, #7A869A)",
  'color.text.subtle': "var(--ds-text-subtle, #42526E)",
  'color.link': "var(--ds-link, #0052CC)",
  'color.link.pressed': "var(--ds-link-pressed, #0747A6)",
  'color.link.visited': "var(--ds-link-visited, #403294)",
  'color.link.visited.pressed': "var(--ds-link-visited-pressed, #403294)"
};
export var fillMap = {
  'color.icon': "var(--ds-icon, #505F79)",
  'color.icon.accent.lime': "var(--ds-icon-accent-lime, #6A9A23)",
  'color.icon.accent.red': "var(--ds-icon-accent-red, #FF5630)",
  'color.icon.accent.orange': "var(--ds-icon-accent-orange, #D94008)",
  'color.icon.accent.yellow': "var(--ds-icon-accent-yellow, #FFAB00)",
  'color.icon.accent.green': "var(--ds-icon-accent-green, #36B37E)",
  'color.icon.accent.teal': "var(--ds-icon-accent-teal, #00B8D9)",
  'color.icon.accent.blue': "var(--ds-icon-accent-blue, #0065FF)",
  'color.icon.accent.purple': "var(--ds-icon-accent-purple, #6554C0)",
  'color.icon.accent.magenta': "var(--ds-icon-accent-magenta, #CD519D)",
  'color.icon.accent.gray': "var(--ds-icon-accent-gray, #5E6C84)",
  'color.icon.disabled': "var(--ds-icon-disabled, #8993A4)",
  'color.icon.inverse': "var(--ds-icon-inverse, #FFFFFF)",
  'color.icon.selected': "var(--ds-icon-selected, #0052CC)",
  'color.icon.brand': "var(--ds-icon-brand, #0065FF)",
  'color.icon.danger': "var(--ds-icon-danger, #DE350B)",
  'color.icon.warning': "var(--ds-icon-warning, #FFC400)",
  'color.icon.warning.inverse': "var(--ds-icon-warning-inverse, #253858)",
  'color.icon.success': "var(--ds-icon-success, #00875A)",
  'color.icon.discovery': "var(--ds-icon-discovery, #8777D9)",
  'color.icon.information': "var(--ds-icon-information, #0747A6)",
  'color.icon.subtle': "var(--ds-icon-subtle, #6B778C)"
};
/**
 * @codegenEnd
 */

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 * @codegen <<SignedSource::28920e82ff201211ac94808a1fd821d4>>
 * @codegenId misc
 * @codegenCommand yarn workspace @atlaskit/primitives codegen-styles
 * @codegenParams ["layer"]
 * @codegenDependency ../../scripts/codegen-file-templates/dimensions.tsx <<SignedSource::cc9b3f12104c6ede803da6a42daac0b0>>
 * @codegenDependency ../../scripts/codegen-file-templates/layer.tsx <<SignedSource::6f10945ad9139d0119003738c65ae40a>>
 */
export var layerMap = {
  card: 100,
  navigation: 200,
  dialog: 300,
  layer: 400,
  blanket: 500,
  modal: 510,
  flag: 600,
  spotlight: 700,
  tooltip: 800
};
/**
 * @codegenEnd
 */

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 * @codegen <<SignedSource::957baf1fa3fc3fe5eb06b497a7ba6599>>
 * @codegenId border
 * @codegenCommand yarn workspace @atlaskit/primitives codegen-styles
 * @codegenParams ["width", "radius"]
 * @codegenDependency ../../../tokens/src/artifacts/tokens-raw/atlassian-shape.tsx <<SignedSource::80457377fd80c31f5dfcb5cc743d7016>>
 */
export var borderWidthMap = {
  'border.width': "var(--ds-border-width, 1px)",
  'border.width.0': "var(--ds-border-width-0, 0px)",
  'border.width.indicator': "var(--ds-border-width-indicator, 3px)",
  'border.width.outline': "var(--ds-border-width-outline, 2px)"
};
export var borderRadiusMap = {
  'border.radius.050': "var(--ds-border-radius-050, 2px)",
  'border.radius': "var(--ds-border-radius, 3px)",
  'border.radius.100': "var(--ds-border-radius-100, 3px)",
  'border.radius.200': "var(--ds-border-radius-200, 8px)",
  'border.radius.300': "var(--ds-border-radius-300, 12px)",
  'border.radius.400': "var(--ds-border-radius-400, 16px)",
  'border.radius.circle': "var(--ds-border-radius-circle, 32032px)"
};
/**
 * @codegenEnd
 */

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 * @codegen <<SignedSource::cd260411f17d54ff1ac7e627ef070ef4>>
 * @codegenId typography
 * @codegenCommand yarn workspace @atlaskit/primitives codegen-styles
 * @codegenParams ["fontSize", "fontWeight", "fontFamily", "lineHeight", "body", "ui"]
 * @codegenDependency ../../scripts/codegen-file-templates/dimensions.tsx <<SignedSource::cc9b3f12104c6ede803da6a42daac0b0>>
 * @codegenDependency ../../scripts/codegen-file-templates/layer.tsx <<SignedSource::6f10945ad9139d0119003738c65ae40a>>
 */
export var fontSizeMap = {
  medium: "var(--ds-font-body, normal 400 14px/20px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
  UNSAFE_small: "var(--ds-font-body-UNSAFE_small, normal 400 12px/16px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
  large: "var(--ds-font-body-large, normal 400 16px/24px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
  small: "var(--ds-font-body-small, normal 400 11px/16px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)"
};
export var fontWeightMap = {
  bold: "var(--ds-font-weight-bold, 700)",
  medium: "var(--ds-font-weight-medium, 500)",
  regular: "var(--ds-font-weight-regular, 400)",
  semibold: "var(--ds-font-weight-semibold, 600)"
};
export var fontFamilyMap = {
  'font.family.body': "var(--ds-font-family-body, ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
  'font.family.brand.body': "var(--ds-font-family-brand-body, \"Charlie Text\", ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
  'font.family.brand.heading': "var(--ds-font-family-brand-heading, \"Charlie Display\", ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
  'font.family.code': "var(--ds-font-family-code, ui-monospace, Menlo, \"Segoe UI Mono\", \"Ubuntu Mono\", monospace)",
  'font.family.heading': "var(--ds-font-family-heading, ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)"
};

/**
 * @codegenEnd
 */

// Generate maps for Box props. backgroundColor, padding, etc
var spacingProperties = [
// Used by Box
'padding', 'paddingBlock', 'paddingBlockStart', 'paddingBlockEnd', 'paddingInline', 'paddingInlineStart', 'paddingInlineEnd',
// Used by Inline and Stack
'gap', 'rowGap', 'columnGap'];
var getSerializedStylesMap = function getSerializedStylesMap(cssProperty, tokenMap) {
  return Object.keys(tokenMap).reduce(function (emotionSpacingMap, token) {
    emotionSpacingMap[token] = css(_defineProperty({}, cssProperty, tokenMap[token]));
    return emotionSpacingMap;
  }, {});
};
export var paddingStylesMap = spacingProperties.reduce(function (styleMap, spacingProperty) {
  styleMap[spacingProperty] = getSerializedStylesMap(spacingProperty, positiveSpaceMap);
  return styleMap;
}, {});
export var spaceStylesMap = spacingProperties.reduce(function (styleMap, spacingProperty) {
  styleMap[spacingProperty] = getSerializedStylesMap(spacingProperty, positiveSpaceMap);
  return styleMap;
}, {});
export var backgroundColorStylesMap = getSerializedStylesMap('backgroundColor', backgroundColorMap);
export var textColorStylesMap = getSerializedStylesMap('color', textColorMap);
export var fontWeightStylesMap = getSerializedStylesMap('fontWeight', fontWeightMap);
export var fontFamilyStylesMap = getSerializedStylesMap('fontFamily', fontFamilyMap);
export var fontStylesMap = getSerializedStylesMap('font', fontSizeMap);
export var surfaceColorStylesMap = getSerializedStylesMap(CURRENT_SURFACE_CSS_VAR, surfaceColorMap);
export var isSurfaceColorToken = function isSurfaceColorToken(color) {
  return surfaceColorMap[color] !== undefined;
};