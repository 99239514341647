/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { all, call, put, takeLatest } from 'redux-saga/effects';

import authRequest from 'src/utils/fetch';

import {
  capturePipelinesExceptionWithTags,
  createErrorMessage,
  isIgnoredErrorForSentry,
} from '../../utils/sentry';
import { REQUEST_ALLOWANCE } from '../actions/pipelines';

export const getAllowanceUrl = (accountUuid: string): string => {
  return `/!api/internal/accounts/${accountUuid}/pipelines_config/allowance`;
};

export const getIsUserTrustedUrl = (accountUuid: string): string => {
  return `/!api/internal/workspaces/${accountUuid}/pipelines-users/is-trusted`;
};

export function* requestAllowanceSaga(action: {
  meta: { accountUuid: string };
}): any {
  const { accountUuid } = action.meta;
  try {
    const allowanceUrl = getAllowanceUrl(accountUuid);
    const res: Response = yield call(fetch, authRequest(allowanceUrl));

    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const payload = yield call([res, 'json']);
    let isTrusted = undefined;
    try {
      const isTrustedUrl = getIsUserTrustedUrl(accountUuid);
      const isTrustedRes = yield call(fetch, authRequest(isTrustedUrl));
      if (!isTrustedRes || !isTrustedRes.ok) {
        throw new Error('Failed to fetch isTrusted status.');
      }
      const isTrustedPayload = yield call([isTrustedRes, 'json']);
      isTrusted = isTrustedPayload?.trusted;
    } catch (e) {
      capturePipelinesExceptionWithTags(e, {
        segment: 'PIPELINES_FAILED_TO_VALIDATE_USER_IS_TRUSTED',
      });
    }

    yield put({
      type: REQUEST_ALLOWANCE.SUCCESS,
      meta: action.meta,
      payload:
        isTrusted !== undefined
          ? { ...payload, is_trusted: isTrusted }
          : payload,
    });
  } catch (e) {
    if (!isIgnoredErrorForSentry(e)) {
      capturePipelinesExceptionWithTags(e, {
        segment: REQUEST_ALLOWANCE.ERROR,
      });
    }
    yield put({
      type: REQUEST_ALLOWANCE.ERROR,
      meta: action.meta,
      payload: e,
    });
  }
}

export function* allowanceSaga(): Generator {
  yield all([takeLatest(REQUEST_ALLOWANCE.REQUEST, requestAllowanceSaga)]);
}
