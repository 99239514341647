import { FC, memo } from 'react';

import { PageSegmentLoadMetric } from '@atlassian/browser-metrics/types';

import { startMetric } from './start-metric';

export type Props = {
  metric: PageSegmentLoadMetric;
};

/**
 * A convenient component wrapper for start-metric for use with PageSegmentLoadMetric
 */
export const PageSegmentMetricStarter: FC<Props> = memo(({ metric }) => {
  startMetric(metric);
  return null;
});
