import { ExperiencePerformanceTypes, ExperienceTypes, UFOExperience } from '@atlassian/ufo';
export var getUfoExperience = function getUfoExperience(moduleName, name) {
  var componentSuffix = shouldAppendComponentSuffix(moduleName) ? ".".concat(formatModuleName(moduleName)) : '';
  return new UFOExperience(name, {
    type: ExperienceTypes.Load,
    performanceType: ExperiencePerformanceTypes.Custom,
    platform: {
      component: "remote-module-loader".concat(componentSuffix)
    }
  });
};

// Replacing characters because UFO only supports alphanumeric characters, hyphens and dots
// https://atlassian.slack.com/archives/C01AY8132S3/p1717138073960519
export var formatModuleName = function formatModuleName(moduleName) {
  return moduleName.replace(/\//g, '-').replace(/[^a-zA-Z0-9-.]/g, '');
};

// For backward compatibility, empty suffix is added if the module name is empty
// or if it is the App Switcher module. This is needed because the App Switcher
// module used to be the only module being loaded.
export var shouldAppendComponentSuffix = function shouldAppendComponentSuffix(moduleName) {
  return moduleName !== '' && moduleName !== '@atlassian/app-switcher-remote-module';
};