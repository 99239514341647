import React from 'react';

import { useSelector } from 'react-redux';

import { SharedProps } from 'src/components/settings/create-settings-hoc';
import { SettingsConnectIframe as BaseSettingsConnectIframe } from 'src/connect/settings/settings-connect-iframe';
import { repositoryTarget } from 'src/connect/targets';
import { useParams } from 'src/router/hooks';
import { getRepositoryOwnerUuid } from 'src/sections/repository/containers/connect-page';
import { BucketState } from 'src/types/state';

import ConnectIframeHeader from '../components/connect-iframe-header';

import withSettings from './settings-hoc';

type Props = { modules?: string } & SharedProps;

const SettingsConnectIframe: React.FC<Props> = props => {
  const { repositoryOwner, repositorySlug, appKey, moduleKey } = useParams<{
    repositoryOwner: string;
    repositorySlug: string;
    appKey: string;
    moduleKey: string;
  }>();

  const repositoryFullSlug = `${repositoryOwner}/${repositorySlug}`;

  const principalId = useSelector((state: BucketState) =>
    getRepositoryOwnerUuid(state.entities, repositoryFullSlug)
  );

  const target = repositoryTarget({
    repositoryOwner: repositoryOwner || '',
    repositorySlug: repositorySlug || '',
  });

  return (
    <BaseSettingsConnectIframe
      appKey={appKey || ''}
      moduleKey={moduleKey || ''}
      principalId={principalId}
      target={target}
      {...props}
    />
  );
};

export default withSettings(SettingsConnectIframe, {
  header: ConnectIframeHeader,
});
