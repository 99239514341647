import { getExtensionAttrs } from '../../utils/extensions';

/**
 * @name bodiedExtension_node
 */

/**
 * @name bodiedExtension_with_marks_node
 */

var createBodiedExtensionNodeSpec = function createBodiedExtensionNodeSpec() {
  var nodeSpec = {
    inline: false,
    group: 'block',
    marks: 'dataConsumer fragment',
    content: '(paragraph | panel | blockquote | orderedList | bulletList | rule | heading | codeBlock | mediaGroup | mediaSingle | decisionList | taskList | table | blockCard | extension | unsupportedBlock | embedCard)+',
    defining: true,
    selectable: true,
    isolating: true,
    attrs: {
      extensionType: {
        default: ''
      },
      extensionKey: {
        default: ''
      },
      parameters: {
        default: null
      },
      text: {
        default: null
      },
      layout: {
        default: 'default'
      },
      localId: {
        default: null
      }
    },
    parseDOM: [{
      context: 'bodiedExtension//',
      tag: '[data-node-type="bodied-extension"]',
      skip: true
    }, {
      tag: '[data-node-type="bodied-extension"]',
      getAttrs: function getAttrs(domNode) {
        return getExtensionAttrs(domNode);
      }
    }],
    toDOM: function toDOM(node) {
      var attrs = {
        'data-node-type': 'bodied-extension',
        'data-extension-type': node.attrs.extensionType,
        'data-extension-key': node.attrs.extensionKey,
        'data-text': node.attrs.text,
        'data-parameters': JSON.stringify(node.attrs.parameters),
        'data-layout': node.attrs.layout,
        'data-local-id:': node.attrs.localId
      };
      return ['div', attrs, 0];
    }
  };
  return nodeSpec;
};
export var bodiedExtension = createBodiedExtensionNodeSpec();