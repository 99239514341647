import {
  MenuItem,
  PullRequestGlobalSearchResult,
  RepositoryGlobalSearchResult,
} from '@atlassian/bitbucket-navigation';

import isMobile from 'src/components/sidebar/src/is-mobile';
import { Issue } from 'src/components/types';
import ErrorCodes from 'src/constants/error-codes';
import { LoadingStatus } from 'src/constants/loading-status';
import {
  FetchUserEmails,
  LoadRepositoryPage,
} from 'src/sections/repository/actions';
import { ThemeSetting } from 'src/sections/repository/sections/source/types';
import { SiteMessage } from 'src/types/site-message';
import { Action } from 'src/types/state';
import { StatuspageIncident } from 'src/types/statuspage';
import createReducer from 'src/utils/create-reducer';
import { isUpToSmallBreakpoint } from 'src/utils/is-responsive-breakpoint';

import {
  LoadGlobal,
  TOGGLE_ATLASSIAN_SWITCHER,
  TOGGLE_CREATE_DRAWER,
  TOGGLE_KB_SHORTCUT_MENU,
  TOGGLE_FEEDBACK_BUTTON,
  TOGGLE_MOBILE_HEADER,
  TOGGLE_UP_TO_SMALL_BREAKPOINT,
  TOGGLE_SEARCH_DRAWER,
  TOGGLE_MARKETING_CONSENT_NEEDED,
  SEARCH,
  NETWORK_OFFLINE,
  NETWORK_ONLINE,
  UPDATE_MOBILE_HEADER_STATE,
  MobileHeaderState,
  OVERRIDE_FEATURE,
} from './actions';
import { PAGE_HIDDEN, PAGE_VISIBLE } from './actions/page-visibility';
import { LOAD_STATUSPAGE_INCIDENTS } from './actions/statuspage';
import {
  getGenericStatusPageIncident,
  getMajorIncidents,
} from './api/statuspage-api';

type UserEmail = {
  is_primary: boolean;
  email: string;
};

type TargetFeatures = {
  'adminhub-access-management'?: boolean;
};

export type GlobalState = {
  needsTermsAndConditions?: boolean;
  bitbucketActions: MenuItem[];
  horizontalNavigationItems: {
    mainItems: MenuItem[];
    secondaryItems: MenuItem[];
    settingsItems: MenuItem[];
  };
  currentUser: string | null | undefined;
  features: { [key: string]: boolean };
  focusedTaskBackButtonUrl: string | null | undefined;
  geoipCountry: string;
  isAtlassianSwitcherOpen: boolean;
  isCreateDrawerOpen: boolean;
  isFocusedTask: boolean;
  isKeyboardShortcutMenuOpen: boolean;
  isFeedbackModalOpen: boolean;
  isNavigationOpen: boolean;
  isMobileHeaderActive: boolean;
  isUpToSmallBreakpointActive: boolean;
  isOrgAdmin?: boolean;
  workspaceOrgId?: string;
  isWorkspaceSumEnabled?: boolean;
  isWorkspaceAdmin?: boolean;
  mobileHeaderState: MobileHeaderState;
  isSearchDrawerOpen: boolean;
  issues: Issue[];
  marketingConsentLocale: string;
  needsMarketingConsent: boolean;
  pullRequests: PullRequestGlobalSearchResult[];
  repositories: RepositoryGlobalSearchResult[];
  searchQuery: string;
  targetFeatures?: TargetFeatures;
  targetUser: string | null | undefined;
  theme: ThemeSetting | null | undefined;
  siteMessage?: SiteMessage | null;
  siteMessageStatuspageIncident?: SiteMessage | null;
  whatsNewUrl?: string;
  isPageVisible: boolean;
  isOffline: boolean;
  currentUserEmail?: string | null;
  repositoryPageLoadingStatus: {
    status: LoadingStatus;
    statusCode?: ErrorCodes;
  };
  workspaceBootstrapping: boolean | null;
};

const initialState: GlobalState = {
  needsTermsAndConditions: false,
  bitbucketActions: [],
  currentUser: null,
  features: {},
  focusedTaskBackButtonUrl: '',
  geoipCountry: '',
  horizontalNavigationItems: {
    mainItems: [],
    secondaryItems: [],
    settingsItems: [],
  },
  isAtlassianSwitcherOpen: false,
  isCreateDrawerOpen: false,
  isFocusedTask: false,
  isKeyboardShortcutMenuOpen: false,
  isFeedbackModalOpen: false,
  isNavigationOpen: true,
  isMobileHeaderActive: isMobile(),
  isUpToSmallBreakpointActive: isUpToSmallBreakpoint(),
  mobileHeaderState: 'none',
  isSearchDrawerOpen: false,
  issues: [],
  marketingConsentLocale: 'UNKNOWN',
  needsMarketingConsent: false,
  pullRequests: [],
  repositories: [],
  searchQuery: '',
  targetFeatures: {},
  targetUser: null,
  theme: null,
  isPageVisible: true,
  isOffline: false,
  repositoryPageLoadingStatus: {
    status: LoadingStatus.Before,
  },
  currentUserEmail: null,
  workspaceBootstrapping: null,
};

export default createReducer<GlobalState>(initialState, {
  [LoadGlobal.SUCCESS](state, action: Action): GlobalState {
    if (!action.payload) {
      return state;
    }
    const {
      needsTermsAndConditions,
      bitbucketActions,
      currentUser,
      features,
      focusedTaskBackButtonUrl,
      horizontalNavigationItems,
      marketing_consent_locale: marketingConsentLocale,
      needs_marketing_consent: needsMarketingConsent,
      whats_new_feed: whatsNewUrl,
      geoip_country: geoipCountry,
      isFocusedTask,
      isNavigationOpen,
      isOrgAdmin,
      workspaceOrgId,
      isWorkspaceSumEnabled,
      isWorkspaceAdmin,
      site_message: siteMessage,
      targetFeatures,
      targetUser,
      theme,
      workspaceBootstrapping,
    } = action.payload.result;

    return {
      ...state,
      needsTermsAndConditions,
      // As we are moving towards loading navigation data from Resources
      // instead of redux, some views may not have navigation data. In that
      // case, fall back to the existing state
      bitbucketActions: bitbucketActions ?? state.bitbucketActions,
      currentUser,
      features,
      focusedTaskBackButtonUrl,
      // As we are moving towards loading navigation data from Resources
      // instead of redux, some views may not have navigation data. In that
      // case, fall back to the existing state
      horizontalNavigationItems:
        horizontalNavigationItems ?? state.horizontalNavigationItems,
      isFocusedTask,
      isNavigationOpen,
      isOrgAdmin: !!isOrgAdmin && !!isWorkspaceSumEnabled,
      workspaceOrgId,
      isWorkspaceSumEnabled,
      isWorkspaceAdmin,
      marketingConsentLocale,
      needsMarketingConsent,
      whatsNewUrl,
      geoipCountry,
      siteMessage,
      targetFeatures,
      targetUser,
      theme,
      workspaceBootstrapping,
    };
  },

  [LOAD_STATUSPAGE_INCIDENTS.SUCCESS](state, action) {
    // If there are no Statuspage incidents, clear the state so we can resume rendering normal
    // SiteMessage banners if they exist.
    const incidents = action.payload || [];
    const majorIncidents: StatuspageIncident[] = getMajorIncidents(incidents);
    if (majorIncidents.length === 0) {
      return { ...state, siteMessageStatuspageIncident: null };
    }
    return {
      ...state,
      siteMessageStatuspageIncident:
        getGenericStatusPageIncident(majorIncidents),
    };
  },

  [SEARCH](state, action) {
    return {
      ...state,
      searchQuery: action.payload,
    };
  },

  [TOGGLE_ATLASSIAN_SWITCHER](state, action) {
    return {
      ...state,
      isAtlassianSwitcherOpen: action.payload,
      isCreateDrawerOpen: false,
      isSearchDrawerOpen: false,
    };
  },

  [TOGGLE_CREATE_DRAWER](state, action) {
    return {
      ...state,
      isAtlassianSwitcherOpen: false,
      isCreateDrawerOpen: action.payload,
      isSearchDrawerOpen: false,
    };
  },

  [TOGGLE_KB_SHORTCUT_MENU](state, action) {
    return {
      ...state,
      isKeyboardShortcutMenuOpen:
        action.payload !== undefined
          ? action.payload
          : !state.isKeyboardShortcutMenuOpen,
    };
  },
  [TOGGLE_FEEDBACK_BUTTON](state, action) {
    return {
      ...state,
      isFeedbackModalOpen:
        action.payload !== undefined
          ? action.payload
          : !state.isFeedbackModalOpen,
    };
  },
  [TOGGLE_MOBILE_HEADER](state, action) {
    return {
      ...state,
      isMobileHeaderActive: action.payload,
      mobileHeaderState: 'none' as MobileHeaderState,
    };
  },

  [UPDATE_MOBILE_HEADER_STATE](state, action) {
    return {
      ...state,
      mobileHeaderState: action.payload,
    };
  },

  [TOGGLE_UP_TO_SMALL_BREAKPOINT](state, action) {
    if (action.payload !== state.isUpToSmallBreakpointActive) {
      return {
        ...state,
        isUpToSmallBreakpointActive: action.payload,
      };
    }
    return state;
  },

  [TOGGLE_SEARCH_DRAWER](state, action) {
    return {
      ...state,
      isAtlassianSwitcherOpen: false,
      isCreateDrawerOpen: false,
      isSearchDrawerOpen:
        action.payload === undefined
          ? !state.isSearchDrawerOpen
          : action.payload,
      searchQuery: '',
    };
  },

  [TOGGLE_MARKETING_CONSENT_NEEDED](state, action) {
    return {
      ...state,
      needsMarketingConsent: action.payload,
    };
  },

  [PAGE_HIDDEN](state) {
    return {
      ...state,
      isPageVisible: false,
    };
  },

  [PAGE_VISIBLE](state) {
    return {
      ...state,
      isPageVisible: true,
    };
  },

  [NETWORK_OFFLINE](state) {
    return {
      ...state,
      isOffline: true,
    };
  },

  [NETWORK_ONLINE](state) {
    return {
      ...state,
      isOffline: false,
    };
  },
  [LoadRepositoryPage.SUCCESS](state) {
    return {
      ...state,
      repositoryPageLoadingStatus: {
        status: LoadingStatus.Success,
      },
    };
  },
  [LoadRepositoryPage.ERROR](state, action) {
    if (
      action.meta &&
      action.meta.status &&
      [ErrorCodes.FORBIDDEN, ErrorCodes.NOT_FOUND].includes(action.meta.status)
    ) {
      return {
        ...state,
        repositoryPageLoadingStatus: {
          status: LoadingStatus.Failed,
          statusCode: action.meta.status,
        },
      };
    }

    return {
      ...state,
    };
  },
  [FetchUserEmails.SUCCESS](state, action) {
    const emailData: UserEmail = action.payload.values.find(
      (data: UserEmail) => data.is_primary
    );

    if (emailData) {
      return {
        ...state,
        currentUserEmail: emailData.email,
      };
    }

    return {
      ...state,
    };
  },

  // Used for overriding features in tests.
  [OVERRIDE_FEATURE](state, action) {
    const { featureKey, featureValue } = action.payload;
    return {
      ...state,
      features: {
        ...state.features,
        [featureKey]: featureValue,
      },
    };
  },
});
