export var listItem = {
  content: '(paragraph | mediaSingle | codeBlock | unsupportedBlock) (paragraph | bulletList | orderedList | mediaSingle | codeBlock | unsupportedBlock)*',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  defining: true,
  selectable: false,
  parseDOM: [{
    tag: 'li'
  }],
  toDOM: function toDOM() {
    return ['li', 0];
  }
};

/**
 * @name list_item_with_task
 * @description this node allows task-list to be nested inside list-item
 */
export var listItemWithTask = {
  content: '(paragraph | mediaSingle | codeBlock | unsupportedBlock) (paragraph | bulletList | orderedList | taskList | mediaSingle | codeBlock | unsupportedBlock)*',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  defining: true,
  selectable: false,
  parseDOM: [{
    tag: 'li'
  }],
  toDOM: function toDOM() {
    return ['li', 0];
  }
};