import { createResource } from 'react-resource-router';

import { FeatureKeys } from '@atlassian/bitbucket-features';

import { PIPELINE_CREATORS_PAGE_SIZE } from 'src/components/pipelines/constants';
import { setPipelineCreators } from 'src/components/pipelines/redux/actions/pipelines';
import { getPipelinesUrl } from 'src/components/pipelines/redux/sagas/pipelines';
import { ResourceContext } from 'src/router/types';

import { fetchData } from './utils';

export type Params = {
  repositoryFullSlug: string;
  branch: string;
  page: string;
};

export const getUrl = (params: Params): string => {
  return getPipelinesUrl(params.repositoryFullSlug, {
    pagelen: PIPELINE_CREATORS_PAGE_SIZE,
    fields: '-values.*,+values.creator,+values.trigger',
  });
};

export const loadPipelineCreatorsResource = createResource({
  type: 'pipeline-creators',
  getKey: ({ match: { params } }) => getUrl(params as Params),
  maxAge: 0,
  getData: async (
    { match: { params } },
    { reduxStore, appData }: ResourceContext
  ) => {
    if (!appData?.features[FeatureKeys.isPipelinesPageLoadOptimisationEnabled])
      return null;

    const { dispatch } = reduxStore;
    const data = await fetchData(
      getUrl(params as Params),
      'pipeline_creators_resource_exception'
    );
    dispatch(setPipelineCreators(data));
    return { status: 'success' };
  },
});
