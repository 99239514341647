(function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory() :
  typeof define === 'function' && define.amd ? define(factory) :
  (global.map = factory());
}(this, (function () { 'use strict';

  // a timeout function that can be flushed and cancelled
  var flushable = function flushable(onComplete, delay) {
    var timeoutId = setTimeout(function () {
      timeoutId = null;
      onComplete(false);
    }, delay);

    var clearTimer = function clearTimer() {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
    };

    return {
      cancel: clearTimer,
      flush: function flush() {
        if (!timeoutId) {
          return;
        }

        clearTimer();
        onComplete(true);
      },
      pending: function pending() {
        return Boolean(timeoutId);
      }
    };
  };

  return flushable;

})));
