import { createContext, useContext } from 'react';

import type { PostOfficeContextValue } from './types';

export const defaultPostOfficeContextValue: PostOfficeContextValue = {
	product: undefined,
	subproduct: undefined,
	locale: undefined,
	isSiteAdmin: undefined,
	tenantId: undefined,
	workspaceId: undefined,
	orgId: undefined,
};

export const PostOfficeContext = createContext<PostOfficeContextValue>(
	defaultPostOfficeContextValue,
);

export const InternalPostOfficeContextProvider = PostOfficeContext.Provider;

/**
 * @deprecated Since version 0.1 - will be removed in v1.0.
 * Use PostOfficeProvider instead.
 *
 * `PostOfficeContextProvider` is a React context provider used to pass down
 * `PostOfficeContextValue` to every placement
 *
 * @context
 * @typedef {Object} PostOfficeContextValue
 * @property {string} [orgId] - The unique identifier for the organization. Optional.
 * @property {string | undefined} product - The name of the product. Can be `undefined`.
 * @property {string} [subproduct] - The name of the subproduct, if any. Optional.
 * @property {string} [tenantId] - The unique tenant identifier. Optional.
 * @property {boolean} [isSiteAdmin] - Flag indicating if the current user is a
 * site administrator. Optional.
 * @property {string} [workspaceId] - The identifier for the workspace. Optional.
 * @property {string | undefined} locale - The current locale as a string. Can be `undefined`.
 *
 * */
export const PostOfficeContextProvider = InternalPostOfficeContextProvider;

export const usePostOfficeContext = (): PostOfficeContextValue => useContext(PostOfficeContext);
