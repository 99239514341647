import { useRouter } from 'react-resource-router';

import getUFORouteName from '@atlassian/ufo-route-name';
import traceUFOTransition from '@atlassian/ufo-trace-transition';

import { getPageLoadType } from '../performance-metrics/get-page-load-type';

export const useReactUFOWatcher = (): void => {
  const [routerState] = useRouter();
  const { route, action } = routerState;
  const pageLoadType = getPageLoadType();
  if (route.name && pageLoadType !== 'full_page' && action !== 'REPLACE') {
    const ufoName = getUFORouteName(route).toLowerCase();
    // Ignore REPLACE actions because they are redirects
    traceUFOTransition(ufoName, route.name);
  }
};
