import initialViewName from 'src/utils/initial-view-name';
import { statsdApiClient } from 'src/utils/metrics';
import { appWasServerSideRendered } from 'src/utils/ssr';

import { getPageLoadDuration } from './get-page-load-duration';
import { getPageLoadType } from './get-page-load-type';
import { shouldSendMetrics } from './should-send-metrics';

export type PageLoadMetricOptions = {
  customEndEvent?: string;
  customStartEvent?: keyof PerformanceTiming;
  tags?: string[];
};

export function sendPageLoadMetric(
  metricName: string,
  options: PageLoadMetricOptions = {}
) {
  const { customEndEvent, customStartEvent, tags: extraTags = [] } = options;

  if (!shouldSendMetrics()) {
    return;
  }

  const loadType = getPageLoadType();
  const duration = getPageLoadDuration(
    metricName,
    customStartEvent,
    customEndEvent
  );

  if (!duration || !loadType) {
    return;
  }

  const tags = [
    'source:frontbucket',
    `loadType:${loadType}`,
    `appWasServerSideRendered:${appWasServerSideRendered()}`,
    ...extraTags,
  ];

  if (loadType === 'full_page') {
    tags.push(`view_name:${initialViewName}`);
  }

  statsdApiClient.histogram({ [metricName]: duration }, { tags });
}
