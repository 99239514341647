/** @jsx jsx */

import { css, jsx } from '@emotion/react';
var formFooterWrapperStyles = css({
  display: 'flex',
  marginTop: "var(--ds-space-300, 24px)",
  justifyContent: 'flex-end'
});
var justifyContentStyles = css({
  justifyContent: 'flex-start'
});

/**
 * __Form footer__
 *
 * A form footer has the content to be shown at the bottom of the form. This is usually the submit button.
 *
 * - [Examples](https://atlaskit.atlassian.com/packages/design-system/form/docs/layout)
 * - [Code](https://atlaskit.atlassian.com/packages/design-system/form/docs/layout)
 * - [Usage](https://atlaskit.atlassian.com/packages/design-system/form/docs/layout)
 */
export default function FormFooter(_ref) {
  var _ref$align = _ref.align,
    align = _ref$align === void 0 ? 'end' : _ref$align,
    children = _ref.children;
  return jsx("footer", {
    css: [formFooterWrapperStyles, align === 'start' && justifyContentStyles]
  }, children);
}