import { ALIGNMENT, INDENTATION } from '../groups';

/** TODO: Flip these positions for RTL */
export var alignmentPositionMap = {
  end: 'right',
  right: 'end',
  center: 'center'
};

/**
 * @name alignment_mark
 */

export var alignment = {
  excludes: "alignment ".concat(INDENTATION),
  group: ALIGNMENT,
  attrs: {
    align: {}
  },
  parseDOM: [{
    tag: 'div.fabric-editor-block-mark',
    getAttrs: function getAttrs(dom) {
      var align = dom.getAttribute('data-align');
      return align ? {
        align: align
      } : false;
    }
  }],
  toDOM: function toDOM(mark) {
    return ['div', {
      class: "fabric-editor-block-mark fabric-editor-alignment fabric-editor-align-".concat(mark.attrs.align),
      'data-align': mark.attrs.align
    }, 0];
  }
};