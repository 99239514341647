import { useContext, useMemo } from 'react';
import UFOInteractionContext from '@atlassian/ufo-interaction-context';
import { getInteractionId } from '@atlassian/ufo-interaction-id-context';
import { addCustomData } from '@atlassian/ufo-interaction-metrics';
export default function UFOCustomData(_ref) {
  var data = _ref.data;
  var interactionContext = useContext(UFOInteractionContext);
  useMemo(function () {
    if (!interactionContext) {
      return;
    }
    interactionContext.addCustomData(data);
  }, [data, interactionContext]);
  return null;
}
export function addUFOCustomData(data) {
  var interactionId = getInteractionId();
  var currentInteractionId = interactionId.current;
  if (!currentInteractionId) {
    return;
  }
  addCustomData(currentInteractionId, [], data);
}