/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { select, call, put } from 'redux-saga/effects';

import { FeatureKeys, getFlagValue } from '@atlassian/bitbucket-features';

import { REQUEST_CHANGES } from 'src/redux/pull-request/actions';
import urls from 'src/redux/pull-request/urls';
import { updateLastReviewedCommit } from 'src/sections/repository/sections/pull-request/components/iterative-review/utils';
import { getCurrentUser } from 'src/selectors/user-selectors';
import authRequest from 'src/utils/fetch';

import { ITERATIVE_REVIEW_COMPLETED } from '../iterative-review-reducer';
import {
  getCurrentPullRequestUrlPieces,
  getPullRequestSourceHash,
} from '../selectors';

import updateParticipantsSaga from './update-participants-saga';
import { getErrorMessage } from './utils/get-error-message';

function* requestPullRequestChanges() {
  const { owner, slug, id } = yield select(getCurrentPullRequestUrlPieces);
  // @ts-ignore
  const sourceBranchHash = yield select(getPullRequestSourceHash);
  const usePRService = (yield call(
    getFlagValue,
    FeatureKeys.usePRServiceIR,
    false
  )) as boolean;

  // @ts-ignore
  const currentUser = yield select(getCurrentUser);

  const url = urls.api.v20.requestChanges(owner, slug, id);
  const authUrl = authRequest(url);

  try {
    const response: Response = yield call(fetch, authUrl, { method: 'POST' });

    if (!response.ok) {
      const message: string = yield call(getErrorMessage, response.clone());
      throw Error(message);
    }

    yield call(updateParticipantsSaga);
    yield put({ type: REQUEST_CHANGES.SUCCESS, payload: { currentUser } });
    yield call(
      updateLastReviewedCommit,
      sourceBranchHash,
      owner,
      slug,
      id,
      usePRService
    );
    // Publish a success event to trigger sagas to mark the iterative review as completed.
    yield put({ type: ITERATIVE_REVIEW_COMPLETED.TRUE });
  } catch (e) {
    yield put({
      type: REQUEST_CHANGES.ERROR,
      payload: e.message,
      error: true,
    });
  }
}

export default requestPullRequestChanges;
