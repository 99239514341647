// Used for overwriting ariaLiveMessages builtin onFocus method.
// Returns custom built string while focusing each group option. This string is used for screen reader announcement.
export function onFocus(props) {
  var _groupData$options$fi;
  var focused = props.focused,
    options = props.options;
  var isOptionFocused = function isOptionFocused(option) {
    return option === focused;
  };
  var groupData = options === null || options === void 0 ? void 0 : options.find(function (option) {
    var _option$options;
    return (_option$options = option.options) === null || _option$options === void 0 ? void 0 : _option$options.some(isOptionFocused);
  });
  var groupOptionIndex = (_groupData$options$fi = groupData === null || groupData === void 0 ? void 0 : groupData.options.findIndex(isOptionFocused)) !== null && _groupData$options$fi !== void 0 ? _groupData$options$fi : 0;
  return "Option ".concat(focused.label, ", ").concat(groupData === null || groupData === void 0 ? void 0 : groupData.label, " group, item ").concat(groupOptionIndex + 1, " out of ").concat(groupData === null || groupData === void 0 ? void 0 : groupData.options.length, ". All in all ");
}

// Helper function which identifies if options are grouped.
export var isOptionsGrouped = function isOptionsGrouped(arr) {
  return arr === null || arr === void 0 ? void 0 : arr.every(function (obj) {
    return obj.hasOwnProperty('options');
  });
};