/**
 * Character key codes which can be used in keyboard events.
 */
export var KEY_UP = 'ArrowUp';
export var KEY_DOWN = 'ArrowDown';
export var KEY_HOME = 'Home';
export var KEY_END = 'End';
export var KEY_TAB = 'Tab';
export var KEY_ENTER = 'Enter';
export var KEY_SPACE = 'Space';
export var ESCAPE = 'Escape';