import React from 'react';

import { FormattedMessage } from 'react-intl';

/* eslint frontbucket-patterns/no-old-dropdown-menu: "warn" */
import DropdownMenu, {
  DropdownItemGroup,
  DropdownItem,
} from '@atlaskit/dropdown-menu';

import { useIntl } from 'src/hooks/intl';

import messages from './group.i18n';
import { DropdownItemContent } from './group.style';

export enum Privacy {
  public = 'public',
  private = 'private',
}

export type PrivacyDropdownProps = {
  onChange: (value: Privacy | null) => void;
  value: Privacy | null;
  allowEmptyChoice?: boolean;
};

const PrivacyDropdown: React.FC<PrivacyDropdownProps> = ({
  value,
  onChange,
  allowEmptyChoice,
}) => {
  const { formatMessage } = useIntl();

  const getTriggerMessage = () => {
    if (value !== null) {
      return formatMessage(messages[value]);
    }

    return formatMessage(messages.privacy);
  };

  const getItem = (
    choice: Privacy | null,
    label: FormattedMessage.MessageDescriptor
  ) => (
    <DropdownItem
      onClick={() => onChange(choice)}
      isDisabled={value === choice}
    >
      <DropdownItemContent>{formatMessage(label)}</DropdownItemContent>
    </DropdownItem>
  );

  return (
    <DropdownMenu
      testId="privacyDropdown"
      boundariesElement="scrollParent"
      triggerType="button"
      trigger={getTriggerMessage()}
      triggerButtonProps={{
        shouldFitContainer: true,
        className: 'triggerButton',
      }}
    >
      <DropdownItemGroup>
        {allowEmptyChoice && getItem(null, messages.all)}
        {getItem(Privacy.public, messages.public)}
        {getItem(Privacy.private, messages.private)}
      </DropdownItemGroup>
    </DropdownMenu>
  );
};

export default PrivacyDropdown;
