import { MergeStrategy, SyncStrategy } from 'src/types/pull-request';

import { Link } from './link';
import { RenderedContent } from './rendered-content';
import { Repository } from './repository';
import { User } from './user';

type BBPullRequestCommit = {
  type: 'commit';
  hash: string;
  message?: string;
  author?: {
    type: string;
    raw: string;
    user: User | null | undefined;
  };
  date?: string;
  links: {
    self: Link;
    html: {
      href: string;
    };
  };
  summary?: RenderedContent;
};

type BBPullRequestRepo = {
  branch: {
    name: string;
    merge_strategies: MergeStrategy[];
    sync_strategies?: SyncStrategy[];
  };
  commit: BBPullRequestCommit;
  repository: Repository | null | undefined;
};

type BBPullRequestDestinationRepo = BBPullRequestRepo & {
  repository: Repository;
};

type BBPullRequestParticipant = {
  approved: boolean;
  state: ParticipantState;
  participated_on: string | null;
  role: 'REVIEWER' | 'PARTICIPANT';
  type: 'participant';
  user: User | null | undefined;
};

export enum ParticipantStateTypes {
  approved = 'approved',
  changesRequested = 'changes_requested',
}

export type ParticipantState =
  | ParticipantStateTypes.approved
  | ParticipantStateTypes.changesRequested
  | null;

// States taken from Bitbucket: /apps/pullrequests/serializers.py
type BBPullRequestState = 'OPEN' | 'MERGED' | 'DECLINED' | 'SUPERSEDED';
export enum DiffType {
  PREVIEW_MERGE_DIFF = 1,
  TOPIC_DIFF = 2,
}
type BBPullRequest = {
  author: User | null | undefined;
  close_source_branch: boolean;
  closed_by: User | null | undefined;
  comment_count: number;
  created_on: string;
  closed_on?: string | null | undefined;
  latest_excluded_files_version?: string | null | undefined;
  description: string;
  diff_type: DiffType;
  destination: BBPullRequestDestinationRepo;
  source: BBPullRequestRepo;
  id: number;
  links: {
    activity: Link;
    approve: Link;
    request_changes: Link;
    comments: Link;
    commits: Link;
    decline: Link;
    diff: Link;
    diffstat: Link;
    html: Link;
    merge: Link;
    self: Link;
    statuses: Link;
  };
  merge_commit: BBPullRequestCommit;
  participants: BBPullRequestParticipant[];
  reason: string;
  rendered: {
    title: RenderedContent;
    description: RenderedContent;
    reason?: RenderedContent;
  };
  reviewers: User[];
  state: BBPullRequestState;
  summary?: RenderedContent;
  task_count: number;
  title: string;
  type: 'pullrequest';
  updated_on: string;
};

declare global {
  namespace BB {
    type PullRequest = BBPullRequest;
    type PullRequestCommit = BBPullRequestCommit;
    type PullRequestParticipant = BBPullRequestParticipant;
    type PullRequestRepo = BBPullRequestRepo;
    type PullRequestState = BBPullRequestState;
  }

  namespace Models {
    type PullRequest<T extends DeepPartial<BBPullRequest>> = T;
    type PullRequestRepo<T extends DeepPartial<BBPullRequestRepo>> = T;
  }
}

export type PullRequest = BBPullRequest;
export type PullRequestCommit = BBPullRequestCommit;
export type PullRequestParticipant = BBPullRequestParticipant;
export type PullRequestRepo = BBPullRequestRepo;
export type PullRequestState = BBPullRequestState;
