/**
 * @name rule_node
 */

var hrDOM = ['hr'];
export var rule = {
  group: 'block',
  parseDOM: [{
    tag: 'hr'
  }],
  toDOM: function toDOM() {
    return hrDOM;
  }
};