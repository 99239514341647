import React, { useEffect } from 'react';

import { useQuery } from '@apollo/react-hooks';
import { captureException } from '@sentry/browser';
import { gql } from 'apollo-boost';

import defaultIcon from './default-icon.svg';
import { Avatar } from './styled';

const FORGE_APP_ARI_PREFIX = 'ari:cloud:ecosystem::app/';

export const FORGE_APP_AVATAR_URL_QUERY = gql`
  query ForgeAppAvatar($appId: ID!) {
    app(id: $appId) {
      avatarUrl
      avatarFileId
    }
  }
`;

type ForgeAppAvatarUrlData = {
  app: {
    avatarUrl: string | null;
    avatarFileId: string | null;
  } | null;
};

type ForgeAppAvatarUrlVariables = {
  appId: string;
};

type ForgeAppAvatarProps = {
  // Forge app UUID
  appId: string;
};

export const ForgeAppAvatar = ({ appId }: ForgeAppAvatarProps): JSX.Element => {
  const { data, error } = useQuery<
    ForgeAppAvatarUrlData,
    ForgeAppAvatarUrlVariables
  >(FORGE_APP_AVATAR_URL_QUERY, {
    variables: { appId: FORGE_APP_ARI_PREFIX + appId },
  });

  useEffect(() => {
    if (error) {
      captureException(error);
    }
  }, [error]);

  return (
    <Avatar
      src={
        // If avatarFileId is null, the app does not have a custom avatar set
        data?.app?.avatarFileId && data?.app?.avatarUrl
          ? data.app.avatarUrl
          : defaultIcon
      }
    />
  );
};
