import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { createTheme } from '@atlaskit/theme/components';
import { getCss } from '../shared/css';
var stateToSelectorMap = {
  focus: '&:focus',
  focusSelected: '&:focus',
  hover: '&:hover',
  active: '&:active',
  disabled: '&[disabled]'
};

// Mapping the new clean css back to the legacy theme format.
// The legacy theme format has all styles at the top level (no nested selectors)
// and uses `getSpecifiers()` to apply the style to all pseudo states
export function getCustomCss(_ref) {
  var _ref$appearance = _ref.appearance,
    appearance = _ref$appearance === void 0 ? 'default' : _ref$appearance,
    _ref$spacing = _ref.spacing,
    spacing = _ref$spacing === void 0 ? 'default' : _ref$spacing,
    _ref$mode = _ref.mode,
    mode = _ref$mode === void 0 ? 'light' : _ref$mode,
    _ref$isSelected = _ref.isSelected,
    isSelected = _ref$isSelected === void 0 ? false : _ref$isSelected,
    _ref$shouldFitContain = _ref.shouldFitContainer,
    shouldFitContainer = _ref$shouldFitContain === void 0 ? false : _ref$shouldFitContain,
    _ref$iconIsOnlyChild = _ref.iconIsOnlyChild,
    iconIsOnlyChild = _ref$iconIsOnlyChild === void 0 ? false : _ref$iconIsOnlyChild,
    _ref$isLoading = _ref.isLoading,
    isLoading = _ref$isLoading === void 0 ? false : _ref$isLoading,
    state = _ref.state;
  var result = getCss({
    appearance: appearance,
    spacing: spacing,
    mode: mode,
    isSelected: isSelected,
    shouldFitContainer: shouldFitContainer,
    isOnlySingleIcon: iconIsOnlyChild
  });

  // we need to disable the default browser focus styles always
  // this is because we are not expressing that we can have two pseudo states at a time
  result.outline = 'none';

  // Pulling relevant styles up to the top level
  var selector = stateToSelectorMap[state];
  if (selector) {
    result = _objectSpread(_objectSpread({}, result), result[selector]);
  }
  if (isLoading) {
    result = _objectSpread(_objectSpread({}, result), result['&[data-has-overlay="true"]']);
  }

  // Delete all selectors and just keep root styles
  Object.keys(result).forEach(function (key) {
    // want to keep this one
    if (key === '&::-moz-focus-inner') {
      return;
    }

    // Not using .startsWith for ie11
    if (key.indexOf('&') === 0) {
      delete result[key];
    }
  });
  return result;
}

// This styling approach works by generating a 'style' and applying with maximum specificity
// To do this we are overwriting all pseudo selectors
export function getSpecifiers(styles) {
  return {
    '&, &:hover, &:active, &:focus, &:focus-visible, &:visited, &:disabled, &[disabled]': styles
  };
}
export function defaultThemeFn(current, values) {
  return current(values);
}
var Theme = createTheme(function (themeProps) {
  return {
    buttonStyles: getCustomCss(themeProps),
    // No styles being applied directly to spinner by default
    // Keeping this for legacy compat. We could remove it, but given
    // that we are changing theme soon there is no point
    spinnerStyles: {}
  };
});

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default Theme;