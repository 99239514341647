import { createResource } from 'react-resource-router';

import { createMergeChecksActivationApi } from './requests';
import { MergeChecksActivationData } from './types';

export const mergeChecksActivationResource =
  createResource<MergeChecksActivationData>({
    type: 'mergeChecksActivationResource',
    getKey: ({ match }) => {
      return `mergeChecksActivationResource.${match.params.workspaceSlug}`;
    },
    maxAge: 1,
    getData: ({ match }) => {
      const { workspaceSlug } = match.params;
      if (!workspaceSlug) {
        throw new Error(
          'Could not fetch merge checks activation, URL params were not available'
        );
      }
      const api = createMergeChecksActivationApi(workspaceSlug);

      return api.getActivation();
    },
  });
