import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/**
 * @name blockCard_node
 */

var getCommonAttributesFromDom = function getCommonAttributesFromDom(dom) {
  var anchor = dom;
  var data = anchor.getAttribute('data-card-data');
  var datasource = anchor.getAttribute('data-datasource');
  return {
    data: data ? JSON.parse(data) : undefined,
    layout: datasource ? dom.getAttribute('data-layout') || undefined : undefined,
    width: Number(dom.getAttribute('data-width')) || undefined,
    datasource: datasource ? JSON.parse(datasource) : undefined
  };
};
export var blockCard = {
  inline: false,
  group: 'block',
  draggable: true,
  selectable: true,
  attrs: {
    url: {
      default: null
    },
    data: {
      default: null
    },
    datasource: {
      default: null
    },
    width: {
      default: null
    },
    layout: {
      default: null
    }
  },
  parseDOM: [{
    tag: 'a[data-block-card]',
    // bump priority higher than hyperlink
    priority: 100,
    getAttrs: function getAttrs(dom) {
      var anchor = dom;
      return _objectSpread({
        url: anchor.getAttribute('href') || undefined
      }, getCommonAttributesFromDom(dom));
    }
  }, {
    tag: 'div[data-block-card]',
    getAttrs: function getAttrs(dom) {
      var anchor = dom;
      return _objectSpread({
        url: anchor.getAttribute('data-card-url') || undefined
      }, getCommonAttributesFromDom(dom));
    }
  }],
  toDOM: function toDOM(node) {
    var _node$attrs;
    var _ref = node.attrs,
      url = _ref.url;
    var _ref2 = node.attrs,
      data = _ref2.data;
    var _ref3 = node.attrs,
      layout = _ref3.layout,
      width = _ref3.width,
      datasource = _ref3.datasource;
    var attrs = {
      'data-block-card': '',
      href: url || '',
      'data-card-data': data ? JSON.stringify(data) : '',
      'data-datasource': datasource ? JSON.stringify(datasource) : '',
      'data-layout': layout,
      'data-width': "".concat(width)
    };
    return ['a', attrs, (node === null || node === void 0 ? void 0 : (_node$attrs = node.attrs) === null || _node$attrs === void 0 ? void 0 : _node$attrs.url) || ' '];
  }
};