import { Commit } from 'src/components/types';
import { BuildStatusCountsMap } from 'src/types';
import { short12Hash } from 'src/utils/short-hash';

export function createCommitStatusMapper(statuses: BuildStatusCountsMap) {
  return (commit: Commit) => {
    const status = statuses[short12Hash(commit.hash)];
    return {
      ...commit,
      extra: {
        ...commit.extra,
        builds: status ? [status.commit_status] : [],
        status_counts: status ? status.status_counts : undefined,
      },
    };
  };
}
