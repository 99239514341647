import { FONT_STYLE } from '../groups';

/**
 * @name em_mark
 */

var emDOM = ['em'];
export var em = {
  inclusive: true,
  group: FONT_STYLE,
  parseDOM: [{
    tag: 'i'
  }, {
    tag: 'em'
  }, {
    style: 'font-style=italic'
  }],
  toDOM: function toDOM() {
    return emDOM;
  }
};