import { defineMessages } from 'react-intl';

import { CustomMergeChecksResourceType } from './types';

export const contextMessages = {
  [CustomMergeChecksResourceType.Repository]: defineMessages({
    description: {
      id: 'frontbucket.settings.merge-checks-tabs.repository-description',
      description: 'Page description section for Repository Checks',
      defaultMessage:
        'Merge checks can improve code quality by setting restrictions about when pull requests can be merged. Merge checks are installed by workspace administrators and can be enabled for repositories in a workspace. {learnMoreLink}.',
    },
    learnMoreLinkLabel: {
      id: 'frontbucket.settings.merge-checks-tabs.repository-learn-more-link-label',
      description: 'Label for the learnMoreLink',
      defaultMessage: 'Learn more about merge checks',
    },
  }),
};
