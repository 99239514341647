/**
 * @name paragraph_node
 */

/**
 * @name paragraph_with_no_marks_node
 */

/**
 * NOTE: Need this because TS is too smart and inline everything.
 * So we need to give them separate identity.
 * Probably there's a way to solve it but that will need time and exploration.
 * // http://bit.ly/2raXFX5
 * type T1 = X | Y
 * type T2 = A | T1 | B // T2 = A | X | Y | B
 */

/**
 * @name paragraph_with_alignment_node
 */

/**
 * @name paragraph_with_indentation_node
 */

export var paragraphStage0 = {
  selectable: false,
  content: 'inline*',
  group: 'block',
  attrs: {
    localId: {
      default: null
    }
  },
  marks: 'strong code em link border strike subsup textColor backgroundColor typeAheadQuery underline confluenceInlineComment action annotation unsupportedMark unsupportedNodeAttribute dataConsumer fragment',
  parseDOM: [{
    tag: 'p',
    getAttrs: function getAttrs(domNode) {
      var _paragraph$attrs, _paragraph$attrs$loca;
      var dom = domNode;
      var attrs = {
        localId: dom.getAttribute('data-local-id') || ((_paragraph$attrs = paragraph.attrs) === null || _paragraph$attrs === void 0 ? void 0 : (_paragraph$attrs$loca = _paragraph$attrs.localId) === null || _paragraph$attrs$loca === void 0 ? void 0 : _paragraph$attrs$loca.default)
      };
      return attrs;
    }
  }],
  toDOM: function toDOM(node) {
    var localId = node.attrs.localId;
    var name = 'p';
    var attrs = localId !== undefined && localId !== null ? [{
      'data-local-id': localId
    }] : [];
    return [name].concat(attrs, [0]);
  }
};
export var paragraph = {
  selectable: false,
  content: 'inline*',
  group: 'block',
  marks: 'strong code em link border strike subsup textColor backgroundColor typeAheadQuery underline confluenceInlineComment action annotation unsupportedMark unsupportedNodeAttribute dataConsumer fragment',
  parseDOM: [{
    tag: 'p'
  }],
  toDOM: function toDOM() {
    return ['p', 0];
  }
};
export var paragraphNodeSpec = function paragraphNodeSpec() {
  var adfStage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'full';
  return adfStage === 'stage0' ? paragraphStage0 : paragraph;
};