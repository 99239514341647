import { N30, N800, R400, Y400 } from '@atlaskit/theme/colors';
import { gridSize } from '@atlaskit/theme/constants';
export var WIDTH_ENUM = {
  values: ['small', 'medium', 'large', 'x-large'],
  widths: {
    small: 400,
    medium: 600,
    large: 800,
    'x-large': 968
  },
  defaultValue: 'medium'
};
export var gutter = 60;
export var verticalOffset = gridSize() * 2;
export var modalPadding = gridSize() * 3;
export var actionMargin = gridSize();
export var titleMargin = gridSize();
export var keylineHeight = 2;
export var keylineColor = N30;
export var focusOutlineColor = N800;
export var dangerColor = R400;
export var warningColor = Y400;