import React, { useState, useEffect, useCallback } from 'react';

import * as Sentry from '@sentry/browser';

import Avatar from '@atlaskit/avatar';
import LoadingButton from '@atlaskit/button/loading-button';
import DynamicTable from '@atlaskit/dynamic-table';
import Lozenge from '@atlaskit/lozenge';
import Panel from '@atlaskit/panel';
import { relativeDateString } from '@atlassian/bitkit-date';

import NameCell from 'src/components/settings/name-cell';
import {
  Invitation,
  InvitationsResponse,
} from 'src/components/types/src/api/v1/invitation';
import { useFlag } from 'src/hooks/flag';
import { useIntl } from 'src/hooks/intl';
import { useObjectState } from 'src/hooks/use-object-state';

import { useIsSumEnabled } from '../permissions';

import messages from './invitations.i18n';
import {
  PanelWrapper,
  PanelHeader,
  LozengeContainer,
} from './invitations.style';
import {
  getWorkspaceInvitations,
  getProjectInvitations,
  getRepositoryInvitations,
  sendWorkspaceInvitation,
  sendRepoInvitation,
  deleteWorkspaceInvitation,
  deleteRepoInvitation,
  sendProjectInvitation,
  deleteProjectInvitation,
} from './request-methods';

const EMAIL_HEADER_WIDTH = 54;
const ACTIONS_HEADER_WIDTH = 23;

export type InvitationsPanelProps = {
  refreshCount?: number;
  workspaceSlug: string;
  groupSlug?: string;
  projectKey?: string;
  repoSlug?: string;
};

const INVITATIONS_PAGE_SIZE = 10;

export const InvitationsPanelImpl: React.FC<InvitationsPanelProps> = ({
  workspaceSlug,
  groupSlug,
  projectKey,
  repoSlug,
  refreshCount = 0,
}) => {
  const intl = useIntl();
  const { showErrorFlag } = useFlag({ enableForPdvs: true });
  const [invitations, setInvitations] = useState<InvitationsResponse>([]);

  const [{ resent, resendInProgress, cancelInProgress }, setInviteState] =
    useObjectState<{
      cancelInProgress: string | null;
      resendInProgress: string | null;
      resent: { [key: string]: boolean };
    }>({
      cancelInProgress: null,
      resendInProgress: null,
      resent: {},
    });

  const handleError = useCallback(
    (e: any) => {
      Sentry.captureException(e);
      showErrorFlag(e?.message || e?.error?.message);
    },
    [showErrorFlag]
  );

  useEffect(() => {
    if (groupSlug) {
      getWorkspaceInvitations(workspaceSlug)
        .then(invites => {
          const groupInvites = invites.filter(invite =>
            invite.groups?.some(groupId => groupId.split('/')[1] === groupSlug)
          );
          setInvitations(groupInvites);
        })
        .catch(e => {
          handleError(e);
        });
    } else if (projectKey) {
      getProjectInvitations(workspaceSlug, projectKey)
        .then(projectInvites => {
          setInvitations(projectInvites);
        })
        .catch(e => {
          handleError(e);
        });
    } else if (repoSlug) {
      getRepositoryInvitations(workspaceSlug, repoSlug)
        .then(repoInvites => {
          setInvitations(repoInvites);
        })
        .catch(e => {
          handleError(e);
        });
    }
  }, [
    workspaceSlug,
    groupSlug,
    projectKey,
    repoSlug,
    refreshCount,
    handleError,
  ]);

  const onResendInvite = useCallback(
    (invite: Invitation) => {
      setInviteState({
        resendInProgress: invite.email,
      });
      if (groupSlug) {
        sendWorkspaceInvitation(workspaceSlug, {
          email: invite.email,
          group_slug: groupSlug,
        })
          .then(() =>
            setInviteState({
              resent: { ...resent, [invite.email]: true },
            })
          )
          .catch(e => handleError(e))
          .finally(() =>
            setInviteState({
              resendInProgress: null,
            })
          );
      } else if (projectKey) {
        sendProjectInvitation(workspaceSlug, projectKey, {
          email: invite.email,
          permission: invite.permission,
        })
          .then(() =>
            setInviteState({
              resent: { ...resent, [invite.email]: true },
            })
          )
          .catch(e => handleError(e))
          .finally(() =>
            setInviteState({
              resendInProgress: null,
            })
          );
      } else if (repoSlug) {
        sendRepoInvitation(workspaceSlug, repoSlug, {
          email: invite.email,
          permission: invite.permission,
        })
          .then(() =>
            setInviteState({
              resent: { ...resent, [invite.email]: true },
            })
          )
          .catch(e => handleError(e))
          .finally(() =>
            setInviteState({
              resendInProgress: null,
            })
          );
      }
    },
    [
      resent,
      workspaceSlug,
      groupSlug,
      projectKey,
      repoSlug,
      setInviteState,
      handleError,
    ]
  );

  const onDeleteInvite = useCallback(
    (invite: Invitation) => {
      setInviteState({
        cancelInProgress: invite.email,
      });

      if (groupSlug) {
        deleteWorkspaceInvitation(workspaceSlug, {
          email: invite.email,
          group_slug: groupSlug,
        })
          .then(() => {
            setInvitations(
              invitations.filter(inv => inv.email !== invite.email)
            );
          })
          .catch(e => handleError(e))
          .finally(() => setInviteState({ cancelInProgress: null }));
      } else if (projectKey) {
        deleteProjectInvitation(workspaceSlug, projectKey, invite)
          .then(() => {
            setInvitations(
              invitations.filter(inv => inv.email !== invite.email)
            );
          })
          .catch(e => handleError(e))
          .finally(() => setInviteState({ cancelInProgress: null }));
      } else if (repoSlug) {
        deleteRepoInvitation(workspaceSlug, repoSlug, invite)
          .then(() => {
            setInvitations(
              invitations.filter(inv => inv.email !== invite.email)
            );
          })
          .catch(e => handleError(e))
          .finally(() => setInviteState({ cancelInProgress: null }));
      }
    },
    [
      workspaceSlug,
      groupSlug,
      projectKey,
      repoSlug,
      invitations,
      setInvitations,
      setInviteState,
      handleError,
    ]
  );

  const getInvitationRows = () =>
    invitations.map(invite => ({
      key: `row-${invite.email}`,
      cells: [
        {
          key: `_email-address-${invite.email}`,
          content: (
            <NameCell
              avatar={<Avatar size="medium" />}
              name={invite.email}
              additionalInfo={relativeDateString(invite.utc_sent_on, intl)}
            />
          ),
        },
        {
          key: `row-${invite.email}-resend-link`,
          content: resent?.[invite.email] ? (
            <LozengeContainer>
              <Lozenge appearance="success">
                {intl.formatMessage(messages.invitationSent)}
              </Lozenge>
            </LozengeContainer>
          ) : (
            <LoadingButton
              appearance="link"
              isLoading={resendInProgress === invite.email}
              onClick={() => onResendInvite(invite)}
            >
              {intl.formatMessage(messages.resendInvitation)}
            </LoadingButton>
          ),
        },
        {
          key: `row-${invite.email}-cancel-link`,
          content: (
            <LoadingButton
              appearance="link"
              spacing="none"
              isLoading={cancelInProgress === invite.email}
              onClick={() => onDeleteInvite(invite)}
            >
              {intl.formatMessage(messages.cancelInvitation)}
            </LoadingButton>
          ),
        },
      ],
    }));

  if (!invitations.length) return null;

  return (
    <PanelWrapper>
      <Panel
        header={
          <PanelHeader>
            {intl.formatMessage(messages.invitationsTableHeader, {
              count: invitations.length,
            })}
          </PanelHeader>
        }
      >
        <DynamicTable
          defaultSortKey="invitation-email"
          defaultSortOrder="ASC"
          rowsPerPage={
            invitations.length > INVITATIONS_PAGE_SIZE
              ? INVITATIONS_PAGE_SIZE
              : undefined
          }
          head={{
            cells: [
              {
                key: 'invitation-email',
                isSortable: true,
                content: intl.formatMessage(messages.emailAddress),
                width: EMAIL_HEADER_WIDTH,
              },
              {
                key: 'actions-resend',
                content: '',
                width: ACTIONS_HEADER_WIDTH,
              },
              {
                key: 'actions-cancel',
                content: intl.formatMessage(messages.actions),
                width: ACTIONS_HEADER_WIDTH,
              },
            ],
          }}
          rows={getInvitationRows()}
        />
      </Panel>
    </PanelWrapper>
  );
};

export const InvitationsPanel = (props: InvitationsPanelProps) => {
  const [isSumEnabled] = useIsSumEnabled();
  if (isSumEnabled) {
    return null;
  }

  return <InvitationsPanelImpl {...props} />;
};
