/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { useModal } from './hooks';
import { keylineHeight, padding } from './internal/constants';
var headerStyles = css({
  display: 'flex',
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
  padding: padding,
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
  paddingBottom: "".concat(padding - keylineHeight, "px"),
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'space-between'
});
/**
 * __Modal header__
 *
 * A modal header contains the title of the modal and can contain other React elements such as a close button.
 *
 * - [Examples](https://atlassian.design/components/modal-dialog/examples#modal-header)
 * - [Code](https://atlassian.design/components/modal-dialog/code#modal-header-props)
 * - [Usage](https://atlassian.design/components/modal-dialog/usage)
 */
var ModalHeader = function ModalHeader(props) {
  var children = props.children,
    userDefinedTestId = props.testId;
  var _useModal = useModal(),
    modalTestId = _useModal.testId;
  var testId = userDefinedTestId || modalTestId && "".concat(modalTestId, "--header");
  return jsx("div", {
    css: headerStyles,
    "data-testid": testId
  }, children);
};
export default ModalHeader;