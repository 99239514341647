import { useMemo, useEffect, useState, useCallback } from 'react';

import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { AppearanceTypes } from '@atlaskit/flag';
import { FeatureKeys, useFeature } from '@atlassian/bitbucket-features';

import { showFlag, showFlagComponent, dismissFlag } from 'src/redux/flags';
import {
  ComponentFlagId,
  FlagText,
  SimpleFlagProps,
} from 'src/redux/flags/types';
import store from 'src/utils/store';

import messages from './flag.i18n';

type PredefinedFlagText = FlagText | FormattedMessage.MessageDescriptor;

const extractTextContent = (description: PredefinedFlagText): FlagText => {
  if (typeof description === 'string' || 'msg' in description) {
    return description;
  }
  return {
    msg: description,
  };
};

/**
 *  Show a predefined flag with the given type, description, title, and coloredAppearance
 * @param type - The type of flag: error | info | normal | success | warning
 * @param description - The message in the body of the flag
 * @param title - [optional] The title at the top of the flag
 * @param coloredAppearance - [optional] Whether the flag should have a colored appearance based on its' type
 */
const showPredefinedFlag = (
  type: AppearanceTypes,
  description: PredefinedFlagText,
  title?: PredefinedFlagText,
  coloredAppearance?: boolean
) =>
  showFlag({
    id: `flags-hook-${type}`,
    title: title ? extractTextContent(title) : { msg: messages[type] },
    description: extractTextContent(description),
    iconType: type,
    coloredAppearance,
    autoDismiss: true,
  });

export const useFlag = ({
  enableForPdvs = false,
}: { enableForPdvs?: boolean } = {}) => {
  const dispatch = useDispatch();
  const storageKey = `is-pdv-bot`;
  const [data, setData] = useState<number | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await store.get(storageKey);
      setData(result);
    };
    fetchData();
  }, [storageKey]);

  const dispatchWrapper = useCallback(
    (fn: (...args: any[]) => any) =>
      (...args: any[]) => {
        if (!enableForPdvs && data === 1) return;
        dispatch(fn(...args));
      },
    [dispatch, data, enableForPdvs]
  );
  const isDefaultDisableFlagsForPdvsEnabled = useFeature(
    FeatureKeys.isDefaultDisableFlagsForPdvsEnabled,
    false
  ) as boolean;

  const flagActions = useMemo(() => {
    if (isDefaultDisableFlagsForPdvsEnabled) {
      return {
        dismissFlag: dispatchWrapper((flagId: ComponentFlagId | string) =>
          dismissFlag(flagId)
        ),
        showFlag: dispatchWrapper(
          (flag: Omit<SimpleFlagProps, 'handleDismissed'>) => showFlag(flag)
        ),
        showFlagComponent: dispatchWrapper((id: ComponentFlagId | string) =>
          showFlagComponent(id)
        ),
        showErrorFlag: dispatchWrapper(
          (
            description: PredefinedFlagText,
            title?: PredefinedFlagText,
            coloredAppearance?: boolean
          ) =>
            showPredefinedFlag('error', description, title, coloredAppearance)
        ),
        showInfoFlag: dispatchWrapper(
          (
            description: PredefinedFlagText,
            title?: PredefinedFlagText,
            coloredAppearance?: boolean
          ) => showPredefinedFlag('info', description, title, coloredAppearance)
        ),
        showNormalFlag: dispatchWrapper(
          (
            description: PredefinedFlagText,
            title?: PredefinedFlagText,
            coloredAppearance?: boolean
          ) =>
            showPredefinedFlag('normal', description, title, coloredAppearance)
        ),
        showSuccessFlag: dispatchWrapper(
          (
            description: PredefinedFlagText,
            title?: PredefinedFlagText,
            coloredAppearance?: boolean
          ) =>
            showPredefinedFlag('success', description, title, coloredAppearance)
        ),
        showWarningFlag: dispatchWrapper(
          (
            description: PredefinedFlagText,
            title?: PredefinedFlagText,
            coloredAppearance?: boolean
          ) =>
            showPredefinedFlag('warning', description, title, coloredAppearance)
        ),
      };
    } else {
      return {
        dismissFlag: (flagId: ComponentFlagId | string) =>
          dispatch(dismissFlag(flagId)),
        showFlag: (flag: Omit<SimpleFlagProps, 'handleDismissed'>) =>
          dispatch(showFlag(flag)),
        showFlagComponent: (id: ComponentFlagId | string) =>
          dispatch(showFlagComponent(id)),
        showErrorFlag: (
          description: PredefinedFlagText,
          title?: PredefinedFlagText,
          coloredAppearance?: boolean
        ) =>
          dispatch(
            showPredefinedFlag('error', description, title, coloredAppearance)
          ),
        showInfoFlag: (
          description: PredefinedFlagText,
          title?: PredefinedFlagText,
          coloredAppearance?: boolean
        ) =>
          dispatch(
            showPredefinedFlag('info', description, title, coloredAppearance)
          ),
        showNormalFlag: (
          description: PredefinedFlagText,
          title?: PredefinedFlagText,
          coloredAppearance?: boolean
        ) =>
          dispatch(
            showPredefinedFlag('normal', description, title, coloredAppearance)
          ),
        showSuccessFlag: (
          description: PredefinedFlagText,
          title?: PredefinedFlagText,
          coloredAppearance?: boolean
        ) =>
          dispatch(
            showPredefinedFlag('success', description, title, coloredAppearance)
          ),
        showWarningFlag: (
          description: PredefinedFlagText,
          title?: PredefinedFlagText,
          coloredAppearance?: boolean
        ) =>
          dispatch(
            showPredefinedFlag('warning', description, title, coloredAppearance)
          ),
      };
    }
  }, [dispatchWrapper, isDefaultDisableFlagsForPdvsEnabled, dispatch]);
  return flagActions;
};
