import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10;

import { css } from '@emotion/core';
import { prefersReducedMotion } from '@atlaskit/motion/accessibility';
import { easeInOut } from '@atlaskit/motion/curves';
import { mediumDurationMs } from '@atlaskit/motion/durations';
import { N0, N30A, N60A, text } from '@atlaskit/theme/colors';
import { borderRadius, layers } from '@atlaskit/theme/constants';
import { gutter, verticalOffset, WIDTH_ENUM } from '../constants';
var maxWidthDimensions = "calc(100vw - ".concat(gutter * 2, "px)");
var maxHeightDimensions = "calc(100vh - ".concat(gutter * 2 - 1, "px)");
export var dialogWidth = function dialogWidth(width) {
  if (!width) {
    return 'auto';
  }

  var isWidthName = WIDTH_ENUM.values.indexOf(width.toString()) !== -1;
  var widthName = isWidthName && width;

  if (widthName) {
    return "".concat(WIDTH_ENUM.widths[widthName], "px");
  }

  return typeof width === 'number' ? "".concat(width, "px") : width;
};
export var dialogHeight = function dialogHeight(height) {
  if (!height) {
    return 'auto';
  }

  return typeof height === 'number' ? "".concat(height, "px") : height;
};
export var getFillScreenStyles = function getFillScreenStyles(scrollDistance) {
  return css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  height: 100vh;\n  width: 100vw;\n\n  // This instead of fixed so PopupSelect's\n  // children are properly positioned.\n  position: absolute;\n  top: ", "px;\n  left: 0;\n\n  z-index: ", ";\n  overflow-y: auto; // Enables scroll outside.\n  -webkit-overflow-scrolling: touch;\n"])), scrollDistance, layers.modal());
};

var modalStackTransition = function modalStackTransition(stackIndex) {
  return css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  // We only want to apply transform on modals shifting to the back of the stack.\n  transform: ", ";\n  transition-property: transform;\n  transition-duration: ", "ms;\n  transition-timing-function: ", ";\n  ", ";\n"])), stackIndex > 0 ? "translateY(".concat(stackIndex * (verticalOffset / 2), "px)") : 'none', mediumDurationMs, easeInOut, prefersReducedMotion());
};

var positionerBaseStyles = css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  top: ", "px;\n  left: 0;\n  right: 0;\n  margin-left: auto;\n  margin-right: auto;\n\n  max-width: ", ";\n  max-height: ", ";\n\n  pointer-events: none;\n  width: max-content;\n"])), gutter, maxWidthDimensions, maxHeightDimensions);
var positionerResponsiveBaseStyles = css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  position: fixed;\n  left: 0;\n  top: 0;\n\n  height: 100%;\n  width: 100%;\n  max-width: 100%;\n\n  z-index: ", ";\n"])), layers.modal());
export var getPositionRelativeStyles = function getPositionRelativeStyles(stackIndex) {
  return css(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", ";\n  ", ";\n\n  margin: 0;\n\n  @media (min-width: 480px) {\n    position: relative;\n    margin: ", "px auto;\n    width: max-content;\n  }\n"])), positionerResponsiveBaseStyles, modalStackTransition(stackIndex), gutter);
};
export var getPositionAbsoluteStyles = function getPositionAbsoluteStyles(stackIndex) {
  return css(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  ", ";\n  ", ";\n\n  @media (min-width: 480px) {\n    ", ";\n    position: absolute;\n  }\n"])), positionerResponsiveBaseStyles, modalStackTransition(stackIndex), positionerBaseStyles);
};
export var getPositionFixedStyles = function getPositionFixedStyles(stackIndex) {
  return css(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  ", ";\n  ", ";\n\n  @media (min-width: 480px) {\n    ", ";\n    position: fixed;\n  }\n"])), positionerResponsiveBaseStyles, modalStackTransition(stackIndex), positionerBaseStyles);
};
export var getDialogStyles = function getDialogStyles(_ref) {
  var isChromeless = _ref.isChromeless,
      height = _ref.height,
      width = _ref.width;
  return css(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  color: ", ";\n  display: flex;\n  flex-direction: column;\n\n  pointer-events: auto;\n\n  height: 100%;\n  width: 100%;\n  max-height: 100vh;\n  max-width: 100vw;\n\n  ", "\n\n  @media (min-width: 480px) {\n    height: ", ";\n    width: ", ";\n    max-height: inherit;\n    max-width: inherit;\n\n    margin-left: inherit;\n    margin-right: inherit;\n\n    ", "\n  }\n"])), text(), isChromeless !== true && css(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n    background-color: ", ";\n  "])), N0), dialogHeight(height), dialogWidth(width), isChromeless !== true && css(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n      border-radius: ", "px;\n      box-shadow: 0 0 0 1px ", ", 0 2px 1px ", ", 0 0 20px -6px ", ";\n    "])), borderRadius(), N30A, N30A, N60A));
};