import React, { FC } from 'react';

import { RouteComponent, useRouter } from 'react-resource-router';

import HistoryWatcher from 'src/components/performance-metrics/history-watcher';
import { RouteContext, ROUTE_NAME } from 'src/router/routes/types';

export const UniversalRoutes: FC = () => {
  const [routerState] = useRouter();
  const { route } = routerState as RouteContext;

  if (
    // The root route "/" is a redirect, so we don't want to render any additional components yet
    route.name === ROUTE_NAME.ROOT
  ) {
    return <RouteComponent />;
  }

  const Wrapper = route.layout;

  return (
    <>
      <HistoryWatcher />
      {Wrapper ? (
        <Wrapper>
          <RouteComponent />
        </Wrapper>
      ) : (
        <RouteComponent />
      )}
    </>
  );
};
