import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import { ForgeMergeCheckModule } from './types';

export const INSTALLED_CUSTOM_MERGE_CHECKS_QUERY = gql`
  query InstalledCustomMergeChecks($workspaceAri: ID!) {
    extensionContext(contextId: $workspaceAri) {
      extensionsByType(type: "bitbucket:mergeCheck") {
        appId
        id
        name
        properties
      }
    }
  }
`;

export type InstalledCustomMergeChecksData = {
  extensionContext: {
    extensionsByType: ForgeMergeCheckModule[];
  };
};

export const useInstalledCustomMergeChecks = (
  workspaceAri: string
): ReturnType<
  typeof useQuery<InstalledCustomMergeChecksData, { workspaceAri: string }>
> =>
  useQuery(INSTALLED_CUSTOM_MERGE_CHECKS_QUERY, {
    variables: { workspaceAri },
  });
