/** ttvc configuration values set during initialization */
export var CONFIG = {
  /** Decide whether to log debug messages. */
  DEBUG: false,
  /** A duration in ms to wait before declaring the page completely idle. */
  IDLE_TIMEOUT: 200,
  /**
   * A duration in ms to wait before assuming that a single network request
   * was not instrumented correctly.
   *
   * If NETWORK_TIMEOUT is set to 0, disable this feature.
   */
  NETWORK_TIMEOUT: 60000
};
export var setConfig = function setConfig(options) {
  if (options !== null && options !== void 0 && options.debug) {
    CONFIG.DEBUG = options.debug;
  }
  if (options !== null && options !== void 0 && options.idleTimeout) {
    CONFIG.IDLE_TIMEOUT = options.idleTimeout;
  }
  if (options !== null && options !== void 0 && options.networkTimeout) {
    CONFIG.NETWORK_TIMEOUT = options.networkTimeout;
  }
};