var name = 'confluenceUnsupportedBlock';
export var confluenceUnsupportedBlock = {
  group: 'block',
  attrs: {
    cxhtml: {
      default: null
    }
  },
  toDOM: function toDOM(node) {
    // NOTE: This node cannot be "contenteditable: false". If it's the only node in a document, PM throws an error because there's nowhere to put the cursor.
    var attrs = {
      'data-node-type': name,
      'data-confluence-unsupported': 'block',
      'data-confluence-unsupported-block-cxhtml': node.attrs['cxhtml']
    };
    return ['div', attrs, 'Unsupported content'];
  },
  parseDOM: [{
    tag: "div[data-node-type=\"".concat(name, "\"]"),
    getAttrs: function getAttrs(dom) {
      return {
        cxhtml: dom.getAttribute('data-confluence-unsupported-block-cxhtml')
      };
    }
  }]
};