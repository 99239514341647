/* eslint frontbucket-patterns/no-new-emotion-styled: "warn" */
import styled from '@emotion/styled';

import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

const space0 = token('space.0', '0px');
const space25 = token('space.025', '2px');
const space50 = token('space.050', '4px');
const space100 = token('space.100', '8px');
const space200 = token('space.200', '16px');
const space300 = token('space.300', '24px');

export const SpinnerContainer = styled.div({
  display: 'flex',
  flex: 1,
  paddingTop: token('space.800', '64px'),
  justifyContent: 'center',
  alignItems: 'flex-start',
});

export const DescriptionContainer = styled.div({
  marginBottom: space200,
});

export const HeaderContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: space200,
  marginBottom: space200,
});

export const AppColumnContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
});

export const MergeCheckColumnContainer = styled.div({
  lineHeight: 1,
  margin: `${space25} ${space0}`,
});

export const Avatar = styled.img({
  width: space300,
  height: space300,
  borderRadius: token('border.radius.100', '3px'),
});

export const Description = styled.sub({
  lineHeight: 1,
  color: token('color.background.accent.gray.bolder', colors.N500),
});

export const TabLabel = styled.span({
  marginRight: space100,
});

export const TableContainer = styled.div({
  flex: 1,
  table: {
    marginTop: space300,

    tr: {
      borderTop: `${space25} solid ${token('color.border', colors.N40)}`,
    },
  },
});

export const LozengeContainer = styled.div({
  marginRight: space50,
});
