import { RouteResourceUpdater } from 'react-resource-router';

import {
  BranchConfig,
  CustomMergeCheckExtensionId,
  CustomMergeCheckKey,
  CustomMergeCheckConfigRouteResource,
  CustomMergeChecksResourceType,
  CustomMergeCheck,
  CustomMergeCheckResourceId,
} from './types';

/**
 * Generate a CustomMergeCheckKey that uniquely identifies a custom merge check
 * for a given resource type, branch config and extension ID. These are used to
 * maintain a local map of the configuration state ('off', 'required' or
 * 'recommended') of custom merge checks.
 * @param resourceType the CustomMergeChecksResourceType of the check
 * @param branchConfig the branch config of the check
 * @param extensionId the Forge extension ID of the check
 * @returns a CustomMergeCheckKey identifying a check
 */
export const customMergeCheckKey = (
  resourceType: CustomMergeChecksResourceType,
  branchConfig: BranchConfig,
  extensionId: CustomMergeCheckExtensionId
): CustomMergeCheckKey =>
  `${resourceType}${branchConfig.type}${branchConfig.identifier}${extensionId}`;

const REPOSITORY_ARI_PREFIX = 'ari:cloud:bitbucket::repository';

const determineResourceType = (
  resource: CustomMergeCheckResourceId
): CustomMergeChecksResourceType => {
  if (resource.startsWith(REPOSITORY_ARI_PREFIX)) {
    return CustomMergeChecksResourceType.Repository;
  }
  throw new Error(`Invalid resource ID ${resource}`);
};

export const updateCustomMergeCheckConfigRouteResource =
  ({
    extensionId,
    branchConfig,
    state,
    resource,
  }: CustomMergeCheck): RouteResourceUpdater<CustomMergeCheckConfigRouteResource> =>
  data => {
    const updatedStateMap = new Map(data.stateMap);
    const key = customMergeCheckKey(
      determineResourceType(resource),
      branchConfig,
      extensionId
    );
    updatedStateMap.set(key, state);
    return {
      ...data,
      stateMap: updatedStateMap,
    };
  };
