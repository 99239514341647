import React, { FC, useCallback, useMemo } from 'react';

import { FormattedMessage } from 'react-intl';

import Badge from '@atlaskit/badge';
import { Box, xcss } from '@atlaskit/primitives';
import Tabs, { TabList, TabPanel, useTab } from '@atlaskit/tabs';
import Tooltip from '@atlaskit/tooltip-18';

import { useIntl } from 'src/hooks/intl';

import { MergeCheckModulesTable } from './merge-check-modules-table';
import { sharedMessages } from './merge-checks-tabs.i18n';
import { contextMessages } from './messages.i18n';
import { DescriptionContainer, TabLabel, TableContainer } from './styled';
import {
  BranchConfig,
  CustomMergeCheckExtensionId,
  CustomMergeCheckConfigurationState,
  CustomMergeCheckConfigRouteResource,
  ForgeMergeCheckModule,
  CustomMergeChecksResourceType,
  CustomMergeCheckKey,
} from './types';
import { urls } from './urls';
import { customMergeCheckKey } from './utils';

const tabWithBadgeStyles = xcss({
  display: 'flex',
  flexDirection: 'row',
});

const TabWithBadge: FC<{ label: string; enabledChecksCount: number }> = ({
  label,
  enabledChecksCount,
}) => {
  const tabAttributes = useTab();
  const { formatMessage } = useIntl();

  return (
    <Box
      {...tabAttributes}
      xcss={tabWithBadgeStyles}
      aria-label={formatMessage(sharedMessages.configurationTabsAriaLabel, {
        tabLabel: label,
        enabledChecks:
          enabledChecksCount > 0
            ? formatMessage(sharedMessages.enabledChecksTooltip, {
                count: enabledChecksCount,
              })
            : '',
      })}
    >
      <TabLabel>{label}</TabLabel>
      {enabledChecksCount > 0 && (
        <Tooltip
          testId="merge-checks-tabs-tooltip"
          position="top"
          content={formatMessage(sharedMessages.enabledChecksTooltip, {
            count: enabledChecksCount,
          })}
        >
          <Badge testId="merge-checks-tabs-badge">{enabledChecksCount}</Badge>
        </Tooltip>
      )}
    </Box>
  );
};

type MergeChecksTabsProps = {
  resourceType: CustomMergeChecksResourceType;
  customMergeChecksResourceState: CustomMergeCheckConfigRouteResource;
  installedCustomMergeChecks: ForgeMergeCheckModule[];
  checkIdBeingConfigured?: CustomMergeCheckExtensionId;
  onSubmitCheckConfiguration: (
    branchConfig: BranchConfig,
    checkModule: ForgeMergeCheckModule,
    oldState: CustomMergeCheckConfigurationState,
    newState: CustomMergeCheckConfigurationState
  ) => void;
};

export const MergeChecksTabs: FC<MergeChecksTabsProps> = ({
  resourceType,
  customMergeChecksResourceState,
  installedCustomMergeChecks,
  checkIdBeingConfigured,
  onSubmitCheckConfiguration,
}) => {
  const { formatMessage } = useIntl();

  // Generate Sets of CustomMergeCheckKeys for all installed custom merge
  // checks. We use this to display the enabled checks count as a badge on each
  // branch config tab. It's required because
  // `customMergeChecksResourceState.stateMap` can contain entries for merge
  // checks that are no longer installed, so we need to make sure these entries
  // aren't counted on the tab badge.
  const installedCustomMergeCheckKeys = useMemo<
    Map<BranchConfig, Set<CustomMergeCheckKey>>
  >(() => {
    const m = new Map<BranchConfig, Set<CustomMergeCheckKey>>();
    customMergeChecksResourceState.branchConfigs.forEach(branchConfig => {
      m.set(
        branchConfig,
        new Set(
          installedCustomMergeChecks.map(check =>
            customMergeCheckKey(resourceType, branchConfig, check.id)
          )
        )
      );
    });
    return m;
  }, [
    customMergeChecksResourceState.branchConfigs,
    installedCustomMergeChecks,
    resourceType,
  ]);

  const renderTab = useCallback(
    (branchConfig: BranchConfig) => {
      const { type, identifier } = branchConfig;
      let label: string = identifier;
      if (type === 'model') {
        switch (identifier) {
          case 'all_branches':
            label = formatMessage(sharedMessages.allBranchesTabLabel);
            break;
          case 'main':
            label = formatMessage(sharedMessages.mainBranchTabLabel);
            break;
        }
      }

      let enabledChecksCount = 0;
      if (customMergeChecksResourceState.stateMap !== undefined) {
        for (const [key, state] of customMergeChecksResourceState.stateMap) {
          if (
            state !== 'off' &&
            installedCustomMergeCheckKeys.get(branchConfig)?.has(key)
          ) {
            enabledChecksCount += 1;
          }
        }
      }

      return (
        <TabWithBadge
          key={`${type}${identifier}`}
          label={label}
          enabledChecksCount={enabledChecksCount}
        />
      );
    },
    [
      customMergeChecksResourceState.stateMap,
      formatMessage,
      installedCustomMergeCheckKeys,
    ]
  );

  const renderTabPanel = useCallback(
    (branchConfig: BranchConfig) => {
      const { type, identifier } = branchConfig;
      return (
        <TabPanel key={`${type}${identifier}`}>
          <TableContainer>
            <MergeCheckModulesTable
              resourceType={resourceType}
              branchConfig={branchConfig}
              installedCheckModules={installedCustomMergeChecks}
              stateMap={customMergeChecksResourceState.stateMap}
              checkIdBeingConfigured={checkIdBeingConfigured}
              onSubmitCheckConfiguration={onSubmitCheckConfiguration}
            />
          </TableContainer>
        </TabPanel>
      );
    },
    [
      checkIdBeingConfigured,
      resourceType,
      installedCustomMergeChecks,
      onSubmitCheckConfiguration,
      customMergeChecksResourceState.stateMap,
    ]
  );

  return (
    <>
      <DescriptionContainer>
        <p>
          <FormattedMessage
            {...contextMessages[resourceType].description}
            values={{
              learnMoreLink: (
                <a href={urls.learnMore} target="_blank">
                  {formatMessage(
                    contextMessages[resourceType].learnMoreLinkLabel
                  )}
                </a>
              ),
            }}
          />
        </p>
      </DescriptionContainer>
      <Tabs id="default">
        <TabList>
          {customMergeChecksResourceState.branchConfigs.map(renderTab)}
        </TabList>
        {customMergeChecksResourceState.branchConfigs.map(renderTabPanel)}
      </Tabs>
    </>
  );
};
