import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
var _templateObject, _templateObject2;
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle';
import { G300 } from '@atlaskit/theme/colors';
export default (function (_ref) {
  var children = _ref.children;
  return jsx("section", {
    css: css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n      margin-left: ", ";\n    "])), "var(--ds-space-500, 40px)")
  }, jsx("div", {
    css: css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n        position: absolute;\n        top: ", ";\n        left: ", ";\n      "])), "var(--ds-space-300, 24px)", "var(--ds-space-300, 24px)")
  }, jsx(CheckCircleIcon, {
    label: "",
    "aria-hidden": true,
    primaryColor: "var(--ds-icon-success, ".concat(G300, ")")
  })), children);
});