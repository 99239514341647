import { useCallback, useEffect, useState } from 'react';

import { captureException } from '@sentry/browser';
import qs from 'qs';
import { useIntl } from 'react-intl-next';
import { useResource } from 'react-resource-router';

import { loadPipelineRunsResource } from 'src/components/pipelines/resources/pipeline-runs';
import { useFlag } from 'src/hooks/flag';
import { request } from 'src/utils/http-request';

import { PIPELINE_RUNS_PAGE_SIZE } from '../constants';
import { Pipeline } from '../models';
import envBaseUrl from '../utils/env-base-url';

import { messages } from './pipeline-runs.i18n';

export const getPipelineRunsUrl = (
  repositoryFullSlug: string,
  pipelineRef: string,
  queryParams = {}
): string => {
  const allQueryParams = {
    page: 1,
    pagelen: PIPELINE_RUNS_PAGE_SIZE, // only fetch latest 10 runs
    fields: '+target.commit.message,+target.commit.summary.html,+target.*,+*',
    ...queryParams,
  };
  return `${envBaseUrl(
    '/!api/internal',
    'pipelines',
    true
  )}/repositories/${repositoryFullSlug}/pipelines/${pipelineRef}/runs?${qs.stringify(
    allQueryParams,
    { allowDots: true }
  )}`;
};

export type UsePipelineRunsProps = {
  repositoryFullSlug: string | undefined;
  pipeline: Pipeline | undefined;
  isPipelinesPageLoadOptimisationEnabled: boolean;
};

export type PipelineRunsState = {
  isLoading: boolean;
  pipelineRuns: Pipeline[];
  resetPipelineRuns: () => void;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getRunsFromResponse = (response: any): Pipeline[] =>
  response.values.map(
    (fetchedPipelineRun: any) => new Pipeline(fetchedPipelineRun)
  );

export const sortRuns = (runs: Pipeline[]): Pipeline[] =>
  runs.sort((a, b) => {
    if (!a.completed_on) {
      return -1;
    }
    if (!b.completed_on) {
      return 1;
    }
    return b.completed_on.localeCompare(a.completed_on);
  });

export function usePipelineRuns({
  repositoryFullSlug,
  pipeline,
  isPipelinesPageLoadOptimisationEnabled,
}: UsePipelineRunsProps): PipelineRunsState {
  const {
    data: resourceData,
    loading: resourceLoading,
    refresh: refreshResource,
    error: resourceError,
  } = useResource(loadPipelineRunsResource);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pipelineRuns, setPipelineRuns] = useState<Pipeline[]>([]);
  const { formatMessage } = useIntl();
  const { showErrorFlag } = useFlag({ enableForPdvs: true });

  const fetchPipelineRuns = useCallback(async () => {
    try {
      if (!repositoryFullSlug || !pipeline?.uuid) {
        setPipelineRuns([]);
        setIsLoading(false);
        return;
      }
      setIsLoading(true);
      const url = getPipelineRunsUrl(repositoryFullSlug, pipeline?.uuid);
      const response = await request(url);
      const runs: Pipeline[] = getRunsFromResponse(response);
      setPipelineRuns(sortRuns(runs));
    } catch (e) {
      captureException(e);
      showErrorFlag(formatMessage(messages.failedFetchErrorFlagMsg));
      setPipelineRuns([]);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    repositoryFullSlug,
    pipeline?.uuid,
    pipeline?.status,
    showErrorFlag,
    formatMessage,
  ]);

  useEffect(() => {
    if (!isPipelinesPageLoadOptimisationEnabled) {
      fetchPipelineRuns();
    }
  }, [fetchPipelineRuns, isPipelinesPageLoadOptimisationEnabled]);

  if (isPipelinesPageLoadOptimisationEnabled) {
    if (resourceError) {
      showErrorFlag(formatMessage(messages.failedFetchErrorFlagMsg));
    }

    return {
      isLoading: resourceLoading,
      pipelineRuns: resourceData || [],
      resetPipelineRuns: refreshResource,
    };
  }

  return {
    isLoading,
    pipelineRuns,
    resetPipelineRuns: fetchPipelineRuns,
  };
}
