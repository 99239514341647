import React from 'react';

import { AnalyticsListener } from '@atlaskit/analytics-next';
import {
  ANALYTICS_BRIDGE_CHANNEL,
  extractAWCDataFromEvent,
  OPERATIONAL_EVENT_TYPE,
  SCREEN_EVENT_TYPE,
  TRACK_EVENT_TYPE,
  UI_EVENT_TYPE,
} from '@atlassian/analytics-bridge';

import {
  publishOperationalEvent,
  publishScreenEvent,
  publishTrackEvent,
  publishUiEvent,
} from 'src/utils/analytics/publish';

// Listens for events fired by children using analytics-bridge (which uses analytics-next) and
// sends them using analytics-web-client.
//
// In contrast to publishing events directly in the child component, this gives components in
// in the tree such as the EngagementProvider a chance to listen and react to these analytics too.
//
// See README for "@atlassian/analytics-bridge" for a good explanation.
export const AnalyticsBridgeListener: React.FC<{}> = ({ children }) => {
  const sendEvent = (event: { type: string; payload: any }) => {
    const { type, payload } = event;
    switch (type) {
      case UI_EVENT_TYPE:
        publishUiEvent(payload);
        break;
      case TRACK_EVENT_TYPE:
        publishTrackEvent(payload);
        break;
      case OPERATIONAL_EVENT_TYPE:
        publishOperationalEvent(payload);
        break;
      case SCREEN_EVENT_TYPE:
        publishScreenEvent(payload.name, payload.attributes);
        break;
      default:
        break;
    }
  };

  return (
    <AnalyticsListener
      channel={ANALYTICS_BRIDGE_CHANNEL}
      onEvent={event => sendEvent(extractAWCDataFromEvent(event))}
    >
      {children}
    </AnalyticsListener>
  );
};
