/* eslint-disable @atlaskit/design-system/no-nested-styles */
/**
 * @jsxRuntime classic
 * @jsx jsx
 */

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { h600 } from '@atlaskit/theme/typography';
var modalBodyStyles = css({
  padding: "var(--ds-space-500, 40px)".concat(" ", "var(--ds-space-200, 16px)"),
  textAlign: 'center'
});

// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-array-arguments -- Ignored via go/DSP-18766
var modalHeadingStyles = css([
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
css(h600({
  theme: {
    mode: 'light'
  }
})), {
  marginBottom: "var(--ds-space-100, 8px)",
  color: 'inherit'
}]);
var modalImageStyles = css({
  width: '100%',
  height: 'auto',
  borderStartEndRadius: "var(--ds-border-radius, 3px)",
  borderStartStartRadius: "var(--ds-border-radius, 3px)",
  '@media (min-width: 320px) and (max-width: 480px)': {
    borderRadius: 0
  }
});
var modalActionContainerStyles = css({
  display: 'flex',
  padding: "var(--ds-space-0, 0px)".concat(" ", "var(--ds-space-500, 40px)", " 36px"),
  justifyContent: 'center',
  flexDirection: 'row',
  flexFlow: 'wrap'
});
var modalActionContainerReversedStyles = css({
  flexDirection: 'row-reverse'
});
var modalActionItemStyles = css({
  margin: "var(--ds-space-0, 0px)".concat(" ", "var(--ds-space-050, 4px)", " ", "var(--ds-space-050, 4px)")
});

/**
 * __Modal body__
 *
 * @internal
 */
export var ModalBody = function ModalBody(_ref) {
  var children = _ref.children;
  return jsx("div", {
    css: modalBodyStyles
  }, children);
};

/**
 * __Modal heading__
 *
 * @internal
 */
export var ModalHeading = function ModalHeading(_ref2) {
  var children = _ref2.children,
    id = _ref2.id;
  return jsx("h1", {
    css: modalHeadingStyles,
    id: id
  }, children);
};

/**
 * __Modal image__
 *
 * @internal
 */
export var ModalImage = function ModalImage(_ref3) {
  var alt = _ref3.alt,
    src = _ref3.src;
  return jsx("img", {
    css: modalImageStyles,
    alt: alt,
    src: src
  });
};

/**
 * __Modal action container__
 *
 * @internal
 */
export var ModalActionContainer = function ModalActionContainer(_ref4) {
  var children = _ref4.children,
    shouldReverseButtonOrder = _ref4.shouldReverseButtonOrder;
  return jsx("div", {
    css: [modalActionContainerStyles, shouldReverseButtonOrder && modalActionContainerReversedStyles]
  }, children);
};

/**
 * __Modal action item__
 *
 * @internal
 */
export var ModalActionItem = function ModalActionItem(_ref5) {
  var children = _ref5.children;
  return jsx("div", {
    css: modalActionItemStyles
  }, children);
};