import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import * as colors from '@atlaskit/theme/colors';
import { fontFamily, fontSize, gridSize as gridSizeFn } from '@atlaskit/theme/constants';

var targetEdgeAndIE11 = function targetEdgeAndIE11(styles) {
  // From https://browserstrangeness.github.io/css_hacks.html
  return {
    '@media screen and (-ms-high-contrast: none)': styles,
    '@supports (-ms-ime-align:auto)': styles
  };
};

var targetFirefox = function targetFirefox(styles) {
  // From https://stackoverflow.com/a/953491
  return {
    '@-moz-document url-prefix()': styles
  };
};

var gridSize = gridSizeFn();
/**
 * Component tree structure:
 * - itemBase
 *   - beforeWrapper
 *   - contentWrapper
 *     - textWrapper
 *     - subTextWrapper
 *   - afterWrapper
 */
// These are the styles which are consistent regardless of theme or spacing

var baseStyles = {
  itemBase: {
    alignItems: 'center',
    border: 'none',
    borderRadius: '3px',
    boxSizing: 'border-box',
    color: 'inherit',
    cursor: 'pointer',
    display: 'flex',
    flexShrink: 0,
    fontSize: 'inherit',
    height: gridSize * 5,
    outline: 'none',
    textAlign: 'left',
    textDecoration: 'none',
    width: '100%',
    '&:focus': {
      boxShadow: "0 0 0 2px ".concat(colors.B100, " inset")
    }
  },
  beforeWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexShrink: 0
  },
  contentWrapper: _objectSpread({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowX: 'hidden'
  }, targetEdgeAndIE11({
    fontFamily: fontFamily()
  })),
  textWrapper: _objectSpread(_objectSpread(_objectSpread({
    flex: '1 1 auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }, targetEdgeAndIE11({
    lineHeight: 18 / fontSize()
  })), targetFirefox({
    lineHeight: 18 / fontSize()
  })), {}, {
    lineHeight: 16 / fontSize()
  }),
  subTextWrapper: _objectSpread({
    flex: '1 1 auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }, targetEdgeAndIE11({
    marginTop: '-2px'
  })),
  afterWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexShrink: 0
  }
}; // These are styles which switch on the spacing prop

var layoutStyles = {
  compact: {
    itemBase: {
      paddingRight: gridSize,
      paddingLeft: gridSize
    },
    beforeWrapper: {
      marginRight: gridSize
    },
    subTextWrapper: {
      fontSize: '10px',
      lineHeight: 1.2
    },
    afterWrapper: {
      marginLeft: gridSize
    }
  },
  default: {
    itemBase: {
      paddingLeft: gridSize * 1.5,
      paddingRight: gridSize * 1.5
    },
    beforeWrapper: {
      marginRight: gridSize * 2
    },
    subTextWrapper: _objectSpread(_objectSpread({
      fontSize: '12px'
    }, targetEdgeAndIE11({
      lineHeight: 16 / 12,
      marginTop: '-2px'
    })), {}, {
      lineHeight: 14 / 12
    }),
    afterWrapper: {
      marginLeft: gridSize * 2
    }
  }
};

var getItemBackgroundColor = function getItemBackgroundColor(background, _ref) {
  var isActive = _ref.isActive,
      isSelected = _ref.isSelected,
      isHover = _ref.isHover,
      isDragging = _ref.isDragging;
  if (isDragging) return background.hint;
  if (isActive) return background.interact;
  if (isSelected) return background.static;
  if (isHover) return background.hint;
  return background.default;
}; // Light theme


export default (function (_ref2) {
  var product = _ref2.product;
  return function (_ref3) {
    var isActive = _ref3.isActive,
        isDragging = _ref3.isDragging,
        isHover = _ref3.isHover,
        isSelected = _ref3.isSelected,
        spacing = _ref3.spacing;
    var containerTextColor = isActive || isSelected ? colors.B400 : colors.N500;
    var containerBackgroundColor = getItemBackgroundColor({
      default: colors.N20,
      hint: colors.N30,
      interact: colors.B50,
      static: colors.N30
    }, {
      isActive: isActive,
      isHover: isHover,
      isSelected: isSelected,
      isDragging: isDragging
    });
    var productBackgroundColor = getItemBackgroundColor(product.background, {
      isActive: isActive,
      isDragging: isDragging,
      isHover: isHover,
      isSelected: isSelected
    });
    return {
      container: {
        itemBase: _objectSpread(_objectSpread(_objectSpread({}, baseStyles.itemBase), layoutStyles[spacing].itemBase), {}, {
          backgroundColor: containerBackgroundColor,
          fill: containerBackgroundColor
        }),
        beforeWrapper: _objectSpread(_objectSpread(_objectSpread({}, baseStyles.beforeWrapper), layoutStyles[spacing].beforeWrapper), {}, {
          color: containerTextColor
        }),
        contentWrapper: baseStyles.contentWrapper,
        textWrapper: _objectSpread(_objectSpread({}, baseStyles.textWrapper), {}, {
          color: containerTextColor
        }),
        subTextWrapper: _objectSpread(_objectSpread(_objectSpread({}, baseStyles.subTextWrapper), layoutStyles[spacing].subTextWrapper), {}, {
          color: colors.N200
        }),
        afterWrapper: _objectSpread(_objectSpread(_objectSpread({}, baseStyles.afterWrapper), layoutStyles[spacing].afterWrapper), {}, {
          color: colors.N500
        })
      },
      product: {
        itemBase: _objectSpread(_objectSpread(_objectSpread({}, baseStyles.itemBase), layoutStyles[spacing].itemBase), {}, {
          backgroundColor: productBackgroundColor,
          fill: productBackgroundColor
        }),
        beforeWrapper: _objectSpread(_objectSpread(_objectSpread({}, baseStyles.beforeWrapper), layoutStyles[spacing].beforeWrapper), {}, {
          color: product.text.default
        }),
        contentWrapper: baseStyles.contentWrapper,
        textWrapper: _objectSpread(_objectSpread({}, baseStyles.textWrapper), {}, {
          color: product.text.default
        }),
        subTextWrapper: _objectSpread(_objectSpread(_objectSpread({}, baseStyles.subTextWrapper), layoutStyles[spacing].subTextWrapper), {}, {
          color: product.text.subtle
        }),
        afterWrapper: _objectSpread(_objectSpread(_objectSpread({}, baseStyles.afterWrapper), layoutStyles[spacing].afterWrapper), {}, {
          color: product.text.default
        })
      }
    };
  };
});