/** @jsx jsx */
import { useContext, useEffect, useLayoutEffect } from 'react';
import { jsx } from '@emotion/react';
import MenuGroup from '@atlaskit/menu/menu-group';
import { Box, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { FocusManagerContext } from '../components/focus-manager';
import isCheckboxItem from '../utils/is-checkbox-item';
import isRadioItem from '../utils/is-radio-item';
var spinnerContainerStyles = xcss({
  display: 'flex',
  minWidth: '160px',
  padding: 'space.250',
  justifyContent: 'center'
});
var LoadingIndicator = function LoadingIndicator(_ref) {
  var _ref$statusLabel = _ref.statusLabel,
    statusLabel = _ref$statusLabel === void 0 ? 'Loading' : _ref$statusLabel,
    testId = _ref.testId;
  return jsx(Box, {
    xcss: spinnerContainerStyles
  }, jsx(Spinner, {
    size: "small",
    label: statusLabel,
    testId: testId
  }));
};
/**
 *
 * MenuWrapper wraps all the menu items.
 * It handles the logic to close the menu when a MenuItem is clicked, but leaves it open
 * if a CheckboxItem or RadioItem is clicked.
 * It also sets focus to the first menu item when opened.
 */
var MenuWrapper = function MenuWrapper(_ref2) {
  var children = _ref2.children,
    isLoading = _ref2.isLoading,
    maxHeight = _ref2.maxHeight,
    maxWidth = _ref2.maxWidth,
    onClose = _ref2.onClose,
    onUpdate = _ref2.onUpdate,
    statusLabel = _ref2.statusLabel,
    setInitialFocusRef = _ref2.setInitialFocusRef,
    spacing = _ref2.spacing,
    testId = _ref2.testId;
  var _useContext = useContext(FocusManagerContext),
    menuItemRefs = _useContext.menuItemRefs;
  var closeOnMenuItemClick = function closeOnMenuItemClick(e) {
    var isTargetMenuItemOrDecendant = menuItemRefs.some(function (menuItem) {
      var isCheckboxOrRadio = isCheckboxItem(menuItem) || isRadioItem(menuItem);
      return menuItem.contains(e.target) && !isCheckboxOrRadio;
    });

    // Close menu if the click is triggered from a MenuItem or
    // its descendant. Don't close the menu if the click is triggered
    // from a MenuItemRadio or MenuItemCheckbox so that the user can
    // select multiple items.
    if (isTargetMenuItemOrDecendant && onClose) {
      onClose(e);
    }
  };

  // Using useEffect here causes a flicker.
  // useLayoutEffect ensures that the update and render happen in the same
  // rAF tick.
  useLayoutEffect(function () {
    onUpdate();
  }, [isLoading, onUpdate]);
  useEffect(function () {
    var _menuItemRefs$find;
    var firstFocusableRef = (_menuItemRefs$find = menuItemRefs.find(function (ref) {
      return !ref.hasAttribute('disabled');
    })) !== null && _menuItemRefs$find !== void 0 ? _menuItemRefs$find : null;
    setInitialFocusRef === null || setInitialFocusRef === void 0 || setInitialFocusRef(firstFocusableRef);
  }, [menuItemRefs, setInitialFocusRef]);
  return jsx(MenuGroup, {
    isLoading: isLoading,
    maxHeight: maxHeight,
    maxWidth: maxWidth,
    onClick: closeOnMenuItemClick,
    role: "menu",
    spacing: spacing,
    testId: testId && "".concat(testId, "--menu-group")
  }, isLoading ? jsx(LoadingIndicator, {
    statusLabel: statusLabel,
    testId: testId && "".concat(testId, "--loading-indicator")
  }) : children);
};
export default MenuWrapper;