import memoize from 'memoize-one';

import { getGlobalRoutesConfig } from './global/config';
import { getSupportedPaths } from './paths';
import { getProjectRoutesConfig } from './project/config';
import { getRepositoryRoutesConfig } from './repository/config';
import { RouteConfig, Routes, RouteFeatures } from './types';
import { getWorkspaceRoutesConfig } from './workspace/config';

const getRouteConfig = (features: RouteFeatures): RouteConfig => {
  return {
    ...getGlobalRoutesConfig(),
    ...getWorkspaceRoutesConfig(features),
    ...getRepositoryRoutesConfig(),
    ...getProjectRoutesConfig(),
  };
};

export const getRoutes = memoize((features: RouteFeatures): Routes => {
  const supportedPaths = getSupportedPaths(features);
  const config = getRouteConfig(features);
  const routes = supportedPaths.map(supportedPath => {
    return {
      ...supportedPath,
      ...config[supportedPath.name],
    };
  });
  return routes;
});
