import { FONT_STYLE } from '../groups';

/**
 * @name underline_mark
 */

export var underline = {
  inclusive: true,
  group: FONT_STYLE,
  parseDOM: [{
    tag: 'u'
  }, {
    style: 'text-decoration',
    getAttrs: function getAttrs(value) {
      return value === 'underline' && null;
    }
  }],
  toDOM: function toDOM() {
    return ['u'];
  }
};