import { bindActions } from './bind-actions';
import createStoreState from './create-state';
export const GLOBAL_SCOPE = '__global__';
export class StoreRegistry {
  constructor(defaultScope) {
    var _this = this;

    if (defaultScope === void 0) {
      defaultScope = GLOBAL_SCOPE;
    }

    this.stores = new Map();

    this.initStore = (key, Store) => {
      const {
        initialState,
        actions
      } = Store;
      const storeState = createStoreState(key, initialState);
      const boundActions = bindActions(actions, storeState);
      const store = {
        storeState,
        actions: boundActions
      };
      this.stores.set(key, store);
      return store;
    };

    this.getStore = function (Store, scopeId) {
      if (scopeId === void 0) {
        scopeId = _this.defaultScope;
      }

      const key = _this.generateKey(Store, scopeId);

      return _this.stores.get(key) || _this.initStore(key, Store);
    };

    this.deleteStore = function (Store, scopeId) {
      if (scopeId === void 0) {
        scopeId = _this.defaultScope;
      }

      const key = _this.generateKey(Store, scopeId);

      _this.stores.delete(key);
    };

    this.generateKey = (Store, scopeId) => `${Store.key}@${scopeId}`;

    this.defaultScope = defaultScope;
  }

}
export const defaultRegistry = new StoreRegistry();