import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { LINK } from '../groups';
import { isRootRelative, isSafeUrl, normalizeUrl } from '../../utils/url';

/**
 * @name link_mark
 */

var getLinkAttrs = function getLinkAttrs(attribute) {
  return function (domNode) {
    var dom = domNode;
    var href = dom.getAttribute(attribute) || '';
    var attrs = {
      __confluenceMetadata: dom.hasAttribute('__confluenceMetadata') ? JSON.parse(dom.getAttribute('__confluenceMetadata') || '') : undefined
    };
    if (!isSafeUrl(href)) {
      return false;
    }
    if (isRootRelative(href)) {
      attrs.href = href;
      return attrs;
    }
    attrs.href = normalizeUrl(href);
    return attrs;
  };
};
export var link = {
  excludes: "".concat(LINK),
  // ED-5844 No multiple links in media node
  group: LINK,
  attrs: {
    href: {},
    __confluenceMetadata: {
      default: null
    }
  },
  inclusive: false,
  parseDOM: [{
    tag: '[data-block-link]',
    getAttrs: getLinkAttrs('data-block-link'),
    contentElement: function contentElement(node) {
      var clone = node.cloneNode(true);
      clone.removeAttribute('data-block-link');
      clone.setAttribute('data-skip-paste', 'true');
      var wrapper = document.createElement('div');
      wrapper.appendChild(clone);
      return wrapper;
    }
  }, {
    tag: 'a[href]',
    getAttrs: getLinkAttrs('href')
  }],
  toDOM: function toDOM(node, isInline) {
    var attrs = Object.keys(node.attrs).reduce(function (attrs, key) {
      if (key === '__confluenceMetadata') {
        if (node.attrs[key] !== null) {
          attrs[key] = JSON.stringify(node.attrs[key]);
        }
      } else if (key === 'href') {
        attrs[key] = isSafeUrl(node.attrs[key]) ? node.attrs[key] : undefined;
      } else {
        attrs[key] = node.attrs[key];
      }
      return attrs;
    }, {});
    if (isInline) {
      return ['a', attrs];
    }
    return ['a', _objectSpread(_objectSpread({}, attrs), {}, _defineProperty(_defineProperty({}, 'data-block-link', 'true'), "class", 'blockLink')), 0];
  }
};
var OPTIONAL_ATTRS = ['title', 'id', 'collection', 'occurrenceKey', '__confluenceMetadata'];
export var toJSON = function toJSON(mark) {
  return {
    type: mark.type.name,
    attrs: Object.keys(mark.attrs).reduce(function (attrs, key) {
      if (OPTIONAL_ATTRS.indexOf(key) === -1 || mark.attrs[key] !== null) {
        attrs[key] = mark.attrs[key];
      }
      return attrs;
    }, {})
  };
};