import React, { useEffect } from 'react';

import { RouteComponentProps } from 'src/router/types';
import { withRouter } from 'src/router/utils';

type HistoryWatcherProps = RouteComponentProps | any;

export const useRouteChangeMarks = (
  history: RouteComponentProps['history']
) => {
  useEffect(() => {
    return history.listen((_location, action) => {
      // Set marker for use in timing.  Possible action types
      // are PUSH, POP and REPLACE. Ignore REPLACE actions because
      // they are redirects
      if (action !== 'REPLACE' && typeof performance.mark === 'function') {
        performance.clearMarks('ROUTECHANGE');
        performance.mark('ROUTECHANGE');
      }
    });
    // This listener on `history` is only meant to be called on the initial mount of the component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const HistoryWatcher: React.FC<HistoryWatcherProps> = props => {
  useRouteChangeMarks(props.history);
  return null;
};

export default withRouter(HistoryWatcher);
