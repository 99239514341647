import { FONT_STYLE } from '../groups';

/**
 * @name strong_mark
 */

var strongDOM = ['strong'];
export var strong = {
  inclusive: true,
  group: FONT_STYLE,
  parseDOM: [{
    tag: 'strong'
  },
  // This works around a Google Docs misbehavior where
  // pasted content will be inexplicably wrapped in `<b>`
  // tags with a font-weight normal.
  {
    tag: 'b',
    getAttrs: function getAttrs(node) {
      var element = node;
      return element.style.fontWeight !== 'normal' && null;
    }
  }, {
    tag: 'span',
    getAttrs: function getAttrs(node) {
      var element = node;
      var fontWeight = element.style.fontWeight;
      return typeof fontWeight === 'string' && (fontWeight === 'bold' || fontWeight === 'bolder' || /^(bold(er)?|[5-9]\d{2,})$/.test(fontWeight)) && null;
    }
  }],
  toDOM: function toDOM() {
    return strongDOM;
  }
};