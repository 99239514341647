import React, { FC, useCallback, useMemo, useState } from 'react';

import * as Sentry from '@sentry/browser';
import { useSelector } from 'react-redux';
import { UseResourceHookResponse } from 'react-resource-router';

import { ModalTransition } from '@atlaskit/modal-dialog-next';
import Spinner from '@atlaskit/spinner';
import { FeatureKeys } from '@atlassian/bitbucket-features';
import { uncurlyUuid } from '@atlassian/bitkit-analytics';

import { SectionError } from 'src/components/error-boundary';
import { useFlag } from 'src/hooks/flag';
import flagMessages from 'src/hooks/flag/flag.i18n';
import { useDjangoFeature } from 'src/hooks/use-django-feature';
import commonMessages from 'src/i18n/common';
import { FlagText } from 'src/redux/flags/types';
import { getCurrentWorkspaceUUID } from 'src/selectors/workspace-selectors';

import { SettingsPageLoadingWrapper } from '../settings.styled';

import { MergeChecksEmptyState } from './empty-state';
import { useInstalledCustomMergeChecks } from './hooks';
import { MergeChecksTabs } from './merge-checks-tabs';
import { messages } from './merge-checks.i18n';
import { ConfirmMergeCheckStateModal } from './modals';
import {
  BranchConfig,
  CustomMergeCheckConfigRouteResource,
  CustomMergeCheckConfigurationState,
  CustomMergeCheckExtensionId,
  CustomMergeChecksConfigureApi,
  CustomMergeChecksResourceType,
  ForgeMergeCheckModule,
  MergeCheckStateChangeType,
} from './types';
import { updateCustomMergeCheckConfigRouteResource } from './utils';

export type CustomMergeChecksSettingsProps = {
  api: CustomMergeChecksConfigureApi;
  resourceType: CustomMergeChecksResourceType;
  resource: UseResourceHookResponse<CustomMergeCheckConfigRouteResource>;
};

type CheckStateToConfirm = {
  branchConfig: BranchConfig;
  checkModule: ForgeMergeCheckModule;
  checkStateChangeType: MergeCheckStateChangeType;
};

export const CustomMergeChecksSettings: FC<CustomMergeChecksSettingsProps> = ({
  api,
  resourceType,
  resource: { loading, data: customMergeChecksConfig, error, update },
}) => {
  const workspaceUuid = useSelector(getCurrentWorkspaceUUID);
  const workspaceAri = useMemo(() => {
    if (workspaceUuid === undefined) {
      throw new Error("Couldn't get workspace UUID");
    }
    return `ari:cloud:bitbucket::workspace/${uncurlyUuid(workspaceUuid)}`;
  }, [workspaceUuid]);
  const {
    data: installedCustomMergeChecksData,
    error: installedCustomMergeChecksError,
    loading: installedCustomMergeChecksLoading,
  } = useInstalledCustomMergeChecks(workspaceAri);

  const { showFlag, dismissFlag } = useFlag({ enableForPdvs: true });
  const isEnabled = useDjangoFeature(FeatureKeys.extensibleMergeChecksEnabled);

  // This state is used to confirm the user's intent to disable a check or changing a check from
  // required to recommended
  const [checkStateToConfirm, setCheckStateToConfirm] = useState<
    CheckStateToConfirm | undefined
  >(undefined);
  const [checkIdBeingConfigured, setCheckIdBeingConfigured] = useState<
    CustomMergeCheckExtensionId | undefined
  >(undefined);

  // Called when user enables a check via dropdown
  const enableCheck = useCallback(
    async (
      branchConfig: BranchConfig,
      checkModule: ForgeMergeCheckModule,
      state: Extract<
        CustomMergeCheckConfigurationState,
        'required' | 'recommended'
      >
    ) => {
      setCheckIdBeingConfigured(checkModule.id);

      const {
        id,
        properties: { name },
      } = checkModule;
      try {
        const updatedCustomMergeCheck = await api.configureCustomMergeCheck(
          id,
          branchConfig,
          state
        );
        update(
          updateCustomMergeCheckConfigRouteResource(updatedCustomMergeCheck)
        );
      } catch (e) {
        Sentry.captureException(e);

        const flagId = 'enableCheckErrorFlag';
        let description: FlagText = {
          msg: messages.enableErrorMessage,
          values: { name },
        };
        let actions = [
          {
            content: { msg: commonMessages.tryAgain },
            onClick: () => {
              enableCheck(branchConfig, checkModule, state);
              dismissFlag(flagId);
            },
          },
        ];

        if (e.status === 403) {
          description = e?.error?.message || description;
          actions = [];
        }

        showFlag({
          id: flagId,
          iconType: 'error',
          title: { msg: flagMessages.error },
          description,
          actions,
          autoDismiss: true,
        });
      } finally {
        setCheckIdBeingConfigured(undefined);
      }
    },
    [api, update, showFlag, dismissFlag]
  );

  // Callback for hitting confirm in the confirm check state modal. This can be either disabling a
  // check or changing a check from required to recommended
  const updateCheckState = useCallback(
    async (
      branchConfig: BranchConfig,
      checkModule: ForgeMergeCheckModule,
      checkStateChangeType: MergeCheckStateChangeType
    ) => {
      setCheckIdBeingConfigured(checkModule.id);

      const {
        id,
        properties: { name },
      } = checkModule;
      try {
        let newConfigState: CustomMergeCheckConfigurationState;
        if (checkStateChangeType === MergeCheckStateChangeType.DISABLE) {
          newConfigState = 'off';
        } else if (
          checkStateChangeType ===
          MergeCheckStateChangeType.REQUIRED_TO_RECOMMENDED
        ) {
          newConfigState = 'recommended';
        } else {
          throw new Error('Invalid check state change type');
        }

        const updatedCustomMergeCheck = await api.configureCustomMergeCheck(
          id,
          branchConfig,
          newConfigState
        );
        update(
          updateCustomMergeCheckConfigRouteResource(updatedCustomMergeCheck)
        );
      } catch (e) {
        Sentry.captureException(e);
        const flagId = 'disableCheckErrorFlag';
        showFlag({
          id: flagId,
          iconType: 'error',
          title: { msg: flagMessages.error },
          description: { msg: messages.disableErrorMessage, values: { name } },
          actions: [
            {
              content: { msg: commonMessages.tryAgain },
              onClick: () => {
                updateCheckState(
                  branchConfig,
                  checkModule,
                  checkStateChangeType
                );
                dismissFlag(flagId);
              },
            },
          ],
          autoDismiss: true,
        });
      } finally {
        setCheckIdBeingConfigured(undefined);
        setCheckStateToConfirm(undefined);
      }
    },
    [api, showFlag, dismissFlag, update]
  );

  // We need to prompt the user to confirm their intent to disable a check or change a check from
  // required to recommended.
  const promptToUpdateCheckState = useCallback(
    (
      branchConfig: BranchConfig,
      checkModule: ForgeMergeCheckModule,
      checkStateChangeType: MergeCheckStateChangeType
    ) => {
      setCheckStateToConfirm({
        branchConfig,
        checkModule,
        checkStateChangeType,
      });
    },
    []
  );

  const onSubmitCheckConfiguration = useCallback(
    (
      branchConfig: BranchConfig,
      checkModule: ForgeMergeCheckModule,
      oldState: CustomMergeCheckConfigurationState,
      newState: CustomMergeCheckConfigurationState
    ) => {
      if (
        newState === 'off' ||
        (oldState === 'required' && newState === 'recommended')
      ) {
        const changeType =
          newState === 'off'
            ? MergeCheckStateChangeType.DISABLE
            : MergeCheckStateChangeType.REQUIRED_TO_RECOMMENDED;
        promptToUpdateCheckState(branchConfig, checkModule, changeType);
      } else {
        enableCheck(branchConfig, checkModule, newState);
      }
    },
    [promptToUpdateCheckState, enableCheck]
  );

  if (loading || installedCustomMergeChecksLoading) {
    return (
      <SettingsPageLoadingWrapper>
        <Spinner size="large" testId="mergeChecksSpinner" />
      </SettingsPageLoadingWrapper>
    );
  }

  if (error || installedCustomMergeChecksError || !customMergeChecksConfig) {
    return <SectionError />;
  }

  const installedCustomMergeChecks =
    installedCustomMergeChecksData?.extensionContext.extensionsByType ?? [];

  if (!isEnabled || installedCustomMergeChecks.length === 0) {
    return (
      <MergeChecksEmptyState
        resourceType={resourceType}
        isEnabled={isEnabled}
      />
    );
  }

  return (
    <>
      <MergeChecksTabs
        resourceType={resourceType}
        customMergeChecksResourceState={customMergeChecksConfig}
        installedCustomMergeChecks={installedCustomMergeChecks}
        checkIdBeingConfigured={checkIdBeingConfigured}
        onSubmitCheckConfiguration={onSubmitCheckConfiguration}
      />
      <ModalTransition>
        {checkStateToConfirm && (
          <ConfirmMergeCheckStateModal
            {...checkStateToConfirm}
            isLoading={checkIdBeingConfigured !== undefined}
            onCancel={() => setCheckStateToConfirm(undefined)}
            onConfirm={updateCheckState}
          />
        )}
      </ModalTransition>
    </>
  );
};
