import { Pipeline } from 'src/components/pipelines/models';

import { ADDON_KEY_PREFIX, ORIGIN, YML_FILE_NAME } from '../constants';

import extractUsername from './extractUsername';
import formatUuid from './formatUuid';
import isInIframe from './isInIframe';

function getEnvFromAddonKey(addonKey?: string): string {
  if (addonKey?.startsWith(ADDON_KEY_PREFIX)) {
    return addonKey.substring(ADDON_KEY_PREFIX.length);
  }
  return '';
}

function getEnvQueryParams(env?: string, addonKey?: string): string {
  const value = env || getEnvFromAddonKey(addonKey);
  return value ? `?env=${value}` : '';
}

export function getEditConfigurationURL(
  repoPath: string,
  branch: string
): string {
  return `${ORIGIN}/${repoPath}/src/${branch}/${YML_FILE_NAME}`;
}

export function getPipelinesPageURL(
  repoPath: string,
  addonKey: string,
  route = '',
  env?: string
): string {
  const envParams = getEnvQueryParams(env, addonKey);

  if (!isInIframe() || envParams) {
    return `/${repoPath}/pipelines${route}${envParams}`;
  }
  return `${ORIGIN}/${repoPath}/addon/${addonKey}/home${
    route ? `#!${route}` : '#!/'
  }`;
}

export function getPipelinePageURL(
  pipeline: Pipeline | { uuid?: string; buildNumber?: string },
  repoPath?: string,
  addonKey?: string,
  env?: string
): string {
  const envParams = getEnvQueryParams(env, addonKey);
  const route = `/results/${pipeline.buildNumber || formatUuid(pipeline.uuid)}`;

  if (!isInIframe() || envParams) {
    return `/${repoPath}/pipelines${route}${envParams}`;
  }
  return repoPath && addonKey
    ? `${ORIGIN}/${repoPath}/addon/${addonKey}/home#!${route}`
    : route;
}

export function getStepPageURL(
  pipeline: Pipeline | { uuid: string; buildNumber?: number },
  stepUuid: string,
  repoPath?: string
): string {
  const pipelineId = pipeline.buildNumber || formatUuid(pipeline.uuid);
  const route = `/results/${pipelineId}/steps/${formatUuid(stepUuid)}`;

  return `/${repoPath}/pipelines${route}`;
}

export function getTestReportPageURL(
  pipeline: Pipeline | { uuid: string; buildNumber?: number },
  stepUuid: string,
  repoPath?: string
): string {
  const stepPage = getStepPageURL(pipeline, stepUuid, repoPath);
  return `${stepPage}/test-report`;
}

export function getAccountPageURL(accountUuid: string): string {
  return `${ORIGIN}/${accountUuid}`;
}

export function getProjectPageURL(
  repoPath: string,
  projectKey: string
): string {
  return `${ORIGIN}/account/user/${extractUsername(
    repoPath
  )}/projects/${projectKey}`;
}

export function getSettingsPageURL(repoPath: string, addonKey: string): string {
  return `${ORIGIN}/${repoPath}/admin/${addonKey}/settings`;
}

export function getDeploymentSettingsPageURL(
  repoPath: string,
  addonKey: string
): string {
  return `${ORIGIN}/${repoPath}/admin/addon/admin/${addonKey}/deployment-settings`;
}

export function getAddConfigurationURL(
  repoPath: string,
  branch: string,
  ymlToCopy = ''
): string {
  let yml = ymlToCopy;
  if (yml) {
    yml = `&source=${encodeURIComponent(yml)}&disableFilename=true`;
  }
  return `${ORIGIN}/${repoPath}/create-file/?filename=${YML_FILE_NAME}&at=${branch}${yml}&fromOnboarding=true`;
}

export function getDeploymentsPageURL(
  repoPath: string,
  addonKey: string
): string {
  return `${ORIGIN}/${repoPath}/addon/${addonKey}/deployments`;
}

export function getPlansPageURL(repoPath = ''): string {
  return `${ORIGIN}/account/user/${extractUsername(
    repoPath
  )}/plans-and-billing/`;
}

export function getAdminPlans(): string {
  return `${ORIGIN}/account/admin/plans/`;
}
