import { AppData } from './app/data';
import { CloneProtocol } from './components/clone-controls/types';
import { MenuItem } from './components/navigation/src/types';
import { Link } from './components/types';
import { OverrideSettings } from './types/override-settings';
import {
  MergeStrategy,
  PullRequestRenderingLimits,
} from './types/pull-request';
import { SiteMessage } from './types/site-message';

// This is a first-draft at typing the Initial State from the Code Review perspective,
// this typing is almost assuredly wrong in some situation or context. TRUST NOTHING.
// Thus the Partial wrapper.

export type InitialState = Partial<{
  app: AppData;
  section: {
    repository: {
      connectActions: unknown[];
      cloneProtocol: CloneProtocol;
      currentRepository: BB.Repository;
      mirrors: unknown[];
      menuItems: MenuItem[];
      bitbucketActions: MenuItem[];
      activeMenuItem: string;
      sizeLimits: {
        hard: number | null;
        soft: number | null;
        readOnly: number | null;
      };
      overrideSettings: OverrideSettings;
    };
  };
  global: {
    theme: unknown;
    features: { [featureName: string]: boolean };
    locale: string;
    isCodeReviewWelcomeDialogOpen: boolean;
    needsTermsAndConditions: boolean;
    is_mobile_user_agent: boolean;
    site_message: '' | SiteMessage;
    codeReviewSidebarWidth: string | number | null;
    path: string;
    currentUser: BB.User;
    sourceBrowserSidebarWidth: string | number | null;
    bitbucketActions: MenuItem[];
    needs_marketing_consent: boolean;
    targetUser: BB.User;
    geoip_country: string;
    targetFeatures: { [featureName: string]: boolean };
    isFocusedTask: boolean;
    isCodeReviewSidebarOpen: boolean;
    flags: unknown[];
    isNavigationOpen: boolean;
    focusedTaskBackButtonUrl: string;
    isSourceBrowserSidebarOpen: boolean;
    whats_new_feed: string;
  };
  repository: {
    pullRequest: {
      defaultMergeStrategy: MergeStrategy | null;
      currentPullRequest: BB.PullRequest;
      renderingLimits?: PullRequestRenderingLimits;
      mergeInProgress?: Link;
      mergeMessageMaxSizeBytes?: number;
    };
  };
}>;

const state: InitialState = JSON.parse(
  // eslint-disable-next-line no-restricted-properties
  JSON.stringify(window.__initial_state__ || {})
);

export default state;
