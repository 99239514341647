import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
var _templateObject;
/** @jsx jsx */

import { css, jsx } from '@emotion/core';
import { Transition } from 'react-transition-group';
import { layers } from '@atlaskit/theme/constants';
import { surveyInnerWidth, surveyOffset } from '../constants';
var animationDuration = 300;
var offscreen = {
  translateX: "".concat(surveyInnerWidth + surveyOffset, "px"),
  opacity: '0'
};
var getAnimationProps = function getAnimationProps(state) {
  switch (state) {
    case 'entering':
      {
        return offscreen;
      }
    case 'entered':
      {
        return {
          translateX: '0',
          opacity: '1'
        };
      }
    case 'exited':
    case 'exiting':
      {
        return offscreen;
      }
  }
};
export default function SurveyMarshal(props) {
  var children = props.children,
    shouldShow = props.shouldShow;
  return jsx(Transition, {
    in: shouldShow,
    timeout: animationDuration,
    unmountOnExit: true
  }, function (state) {
    var _getAnimationProps = getAnimationProps(state),
      translateX = _getAnimationProps.translateX,
      opacity = _getAnimationProps.opacity;
    return jsx("div", {
      css: css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n              position: fixed;\n              right: ", "px;\n              bottom: ", "px;\n              z-index: ", ";\n              transform: translateX(", ");\n              opacity: ", ";\n              transition: all ", "ms ease-in-out;\n              transition-property: transform, opacity;\n            "])), surveyOffset, surveyOffset, layers.flag(), translateX, opacity, animationDuration)
    }, children());
  });
}