import { getExtensionAttrs } from '../../utils/extensions';

/**
 * @name inlineExtension_node
 */

/**
 * @name inlineExtension_with_marks_node
 */

var createInlineExtensionNodeSpec = function createInlineExtensionNodeSpec() {
  var nodeSpec = {
    inline: true,
    group: 'inline',
    selectable: true,
    attrs: {
      extensionType: {
        default: ''
      },
      extensionKey: {
        default: ''
      },
      parameters: {
        default: null
      },
      text: {
        default: null
      },
      localId: {
        default: null
      }
    },
    parseDOM: [{
      tag: 'span[data-extension-type]',
      getAttrs: function getAttrs(domNode) {
        return getExtensionAttrs(domNode, true);
      }
    }],
    toDOM: function toDOM(node) {
      var attrs = {
        'data-extension-type': node.attrs.extensionType,
        'data-extension-key': node.attrs.extensionKey,
        'data-text': node.attrs.text,
        'data-parameters': JSON.stringify(node.attrs.parameters),
        'data-local-id:': node.attrs.localId,
        contenteditable: 'false'
      };
      return ['span', attrs];
    }
  };
  return nodeSpec;
};
export var inlineExtension = createInlineExtensionNodeSpec();