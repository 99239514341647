import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { B100 } from '@atlaskit/theme/colors';
import { gridSize } from '@atlaskit/theme/constants';
var baseStyles = {
  itemBase: {
    alignItems: 'center',
    border: 0,
    borderRadius: '50%',
    color: 'inherit',
    cursor: 'pointer',
    display: 'flex',
    fontSize: 'inherit',
    justifyContent: 'center',
    lineHeight: 1,
    outline: 'none',
    padding: 0,
    position: 'relative',
    // allow badge positioning
    '&:focus': {
      boxShadow: "0 0 0 2px ".concat(B100)
    }
  },
  badgeWrapper: {
    pointerEvents: 'none',
    position: 'absolute',
    userSelect: 'none'
  },
  itemWrapper: {
    display: 'flex'
  }
};
var sizeStyles = {
  large: {
    itemBase: {
      height: "".concat(gridSize() * 5, "px"),
      width: "".concat(gridSize() * 5, "px")
    },
    badgeWrapper: {
      left: "".concat(gridSize() * 2, "px"),
      top: 0
    },
    itemWrapper: {}
  },
  small: {
    itemBase: {
      height: "".concat(gridSize() * 4, "px"),
      width: "".concat(gridSize() * 4, "px")
    },
    badgeWrapper: {
      left: "".concat(gridSize() * 2.5, "px"),
      top: "-".concat(gridSize() / 2, "px")
    },
    itemWrapper: {
      padding: "".concat(gridSize() / 2, "px")
    }
  }
};
export default (function (_ref) {
  var product = _ref.product;
  return function (_ref2) {
    var isActive = _ref2.isActive,
        isHover = _ref2.isHover,
        isSelected = _ref2.isSelected,
        _ref2$size = _ref2.size,
        size = _ref2$size === void 0 ? 'large' : _ref2$size;
    return {
      itemBase: _objectSpread(_objectSpread(_objectSpread({}, baseStyles.itemBase), sizeStyles[size].itemBase), {}, {
        backgroundColor: function () {
          if (isSelected) return product.background.static;
          if (isActive) return product.background.interact;
          if (isHover) return product.background.hint;
          return product.background.default;
        }(),
        color: product.text.default
      }),
      badgeWrapper: _objectSpread(_objectSpread({}, baseStyles.badgeWrapper), sizeStyles[size].badgeWrapper),
      itemWrapper: _objectSpread({}, sizeStyles[size].itemWrapper)
    };
  };
});