var localAnalyticsEnabled;
var localProduct;
export var getBscGlobalState = function getBscGlobalState() {
  if (localAnalyticsEnabled == null) {
    localAnalyticsEnabled = true;
  }
  return {
    analyticsEnabled: localAnalyticsEnabled,
    product: localProduct
  };
};
export var initializeGlobalState = function initializeGlobalState() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref$analyticsEnabled = _ref.analyticsEnabled,
    analyticsEnabled = _ref$analyticsEnabled === void 0 ? true : _ref$analyticsEnabled,
    _ref$product = _ref.product,
    product = _ref$product === void 0 ? 'NONE' : _ref$product;
  localAnalyticsEnabled = analyticsEnabled;
  localProduct = product;
};
export var clearGlobalState = function clearGlobalState() {
  localAnalyticsEnabled = undefined;
  localProduct = undefined;
};