import React from 'react';

/* eslint frontbucket-patterns/no-old-dropdown-menu: "warn" */
import DropdownMenu, {
  DropdownItemGroup,
  DropdownItem,
} from '@atlaskit/dropdown-menu';

import { DropMenuContainer } from 'src/components/settings/privileges-dropdown/privileges-dropdown.styles';
import { useIntl } from 'src/hooks/intl';

import { AccessLevel } from './constants';
import messages from './shared.i18n';
import { AccessLevelFilter } from './types';

export type AccessLevelDropdownProps = {
  value: AccessLevelFilter;
  onChange: (accessLevel: AccessLevelFilter) => void;
  isDisabled?: boolean;
  allowEmptyChoice?: boolean;
  isProjectContext?: boolean;
};

const AccessLevelDropdown: React.FC<AccessLevelDropdownProps> = ({
  value,
  onChange,
  isDisabled,
  allowEmptyChoice = false,
  isProjectContext = false,
}) => {
  const { formatMessage } = useIntl();
  let accessLevels = Object.values(AccessLevel);

  if (isProjectContext) {
    accessLevels = [AccessLevel.workspace, AccessLevel.project];
  }

  const getTriggerLabel = () => {
    if (value) {
      return formatMessage(messages[value]);
    }
    return formatMessage(messages.allAccessLevels);
  };

  return (
    <DropMenuContainer
      showValueDescription={false}
      allowEmptyChoice={allowEmptyChoice}
      fitContainer
      shiftLeft
    >
      <DropdownMenu
        testId="accessLevelDropdown"
        boundariesElement="viewport"
        shouldFitContainer
        trigger={getTriggerLabel()}
        triggerType="button"
        triggerButtonProps={{
          isDisabled,
          shouldFitContainer: true,
          className: 'triggerButton',
        }}
      >
        <DropdownItemGroup>
          {allowEmptyChoice && (
            <DropdownItem onClick={() => onChange(null)} isDisabled={!value}>
              {formatMessage(messages.allAccessLevelsMenuItem)}
            </DropdownItem>
          )}
          {accessLevels.map(accessLevel => (
            <DropdownItem
              key={accessLevel}
              isDisabled={accessLevel === value}
              onClick={() => onChange(accessLevel)}
            >
              {formatMessage(messages[accessLevel])}
            </DropdownItem>
          ))}
        </DropdownItemGroup>
      </DropdownMenu>
    </DropMenuContainer>
  );
};

export default AccessLevelDropdown;
