import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { css } from '@emotion/react';
import { tabColors, tabLineColors } from './colors';
var tabLeftRightPadding = "var(--ds-space-100, 8px)";
var tabTopBottomPadding = "var(--ds-space-050, 4px)";
// TODO this should probably be `border.width.indicator`
var underlineHeight = "var(--ds-border-width-outline, 2px)";
var getTabPanelStyles = function getTabPanelStyles() {
  return {
    flexGrow: 1,
    /*
      NOTE min-height set to 0% because of Firefox bug
      FF http://stackoverflow.com/questions/28636832/firefox-overflow-y-not-working-with-nested-flexbox
    */
    minHeight: '0%',
    display: 'flex',
    padding: "0 ".concat(tabLeftRightPadding)
  };
};
export var getTabsStyles = function getTabsStyles() {
  return (
    // eslint-disable-next-line @repo/internal/styles/no-exported-styles
    css({
      '& [role="tabpanel"]': getTabPanelStyles(),
      // The hidden attribute doesn't work on flex elements
      // Change display to be none
      // eslint-disable-next-line @atlaskit/design-system/no-nested-styles
      '& > [hidden]': {
        display: 'none'
      }
    })
  );
};
var tabLineStyles = {
  content: '""',
  borderRadius: "var(--ds-border-radius-050, 2px)",
  bottom: 0,
  margin: 0,
  position: 'absolute',
  width: 'inherit',
  left: tabLeftRightPadding,
  right: tabLeftRightPadding
};
export var getTabListStyles = function getTabListStyles() {
  return (
    // eslint-disable-next-line @repo/internal/styles/no-exported-styles
    css({
      '& [role="tab"]': getTabStyles(),
      fontWeight: "var(--ds-font-weight-medium, 500)",
      '&::before': _objectSpread(_objectSpread({}, tabLineStyles), {}, {
        height: underlineHeight,
        // This line is not a border so the selected line is visible in high contrast mode
        backgroundColor: tabLineColors.lineColor
      })
    })
  );
};
export var getTabStyles = function getTabStyles() {
  var colors = tabColors;
  return {
    color: colors.labelColor,
    cursor: 'pointer',
    lineHeight: 1.8,
    margin: 0,
    padding: "".concat(tabTopBottomPadding, " ").concat(tabLeftRightPadding),
    position: 'relative',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
      // TODO: interaction states will be reviewed in DSP-1438
      color: colors.hoverLabelColor,
      '&::after': _objectSpread(_objectSpread({}, tabLineStyles), {}, {
        borderBottom: "".concat(underlineHeight, " solid ").concat(tabLineColors.hoveredColor),
        height: 0
      })
    },
    '&:active': {
      // TODO: interaction states will be reviewed in DSP-1438
      color: colors.activeLabelColor,
      '&::after': _objectSpread(_objectSpread({}, tabLineStyles), {}, {
        borderBottom: "".concat(underlineHeight, " solid ").concat(tabLineColors.activeColor),
        height: 0
      })
    },
    '&[aria-selected="true"]': {
      color: colors.selectedColor,
      '&::after': _objectSpread(_objectSpread({}, tabLineStyles), {}, {
        // This line is a border so it is visible in high contrast mode
        borderBottom: "".concat(underlineHeight, " solid ").concat(tabLineColors.selectedColor),
        height: 0
      })
    }
  };
};