/**
 * @jsxRuntime classic
 * @jsx jsx
 */

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { N800 } from '@atlaskit/theme/colors';
var descriptionStyles = css({
  color: "var(--ds-text, ".concat(N800, ")"),
  marginBlockEnd: "var(--ds-space-300, 24px)",
  marginBlockStart: "var(--ds-space-0, 0px)"
});

/**
 * __Description__
 *
 * Description of Empty State.
 *
 * @internal
 */
var Description = function Description(_ref) {
  var children = _ref.children;
  return jsx("p", {
    css: descriptionStyles
  }, children);
};
export default Description;