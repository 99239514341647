import qs from 'qs';

import { YML_FILE_NAME } from 'src/components/pipelines/constants';
import { Pipeline } from 'src/components/pipelines/models';

import {
  RepositoryDynamicPipelinesParams,
  WorkspaceDynamicPipelinesParams,
} from './models/DynamicPipelines';
import { formatUuid } from './utils';
import envBaseUrl from './utils/env-base-url';

export const getKnownHostsUrl = (
  repoPath: string,
  page?: string | number,
  pagelen?: string | number,
  uuid?: string
): string => {
  const query = qs.stringify({
    page,
    pagelen,
  });
  return `${repoPath}/pipelines_config/ssh/known_hosts${
    uuid ? `/${uuid}` : '/'
  }${query ? `?${query}` : ''}`;
};

export function getPipelinesPageURL(repoPath: string): string {
  return `/${repoPath}/pipelines/results/`;
}

export function getStepPageURL(
  repoPath: string,
  pipeline: Pipeline | { uuid: string; buildNumber?: number },
  stepUuid: string
): string {
  return `/${repoPath}/pipelines/results/${
    pipeline.buildNumber || formatUuid(pipeline.uuid)
  }/steps/${formatUuid(stepUuid)}`;
}

export function getArtifactsPageURL(
  repoPath: string,
  pipelineUuid: string,
  stepUuid: string
): string {
  return `${getStepPageURL(
    repoPath,
    { uuid: pipelineUuid },
    stepUuid
  )}/artifacts`;
}

export function getMetricsPageURL(
  repoPath: string,
  pipelineUuid: string,
  stepUuid: string
): string {
  return `${getStepPageURL(
    repoPath,
    { uuid: pipelineUuid },
    stepUuid
  )}/metrics`;
}

export function getServicePageURL(
  repoPath: string,
  pipelineUuid: string,
  stepUuid: string,
  serviceUuid?: string
): string {
  return `${getStepPageURL(repoPath, { uuid: pipelineUuid }, stepUuid)}${
    serviceUuid ? `/services/${formatUuid(serviceUuid)}` : ''
  }`;
}

export function getTestReportPageURL(
  repoPath: string,
  pipeline: Pipeline | { uuid: string; buildNumber?: number },
  stepUuid: string
): string {
  return `${getStepPageURL(repoPath, pipeline, stepUuid)}/test-report`;
}

export function getGettingStartedPageURL(repoPath: string): string {
  return `${getPipelinesPageURL(repoPath)}getting-started`;
}

export function getEditConfigurationURL(
  repoPath: string,
  branch: string,
  queryParams?: Record<string, any>
): string {
  const search = qs.stringify(queryParams, {
    addQueryPrefix: true,
    skipNulls: true,
  });
  return `/${repoPath}/src/${branch}/${YML_FILE_NAME}${search}`;
}

export function getDeploymentsSettingsPageURL(
  repoPath: string,
  isDeploymentsSettingsInFrontbucket: boolean
) {
  if (isDeploymentsSettingsInFrontbucket) {
    return `/${repoPath}/admin/pipelines/deployment-settings`;
  } else {
    return `/${repoPath}/admin/addon/admin/pipelines/deployment-settings`;
  }
}

export function getPipelinesSettingsPageURL(repoPath: string) {
  return `/${repoPath}/admin/pipelines/settings`;
}

export function getAdminPlansPageUrl(workspace: string) {
  return `/${workspace}/workspace/settings/plans-and-billing`;
}

export function getWorkspaceVariablesPageUrl(workspace: string) {
  return `/${workspace}/workspace/settings/pipelines/account-variables`;
}

export function getWorkspaceRunnersPageUrl(workspace: string) {
  return `/${workspace}/workspace/settings/pipelines/account-runners`;
}

export function getWorkspaceDynamicPipelinesPageUrl(workspace: string) {
  return `/${workspace}/workspace/settings/pipelines/dynamic-pipelines`;
}

export const getWorkspaceDynamicPipelinesProvidersUrl = (
  params: WorkspaceDynamicPipelinesParams
) => {
  return `${envBaseUrl('/!api/internal')}/workspaces/${
    params.workspaceSlug
  }/pipelines-config/dynamic-pipelines-provider`;
};

export const getRepositoryDynamicPipelinesProvidersUrl = (
  params: RepositoryDynamicPipelinesParams
) => {
  const { repositoryOwner, repositorySlug } = params;
  return `${envBaseUrl(
    '/!api/internal'
  )}/repositories/${repositoryOwner}/${repositorySlug}/pipelines-config/dynamic-pipelines-provider`;
};
