import { type DependencyList, useMemo } from 'react';

export function toDependencyList<T extends Record<string, unknown>>(obj: T): DependencyList {
	return Object.entries(obj)
		.sort()
		.flatMap((v) => v);
}

export function useStableObject<T extends Record<string, unknown>>(obj: T): T {
	return useMemo(() => obj, toDependencyList(obj));
}
