import { createResource } from 'react-resource-router';

import { FeatureKeys } from '@atlassian/bitbucket-features';

import { customMergeChecksConfigApi } from 'src/components/settings/merge-checks';
import {
  CustomMergeCheckKey,
  CustomMergeCheckConfigurationState,
  CustomMergeCheckConfigRouteResource,
  CustomMergeChecksResourceType,
} from 'src/components/settings/merge-checks/types';
import { customMergeCheckKey } from 'src/components/settings/merge-checks/utils';
import { ResourceContext } from 'src/router/types';
import urls from 'src/sections/repository/urls';
import { getFeatures } from 'src/selectors/feature-selectors';

export const customMergeChecksConfigRouteResource =
  createResource<CustomMergeCheckConfigRouteResource>({
    type: 'custom-merge-checks-configuration',
    getKey: ({ match }) => {
      // TODO: generate key based on route resource type
      const { repositoryOwner, repositorySlug } = match.params;
      return `${repositoryOwner}/${repositorySlug}/custom-merge-checks`;
    },
    maxAge: 60000, // cache for 1 minute so navigating between pages is fast
    getData: async ({ match }, { reduxStore }: ResourceContext) => {
      const { repositoryOwner, repositorySlug } = match.params;

      if (!repositoryOwner || !repositorySlug) {
        throw new Error(
          'This resource must be rendered on a route that includes both :repositoryOwner and :repositorySlug'
        );
      }

      const state = reduxStore.getState();

      const stateMap = new Map<
        CustomMergeCheckKey,
        CustomMergeCheckConfigurationState
      >();
      if (!getFeatures(state)[FeatureKeys.extensibleMergeChecksEnabled]) {
        return {
          branchConfigs: [],
          stateMap,
        };
      }

      // TODO: Get resource type appropriate URL from route
      const { getBranchConfigs, getCustomMergeChecks } =
        customMergeChecksConfigApi(
          urls.api.internal.customMergeChecks(repositoryOwner, repositorySlug)
        );

      const [branchConfigs, customMergeChecks] = await Promise.all([
        getBranchConfigs(),
        getCustomMergeChecks(),
      ]);

      for (const customMergeCheck of customMergeChecks) {
        const key = customMergeCheckKey(
          // TODO: Get resource type from route
          CustomMergeChecksResourceType.Repository,
          customMergeCheck.branchConfig,
          customMergeCheck.extensionId
        );
        stateMap.set(key, customMergeCheck.state);
      }

      return {
        branchConfigs,
        stateMap,
      };
    },
  });
