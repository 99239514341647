import Loadable from 'react-loadable';

import { LoadingPage } from 'src/components/react-loadable';
import { loadGlobalResource } from 'src/redux/global/resources/load-global';
import { projectNavigationResource } from 'src/sections/project/project-navigation-resource';
import { ProjectSettingsAccessKeysLoadable } from 'src/sections/project/sections/admin/components/settings-pages/access-keys';
import { ProjectSettingsAccessTokensLoadable } from 'src/sections/project/sections/admin/components/settings-pages/access-tokens';
import { ProjectSettingsBranchRestrictionsLoadable } from 'src/sections/project/sections/admin/components/settings-pages/branch-restrictions';
import { ProjectSettingsBranchingModelLoadable } from 'src/sections/project/sections/admin/components/settings-pages/branching-model';
import { ProjectSettingsDefaultReviewersLoadable } from 'src/sections/project/sections/admin/components/settings-pages/default-reviewers';
import { ProjectSettingsDetailsLoadable } from 'src/sections/project/sections/admin/components/settings-pages/details';
import { ProjectSettingsMergeStrategiesLoadable } from 'src/sections/project/sections/admin/components/settings-pages/merge-strategies';
import { ProjectSettingsPermissionsLoadable } from 'src/sections/project/sections/admin/components/settings-pages/permissions';
import { loadProjectSettingsPermissionsResource } from 'src/sections/project/sections/admin/components/settings-pages/permissions/resources';
import { ProjectRepositoriesLoadable } from 'src/sections/project/sections/repositories';
import { workspaceNavigationResource } from 'src/sections/workspace/workspace-navigation-resource';

import { RouteConfig, ROUTE_NAME } from '../types';

const ProjectLayout = Loadable({
  loading: LoadingPage,
  loader: () => import('src/sections/project/layout'),
});

export const getProjectRoutesConfig = (): RouteConfig => {
  return {
    [ROUTE_NAME.PROJECT_REPOSITORIES]: {
      // @ts-ignore: requires RouteContext type on component
      component: ProjectRepositoriesLoadable,
      layout: ProjectLayout,
      // workspaceNavigationResource is required for create links
      // in ./src/sections/project/sections/repositories/project-repositories-empty.tsx
      // XXX this should get switched to use the project nav resource instead
      resources: [
        loadGlobalResource,
        workspaceNavigationResource,
        projectNavigationResource,
      ],
    },
    [ROUTE_NAME.PROJECT_SETTINGS_DETAILS]: {
      // @ts-ignore: requires RouteContext type on component
      component: ProjectSettingsDetailsLoadable,
      layout: ProjectLayout,
      resources: [loadGlobalResource, projectNavigationResource],
    },
    [ROUTE_NAME.PROJECT_SETTINGS_DEFAULT_REVIEWERS]: {
      // @ts-ignore: requires RouteContext type on component
      component: ProjectSettingsDefaultReviewersLoadable,
      layout: ProjectLayout,
      resources: [loadGlobalResource, projectNavigationResource],
    },
    [ROUTE_NAME.PROJECT_SETTINGS_BRANCHING_MODEL]: {
      // @ts-ignore: requires RouteContext type on component
      component: ProjectSettingsBranchingModelLoadable,
      layout: ProjectLayout,
      resources: [loadGlobalResource, projectNavigationResource],
    },
    [ROUTE_NAME.PROJECT_SETTINGS_ACCESS_KEYS]: {
      // @ts-ignore: requires RouteContext type on component
      component: ProjectSettingsAccessKeysLoadable,
      layout: ProjectLayout,
      resources: [loadGlobalResource, projectNavigationResource],
    },

    [ROUTE_NAME.PROJECT_SETTINGS_MERGE_STRATEGIES]: {
      // @ts-ignore: requires RouteContext type on component
      component: ProjectSettingsMergeStrategiesLoadable,
      layout: ProjectLayout,
      resources: [loadGlobalResource, projectNavigationResource],
    },
    [ROUTE_NAME.PROJECT_SETTINGS_BRANCH_RESTRICTIONS]: {
      // @ts-ignore: requires RouteContext type on component
      component: ProjectSettingsBranchRestrictionsLoadable,
      layout: ProjectLayout,
      resources: [loadGlobalResource, projectNavigationResource],
    },
    [ROUTE_NAME.PROJECT_SETTINGS_PERMISSIONS]: {
      // @ts-ignore: requires RouteContext type on component
      component: ProjectSettingsPermissionsLoadable,
      layout: ProjectLayout,
      resources: [
        loadGlobalResource,
        loadProjectSettingsPermissionsResource,
        projectNavigationResource,
      ],
    },
    [ROUTE_NAME.PROJECT_SETTINGS_ACCESS_TOKENS]: {
      // @ts-ignore: requires RouteContext type on component
      component: ProjectSettingsAccessTokensLoadable,
      layout: ProjectLayout,
      resources: [loadGlobalResource, projectNavigationResource],
    },
  };
};
