import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
const _excluded = ["item"],
  _excluded2 = ["items"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { GuardPolicy, INDEXEDDB_TIMEOUT, NAMESPACE_AND_TIME_ADDED_INDEX, RESILIENCE_DB_NAME, RESILIENCE_STORE_NAME, RETRY_INDEX, StoreType, TIME_ADDED_INDEX, TIME_TO_PROCESS_AFTER_INDEX } from './constants';
import { GET_ITEM_COUNT, VISIBILITY_TIMEOUT } from './defaults';
import { CallbackProcessingError, InvalidPolicyError, NoIndexedDbError, shouldIgnoreResilienceDbError } from './errors';
import IndexedDbEventCountGuard from './IndexedDbEventCountGuard';
import { commitTransaction, convertToItemWrapper, createOptionsWithDefaults, monitorErrorsOnRequest, requestToPromise } from './util';

/**
 * We have rolled our own client for IndexedDb as many Indexeddb libraries are
 * not flexible enough for our use case of:
 * 1. Adding specific indexes for time,
 * 2. Getting items on a specific index,
 * 3. Updating a field on an event while getting the event inside of a single ACID compliant transaction.
 */
export default class IndexedDbConnector {
  constructor(_namespace, _options = {}) {
    _defineProperty(this, "startDB", async () => {
      return new Promise(async (resolve, reject) => {
        if (typeof window !== 'undefined') {
          const dbTimeout = window.setTimeout(() => {
            this.logger.warn('IndexedDB timed out.');
            reject(new NoIndexedDbError());
          }, INDEXEDDB_TIMEOUT);

          /*
           * The database version number can never change.
           * An upgrade transaction is limited to only one connection to the database.
           * Once this is done, we cannot open any connections with older versions of the schema.
           * https://www.w3.org/TR/IndexedDB/#upgrade-transaction-construct
           *
           * Due to the nature of how AWC is used (multiple tabs that are very long lived),
           * we will not be able to run version upgrades via indexeddb.
           *
           * This does not mean we cant change what is stored in the tables,
           * this limitation just prevents us from:
           *   - Creating new ObjectStores in this database connection, and
           *   - Creating new indexes on our ObjectStores
           *
           * Any upgrades we wish to run in the future will have to create a new database,
           * and migrate all the data from older databases.
           *
           * This also means any migrations we make will have to be supported until we have evidence
           * that no events are coming from old versions of the database.
           * This may take a long time.
           */
          const request = window.indexedDB.open(RESILIENCE_DB_NAME, 1);
          request.onupgradeneeded = event => {
            if (event.oldVersion !== 0) {
              throw new Error('We cannot upgrade the database. Do not do this.');
            }
            const db = request.result;
            const store = db.createObjectStore(RESILIENCE_STORE_NAME, {
              keyPath: 'id'
            });
            store.createIndex(TIME_TO_PROCESS_AFTER_INDEX, TIME_TO_PROCESS_AFTER_INDEX, {
              unique: false
            });
            store.createIndex(RETRY_INDEX, RETRY_INDEX, {
              unique: false
            });
            store.createIndex(TIME_ADDED_INDEX, TIME_ADDED_INDEX, {
              unique: false
            });
            // Compound key index of product - timeAdded.
            store.createIndex(NAMESPACE_AND_TIME_ADDED_INDEX, ['namespace', 'timeAdded'], {
              unique: false
            });
          };
          try {
            await requestToPromise(request);
            return resolve(request.result);
          } catch (error) {
            this.logger.warn('IndexedDB failed to initialise.', error);
            reject(new NoIndexedDbError());
          } finally {
            window.clearTimeout(dbTimeout);
          }
        } else {
          this.logger.warn("IndexedDB failed to initialise. No 'window' object.");
          reject(new NoIndexedDbError());
        }
      });
    });
    _defineProperty(this, "addItem", async (item, options = {}, policy = GuardPolicy.ABANDON) => {
      const {
        logger,
        namespace
      } = this;
      const value = convertToItemWrapper(item, namespace, options);
      const {
        objectStore
      } = await this.getObjectStoreAndTransaction('readwrite');
      if (policy === GuardPolicy.IGNORE) {
        throw new InvalidPolicyError(policy, 'IndexedDbConnector#addItem');
      }
      try {
        if (!value.namespace || value.namespace.length === 0 || typeof value.namespace !== 'string') {
          throw new Error('Namespace not specified');
        }

        // Making space for 1 event, if required.
        const bulkAddResult = await this.globalEventLimitGuard.insertItems(objectStore, [value], policy);
        if (bulkAddResult.items.length === 1) {
          return {
            item: bulkAddResult.items[0],
            numberOfEvictedItems: bulkAddResult.numberOfEvictedItems
          };
        }
        // Should never happen as the Policy and EventCountGuard should cause another pathway
        throw new Error(`Incorrect number of items added. Expected: 1, got: ${bulkAddResult.items.length}`);
      } catch (error) {
        if (shouldIgnoreResilienceDbError(error)) {
          throw error;
        }
        logger.log('Failed to add item to table', error);
        throw new Error('Request to add item to table failed');
      }
    });
    _defineProperty(this, "bulkAddItem", async (itemOptions, policy = GuardPolicy.ABANDON) => {
      const {
        logger,
        namespace
      } = this;
      const items = itemOptions.map(_ref => {
        let {
            item
          } = _ref,
          options = _objectWithoutProperties(_ref, _excluded);
        return convertToItemWrapper(item, namespace, options);
      });
      const {
        objectStore
      } = await this.getObjectStoreAndTransaction('readwrite');
      try {
        return await this.globalEventLimitGuard.insertItems(objectStore, items, policy);
      } catch (error) {
        if (shouldIgnoreResilienceDbError(error)) {
          throw error;
        }
        logger.log('Failed to add item to table', error);
        throw new Error('Request to add item to table failed');
      }
    });
    _defineProperty(this, "getItems", async (count = GET_ITEM_COUNT) => {
      const fixedCount = count > 0 ? count : GET_ITEM_COUNT;
      const {
        logger
      } = this;
      const maxAttempts = this.options.maxAttempts;
      const {
        transaction,
        objectStore
      } = await this.getObjectStoreAndTransaction('readwrite');
      const timeIndex = objectStore.index(TIME_TO_PROCESS_AFTER_INDEX);
      const upperBoundOpenKeyRange = IDBKeyRange.upperBound(Date.now());
      const request = timeIndex.openCursor(upperBoundOpenKeyRange);
      const getResult = await new Promise(async (resolve, reject) => {
        const items = [];
        let numberOfDeletedItems = 0;
        request.onerror = error => {
          logger.error('Failed to open cursor:', error);
          reject('Failed to open cursor');
        };

        // Requests for Cursors call onsuccess multiple times and cannot be converted to promises
        request.onsuccess = event => {
          const cursor = event.target.result;
          if (cursor) {
            // Prevent mutation of the value we are returning
            const value = _objectSpread({}, cursor.value);
            items.push(value);

            // Mutations seem to be required for testing indexeddb library
            const updatedValue = cursor.value;
            updatedValue.retryAttempts += 1;
            updatedValue.timeToBeProcessedAfter = Date.now() + VISIBILITY_TIMEOUT;
            if (updatedValue.retryAttempts >= maxAttempts) {
              ++numberOfDeletedItems;
              const request = cursor.delete();
              monitorErrorsOnRequest(request, logger);
            } else {
              const request = cursor.update(updatedValue);
              monitorErrorsOnRequest(request, logger);
            }
            if (items.length < fixedCount) {
              cursor.continue();
            } else {
              resolve({
                items,
                numberOfDeletedItems
              });
            }
          } else {
            resolve({
              items,
              numberOfDeletedItems
            });
          }
        };
      });
      await commitTransaction(transaction, this.logger);
      return getResult;
    });
    _defineProperty(this, "deleteItems", async itemIds => {
      const {
        transaction,
        objectStore
      } = await this.getObjectStoreAndTransaction('readwrite');
      try {
        const deletePromises = itemIds.map(id => this.deleteItem(objectStore, id));
        await commitTransaction(transaction, this.logger);
        await Promise.all(deletePromises);
      } catch (error) {
        this.logger.warn('Failed to delete items from indexeddb.', error);
        throw error;
      }
    });
    _defineProperty(this, "getItemCount", async () => {
      const {
        transaction,
        objectStore
      } = await this.getObjectStoreAndTransaction('readonly');
      const timeIndex = objectStore.index(TIME_TO_PROCESS_AFTER_INDEX);
      const upperBoundOpenKeyRange = IDBKeyRange.upperBound(Date.now());
      const request = timeIndex.count(upperBoundOpenKeyRange);
      const event = await requestToPromise(request);
      await commitTransaction(transaction, this.logger);
      return event.target.result;
    });
    _defineProperty(this, "processItems", async (processFn, count = GET_ITEM_COUNT) => {
      const _await$this$getItems = await this.getItems(count),
        {
          items
        } = _await$this$getItems,
        partialResult = _objectWithoutProperties(_await$this$getItems, _excluded2);
      try {
        const result = await processFn(items, partialResult);
        const itemIds = items.map(item => item.id);

        // We should not await this delete as a rejected promise would get caught by the catch statement
        await this.deleteItems(itemIds);
        return result;
      } catch (error) {
        // Eventually we will need a intermediate class to catch all errors but we should ignore this error
        // This allows libraries beneath to distinguish between a success or failure in processing to update schedulers or anything else
        // To provide back off or any other mechanism to allow what ever is cauing the errors to recover
        throw new CallbackProcessingError(error);
      }
    });
    _defineProperty(this, "deleteItem", async (objectStore, id) => {
      const {
        logger
      } = this;
      try {
        await requestToPromise(objectStore.delete(id));
      } catch (error) {
        logger.error('Failed to delete item:', id, error);
        throw error;
      }
    });
    _defineProperty(this, "getObjectStoreAndTransaction", async mode => {
      const transaction = (await this.db).transaction(RESILIENCE_STORE_NAME, mode);
      const objectStore = transaction.objectStore(RESILIENCE_STORE_NAME);
      return {
        transaction,
        objectStore
      };
    });
    this.options = createOptionsWithDefaults(_options);
    this.namespace = _namespace;
    this.logger = this.options.logger;
    if (!window.indexedDB) {
      throw new NoIndexedDbError();
    }
    this.db = this.startDB();
    // We just swallow any errors as we know that these are intermittent errors
    // when switching from indexeddb -> localStorage
    this.db.catch(() => {});

    /**
     *
     * This class will enforce the number of analytics events we can store in our IndexedDB object store
     * if asked before adding items to the object store.
     */
    this.globalEventLimitGuard = new IndexedDbEventCountGuard(this.options.maxEventLimit, this.namespace, this.logger, this.deleteItem);
  }
  storeType() {
    return StoreType.INDEXEDDB;
  }
}