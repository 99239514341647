import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { gridSize as gridSizeFn } from '@atlaskit/theme/constants';
import { GLOBAL_NAV_WIDTH } from '../../../common/constants';
var gridSize = gridSizeFn();
var baseStyles = {
  alignItems: 'center',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,
  justifyContent: 'space-between',
  paddingBottom: "calc(".concat(gridSize * 3 - 4, "px)"),
  paddingTop: gridSize * 3,
  transition: 'background-color 0.3s cubic-bezier(0.2, 0, 0, 1), color 0.3s cubic-bezier(0.2, 0, 0, 1)',
  width: GLOBAL_NAV_WIDTH
};
export default (function (_ref) {
  var product = _ref.product;
  return function () {
    var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
      topOffset: '0'
    },
        topOffset = _ref2.topOffset;

    return _objectSpread(_objectSpread({}, baseStyles), {}, {
      height: "calc(100vh - ".concat(topOffset, "px)"),
      backgroundColor: product.background.default,
      color: product.text.default,
      fill: product.background.default
    });
  };
});