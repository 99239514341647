import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { fg } from '@atlaskit/platform-feature-flags';
import { MessageDeliveryStatus } from './constants';
export class Logger {
  /**
   * Utility class to simplify and allow for wider use of sending operational event to the Analytics web client for logging/metrics.
   *
   * @param actionSubject A (MessageActions) string denoting either the start or stop phase of a message.
   * @param actionSubjectId Unique messageId to be logged with this even.
   * @param metricsData Additional data from plugin to be sent with event for metrics logging.
   * @param analyticsClient An instance of AnalyticsWebClient, unique to each caller
   */
  constructor(actionSubject, actionSubjectId, metricsData, analyticsClient) {
    this.state = {
      actionSubject: actionSubject,
      actionSubjectId: actionSubjectId,
      metricsData,
      analyticsClient: analyticsClient
    };
  }

  /**
   * Send operational event to the Analytics web client for logging/metrics.
   *
   * @param action A (MessageDeliveryStatuses) string denoting the ultimate disposition of this message start or stop request.
   */
  sendOperationalEvent(action, additionalAttributes) {
    if (fg('platform.post-office.enable-full-choreographer-logging')) {
      const {
        analyticsClient,
        actionSubject,
        actionSubjectId,
        metricsData: attributes
      } = this.state;
      void analyticsClient.sendOperationalEvent({
        source: 'choreographer',
        action,
        actionSubject,
        actionSubjectId,
        attributes: _objectSpread(_objectSpread({}, attributes !== null && attributes !== void 0 ? attributes : {}), additionalAttributes !== null && additionalAttributes !== void 0 ? additionalAttributes : {}),
        tags: ['postOffice']
      });
    }
  }
  blocked(additionalAttributes) {
    this.sendOperationalEvent(MessageDeliveryStatus.BLOCKED, additionalAttributes);
  }
  error(additionalAttributes) {
    this.sendOperationalEvent(MessageDeliveryStatus.ERROR, additionalAttributes);
  }
  unregistered(additionalAttributes) {
    this.sendOperationalEvent(MessageDeliveryStatus.UNREGISTERED, additionalAttributes);
  }
  started(additionalAttributes) {
    this.sendOperationalEvent(MessageDeliveryStatus.STARTED, additionalAttributes);
  }
  stopped(additionalAttributes) {
    this.sendOperationalEvent(MessageDeliveryStatus.STOPPED, additionalAttributes);
  }
}