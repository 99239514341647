/**
 * @name blockquote_node
 */

var createBlockquoteSpec = function createBlockquoteSpec(allowList) {
  var blockquoteSpec = {
    group: 'block',
    defining: true,
    selectable: false,
    parseDOM: [{
      tag: 'blockquote'
    }],
    toDOM: function toDOM() {
      return ['blockquote', 0];
    }
  };
  if (allowList) {
    blockquoteSpec.content = '(paragraph | orderedList | bulletList | unsupportedBlock)+';
  } else {
    blockquoteSpec.content = '(paragraph | unsupportedBlock)+';
  }
  return blockquoteSpec;
};
export var blockquote = createBlockquoteSpec(false);
/**
 * @name blockquote_with_list_node
 */
export var blockquoteWithList = createBlockquoteSpec(true);