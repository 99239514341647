import React, { useMemo } from 'react';

import { useIntl } from 'react-intl-next';

import { PostOfficeContextProvider as PostOfficeBaseProvider } from '@atlassian/post-office-frontend';

import { useAppData } from 'src/app/data';

type PostOfficeContextProviderProps = { children: React.ReactNode };

/*
 * This component is used to provide the PostOfficeContext to the application. It provides the product, subproduct and locale at the application level.
 */
const PostOfficeContextProviderImpl = ({
  children,
}: PostOfficeContextProviderProps): JSX.Element => {
  const { locale } = useIntl();
  const { tenantId } = useAppData();
  const workspaceAri = tenantId
    ? `ari:cloud:bitbucket::workspace/${tenantId}`
    : undefined; // Bitbucket tenantId is the equivalent of Atlassian workspaceId

  const providerValue = useMemo(
    () => ({
      product: 'bitbucket',
      locale,
      workspaceId: workspaceAri,
    }),
    [locale, workspaceAri]
  );

  return (
    <PostOfficeBaseProvider value={providerValue}>
      {children}
    </PostOfficeBaseProvider>
  );
};

export const PostOfficeContextProvider = PostOfficeContextProviderImpl;
