import { defineMessages } from 'react-intl';

export const sharedMessages = defineMessages({
  allBranchesTabLabel: {
    id: 'frontbucket.settings.merge-checks-tabs.allBranchesTabLabel',
    description: "Label for the 'All branches' tab",
    defaultMessage: 'All branches',
  },
  mainBranchTabLabel: {
    id: 'frontbucket.settings.merge-checks-tabs.mainBranchTabLabel',
    description: "Label for the 'Main branch' tab",
    defaultMessage: 'Main branch',
  },
  enabledChecksTooltip: {
    id: 'frontbucket.settings.merge-checks-tabs.enabledChecksTooltip',
    description: 'Tooltip for how many checks are enabled',
    defaultMessage:
      '{count} {count, plural, one {check} other {checks}} enabled',
  },
  tableColumnApp: {
    id: 'frontbucket.settings.merge-checks-tabs.tableColumnApp',
    description: 'Table column title for "App"',
    defaultMessage: 'App',
  },
  tableColumnMergeCheck: {
    id: 'frontbucket.settings.merge-checks-tabs.tableColumnMergeCheck',
    description: 'Table column title for "Merge Check"',
    defaultMessage: 'Merge Check',
  },
  tableColumnType: {
    id: 'frontbucket.settings.merge-checks-tabs.tableColumnType',
    description: 'Table column title for "Type"',
    defaultMessage: 'Type',
  },
  configurationTabsAriaLabel: {
    id: 'frontbucket.settings.merge-checks-tabs.configurationTabsAriaLabel',
    description: 'ARIA label for the custom merge checks configuration tabs',
    defaultMessage:
      'Custom merge checks repository configuration - {tabLabel}. {enabledChecks}',
  },
  configurationTableAriaLabel: {
    id: 'frontbucket.settings.merge-checks-tabs.configurationTableAriaLabel',
    description: 'ARIA label for the custom merge checks configuration table',
    defaultMessage:
      'Custom merge checks repository configuration - {branchConfig}',
  },
  checkDropdownAriaLabel: {
    id: 'frontbucket.settings.merge-checks-tabs.checkDropdownAriaLabel',
    description:
      'ARIA label for the custom merge checks configuration dropdown button',
    defaultMessage:
      '{appName}. {checkName}. Runs {triggers}. Currently {status}',
  },
});
