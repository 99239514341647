import { CONFIG } from './constants';
import { Logger } from './logger';
import { getNetworkIdleObservable } from './network-idle-observable';
import { requestIdleCallback } from './request-idle-callback';

/**
 * Request a callback when the CPU and network have both been simultaneously
 * idle for IDLE_TIMEOUT.
 *
 * NOTE: will only trigger once
 */
export function requestAllIdleCallback(callback) {
  var networkIdleObservable = getNetworkIdleObservable();

  // state
  var networkIdle = networkIdleObservable.isIdle();
  var timeout;
  var handleNetworkChange = function handleNetworkChange(message) {
    networkIdle = message === 'IDLE';
    if (networkIdle) {
      requestIdleCallback(handleCpuIdle);
    } else {
      window.clearTimeout(timeout);
      timeout = undefined;
    }
  };
  var handleCpuIdle = function handleCpuIdle() {
    Logger.debug('requestAllIdleCallback.handleCpuIdle()');
    if (networkIdle && !timeout) {
      handleAllIdle();
    }
  };
  var handleAllIdle = function handleAllIdle() {
    timeout = window.setTimeout(function () {
      // Did we have to clear a "hung" request from observable state?
      var didNetworkTimeOut = networkIdleObservable.didNetworkTimeOut();
      networkIdleObservable.resetDidNetworkTimeOut();
      Logger.info('requestAllIdleCallback: ALL IDLE');
      callback(didNetworkTimeOut);
      unsubscribe();
    }, CONFIG.IDLE_TIMEOUT);
  };
  var unsubscribe = networkIdleObservable.subscribe(handleNetworkChange);

  // base case
  if (networkIdle) {
    handleNetworkChange('IDLE');
  }
}