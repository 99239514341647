import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
var _templateObject;
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { fontSize } from '@atlaskit/theme/constants';
import SuccessContainer from './SuccessContainer';
export default (function () {
  return jsx(SuccessContainer, null, jsx("h1", {
    css: css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n        font-size: ", "px;\n        font-weight: 600;\n        margin-top: 0;\n        line-height: ", ";\n      "])), fontSize(), "var(--ds-font-lineHeight-300, 24px)")
  }, "Thanks for your feedback"));
});