import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
import _objectDestructuringEmpty from "@babel/runtime/helpers/objectDestructuringEmpty";
var _templateObject;
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { fontSize } from '@atlaskit/theme/constants';
import SuccessContainer from './SuccessContainer';
export default (function (_ref) {
  _objectDestructuringEmpty(_ref);
  return jsx(SuccessContainer, null, jsx("h1", {
    css: css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n        font-size: ", "px;\n        font-weight: 600;\n        line-height: ", ";\n        margin: 0;\n      "])), fontSize(), "var(--ds-font-lineHeight-300, 24px)")
  }, "Thanks for signing up"), jsx("p", null, "We may reach out to you in the future to participate in additional research."));
});