import { B100, B400, B500, N30, N500 } from '@atlaskit/theme/colors';
export var tabColors = {
  labelColor: "var(--ds-text-subtle, ".concat(N500, ")"),
  activeLabelColor: "var(--ds-text, ".concat(B500, ")"),
  hoverLabelColor: "var(--ds-text-subtle, ".concat(B400, ")"),
  selectedColor: "var(--ds-text-selected, ".concat(B400, ")"),
  focusBorderColor: "var(--ds-border-focused, ".concat(B100, ")")
};
export var tabLineColors = {
  lineColor: "var(--ds-border, ".concat(N30, ")"),
  hoveredColor: "var(--ds-border, transparent)",
  activeColor: "var(--ds-border, transparent)",
  selectedColor: "var(--ds-border-selected, ".concat(B400, ")")
};