import { getExtensionAttrs } from '../../utils/extensions';

/**
 * @stage 0
 * @name extensionFrame_node
 * @description Wraps the block content
 */

/**
 * @returns NodeSpec for ExtensionFrameDefinition
 */
export var extensionFrame = {
  type: 'extensionFrame',
  content: '(paragraph | panel | blockquote | orderedList | bulletList | rule | heading | codeBlock | mediaGroup | mediaSingle | decisionList | taskList | table | extension | bodiedExtension | unsupportedBlock | blockCard | embedCard)+',
  isolating: true,
  marks: 'dataConsumer fragment',
  definingAsContext: false,
  definingForContent: true,
  selectable: false,
  attrs: {},
  parseDOM: [{
    context: 'extensionFrame//',
    tag: 'div[data-extension-frame]',
    skip: true
  }, {
    tag: 'div[data-extension-frame]'
  }],
  toDOM: function toDOM() {
    var attrs = {
      'data-extension-frame': 'true'
    };
    return ['div', attrs, 0];
  }
};

/**
 * @stage 0
 * @name multiBodiedExtension_node
 * @description Wraps multiple extensionFrame objects.
 */

/**
 * @returns NodeSpec for MultiBodiedExtensionDefinition
 */
var createMultiBodiedExtensionNodeSpec = function createMultiBodiedExtensionNodeSpec() {
  var nodeSpec = {
    inline: false,
    group: 'blockRootOnly',
    content: 'extensionFrame+',
    block: true,
    definingAsContext: true,
    selectable: true,
    attrs: {
      extensionKey: {
        default: ''
      },
      extensionType: {
        default: ''
      },
      parameters: {
        default: null
      },
      text: {
        default: null
      },
      layout: {
        default: 'default'
      },
      localId: {
        default: null
      }
    },
    parseDOM: [{
      context: 'multiBodiedExtension//',
      tag: '[data-node-type="multi-bodied-extension"]',
      skip: true
    }, {
      tag: '[data-node-type="multi-bodied-extension"]',
      getAttrs: function getAttrs(domNode) {
        return getExtensionAttrs(domNode);
      }
    }],
    toDOM: function toDOM(node) {
      var attrs = {
        'data-node-type': 'multi-bodied-extension',
        'data-extension-type': node.attrs.extensionType,
        'data-extension-key': node.attrs.extensionKey,
        'data-text': node.attrs.text,
        'data-parameters': JSON.stringify(node.attrs.parameters),
        'data-layout': node.attrs.layout,
        'data-local-id:': node.attrs.localId
      };
      return ['div', attrs, 0];
    }
  };
  return nodeSpec;
};
export var multiBodiedExtension = createMultiBodiedExtensionNodeSpec();