export enum CustomMergeChecksResourceType {
  Repository = 'repository',
}

export type CustomMergeChecksConfigApi = {
  getBranchConfigs: () => Promise<BranchConfigsResponse>;
  getCustomMergeChecks: () => Promise<CustomMergeChecksConfigurationResponse>;
};

export type CustomMergeChecksConfigureApi = {
  configureCustomMergeCheck: (
    extensionId: CustomMergeCheckExtensionId,
    branch: BranchConfig,
    state: CustomMergeCheckConfigurationState
  ) => Promise<CustomMergeCheck>;
};

// API response for fetching branch configs from bbc-checks
export type BranchConfigsResponse = BranchConfig[];

// API response for fetching custom merge checks
export type CustomMergeChecksConfigurationResponse = CustomMergeCheck[];

export type CustomMergeCheck = {
  extensionId: CustomMergeCheckExtensionId;
  state: CustomMergeCheckConfigurationState;
  resource: CustomMergeCheckResourceId;
  branchConfig: BranchConfig;
};

// Uniquely identifies a custom merge check for a branch config and resource type
export type CustomMergeCheckKey = string;

// Data shape for customMergeChecksConfigRouteResource
export type CustomMergeCheckConfigRouteResource = {
  branchConfigs: BranchConfig[];
  stateMap: Map<CustomMergeCheckKey, CustomMergeCheckConfigurationState>;
};

// Partial type (just what we use) of the Forge check module representation
export type ForgeMergeCheckModule = {
  properties: {
    // Check name
    name: string;
    description: string;
    triggers: CheckTrigger[];
  };
  id: CustomMergeCheckExtensionId;
  // App UUID
  appId: string;
  // App name
  name: string;
};

export type BranchConfig = {
  type: BranchConfigType;
  identifier: BranchConfigIdentifier;
};

export type CustomMergeCheckExtensionId = string;

// A workspace/project/repository ARI
export type CustomMergeCheckResourceId = string;

export type CheckTrigger =
  | 'on-merge'
  | 'on-code-pushed'
  | 'on-reviewer-status-changed';

export type CheckTriggerType = 'pre-merge' | 'on-merge';

export type BranchConfigType = 'model';

export type BranchConfigIdentifier = 'main' | 'all_branches';

export type CustomMergeCheckConfigurationState =
  | 'off'
  | 'recommended'
  | 'required';

export enum MergeCheckStateChangeType {
  DISABLE = 'disable',
  REQUIRED_TO_RECOMMENDED = 'required-to-recommended',
}
