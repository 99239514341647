/**
 * Request a callback when we are confident that any synchronous CPU work has
 * been flushed from the task queue.
 */
export var requestIdleCallback = function requestIdleCallback(callback) {
  var _window$requestIdleCa;
  // if browser supports it, wait until CPU is idle
  // eslint-disable-next-line compat/compat
  var requestCallback = (_window$requestIdleCa = window.requestIdleCallback) !== null && _window$requestIdleCa !== void 0 ? _window$requestIdleCa : window.setTimeout;
  requestCallback(function () {
    // wait *two* animation frames, to ensure the page has a chance to flush
    // pending changes to the DOM
    window.requestAnimationFrame(function () {
      return window.requestAnimationFrame(callback);
    });
  });
};