import React, { FC } from 'react';

import DynamicTable from '@atlaskit/dynamic-table';
import Lozenge from '@atlaskit/lozenge';
import { Box, Inline, xcss, Flex } from '@atlaskit/primitives';

import { useIntl } from 'src/hooks/intl';

import { ForgeAppAvatar } from './forge-app-avatar';
import { MergeChecksConfigureDropdown } from './merge-checks-configure-dropdown';
import { sharedMessages } from './merge-checks-tabs.i18n';
import { messages } from './merge-checks.i18n';
import {
  AppColumnContainer,
  MergeCheckColumnContainer,
  Description,
  LozengeContainer,
} from './styled';
import {
  BranchConfig,
  CustomMergeCheckExtensionId,
  ForgeMergeCheckModule,
  CustomMergeCheckConfigurationState,
  CheckTrigger,
  CheckTriggerType,
  CustomMergeCheckKey,
  CustomMergeChecksResourceType,
} from './types';
import { customMergeCheckKey } from './utils';

type MergeCheckModulesTableProps = {
  resourceType: CustomMergeChecksResourceType;
  branchConfig: BranchConfig;
  installedCheckModules: ForgeMergeCheckModule[];
  stateMap: Map<CustomMergeCheckKey, CustomMergeCheckConfigurationState>;
  checkIdBeingConfigured?: CustomMergeCheckExtensionId;
  onSubmitCheckConfiguration: (
    branchConfig: BranchConfig,
    checkModule: ForgeMergeCheckModule,
    oldState: CustomMergeCheckConfigurationState,
    newState: CustomMergeCheckConfigurationState
  ) => void;
};

export const MergeCheckModulesTable: FC<MergeCheckModulesTableProps> = ({
  resourceType,
  branchConfig,
  installedCheckModules,
  stateMap,
  checkIdBeingConfigured,
  onSubmitCheckConfiguration,
}) => {
  const { formatMessage } = useIntl();

  const renderTriggerLozenges = (triggers: CheckTrigger[]) => {
    const hasOnMergeTrigger = triggers.includes('on-merge');
    const triggerTypes: CheckTriggerType[] = [];
    if (triggers.length > (hasOnMergeTrigger ? 1 : 0)) {
      triggerTypes.push('pre-merge');
    }
    if (hasOnMergeTrigger) {
      triggerTypes.push('on-merge');
    }

    return (
      <Flex>
        {triggerTypes.map(triggerType => (
          <LozengeContainer key={triggerType}>
            <Lozenge maxWidth={250}>
              {formatMessage(
                triggerType === 'pre-merge'
                  ? messages.preMerge
                  : messages.onMerge
              )}
            </Lozenge>
          </LozengeContainer>
        ))}
      </Flex>
    );
  };

  const renderActionCell = (checkModule: ForgeMergeCheckModule) => {
    return (
      // set min-width on the dropdown to avoid reflowing the row when different items are selected
      <Box xcss={xcss({ minWidth: '175px' })}>
        <MergeChecksConfigureDropdown
          checkModule={checkModule}
          branchConfig={branchConfig}
          checkState={stateMap.get(
            customMergeCheckKey(resourceType, branchConfig, checkModule.id)
          )}
          isLoading={checkIdBeingConfigured === checkModule.id}
          isDisabled={checkIdBeingConfigured !== undefined}
          onSubmitCheckConfiguration={onSubmitCheckConfiguration}
        />
      </Box>
    );
  };

  return (
    <DynamicTable
      head={{
        cells: [
          { content: formatMessage(sharedMessages.tableColumnApp) },
          { content: formatMessage(sharedMessages.tableColumnMergeCheck) },
          { content: formatMessage(sharedMessages.tableColumnType) },
          { content: null },
        ],
      }}
      label={formatMessage(sharedMessages.configurationTableAriaLabel, {
        branchConfig: formatMessage(
          branchConfig.identifier === 'all_branches'
            ? sharedMessages.allBranchesTabLabel
            : sharedMessages.mainBranchTabLabel
        ),
      })}
      rows={installedCheckModules.map((checkModule, index) => {
        const {
          appId,
          name: appName,
          properties: { name, description, triggers },
        } = checkModule;
        return {
          key: index.toString(),
          cells: [
            {
              content: (
                <AppColumnContainer>
                  <Inline space="space.100">
                    <ForgeAppAvatar appId={appId} />
                    <span>{appName}</span>
                  </Inline>
                </AppColumnContainer>
              ),
            },
            {
              content: (
                <MergeCheckColumnContainer>
                  <p>{name}</p>
                  <Description>{description}</Description>
                </MergeCheckColumnContainer>
              ),
            },
            {
              content: renderTriggerLozenges(triggers),
            },
            {
              content: renderActionCell(checkModule),
            },
          ],
        };
      })}
      rowsPerPage={10}
      testId="merge-checks-modules-table"
    />
  );
};
