/**
 * @name nestedExpand_content
 * @minItems 1
 * @allowUnsupportedBlock true
 */

/**
 * @name nestedExpand_node
 */

/**
 * @name nestedExpand_with_no_marks_node
 */

var createNestedExpandSpec = function createNestedExpandSpec() {
  var nestedExpandSpec = {
    inline: false,
    marks: 'unsupportedMark unsupportedNodeAttribute',
    isolating: true,
    selectable: true,
    attrs: {
      title: {
        default: ''
      },
      __expanded: {
        default: true
      }
    },
    parseDOM: [{
      context: 'nestedExpand//',
      tag: '[data-node-type="nestedExpand"]',
      skip: true
    }, {
      tag: '[data-node-type="nestedExpand"] button',
      ignore: true
    }, {
      tag: '[data-node-type="expand"] button',
      ignore: true
    }, {
      tag: 'div[data-node-type="nestedExpand"]',
      getAttrs: function getAttrs(domNode) {
        var dom = domNode;
        return {
          title: dom.getAttribute('data-title'),
          __expanded: true
        };
      }
    }],
    toDOM: function toDOM(node) {
      var attrs = {
        'data-node-type': 'nestedExpand',
        'data-title': node.attrs.title,
        'data-expanded': node.attrs.__expanded
      };
      return ['div', attrs, 0];
    }
  };
  nestedExpandSpec.content = '(paragraph | heading | mediaSingle | mediaGroup | codeBlock | bulletList | orderedList | taskList | decisionList | rule | panel | blockquote | unsupportedBlock)+';
  return nestedExpandSpec;
};

/**
 * @name nestedExpand
 * @description an expand that can be nested (eg. inside table, layout).
 */
export var nestedExpand = createNestedExpandSpec();