import { isNumber } from 'lodash-es';

import { LoadingStatus } from 'src/constants/loading-status';
import { Action } from 'src/types/state';
import createReducer from 'src/utils/create-reducer';

import { LogRequestMeta, StepLogMap } from '../../models';
import {
  CLEAR_CURRENT_PIPELINE,
  REQUEST_FLAT_NESTED_LOGS,
  REQUEST_DELETE_LOG,
  REQUEST_LOG,
  REQUEST_SERVICE_LOG,
} from '../actions/pipelines';

export type LogState = {
  commands: { [uuid: string]: (string[] | null)[] };
  commandsV2: { [uuid: string]: (string[] | null)[] };
  stepLogs: { [uuid: string]: StepLogMap };
  fullLog: { [uuid: string]: string };
  fullLogError: { [uuid: string]: string | undefined };
  serviceLog: { [uuid: string]: string[] };
  serviceLogError: { [uuid: string]: string | undefined };
  fetchedStatus: { [uuid: string]: LoadingStatus };
};

export const initialState: LogState = {
  commands: {},
  commandsV2: {},
  stepLogs: {},
  fullLog: {},
  fullLogError: {},
  serviceLog: {},
  serviceLogError: {},
  fetchedStatus: {},
};

export const log = createReducer(initialState, {
  [CLEAR_CURRENT_PIPELINE]() {
    return { ...initialState };
  },
  [REQUEST_LOG.REQUEST](
    state: LogState,
    action: Action & {
      meta: LogRequestMeta;
    }
  ) {
    if (!action.meta?.stepUuid) {
      return state;
    }

    return {
      ...state,
      ...(!action.meta?.index
        ? {
            fullLogError: {
              ...state.fullLogError,
              [action.meta.stepUuid]: undefined,
            },
          }
        : {}),
      fetchedStatus: {
        ...state.fetchedStatus,
        [action.meta.stepUuid]: LoadingStatus.Fetching,
      },
    };
  },
  [REQUEST_FLAT_NESTED_LOGS.SUCCESS](
    state: LogState,
    action: Action<{
      logLines: string[];
      stepLog: StepLogMap;
    }> & {
      meta: LogRequestMeta;
    }
  ) {
    const { logLines, stepLog } = action.payload || {};

    if (!isNumber(action.meta.index) || !logLines || !stepLog) {
      return state;
    }

    const commandsV2 = state.commandsV2[action.meta.stepUuid] || [];
    commandsV2[action.meta.index] = logLines;

    return {
      ...state,
      commandsV2: {
        ...state.commandsV2,
        [action.meta.stepUuid]: commandsV2,
      },
      stepLogs: {
        ...state.stepLogs,
        [action.meta.stepUuid]: stepLog,
      },
    };
  },
  [REQUEST_LOG.SUCCESS](
    state: LogState,
    action: Action<string[]> & {
      meta: LogRequestMeta;
    }
  ) {
    if (!action.meta?.stepUuid || !Array.isArray(action.payload)) {
      return state;
    }

    const commands = state.commands[action.meta.stepUuid] || [];

    if (isNumber(action.meta.index)) {
      commands[action.meta.index] = action.payload;
    }

    return {
      ...state,
      ...(!Object.prototype.hasOwnProperty.call(action.meta, 'index')
        ? {
            fullLog: {
              ...state.fullLog,
              [action.meta.stepUuid]: action.payload.join('\r\n'),
            },
          }
        : {
            commands: {
              ...state.commands,
              [action.meta.stepUuid]: commands,
            },
          }),
      fetchedStatus: {
        ...state.fetchedStatus,
        [action.meta.stepUuid]: LoadingStatus.Success,
      },
    };
  },
  [REQUEST_LOG.ERROR](
    state: LogState,
    action: Action<Error> & {
      meta: LogRequestMeta;
    }
  ) {
    if (!action.meta?.stepUuid) {
      return state;
    }

    return {
      ...state,
      ...(!Object.prototype.hasOwnProperty.call(action.meta, 'index')
        ? {
            fullLogError: {
              ...state.fullLogError,
              [action.meta.stepUuid]: action.payload?.message,
            },
          }
        : {}),
      fetchedStatus: {
        ...state.fetchedStatus,
        [action.meta.stepUuid]: LoadingStatus.Failed,
      },
    };
  },
  [REQUEST_SERVICE_LOG.REQUEST](
    state: LogState,
    action: Action & {
      meta: { serviceUuid: string };
    }
  ) {
    if (!action.meta?.serviceUuid) {
      return state;
    }

    return {
      ...state,
      serviceLogError: {
        ...state.serviceLogError,
        [action.meta.serviceUuid]: undefined,
      },
      fetchedStatus: {
        ...state.fetchedStatus,
        [action.meta.serviceUuid]: LoadingStatus.Fetching,
      },
    };
  },
  [REQUEST_SERVICE_LOG.SUCCESS](
    state: LogState,
    action: Action<string> & {
      meta: { serviceUuid: string };
    }
  ) {
    if (!action.meta?.serviceUuid) {
      return state;
    }

    const chunkLines = [''].concat(
      action.payload ? action.payload.split(/\r?\n|\r/) : []
    );

    return {
      ...state,
      serviceLog: {
        ...state.serviceLog,
        [action.meta.serviceUuid]: chunkLines,
      },
      fetchedStatus: {
        ...state.fetchedStatus,
        [action.meta.serviceUuid]: LoadingStatus.Success,
      },
    };
  },
  [REQUEST_SERVICE_LOG.ERROR](
    state: LogState,
    action: Action<Error> & {
      meta: { serviceUuid: string };
    }
  ) {
    if (!action.meta?.serviceUuid) {
      return state;
    }

    return {
      ...state,
      serviceLogError: {
        ...state.serviceLogError,
        [action.meta.serviceUuid]: action.payload?.message,
      },
      fetchedStatus: {
        ...state.fetchedStatus,
        [action.meta.serviceUuid]: LoadingStatus.Failed,
      },
    };
  },
  [REQUEST_DELETE_LOG.REQUEST](state: LogState) {
    return {
      ...state,
      fetchedStatus: {
        ...state.fetchedStatus,
        DELETE_LOG: LoadingStatus.Fetching,
      },
    };
  },
  [REQUEST_DELETE_LOG.SUCCESS](
    state: LogState,
    action: Action & {
      meta: { stepUuid: string; serviceUuid?: string };
    }
  ) {
    if (!action.meta?.stepUuid) {
      return state;
    }

    return {
      ...state,
      ...(action.meta.serviceUuid
        ? {
            serviceLog: {
              ...state.serviceLog,
              [action.meta.serviceUuid]: [],
            },
          }
        : {
            commands: {
              ...state.commands,
              [action.meta.stepUuid]: [],
            },
            fullLog: {
              ...state.fullLog,
              [action.meta.stepUuid]: '',
            },
            ...(action.meta.incrementalRequestEnabled
              ? {
                  stepLogs: {
                    ...state.stepLogs,
                    [action.meta.stepUuid]: new Map(),
                  },
                }
              : {}),
            ...(action.meta.incrementalRequestEnabled
              ? {
                  commandsV2: {
                    ...state.commandsV2,
                    [action.meta.stepUuid]: [],
                  },
                }
              : {}),
          }),
      fetchedStatus: {
        ...state.fetchedStatus,
        DELETE_LOG: LoadingStatus.Success,
      },
    };
  },
});
