/**
 * @name caption_node
 */

export var caption = {
  content: '(text|hardBreak|mention|emoji|date|placeholder|inlineCard|status|unsupportedInline)*',
  isolating: true,
  marks: '_',
  selectable: false,
  parseDOM: [{
    tag: 'figcaption[data-caption]'
  }],
  toDOM: function toDOM() {
    var attrs = {
      'data-caption': 'true'
    };
    return ['figcaption', attrs, 0];
  }
};