"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatsigEndpoint = void 0;
var StatsigEndpoint;
(function (StatsigEndpoint) {
    StatsigEndpoint["Initialize"] = "initialize";
    StatsigEndpoint["Rgstr"] = "rgstr";
    StatsigEndpoint["LogEventBeacon"] = "log_event_beacon";
})(StatsigEndpoint = exports.StatsigEndpoint || (exports.StatsigEndpoint = {}));
var NO_CONTENT = 204;
var StatsigNetwork = /** @class */ (function () {
    function StatsigNetwork(options, identity, errorBoundary) {
        this.retryCodes = {
            408: true,
            500: true,
            502: true,
            503: true,
            504: true,
            522: true,
            524: true,
            599: true,
        };
        this.canUseKeepalive = false;
        this._options = options;
        this._identity = identity;
        this._errorBoundary = errorBoundary;
        this.leakyBucket = {};
        this._init();
    }
    StatsigNetwork.prototype.fetchValues = function (user, sinceTime, timeout, previousDerivedFields) {
        var input = {
            user: user,
            statsigMetadata: this._identity._statsigMetadata,
            sinceTime: sinceTime !== null && sinceTime !== void 0 ? sinceTime : undefined,
            hash: 'djb2',
            previousDerivedFields: previousDerivedFields,
        };
        return this._postWithTimeout(StatsigEndpoint.Initialize, input, timeout, // timeout for early returns
        3);
    };
    StatsigNetwork.prototype.sendLogBeacon = function (payload) {
        if (this._options.localMode) {
            return true;
        }
        var url = new URL(this._options.eventLoggingApi + StatsigEndpoint.LogEventBeacon);
        url.searchParams.append('k', this._identity._sdkKey);
        payload.clientTime = Date.now() + '';
        var stringPayload = null;
        try {
            stringPayload = JSON.stringify(payload);
        }
        catch (_e) {
            return false;
        }
        return navigator.sendBeacon(url.toString(), stringPayload);
    };
    StatsigNetwork.prototype.postToEndpoint = function (endpointName, body, retries, backoff, useKeepalive) {
        if (retries === void 0) { retries = 0; }
        if (backoff === void 0) { backoff = 1000; }
        if (useKeepalive === void 0) { useKeepalive = false; }
        return __awaiter(this, void 0, void 0, function () {
            var api, url, counter, postBody, statsigMetadata, params;
            var _this = this;
            return __generator(this, function (_a) {
                if (this._options.localMode) {
                    return [2 /*return*/, Promise.reject('no network requests in localMode')];
                }
                if (typeof fetch !== 'function') {
                    // fetch is not defined in this environment, short circuit
                    return [2 /*return*/, Promise.reject('fetch is not defined')];
                }
                if (typeof window === 'undefined' && !this._options.ignoreWindowUndefined) {
                    // by default, dont issue requests from the server
                    return [2 /*return*/, Promise.reject('window is not defined')];
                }
                api = [StatsigEndpoint.Initialize].includes(endpointName)
                    ? this._options.api
                    : this._options.eventLoggingApi;
                url = api + endpointName;
                counter = this.leakyBucket[url];
                if (counter != null && counter >= 30) {
                    return [2 /*return*/, Promise.reject(new Error('Request failed because you are making the same request too frequently.'))];
                }
                if (counter == null) {
                    this.leakyBucket[url] = 1;
                }
                else {
                    this.leakyBucket[url] = counter + 1;
                }
                postBody = JSON.stringify(body);
                statsigMetadata = this._identity._statsigMetadata;
                params = {
                    method: 'POST',
                    body: postBody,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        'STATSIG-API-KEY': this._identity._sdkKey,
                        'STATSIG-CLIENT-TIME': Date.now() + '',
                        'STATSIG-SDK-TYPE': statsigMetadata.sdkType,
                        'STATSIG-SDK-VERSION': statsigMetadata.sdkVersion,
                    },
                };
                if (this.canUseKeepalive && useKeepalive) {
                    params.keepalive = true;
                }
                return [2 /*return*/, fetch(url, params)
                        .then(function (res) { return __awaiter(_this, void 0, void 0, function () {
                        var networkResponse, text, errorText;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!res.ok) return [3 /*break*/, 4];
                                    networkResponse = res;
                                    if (!(res.status === NO_CONTENT)) return [3 /*break*/, 1];
                                    networkResponse.data = { has_updates: false, is_no_content: true };
                                    return [3 /*break*/, 3];
                                case 1: return [4 /*yield*/, res.text()];
                                case 2:
                                    text = _a.sent();
                                    networkResponse.data = JSON.parse(text);
                                    _a.label = 3;
                                case 3: return [2 /*return*/, Promise.resolve(networkResponse)];
                                case 4:
                                    if (!this.retryCodes[res.status]) {
                                        retries = 0;
                                    }
                                    return [4 /*yield*/, res.text()];
                                case 5:
                                    errorText = _a.sent();
                                    return [2 /*return*/, Promise.reject(new Error("".concat(res.status, ": ").concat(errorText)))];
                            }
                        });
                    }); })
                        .catch(function (e) {
                        if (retries > 0) {
                            return new Promise(function (resolve, reject) {
                                setTimeout(function () {
                                    _this.leakyBucket[url] = Math.max(_this.leakyBucket[url] - 1, 0);
                                    _this.postToEndpoint(endpointName, body, retries - 1, backoff * 2, useKeepalive)
                                        .then(resolve)
                                        .catch(reject);
                                }, backoff);
                            });
                        }
                        return Promise.reject(e);
                    })
                        .finally(function () {
                        _this.leakyBucket[url] = Math.max(_this.leakyBucket[url] - 1, 0);
                    })];
            });
        });
    };
    StatsigNetwork.prototype.supportsKeepalive = function () {
        return this.canUseKeepalive;
    };
    StatsigNetwork.prototype._init = function () {
        if (!this._options.disableNetworkKeepalive) {
            try {
                this.canUseKeepalive = 'keepalive' in new Request('');
            }
            catch (_e) { }
        }
    };
    StatsigNetwork.prototype._postWithTimeout = function (endpointName, body, timeout, retries, backoff) {
        var _this = this;
        if (timeout === void 0) { timeout = 0; }
        if (retries === void 0) { retries = 0; }
        if (backoff === void 0) { backoff = 1000; }
        var hasTimedOut = false;
        var timer = null;
        var cachedReturnValue = null;
        var eventuals = [];
        var eventually = function (boundScope) { return function (fn) {
            if (hasTimedOut && cachedReturnValue) {
                fn(cachedReturnValue);
            }
            else {
                eventuals.push(fn);
            }
            return boundScope;
        }; };
        if (timeout != 0) {
            timer = new Promise(function (resolve, reject) {
                setTimeout(function () {
                    hasTimedOut = true;
                    reject(new Error("The initialization timeout of ".concat(timeout, "ms has been hit before the network request has completed.")));
                }, timeout);
            });
        }
        var fetchPromise = this.postToEndpoint(endpointName, body, retries, backoff)
            .then(function (res) {
            if (!res.ok) {
                return Promise.reject(new Error("Request to ".concat(endpointName, " failed with status ").concat(res.status)));
            }
            if (typeof res.data !== 'object') {
                var error = new Error("Request to ".concat(endpointName, " received invalid response type. Expected 'object' but got '").concat(typeof res.data, "'"));
                _this._errorBoundary._logError('postWithTimeoutInvalidRes', error, function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        return [2 /*return*/, this._getErrorData(endpointName, body, retries, backoff, res)];
                    });
                }); });
                return Promise.reject(error);
            }
            var json = res.data;
            return _this._errorBoundary._capture('postWithTimeout', function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    cachedReturnValue = json;
                    if (hasTimedOut) {
                        eventuals.forEach(function (fn) { return fn(json); });
                        eventuals = [];
                    }
                    return [2 /*return*/, Promise.resolve(json)];
                });
            }); }, function () {
                return Promise.resolve({});
            }, function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, this._getErrorData(endpointName, body, retries, backoff, res)];
                });
            }); });
        })
            .catch(function (e) {
            return Promise.reject(e);
        });
        var racingPromise = (timer ? Promise.race([fetchPromise, timer]) : fetchPromise);
        racingPromise.eventually = eventually(racingPromise);
        return racingPromise;
    };
    StatsigNetwork.prototype._getErrorData = function (endpointName, body, retries, backoff, res) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var headers_1;
            return __generator(this, function (_b) {
                try {
                    headers_1 = {};
                    ((_a = res.headers) !== null && _a !== void 0 ? _a : []).forEach(function (value, key) {
                        headers_1[key] = value;
                    });
                    return [2 /*return*/, {
                            responseInfo: {
                                headers: headers_1,
                                status: res.status,
                                statusText: res.statusText,
                                type: res.type,
                                url: res.url,
                                redirected: res.redirected,
                                bodySnippet: res.data ? JSON.stringify(res.data).slice(0, 500) : null,
                            },
                            requestInfo: {
                                endpointName: endpointName,
                                bodySnippet: JSON.stringify(body).slice(0, 500),
                                retries: retries,
                                backoff: backoff,
                            },
                        }];
                }
                catch (_e) {
                    return [2 /*return*/, {
                            statusText: 'statsig::failed to extract extra data',
                        }];
                }
                return [2 /*return*/];
            });
        });
    };
    return StatsigNetwork;
}());
exports.default = StatsigNetwork;
