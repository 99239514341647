import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _typeof from "@babel/runtime/helpers/typeof";
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css as cssEmotion } from '@emotion/react';

// eslint-disable-next-line import/no-extraneous-dependencies

import { allSpaceMap, backgroundColorMap, borderColorMap, borderRadiusMap, borderWidthMap, dimensionMap, layerMap, opacityMap, positiveSpaceMap, shadowMap, textColorMap } from './style-maps.partial';
export var tokensMap = {
  backgroundColor: backgroundColorMap,
  blockSize: dimensionMap,
  borderBlockColor: borderColorMap,
  borderBlockEndColor: borderColorMap,
  borderBlockEndWidth: borderWidthMap,
  borderBlockStartColor: borderColorMap,
  borderBlockStartWidth: borderWidthMap,
  borderBlockWidth: borderWidthMap,
  borderBottomColor: borderColorMap,
  borderBottomLeftRadius: borderRadiusMap,
  borderBottomRightRadius: borderRadiusMap,
  borderBottomWidth: borderWidthMap,
  borderColor: borderColorMap,
  borderEndEndRadius: borderRadiusMap,
  borderEndStartRadius: borderRadiusMap,
  borderInlineColor: borderColorMap,
  borderInlineEndColor: borderColorMap,
  borderInlineEndWidth: borderWidthMap,
  borderInlineStartColor: borderColorMap,
  borderInlineStartWidth: borderWidthMap,
  borderInlineWidth: borderWidthMap,
  borderLeftColor: borderColorMap,
  borderLeftWidth: borderWidthMap,
  borderRadius: borderRadiusMap,
  borderRightColor: borderColorMap,
  borderRightWidth: borderWidthMap,
  borderStartEndRadius: borderRadiusMap,
  borderStartStartRadius: borderRadiusMap,
  borderTopColor: borderColorMap,
  borderTopLeftRadius: borderRadiusMap,
  borderTopRightRadius: borderRadiusMap,
  borderTopWidth: borderWidthMap,
  borderWidth: borderWidthMap,
  bottom: allSpaceMap,
  boxShadow: shadowMap,
  color: textColorMap,
  columnGap: positiveSpaceMap,
  gap: positiveSpaceMap,
  height: dimensionMap,
  inlineSize: dimensionMap,
  inset: allSpaceMap,
  insetBlock: allSpaceMap,
  insetBlockEnd: allSpaceMap,
  insetBlockStart: allSpaceMap,
  insetInline: allSpaceMap,
  insetInlineEnd: allSpaceMap,
  insetInlineStart: allSpaceMap,
  left: allSpaceMap,
  margin: allSpaceMap,
  marginBlock: allSpaceMap,
  marginBlockEnd: allSpaceMap,
  marginBlockStart: allSpaceMap,
  marginBottom: allSpaceMap,
  marginInline: allSpaceMap,
  marginInlineEnd: allSpaceMap,
  marginInlineStart: allSpaceMap,
  marginLeft: allSpaceMap,
  marginRight: allSpaceMap,
  marginTop: allSpaceMap,
  maxBlockSize: dimensionMap,
  maxHeight: dimensionMap,
  maxInlineSize: dimensionMap,
  maxWidth: dimensionMap,
  minBlockSize: dimensionMap,
  minHeight: dimensionMap,
  minInlineSize: dimensionMap,
  minWidth: dimensionMap,
  opacity: opacityMap,
  outlineColor: borderColorMap,
  outlineOffset: positiveSpaceMap,
  outlineWidth: borderWidthMap,
  padding: positiveSpaceMap,
  paddingBlock: positiveSpaceMap,
  paddingBlockEnd: positiveSpaceMap,
  paddingBlockStart: positiveSpaceMap,
  paddingBottom: positiveSpaceMap,
  paddingInline: positiveSpaceMap,
  paddingInlineEnd: positiveSpaceMap,
  paddingInlineStart: positiveSpaceMap,
  paddingLeft: positiveSpaceMap,
  paddingRight: positiveSpaceMap,
  paddingTop: positiveSpaceMap,
  right: allSpaceMap,
  rowGap: positiveSpaceMap,
  top: allSpaceMap,
  width: dimensionMap,
  zIndex: layerMap
};
var uniqueSymbol = Symbol('UNSAFE_INTERNAL_styles');
var isSafeEnvToThrow = function isSafeEnvToThrow() {
  return (typeof process === "undefined" ? "undefined" : _typeof(process)) === 'object' && _typeof(process.env) === 'object' && process.env.NODE_ENV !== 'production';
};
var reNestedSelectors = /(\.|\s|&+|\*\>|#|\[.*\])/;
var safeSelectors = /^@media .*$|^::?.*$|^@supports .*$/;
var transformStyles = function transformStyles(styleObj) {
  if (!styleObj || _typeof(styleObj) !== 'object') {
    return styleObj;
  }

  // If styles are defined as a CSSObject[], recursively call on each element until we reach CSSObject
  if (Array.isArray(styleObj)) {
    return styleObj.map(transformStyles);
  }

  // Modifies styleObj in place. Be careful.
  Object.entries(styleObj).forEach(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      key = _ref2[0],
      value = _ref2[1];
    // If key is a pseudo class or a pseudo element, then value should be an object.
    // So, call transformStyles on the value
    if (_typeof(value) === 'object' && safeSelectors.test(key)) {
      styleObj[key] = transformStyles(value);
      return;
    }
    if (isSafeEnvToThrow()) {
      // We don't support `.class`, `[data-testid]`, `> *`, `#some-id`
      if (reNestedSelectors.test(key)) {
        throw new Error("Styles not supported for key '".concat(key, "'."));
      }
    }

    // We have now dealt with all the special cases, so,
    // check whether what remains is a style property
    // that can be transformed.
    if (!(key in tokensMap)) {
      return;
    }
    var tokenValue = tokensMap[key][value];
    styleObj[key] = tokenValue !== null && tokenValue !== void 0 ? tokenValue : value;
  });
  return styleObj;
};
var baseXcss = function baseXcss(style) {
  var transformedStyles = transformStyles(style);
  return _defineProperty({}, uniqueSymbol, cssEmotion(transformedStyles));
};

/**
 * Picks out runtime XCSS objects and build-time XCSS strings. This is needed
 * to supported both Emotion and Compiled styles until we've fully migrated
 * to Compiled.
 *
 * @private
 * @deprecated
 */
export var parseXcss = function parseXcss(args) {
  if (Array.isArray(args)) {
    var emotion = [];
    var staticArr = [];
    var _iterator = _createForOfIteratorHelper(args),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var arg = _step.value;
        var result = parseXcss(arg);
        if (result.emotion) {
          emotion.push.apply(emotion, _toConsumableArray(result.emotion));
        }
        if (result.static) {
          staticArr.push(result.static);
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    return {
      emotion: emotion,
      static: staticArr.join(' ')
    };
  }
  var objArgs = args;
  var _ref4 = objArgs || {},
    styles = _ref4[uniqueSymbol];
  if (styles) {
    return {
      emotion: [styles]
    };
  }
  if (args) {
    // We use string interpolation here instead of .toString() just
    // in case the resulting object doesn't have the method available.
    var stringifiedArgs = "".concat(args);
    if (stringifiedArgs) {
      return {
        static: stringifiedArgs
      };
    }
  }
  return {};
};

// Media queries should not contain nested media queries

// Allow only a specific subset of chained selectors to maintain workable TypeScript performance

// Pseudos should not contain nested pseudos, or media queries

/**
 * ### xcss
 *
 * `xcss` is a safer, tokens-first approach to CSS-in-JS. It allows token-backed values for
 * CSS application.
 *
 * ```tsx
 * const styles = xcss({
 *   padding: 'space.100'
 * })
 * ```
 */
export function xcss(style) {
  return baseXcss(style);
}