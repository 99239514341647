import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { unstable_IdlePriority as idlePriority, unstable_scheduleCallback as scheduleCallback } from 'scheduler';
import { startLighthouseObserver } from '@atlassian/ufo-additional-payload';
import { setUFOConfig } from '@atlassian/ufo-config';
import { initTTVC } from '@atlassian/ufo-dropbox-ttvc';
import { setupHiddenTimingCapture } from '@atlassian/ufo-hidden-timing';
import { sinkInteractionHandler } from '@atlassian/ufo-interaction-metrics';
import { getVCObserver } from '@atlassian/ufo-vc';
var initialized = false;
function sinkInteraction(instance, payloadPackage) {
  sinkInteractionHandler(function (interactionId, interaction) {
    scheduleCallback(idlePriority, function () {
      var payloads = payloadPackage.createPayloads(interactionId, interaction);
      payloads === null || payloads === void 0 || payloads.forEach(function (payload) {
        instance.sendOperationalEvent(payload);
      });
    });
  });
}
export var init = function init(analyticsWebClientAsync, config) {
  var _config$vc;
  if (initialized) {
    return;
  }
  setUFOConfig(config);
  if (config.enableTTVC) {
    initTTVC();
  }
  if ((_config$vc = config.vc) !== null && _config$vc !== void 0 && _config$vc.enabled) {
    getVCObserver({
      heatmapSize: config.vc.heatmapSize,
      oldDomUpdates: config.vc.oldDomUpdates,
      devToolsEnabled: config.vc.devToolsEnabled,
      selectorConfig: config.vc.selectorConfig
    }).start({
      startTime: 0
    });
  }
  setupHiddenTimingCapture();
  startLighthouseObserver();
  initialized = true;
  Promise.all([analyticsWebClientAsync,
  // eslint-disable-next-line import/dynamic-import-chunkname
  import( /* webpackChunkName: "create-payloads" */'@atlassian/ufo-create-payload')]).then(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      awc = _ref2[0],
      payloadPackage = _ref2[1];
    if (awc.getAnalyticsWebClientPromise) {
      awc.getAnalyticsWebClientPromise().then(function (client) {
        var instance = client.getInstance();
        sinkInteraction(instance, payloadPackage);
      });
    } else if (awc.sendOperationalEvent) {
      sinkInteraction(awc, payloadPackage);
    }
  });
};