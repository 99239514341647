import React from 'react';

import { InjectedIntl, injectIntl } from 'react-intl';

import Avatar from '@atlaskit/avatar';

import { Icon } from 'src/components/sidebar/src/expander/styles';
import { Workspace } from 'src/components/types';

import messages from './i18n';

type WorkspaceIconProps = {
  workspace?: Workspace;
  intl: InjectedIntl;
};

const WorkspaceIcon: React.FC<WorkspaceIconProps> = ({ workspace, intl }) => {
  const src = workspace ? workspace.links.avatar.href : undefined;

  const altText = workspace
    ? intl.formatMessage(messages.workspaceBreadcrumbsAvatarAltText, {
        workspaceName: workspace.name,
      })
    : undefined;

  return (
    <Icon>
      <Avatar size="xsmall" appearance="square" src={src} name={altText} />
    </Icon>
  );
};

export default injectIntl(WorkspaceIcon);
