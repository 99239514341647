import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { N200 } from '@atlaskit/theme/colors';
import { gridSize as gridSizeFn } from '@atlaskit/theme/constants';
var gridSize = gridSizeFn();
var fontSize = 11;
var baseStyles = {
  headingBase: {
    alignItems: 'center',
    display: 'flex',
    flexShrink: 0,
    fontSize: "".concat(fontSize, "px"),
    fontWeight: 600,
    lineHeight: gridSize * 2 / fontSize,
    margin: "".concat(gridSize * 4, "px 0 ").concat(gridSize, "px"),
    padding: "0 ".concat(gridSize * 1.5, "px"),
    textTransform: 'uppercase'
  },
  textWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%'
  },
  afterWrapper: {
    lineHeight: 1,
    marginLeft: "".concat(gridSize / 2, "px")
  }
};
export default (function (_ref) {
  var product = _ref.product;
  return function () {
    return {
      container: _objectSpread(_objectSpread({}, baseStyles), {}, {
        headingBase: _objectSpread(_objectSpread({}, baseStyles.headingBase), {}, {
          color: N200
        })
      }),
      product: _objectSpread(_objectSpread({}, baseStyles), {}, {
        headingBase: _objectSpread(_objectSpread({}, baseStyles.headingBase), {}, {
          color: product.text.subtle
        })
      })
    };
  };
});