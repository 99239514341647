import React, { useCallback, useMemo, memo } from 'react';

import { useSelector } from 'react-redux';

import Flag, { ActionsType } from '@atlaskit/flag';
import InfoIcon from '@atlaskit/icon/glyph/info';

import { useIntl } from 'src/hooks/intl';
import { ComponentFlagProps } from 'src/redux/flags/types';
import { getSiteMessageFlag } from 'src/selectors/global-selectors';
import { getCurrentUserUuid } from 'src/selectors/user-selectors';
import { SiteMessage } from 'src/types/site-message';

import messages from './site-message-flag.i18n';
import { messageDismissed } from './site-message-store';

export const SiteMessageFlag: React.FC<ComponentFlagProps> = memo(
  ({ id, handleDismissed, ...flagProps }: ComponentFlagProps) => {
    const currentUserUuid = useSelector(getCurrentUserUuid);
    const siteMessage: SiteMessage | undefined =
      useSelector(getSiteMessageFlag);
    const { formatMessage } = useIntl();

    const markSiteMessageSeen = useCallback(
      (messageId: number) => {
        if (currentUserUuid) {
          messageDismissed(currentUserUuid, messageId);
        }
      },
      [currentUserUuid]
    );

    const actions: ActionsType = useMemo(() => {
      if (!siteMessage) {
        return [];
      }
      const { id: siteMessageId, url } = siteMessage;
      const dismiss = {
        content: formatMessage(messages.dismissButton),
        onClick: () => {
          markSiteMessageSeen(siteMessageId);
          handleDismissed(id);
        },
      };
      const learnMore = {
        content: formatMessage(messages.learnMoreButton),
        href: url,
        target: '_new',
        onClick: () => {
          markSiteMessageSeen(siteMessageId);
        },
      };
      return url ? [dismiss, learnMore] : [dismiss];
    }, [formatMessage, id, markSiteMessageSeen, handleDismissed, siteMessage]);

    if (!siteMessage) {
      return null;
    }

    return (
      <Flag
        {...flagProps}
        actions={actions}
        appearance="info"
        description={siteMessage.text}
        icon={<InfoIcon label="" secondaryColor="inherit" />}
        id={id}
        title={siteMessage.title}
      />
    );
  }
);
