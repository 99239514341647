import React from 'react';

import { useSelector } from 'react-redux';

import UFOCustomData from '@atlassian/ufo-custom-data';

import { uncurlyUuid } from 'src/components/analytics';
import { getCurrentRepository } from 'src/selectors/repository-selectors';

export const RepositoryCustomData: React.FC = () => {
  const repository = useSelector(getCurrentRepository);

  const data: { repositoryId?: string } = {};
  if (repository?.uuid) {
    data.repositoryId = uncurlyUuid(repository.uuid);
  }

  return <UFOCustomData data={data} />;
};
