import React, { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import EmptyState from '@atlaskit/empty-state';
import { Box, xcss } from '@atlaskit/primitives';

import { useIntl } from 'src/hooks/intl';

import { contextMessages } from '../messages.i18n';
import { CustomMergeChecksResourceType } from '../types';
import { urls } from '../urls';

import { messages } from './merge-checks-empty-state.i18n';
import emptyStateImage from './merge-checks-empty-state.svg';

const nextStepsMessageXcss = xcss({
  marginTop: 'space.200',
});

type MergeChecksEmptyStateProps = {
  resourceType: CustomMergeChecksResourceType;
  isEnabled: boolean;
};

export const MergeChecksEmptyState: FC<MergeChecksEmptyStateProps> = ({
  resourceType,
  isEnabled,
}) => {
  const { formatMessage } = useIntl();

  const renderDescription = () => (
    <>
      <FormattedMessage
        {...contextMessages[resourceType].description}
        values={{
          learnMoreLink: (
            <a href={urls.learnMore} target="_blank">
              {formatMessage(contextMessages[resourceType].learnMoreLinkLabel)}
            </a>
          ),
        }}
      />
      <Box xcss={nextStepsMessageXcss}>
        {isEnabled ? (
          <div data-testid="installExampleAppsMessage">
            <FormattedMessage
              {...messages.installExampleMergeCheckApps}
              values={{
                exampleAppsLink: (
                  <a href={urls.exampleApps} target="_blank">
                    {formatMessage(messages.exampleAppsLinkText)}
                  </a>
                ),
              }}
            />
          </div>
        ) : (
          <div data-testid="checksNotEnabledMessage">
            {formatMessage(messages.checksNotEnabled)}
          </div>
        )}
      </Box>
    </>
  );

  return (
    <EmptyState
      header=""
      description={renderDescription()}
      imageUrl={emptyStateImage}
      testId="mergeChecksEmptyState"
    />
  );
};
