import React, { ReactNode } from 'react';

import { injectIntl, InjectedIntl } from 'react-intl';

import Button from '@atlaskit/button/standard-button';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close';
import ModalDialog, {
  ModalBody,
  ModalHeader,
} from '@atlaskit/modal-dialog-next';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import i18n from '../i18n';
import { DialogContainer, DialogTitle } from '../styles';

interface DialogHeaderProps {
  intl: InjectedIntl;
  onClose: () => void;
}

const DialogHeader = injectIntl(
  React.memo((props: DialogHeaderProps) => {
    const { onClose, intl } = props;

    return (
      <ModalHeader>
        <DialogTitle>
          {intl.formatMessage(i18n.buildSummaryDialogHeading)}
          <Button
            onClick={onClose}
            appearance="subtle"
            spacing="none"
            iconBefore={
              <EditorCloseIcon
                label={intl.formatMessage(i18n.buildDialogCloseButton)}
                primaryColor={token('color.icon', colors.N800)}
              />
            }
          />
        </DialogTitle>
      </ModalHeader>
    );
  })
);

type BuildStatusModalDialogProps = {
  children: ReactNode;
  onClose: () => void;
};

export const BuildStatusModalDialog = React.memo(
  (props: BuildStatusModalDialogProps) => (
    <ModalDialog width="medium" onClose={props.onClose}>
      <DialogContainer>
        <DialogHeader onClose={props.onClose} />
        <ModalBody>{props.children}</ModalBody>
      </DialogContainer>
    </ModalDialog>
  )
);
