{
	"name": "@atlassian/post-office-frontend",
	"version": "0.4.6",
	"packageManager": "yarn@4.2.2",
	"sideEffects": false,
	"source": "./src/index.ts",
	"main": "./dist/cjs/index.js",
	"module": "./dist/esm/index.js",
	"types": "./dist/types/index.d.ts",
	"repository": {
		"type": "git",
		"url": "git+https://stash.atlassian.com/projects/ATLASSIAN/repos/atlassian-frontend-monorepo/browse/post-office.git",
		"directory": "packages/post-office-clients/post-office-frontend"
	},
	"files": [
		"README.md",
		"dist",
		"src",
		"tsconfig.json",
		"tsconfig.build.json"
	],
	"scripts": {
		"prepack": "atlas packages permission grant && yarn && yarn build:published && yarn publish:remove-exports @atlassian/post-office-frontend",
		"publish:patch": "yarn version patch && yarn npm publish",
		"publish:minor": "yarn version minor && yarn npm publish"
	},
	"peerDependencies": {
		"@atlaskit/feature-gate-js-client": "workspace:^",
		"@atlassian/post-office-context": "^0.0.7",
		"@atlassiansox/cross-flow-support": "^3.12.1",
		"@emotion/react": "^11.11.4",
		"react": ">16.0.0",
		"react-dom": ">16.0.0"
	},
	"dependencies": {
		"@atlaskit/analytics-namespaced-context": "workspace:^",
		"@atlassian/post-office-frontend-performance-tracking": "workspace:^",
		"@atlassian/remote-module-loader": "^3.3.0",
		"memoize-one": "^6.0.0"
	},
	"devDependencies": {
		"@atlassian/post-office-context": "workspace:^",
		"@atlassiansox/cross-flow-support": "^3.12.1",
		"@emotion/react": "^11.11.4",
		"@testing-library/jest-dom": "^6.4.2",
		"@testing-library/react": "14.2.1",
		"@testing-library/react-hooks": "^8.0.1",
		"@types/node": "^20.11.27",
		"@types/react": "^18.2.33",
		"@types/react-dom": "^18.2.14",
		"eslint": "^8.57.0",
		"jest": "^29.7.0",
		"jest-environment-jsdom": "^29.7.0",
		"npm-run-all2": "^5.0.0",
		"parcel": "^2.6.0",
		"react": "^18.2.0",
		"react-dom": "^18.2.0",
		"typescript": "~5.4.2"
	},
	"targets": {
		"main": {
			"includeNodeModules": [
				"@atlassian/post-office-context"
			]
		},
		"module": {
			"includeNodeModules": [
				"@atlassian/post-office-context"
			]
		}
	}
}
