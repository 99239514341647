function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
export var getCLS = function getCLS(start, stop, buffer) {
  var layoutShifts = buffer.getAll().filter(function (entry) {
    return entry.startTime >= start && entry.startTime <= stop;
  });
  var sessionWindows = [];
  var currentWindow = null;

  // Group layout shifts into session windows
  var _iterator = _createForOfIteratorHelper(layoutShifts),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var shift = _step.value;
      var startTime = shift.startTime;
      var endTime = shift.startTime + shift.duration;
      var score = shift.value;
      if (currentWindow === null || startTime - currentWindow.endTime > 1000 || endTime - currentWindow.startTime > 5000) {
        // Start a new session window
        currentWindow = {
          startTime: startTime,
          endTime: endTime,
          score: score
        };
        sessionWindows.push(currentWindow);
      } else {
        // Add layout shift to current session window
        currentWindow.endTime = endTime;
        currentWindow.score += score;
      }
    }

    // Find session window with highest cumulative score
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  var maxScore = sessionWindows.reduce(function (max, sessionWindow) {
    return sessionWindow.score > max ? sessionWindow.score : max;
  }, 0);

  // Return score of largest burst as CLS metric
  return Math.round(maxScore * 10000) / 10000;
};