import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
var _templateObject, _templateObject2;
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import Button from '@atlaskit/button/custom-theme-button';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import { N0, N50, N50A, N60A } from '@atlaskit/theme/colors';
import { borderRadius } from '@atlaskit/theme/constants';
import { surveyInnerWidth } from '../constants';
export default (function (_ref) {
  var children = _ref.children,
    onDismiss = _ref.onDismiss;
  return jsx("div", {
    css: css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n        background-color: ", ";\n        border-radius: ", "px;\n        padding: ", ";\n        box-shadow: ", ";\n        width: ", "px;\n      "])), "var(--ds-surface-overlay, ".concat(N0, ")"), borderRadius(), "var(--ds-space-300, 24px)", "var(--ds-shadow-overlay, ".concat("0 20px 32px -8px ".concat(N50A, ", 0 0 1px ").concat(N60A), ")"), surveyInnerWidth)
  }, jsx("div", {
    css: css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n          position: absolute;\n          top: ", ";\n          right: ", ";\n        "])), "var(--ds-space-200, 16px)", "var(--ds-space-200, 16px)")
  }, jsx(Button, {
    iconBefore: jsx(CrossIcon, {
      label: "",
      primaryColor: "var(--ds-icon-subtle, ".concat(N50, ")")
    }),
    "aria-label": "Dismiss",
    appearance: "subtle",
    onClick: onDismiss
  })), children);
});