/**
 * THIS FILE WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * The color map is used to map a background color token to a matching text color that will meet contrast.
 *
 * @codegen <<SignedSource::6d974d81ce795e3ce5be6c5035a69fb4>>
 * @codegenCommand yarn codegen-styles
 * @codegenDependency ../../../tokens/src/artifacts/tokens-raw/atlassian-light.tsx <<SignedSource::eface03157c2df5c1b3b305911d3ed4d>>
 */
export default {
  'neutral.bold': 'inverse',
  'neutral.bold.hovered': 'inverse',
  'neutral.bold.pressed': 'inverse',
  'selected.bold': 'inverse',
  'selected.bold.hovered': 'inverse',
  'selected.bold.pressed': 'inverse',
  'brand.bold': 'inverse',
  'brand.bold.hovered': 'inverse',
  'brand.bold.pressed': 'inverse',
  'brand.boldest': 'inverse',
  'brand.boldest.hovered': 'inverse',
  'brand.boldest.pressed': 'inverse',
  'danger.bold': 'inverse',
  'danger.bold.hovered': 'inverse',
  'danger.bold.pressed': 'inverse',
  'warning.bold': 'warning.inverse',
  'warning.bold.hovered': 'warning.inverse',
  'warning.bold.pressed': 'warning.inverse',
  'success.bold': 'inverse',
  'success.bold.hovered': 'inverse',
  'success.bold.pressed': 'inverse',
  'discovery.bold': 'inverse',
  'discovery.bold.hovered': 'inverse',
  'discovery.bold.pressed': 'inverse',
  'information.bold': 'inverse',
  'information.bold.hovered': 'inverse',
  'information.bold.pressed': 'inverse'
};