import { PostOfficeAnalyticsContext } from '@atlaskit/analytics-namespaced-context';
import FeatureGates from '@atlaskit/feature-gate-js-client';
import { type Context } from '@atlassian/post-office-context';
import { UFOv1, UFOv2 } from '@atlassian/post-office-frontend-performance-tracking';
import { RemoteComponent } from '@atlassian/remote-module-loader/remote-component';
import {
	type FunctionComponent,
	type PropsWithChildren,
	type ReactElement,
	type ReactNode,
	useCallback,
	useMemo,
} from 'react';

import { ANALYTICS_CHANNEL, ANALYTICS_SOURCE } from './constants';
import { requestInitialData } from './initialDataClient';
import { type PluginEnv, type PluginVariant } from './plugin';
import PluginRegistry from './pluginRegistry';
import { useStableObject } from './useStableObject';
import { version as postOfficeFrontendVersion } from '../package.json';

const PLACEMENT_PATH = `/api/v1/placements/`;

interface BaseProps {
	placementId: string;
	placementProps?: Record<string, unknown>;
	ErrorFallback?: ReactElement | null;
	Fallback?: ReactElement | null;
	context: Context;
	baseUrl?: string;
}

interface EnvironmentInitializerProps {
	env: PluginEnv;
	variant: PluginVariant;
}

interface OverridesProps {
	overrides?: {
		moduleUri: string;
	};
}

type RemotePlacementOnEvent = (event: {
	type:
		| 'fetch-complete'
		| 'fetch-error'
		| 'render-complete'
		| 'render-error'
		| 'initial-data-complete'
		| 'initial-data-error'
		| 'component-ready'
		| 'component-error'
		| 'unmount';
	error?: unknown;
}) => void;

type Props = PropsWithChildren<BaseProps> & EnvironmentInitializerProps & OverridesProps;
const Placement: FunctionComponent<Props> = ({
	env,
	variant,
	placementId,
	placementProps,
	children,
	ErrorFallback,
	Fallback,
	context,
	baseUrl,
	overrides,
}) => {
	const isUFOEnabled = FeatureGates.checkGate('enable_ufo_tracking_in_post_office');

	const pluginRegistry = PluginRegistry.getRegistry();
	const plugin = pluginRegistry.getPluginForPlacement(placementId, env, variant, overrides);

	const stablePlacementProps = useStableObject(placementProps ?? {});
	const stableContext = useStableObject(context);

	const ufoV1PlacementTracker = useMemo(
		() => (isUFOEnabled ? new UFOv1.PlacementPerformanceTracker(placementId) : undefined),
		[isUFOEnabled, placementId],
	);

	// Callback function to listen to the different stages of the remote component and track its performance
	const remoteComponentOnEvent = useCallback<RemotePlacementOnEvent>(
		({ type, error }) => {
			switch (type) {
				case 'fetch-complete':
					void ufoV1PlacementTracker?.markFetchComplete();
					break;
				case 'fetch-error':
					void ufoV1PlacementTracker?.markFetchFailure(error as Error);
					break;
				case 'render-complete':
					void ufoV1PlacementTracker?.markRenderComplete();
					break;
				case 'render-error':
					void ufoV1PlacementTracker?.markRenderFailure(error as Error);
					break;
				case 'initial-data-complete':
					void ufoV1PlacementTracker?.markInitialDataComplete();
					break;
				case 'initial-data-error':
					void ufoV1PlacementTracker?.markInitialDataFailure(error as Error);
					break;
				case 'component-error':
					void ufoV1PlacementTracker?.markComponentFailure(error as Error);
					break;
				case 'component-ready':
					void ufoV1PlacementTracker?.markComponentReady();
					break;
				case 'unmount':
					void ufoV1PlacementTracker?.markComponentAborted();
					break;
			}
		},
		[ufoV1PlacementTracker],
	);

	const initialData = useMemo(
		() =>
			requestInitialData(placementId, stableContext, global.fetch, {
				baseUrl,
				path: baseUrl ? PLACEMENT_PATH : undefined,
			}),
		[baseUrl, placementId, stableContext],
	);

	const placementAnalyticsContext = useMemo(
		() => ({
			source: ANALYTICS_SOURCE,
			componentName: `Placement`,
			attributes: {
				placementId: placementId,
				channel: 'in-app',
				postOfficeFrontendVersion,
			},
		}),
		[placementId],
	);

	return (
		<FeatureFlaggedUfoWrapper placementId={placementId} isEnabled={isUFOEnabled}>
			<PostOfficeAnalyticsContext data={placementAnalyticsContext}>
				<RemoteComponent
					analyticsChannel={ANALYTICS_CHANNEL}
					remotePlugin={plugin}
					onEvent={remoteComponentOnEvent}
					ErrorFallback={ErrorFallback}
					Fallback={Fallback}
					initialData={initialData}
					context={stableContext}
					baseUrl={baseUrl}
					{...stablePlacementProps}
				>
					{children}
				</RemoteComponent>
			</PostOfficeAnalyticsContext>
		</FeatureFlaggedUfoWrapper>
	);
};

export default Placement;

interface FeatureFlaggedUfoWrapperProps {
	isEnabled: boolean;
	placementId: string; // Adjust the type according to your actual context structure
	children: ReactNode;
}

const FeatureFlaggedUfoWrapper: FunctionComponent<FeatureFlaggedUfoWrapperProps> = ({
	isEnabled,
	placementId,
	children,
}) => {
	if (isEnabled) {
		return (
			<UFOv2.UFOSegment name={UFOv2.SegmentNames.PLACEMENT}>
				<UFOv2.UFOSegment name={`${UFOv2.SegmentNames.PLACEMENT}.${placementId}`}>
					{children}
				</UFOv2.UFOSegment>
			</UFOv2.UFOSegment>
		);
	}
	return <>{children}</>;
};
