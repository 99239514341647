import { matchRoute } from 'react-resource-router';

import { init as initReactUFO } from '@atlassian/ufo-interaction-metrics-init';
import { configure as configureUFOResourceTiming } from '@atlassian/ufo-resource-timing';
import getUFORouteName from '@atlassian/ufo-route-name';
import traceUFOPageLoad from '@atlassian/ufo-trace-pageload';

import { AppData } from 'src/app/data';
import { getRouteFeaturesFromAppData, getRoutes } from 'src/router';
import { analyticsClient } from 'src/utils/analytics/client';

import { devConfig, prodConfig } from './config';

// not explicitly required, but just defensive programming to ensure we only
// initialise React UFO once
let isInitialised = false;

export function initUFO(appData: AppData): void {
  if (!appData.features['enable-react-ufo'] || isInitialised) {
    return;
  }

  const isProd = appData.frontbucket_environment === 'production';
  const config = isProd ? prodConfig : devConfig;

  configureUFOResourceTiming({
    mapResources: url => url,

    // Fetch requests URLs currently contain UGC, e.g. workspace/repository/branch names
    // TODO: provide a `sanitiseEndpoints` function that strips-out /
    // anonymizes UGC out of fetch requests url
    sanitiseEndpoints: url => url,
  });

  initReactUFO(Promise.resolve(analyticsClient()), config);

  const routes = getRoutes(getRouteFeaturesFromAppData(appData));
  const matchedRoute = matchRoute(
    routes,
    window.location.pathname,
    // NOTE: we don't need any matching for query params, weird that the
    // library won't make this an optional argument (instead have to set it
    // to undefined manually)
    undefined
  );

  // NOTE: call `traceUFOPageLoad` as early as possible, but _after_ calling
  // `initReactUFO`
  if (matchedRoute?.route) {
    const ufoName = getUFORouteName(matchedRoute?.route).toLowerCase();
    traceUFOPageLoad(ufoName, matchedRoute.route.name);
  }

  isInitialised = true;
}
