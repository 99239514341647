import React, { useEffect } from 'react';

import ModalDialog, { ActionProps } from 'src/components/modal-dialog-actions';
import { useIntl } from 'src/hooks/intl';

import messages from './confirmation-modal.i18n';
import {
  ActionButton,
  ConfirgurableProps,
  SharedProps,
  ModalText,
  PredifinedActionButton,
} from './confirmation-modal.types';

export type ConfirmationModalProps = ConfirgurableProps & SharedProps;

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  onShow,
  onCancel,
  onConfirm,
  isLoading = false,
  isDisabled = false,
  header,
  body,
  appearance,
  customButtonText,
  buttonText = {},
}) => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (typeof onShow === 'function') {
      onShow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getModalText = (text: ModalText) =>
    typeof text === 'string' ? text : formatMessage(text);

  const getButtonText = (button: ActionButton) => {
    let text: any =
      button === ActionButton.confirm ? messages.confirm : messages.close;

    if (buttonText && button in buttonText) {
      text = messages[buttonText[button] as PredifinedActionButton];
    } else if (customButtonText) {
      text = customButtonText[button];
    }

    return getModalText(text);
  };

  const actions: ActionProps[] = [
    {
      text: getButtonText(ActionButton.cancel),
      onClick: onCancel,
      appearance: 'subtle',
    },
    {
      text: getButtonText(ActionButton.confirm),
      onClick: onConfirm,
      appearance: appearance || 'primary',
      autoFocus: true,
      isLoading,
      isDisabled,
    },
  ];

  return (
    <ModalDialog
      actions={actions}
      heading={getModalText(header)}
      width="small"
      onClose={onCancel}
      appearance={appearance}
    >
      {body}
    </ModalDialog>
  );
};

export default ConfirmationModal;
