import { get } from 'lodash-es';
import { denormalize } from 'normalizr';
import { pathToRegexp } from 'path-to-regexp';
import createCachedSelector from 're-reselect';
import { Selector, createSelector } from 'reselect';

import { MenuItem } from 'src/components/navigation';
import {
  FORGE_MENU_ITEM_TYPE,
  findNestedMenuItem,
} from 'src/components/navigation/src/utils/create-nested-menu';
import { Repository, User, Team, Workspace } from 'src/components/types';
import { BranchingModelState } from 'src/redux/branches/reducers/branch-list-reducer';
import { PipelinesEnabledLoadingState } from 'src/sections/repository/reducers/details';
import { RepositorySizeLimits } from 'src/sections/repository/reducers/section';
import { repository as repoSchema } from 'src/sections/repository/schemas';
import { Mirror, Ref } from 'src/sections/repository/types';
import { OverrideSettings } from 'src/types/override-settings';
import { BucketState } from 'src/types/state';
import { REPO_FULL_SLUG_REGEX } from 'src/utils/validate-repository-path';

import {
  getEntities,
  getRecentlyViewedRepositoriesKeys as getRecentlyViewedRepositoryKeys,
  getRepository,
} from './state-slicing-selectors';

const getCurrentRepositoryKey: Selector<
  BucketState,
  string | null | undefined
> = createSelector(
  getRepository,
  repository =>
    repository && repository.section && repository.section.currentRepository
);

export const getCurrentRepository: Selector<
  BucketState,
  Repository | undefined
> = createSelector(getCurrentRepositoryKey, getEntities, (key, entities) =>
  denormalize(key, repoSchema, entities)
);

export const getCurrentRepositorySshCloneLink = createSelector(
  getCurrentRepository,
  repo => {
    if (!repo) {
      return null;
    }
    return repo.links.clone[1].href;
  }
);

export const getRepositoryBranchingModel: Selector<
  BucketState,
  BranchingModelState
> = createSelector(
  getRepository,
  repository => repository.branches.branchList.branchingModel
);

export const getCurrentRepositoryMainBranchName: Selector<
  BucketState,
  string | undefined
> = createSelector(getCurrentRepository, currentRepository =>
  currentRepository && currentRepository.mainbranch
    ? currentRepository.mainbranch.name
    : undefined
);

export const getRepositoryMainBranch: Selector<
  BucketState,
  Ref | null | undefined
> = createSelector(getRepository, repository => repository.details.mainBranch);

export const getMenuItems: Selector<BucketState, MenuItem[]> = createSelector(
  getRepository,
  repository => repository.section.menuItems
);

export const getSizeLimits: Selector<BucketState, RepositorySizeLimits> =
  createSelector(getRepository, repository => repository.section.sizeLimits);

export const getRepositoryOverrideSettings: Selector<
  BucketState,
  OverrideSettings
> = createSelector(
  getRepository,
  repository => repository?.section?.overrideSettings || {}
);

export const getCurrentRepositoryFullSlug: Selector<
  BucketState,
  string | null | undefined
> = createSelector(getCurrentRepository, currentRepository =>
  currentRepository ? currentRepository.full_name : null
);

export const getCurrentRepositoryOwnerName: Selector<BucketState, string> =
  createSelector(getCurrentRepository, currentRepository =>
    currentRepository ? currentRepository.full_name.split('/')[0] : ''
  );

export const getCurrentRepositoryOwner: Selector<
  BucketState,
  User | Team | null | undefined
> = createSelector(getCurrentRepository, currentRepository =>
  currentRepository ? currentRepository.owner : null
);
export const getCurrentRepositoryProjectUuid: Selector<
  BucketState,
  string | null
> = createSelector(
  getCurrentRepository,
  currentRepository => currentRepository?.project?.uuid || null
);

export const getCurrentRepositoryProjectKey: Selector<
  BucketState,
  string | null
> = createSelector(
  getCurrentRepository,
  currentRepository => currentRepository?.project?.key || null
);

export const getCurrentRepositorySlug: Selector<
  BucketState,
  string | null | undefined
> = createSelector(getCurrentRepository, currentRepository =>
  currentRepository ? currentRepository.full_name.split('/')[1] : null
);

export const getCurrentRepositoryUuid: Selector<
  BucketState,
  string | null | undefined
> = createSelector(getCurrentRepository, currentRepository =>
  currentRepository ? currentRepository.uuid : null
);

export const getRecentlyViewedRepositories: Selector<
  BucketState,
  Repository[]
> = createSelector(
  getRecentlyViewedRepositoryKeys,
  getEntities,
  (keys, entities) =>
    // We might have some UUIDs in redux that map to a model that's pending fetching or that
    // no longer exists, so we filter those results out (that denormalize to `undefined`)
    // @ts-ignore TODO: fix noImplicitAny error here
    denormalize(keys, [repoSchema], entities).filter(entity => !!entity)
);

export const getRecentlyViewedRepositoryCount: Selector<BucketState, number> =
  createSelector(getRecentlyViewedRepositoryKeys, keys => keys.length);

export const getCurrentRepoWorkspaceSlug = createSelector(
  getCurrentRepository,
  repository => repository?.workspace?.slug
);

export const getRepositoryDetails = createSelector(
  getRepository,
  getEntities,
  (currentRepository, entities) => ({
    accessLevel: currentRepository.details.accessLevel,
    branchCount: currentRepository.details.branchCount,
    commitsBehindParent: currentRepository.details.commitsBehindParent,
    forkCount: currentRepository.details.forkCount,
    grantedAccess: currentRepository.details.grantedAccess,
    hasError: currentRepository.details.hasError,
    isAccountAdmin: currentRepository.details.isAccountAdmin,
    isLoading: currentRepository.details.isLoading,
    isReadOnly: currentRepository.details.isReadOnly,
    language: currentRepository.details.language,
    lastUpdated: currentRepository.details.lastUpdated,
    openPullRequestCount: currentRepository.details.openPullRequestCount,
    parent: denormalize(currentRepository.details.parent, repoSchema, entities),
    size: currentRepository.details.size,
    watcherCount: currentRepository.details.watcherCount,
    landingPage: currentRepository.details.landingPage,
    analyticsKey: currentRepository.details.analyticsKey,
    lfsHasLimits: currentRepository.details.lfsHasLimits,
    lfsUsage: currentRepository.details.lfsUsage,
    lfsRemaining: currentRepository.details.lfsRemaining,
    xferStatus: currentRepository.details.xferStatus,
    isPipelinesEnabledLoadingState:
      currentRepository.details.isPipelinesEnabledLoadingState,
    isPipelinesEnabled: currentRepository.details.isPipelinesEnabled,
    isPipelinesPremium: currentRepository.details.isPipelinesPremium,
    pushFileSizeLimitDisabled:
      currentRepository.details.pushFileSizeLimitDisabled,
  })
);

export const getIsPipelinesEnabledLoadingState = createSelector(
  getRepositoryDetails,
  repositoryDetails =>
    repositoryDetails
      ? repositoryDetails.isPipelinesEnabledLoadingState
      : // Default to "loaded" if we don't have a repo available, otherwise
        // the API request URL won't be able to be constructed.
        PipelinesEnabledLoadingState.Loaded
);

export const getIsPipelinesEnabled = createSelector(
  getRepositoryDetails,
  repositoryDetails =>
    repositoryDetails ? repositoryDetails.isPipelinesEnabled : false
);

export const getIsPipelinesPremium = createSelector(
  getRepositoryDetails,
  repositoryDetails =>
    repositoryDetails ? repositoryDetails.isPipelinesPremium : false
);

export const getRepositoryGrantedAccess = createSelector(
  getRepositoryDetails,
  repositoryDetails =>
    repositoryDetails ? repositoryDetails.grantedAccess : undefined
);

export const getRepositoryAccessLevel = createSelector(
  getRepositoryDetails,
  repositoryDetails =>
    repositoryDetails ? repositoryDetails.accessLevel : undefined
);

export const getIsRepositoryWorkspaceAdmin = createSelector(
  getRepositoryDetails,
  repositoryDetails => !!repositoryDetails?.isAccountAdmin
);

export const getRepositoryMirrors: Selector<BucketState, Mirror[] | null> =
  createSelector(getRepository, repository => repository.section.mirrors);

export const getRepositorySourceTreeHasXcode: Selector<BucketState, boolean> =
  createSelector(getRepository, repository =>
    get(repository, 'source.section.fileTree.tree.contents', []).some(
      // @ts-ignore TODO: fix noImplicitAny error here
      ({ type, name }) =>
        type === 'directory' &&
        (name.endsWith('.xcodeproj') || name.endsWith('.xcworkspace'))
    )
  );

export const isSyncDialogOpen: Selector<BucketState, boolean> = createSelector(
  getRepository,
  repository => repository.section.isSyncDialogOpen
);

const repoSettingsPathRegex = new RegExp(/^\/[^/]+\/[^/]+\/admin($|\/)/);

export const getIsRepoSettingsPath = createSelector(
  (pathname: string) => ({
    pathname,
  }),
  ({ pathname }) => repoSettingsPathRegex.test(pathname)
);

export const getSelectedMenuItem = createCachedSelector(
  getMenuItems,
  (_state: BucketState, pathname: string, matchUrl: string) => ({
    pathname,
    matchUrl,
  }),
  (menuItems, { pathname }) =>
    findNestedMenuItem(menuItems, item => {
      const prefixes = item.matching_url_prefixes || [];
      const itemUrl = item.url.replace(/\/$/, '');

      // compare the paths after the repoSubpath because repo URLs can be UUIDs or slugs
      const repoPathBase = pathToRegexp(`/${REPO_FULL_SLUG_REGEX}`, [], {
        end: false,
      });
      const normalizedPathname = pathname.replace(repoPathBase, '');
      const normalizedItemUrl = itemUrl.replace(repoPathBase, '');
      const pathNameWithoutTrailingSlash = pathname.replace(/\/$/, '');

      if (item.type === FORGE_MENU_ITEM_TYPE) {
        // Forge menu item urls are constructed by bitbucket backend
        // (see https://bitbucket.org/bitbucket/core/src/0fec99b2b692b306adfa1033bc224348f6aa5795/apps/forge/menus.py#lines-45),
        // and we expect the path name and item url to be an exact match.
        return pathNameWithoutTrailingSlash === itemUrl;
      } else if (repoSettingsPathRegex.test(pathname)) {
        // This conditional checks if the current path is under the admin (settings) section of the repo path structure.
        // The pathname and item url comparison is exact because some of the settings menu item urls are subpaths of the others.
        // It also checks the menu item "matching_url_prefixes" for any menu items which need to be
        // highlighted in the UI for more than one route. e.g. /admin/webhooks and /admin/webhooks/new
        return (
          pathNameWithoutTrailingSlash === itemUrl ||
          (pathNameWithoutTrailingSlash.startsWith(itemUrl) &&
            prefixes.some(prefix => normalizedPathname.startsWith(prefix)))
        );
      } else {
        return (
          normalizedPathname.indexOf(normalizedItemUrl) === 0 ||
          prefixes.some(prefix => normalizedPathname.startsWith(prefix))
        );
      }
    })
)((_state, pathname, matchUrl) => `${matchUrl}:${pathname}`);

export const getRepoSettingsData = createSelector(
  getCurrentRepository,
  getRepositoryDetails,
  (currentRepository, details) =>
    currentRepository
      ? {
          uuid: currentRepository.uuid,
          name: currentRepository.name,
          slug: currentRepository.slug,
          fullName: currentRepository.full_name,
          project: currentRepository.project,
          description: currentRepository.description,
          isPrivate: currentRepository.is_private,
          forkPolicy: currentRepository.fork_policy,
          language: currentRepository.language,
          mainbranch: currentRepository.mainbranch,
          website: currentRepository.website,
          landingPage: details.landingPage,
          analyticsKey: details.analyticsKey,
          lfsHasLimits: details.lfsHasLimits,
          lfsUsage: details.lfsUsage,
          lfsRemaining: details.lfsRemaining,
          size: details.size,
          pushFileSizeLimitDisabled: details.pushFileSizeLimitDisabled,
        }
      : null
);

export const getRepositoryAvatar = createSelector(
  getCurrentRepository,
  currentRepository => currentRepository?.links.avatar.href
);

export const getXferStatus = createSelector(
  getRepositoryDetails,
  ({ xferStatus }) => xferStatus
);

export const getCurrentRepositoryForkCount = createSelector(
  getRepositoryDetails,
  ({ forkCount }) => forkCount
);

export const getCurrentRepositoryName = createSelector(
  getCurrentRepository,
  repo => repo?.name
);

export const getCurrentRepositoryWorkspace: Selector<
  BucketState,
  Workspace | null
> = createSelector(
  getCurrentRepository,
  currentRepository => currentRepository?.workspace || null
);

export const getCurrentRepositoryWorkspaceUUID: Selector<
  BucketState,
  string | null
> = createSelector(
  getCurrentRepositoryWorkspace,
  currentWorkspace => currentWorkspace?.uuid || null
);

export const getIsCurrentRepositoryPrivate = createSelector(
  getCurrentRepository,
  currentRepository => currentRepository?.is_private
);

export const getCurrentRepositoryLinkData = createSelector(
  getCurrentRepository,
  currentRepository =>
    currentRepository
      ? {
          name: currentRepository.name,
          links: currentRepository.links,
          landingPage: currentRepository.landing_page,
          fullName: currentRepository.full_name,
        }
      : {}
);
