import urls from 'src/urls/workspaces';
import { createHttpRequest } from 'src/utils/http-request';

import { MergeChecksActivationData } from './types';

export type MergeChecksActivationApi = {
  getActivation: () => Promise<MergeChecksActivationData>;
  setActivation: (activated: boolean) => Promise<void>;
};

export const createMergeChecksActivationApi = (
  workspaceSlug: string
): MergeChecksActivationApi => ({
  getActivation: () =>
    createHttpRequest<MergeChecksActivationData>('GET', {
      throwErrorType: 'errorObject',
    })(urls.api.internal.mergeChecksActivation(workspaceSlug)),
  setActivation: activated =>
    createHttpRequest<void>(
      'PUT',
      { successCustomHandler: () => null } // handle 204 response
    )(urls.api.internal.mergeChecksActivation(workspaceSlug), {
      activated,
    }),
});
