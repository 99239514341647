/** @jsx jsx */
import React, { useMemo } from 'react';
import { jsx } from '@emotion/core';
import { getPositionAbsoluteStyles, getPositionFixedStyles, getPositionRelativeStyles } from '../styles/modal';

var Positioner = function Positioner(_ref) {
  var scrollBehavior = _ref.scrollBehavior,
      stackIndex = _ref.stackIndex,
      children = _ref.children,
      testId = _ref.testId;
  var positionerStyles = useMemo(function () {
    switch (scrollBehavior) {
      case 'outside':
        return getPositionRelativeStyles(stackIndex);

      case 'inside-wide':
        return getPositionFixedStyles(stackIndex);

      default:
        return getPositionAbsoluteStyles(stackIndex);
    }
  }, [scrollBehavior, stackIndex]);
  return jsx("div", {
    css: positionerStyles,
    "data-testid": testId && "".concat(testId, "--positioner")
  }, children);
};

Positioner.displayName = 'Positioner';
export default Positioner;