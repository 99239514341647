/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import ScrollLock from 'react-scrolllock';
import warnOnce from '@atlaskit/ds-lib/warn-once';
import { bodyStyles, wrapperStyles } from '../styles/content';
import Footer from './footer';
import Header from './header';
import ScrollContainer from './scroll-container';
var emptyObject = {};
export default function Content(props) {
  var actions = props.actions,
      appearance = props.appearance,
      DeprecatedBody = props.body,
      children = props.children,
      _props$components = props.components,
      components = _props$components === void 0 ? emptyObject : _props$components,
      footer = props.footer,
      header = props.header,
      heading = props.heading,
      isChromeless = props.isChromeless,
      isHeadingMultiline = props.isHeadingMultiline,
      onClose = props.onClose,
      shouldScroll = props.shouldScroll,
      testId = props.testId,
      headingId = props.headingId,
      stackIndex = props.stackIndex;
  var _components$Container = components.Container,
      Container = _components$Container === void 0 ? 'div' : _components$Container,
      CustomBody = components.Body;
  var Body = CustomBody || DeprecatedBody || 'div';

  if (process.env.NODE_ENV !== 'production') {
    if (header) {
      warnOnce("@atlaskit/modal-dialog: Deprecation warning - Use of the header prop in ModalDialog is deprecated. Please compose your ModalDialog using the 'components' prop instead");
    }

    if (footer) {
      warnOnce("@atlaskit/modal-dialog: Deprecation warning - Use of the footer prop in ModalDialog is deprecated. Please compose your ModalDialog using the 'components' prop instead");
    }

    if (DeprecatedBody) {
      warnOnce("@atlaskit/modal-dialog: Deprecation warning - Use of the body prop in ModalDialog is deprecated. Please compose your ModalDialog using the 'components' prop instead");
    }
  }

  return jsx(Container, {
    css: wrapperStyles,
    "data-modal-stack": stackIndex,
    "data-testid": testId
  }, isChromeless ? children : jsx(React.Fragment, null, jsx(Header, {
    id: headingId,
    appearance: appearance,
    component: components.Header ? components.Header : header,
    heading: heading,
    onClose: onClose,
    isHeadingMultiline: isHeadingMultiline,
    testId: testId
  }), jsx(ScrollLock, null, jsx(ScrollContainer, {
    shouldScroll: shouldScroll,
    testId: testId
  }, jsx(Body, {
    css: bodyStyles,
    "data-testid": testId && "".concat(testId, "--body")
  }, children))), jsx(Footer, {
    actions: actions,
    appearance: appearance,
    component: components.Footer ? components.Footer : footer,
    onClose: onClose,
    testId: testId
  })));
}