/** @jsx jsx */
import { Fragment } from 'react';
import { jsx } from '@emotion/react';
import FocusRing from '@atlaskit/focus-ring';
import { useTabPanel } from '../hooks';
// Note this is not being memoized as children is an unstable reference
/**
 * __TabPanel__
 *
 * A TabPanel houses the contents of a Tab.
 *
 * - [Examples](https://atlassian.design/components/tabs/examples)
 * - [Code](https://atlassian.design/components/tabs/code)
 * - [Usage](https://atlassian.design/components/tabs/usage)
 */
var TabPanel = function TabPanel(_ref) {
  var children = _ref.children,
    testId = _ref.testId;
  var _useTabPanel = useTabPanel(),
    role = _useTabPanel.role,
    id = _useTabPanel.id,
    hidden = _useTabPanel.hidden,
    ariaLabelledBy = _useTabPanel['aria-labelledby'],
    onMouseDown = _useTabPanel.onMouseDown,
    tabIndex = _useTabPanel.tabIndex;
  return jsx(FocusRing, {
    isInset: true
  }, jsx("div", {
    "data-testid": testId,
    role: role,
    id: id,
    hidden: hidden,
    "aria-labelledby": ariaLabelledBy,
    onMouseDown: onMouseDown,
    tabIndex: tabIndex
  }, jsx(Fragment, null, children)));
};
export default TabPanel;