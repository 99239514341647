import React from 'react';

import { FormattedMessage } from 'react-intl';

import ModalDialog from 'src/components/modal-dialog-actions';
import { useIntl } from 'src/hooks/intl';

import messages from './modals.i18n';

type PartialInvitationMessageEmails = {
  successfullyAdded: string[];
  successfullyInvited: string[];
  nonAdminErrors: string[];
  domainErrors: string[];
};

export type PartialInvitationMessageModalProps = {
  onClose: () => void;
  emails: PartialInvitationMessageEmails;
};

const PartialInvitationMessageModal: React.FC<
  PartialInvitationMessageModalProps
> = ({ onClose, emails }: PartialInvitationMessageModalProps) => {
  const { formatMessage } = useIntl();

  const actions = [
    {
      text: formatMessage(messages.close),
      onClick: onClose,
      autoFocus: true,
      testId: 'partial-invitation-message-modal--close-btn',
    },
  ];

  const {
    successfullyAdded,
    successfullyInvited,
    nonAdminErrors,
    domainErrors,
  } = emails;

  const listUsers = (
    message: FormattedMessage.MessageDescriptor,
    listedEmails: string[]
  ) => {
    const emailList = (
      <ul>
        {listedEmails.map((email: string) => {
          return <li key={`partial-invite-${email}`}>{email}</li>;
        })}
      </ul>
    );
    return (
      <>
        <p>{formatMessage(message, { count: listedEmails.length })}</p>
        {emailList}
        <br />
      </>
    );
  };
  return (
    <ModalDialog
      actions={actions}
      heading={formatMessage(messages.partialInvitationsModalHeader, {
        count: nonAdminErrors.length + domainErrors.length,
      })}
      width="medium"
      onClose={onClose}
    >
      {!!successfullyAdded?.length &&
        listUsers(messages.successfulAdditions, successfullyAdded)}
      {!!successfullyInvited?.length &&
        listUsers(messages.successfulInvitations, successfullyInvited)}
      {!!nonAdminErrors?.length &&
        listUsers(messages.nonAdminErrorMessage, nonAdminErrors)}
      {!!domainErrors?.length &&
        listUsers(messages.domainErrorMessage, domainErrors)}
    </ModalDialog>
  );
};

export default PartialInvitationMessageModal;
