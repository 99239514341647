import React from 'react';

import { injectIntl, InjectedIntl } from 'react-intl';

import { BreadcrumbsItem } from '@atlaskit/breadcrumbs';

import { ComponentLink as Link } from 'src/components/component-link';
import { useAnalytics } from 'src/components/settings/analytics';
import RepositoryBreadcrumbs from 'src/sections/repository/containers/breadcrumbs';

import messages from './breadcrumbs.i18n';
import { BreadcrumbItemStyled } from './settings.styled';

type RepoAdminBreadcrumbsProps = {
  settingsHref: string;
  intl: InjectedIntl;
};

const RepoAdminBreadcrumbs: React.FC<RepoAdminBreadcrumbsProps> = ({
  intl,
  settingsHref,
}) => {
  const text = intl.formatMessage(messages.admin);
  const { publishBreadcrumbClickEvent } = useAnalytics();

  return (
    <RepositoryBreadcrumbs>
      <BreadcrumbItemStyled>
        <BreadcrumbsItem
          href={settingsHref}
          text={text}
          component={Link}
          onClick={() => publishBreadcrumbClickEvent('SettingsLink')}
        />
      </BreadcrumbItemStyled>
    </RepositoryBreadcrumbs>
  );
};

export default injectIntl(RepoAdminBreadcrumbs);
