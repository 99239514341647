import qs from 'qs';

import { getCookie } from '@atlassian/browser-storage-controls';

export enum PipelinesEnvEnum {
  'PRODUCTION' = '',
  'STAGING' = 'stg-west',
  'DDEV' = 'ddev',
}

export type apiPrefix = '/!api/internal' | '/!api/2.0';
export type apiKey = 'pipelines' | 'deployments';

export const getEnv = () => {
  const envFallback = getCookie('bbc-frontbucket-pipelines-env') ?? '';
  return (
    (qs.parse(location.search, { ignoreQueryPrefix: true }).env as string) ??
    envFallback
  );
};

export const getEnvKey = () => {
  const env = getEnv();
  if (env === PipelinesEnvEnum.STAGING) {
    return 'STAGING';
  } else if (env === PipelinesEnvEnum.DDEV) {
    return 'DDEV';
  }
  return `PRODUCTION`;
};

export const getAddonKey = () => {
  const env = getEnv();
  if (env === PipelinesEnvEnum.STAGING) {
    return `pipelines-stg-west`;
  } else if (env === PipelinesEnvEnum.DDEV) {
    return `pipelines-ddev`;
  }
  return `pipelines`;
};

export const getWebSocketUrl = () => {
  const env = getEnv();
  if (env === PipelinesEnvEnum.STAGING) {
    return `wss://bitbucketci-ws-service.stg.services.atlassian.com/ws`;
  } else if (env === PipelinesEnvEnum.DDEV) {
    return `wss://bitbucketci-ws-service.dev.services.atlassian.com/ws`;
  }
  return `wss://bitbucketci-ws-service.services.atlassian.com/ws`;
};

export default function (
  apiPrefix: apiPrefix = '/!api/2.0',
  apiKey: apiKey = 'pipelines',
  // some environment APIs are inconsistent
  fixInternal = false
) {
  const env = getEnv();
  const internalPipelielinesApiPart =
    apiPrefix === '/!api/2.0' ? '' : fixInternal ? '' : '/internal';
  if (env === PipelinesEnvEnum.STAGING) {
    return `/!api/internal/${apiKey}/stg-west${internalPipelielinesApiPart}`;
  } else if (env === PipelinesEnvEnum.DDEV) {
    return `/!api/internal/${apiKey}/ddev${internalPipelielinesApiPart}`;
  }
  return apiPrefix;
}
