import React, { Component } from 'react';

import { InjectedIntl, injectIntl } from 'react-intl';

import Button from '@atlaskit/button/custom-theme-button';
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import TimeIcon from '@atlaskit/icon/glyph/recent';
import { colors } from '@atlaskit/theme';
/* eslint frontbucket-patterns/no-old-tooltip: "warn" */
import Tooltip from '@atlaskit/tooltip';

import messages from '../../i18n';
import { BuildStatusesMap } from '../../types';

export type TriggerProps = {
  buildCounts: BuildStatusesMap;
  intl: InjectedIntl;
  onTrigger: () => void;
  isLoading?: boolean;
  showIcon?: boolean;
  iconSize?: 'small' | 'medium' | 'large' | 'xlarge';
};

class Trigger extends Component<TriggerProps> {
  static defaultProps = {
    showIcon: true,
  };

  render() {
    const { buildCounts, iconSize, onTrigger, isLoading, intl, showIcon } =
      this.props;

    const { FAILED, INPROGRESS, STOPPED, SUCCESSFUL } = buildCounts;
    const total = FAILED + INPROGRESS + STOPPED + SUCCESSFUL;

    let IconComponent = CheckCircleIcon;
    let tooltipLabel = intl.formatMessage(messages.tooltipPassedStatus, {
      count: SUCCESSFUL,
      total,
    });
    let primaryColor = colors.G400;

    if (FAILED) {
      tooltipLabel = intl.formatMessage(messages.tooltipFailedStatus, {
        count: FAILED,
        total,
      });
      IconComponent = ErrorIcon;
      primaryColor = colors.R400;
    } else if (STOPPED) {
      tooltipLabel = intl.formatMessage(messages.tooltipPassedStatus, {
        count: SUCCESSFUL,
        total,
      });
      IconComponent = ErrorIcon;
      primaryColor = colors.R400;
    } else if (INPROGRESS) {
      IconComponent = TimeIcon;
      primaryColor = colors.B300;
    }

    const BuildIcon = showIcon ? (
      <IconComponent
        size={iconSize}
        label={tooltipLabel}
        primaryColor={primaryColor}
      />
    ) : undefined;

    const buttonLabel = !showIcon
      ? intl.formatMessage(messages.buttonLabel, {
          count: total,
        })
      : undefined;

    return (
      <Tooltip content={tooltipLabel} position="bottom">
        <Button
          onClick={onTrigger}
          isLoading={isLoading}
          appearance="link"
          spacing={showIcon ? 'default' : 'none'}
          iconBefore={BuildIcon}
          aria-label={intl.formatMessage(messages.buildInformationButtonLabel)}
        >
          {buttonLabel}
        </Button>
      </Tooltip>
    );
  }
}

export default injectIntl(Trigger);
