/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
/** @jsx jsx */
import { jsx } from '@emotion/react';
export var LogoSvg = function LogoSvg(_ref) {
  var alt = _ref.alt,
    _ref$isDisabled = _ref.isDisabled,
    isDisabled = _ref$isDisabled === void 0 ? false : _ref$isDisabled,
    className = _ref.className,
    _ref$size = _ref.size,
    size = _ref$size === void 0 ? 24 : _ref$size;
  var stroke = isDisabled ? "var(--ds-icon-disabled, #091E424F)" : "var(--ds-icon, #44546F)";
  return jsx("svg", {
    width: size,
    height: size,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    stroke: stroke
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
    ,
    className: className,
    role: alt === '' ? 'presentation' : undefined
  }, alt && jsx("title", null, alt), jsx("path", {
    d: "M14.5 14.5L17.5 17.5",
    strokeWidth: "1.5",
    strokeLinecap: "square"
  }), jsx("path", {
    d: "M9.5 14.5L6.5 17.5",
    strokeWidth: "1.5",
    strokeLinecap: "square"
  }), jsx("path", {
    d: "M9.5 9.5L6.5 6.5",
    strokeWidth: "1.5",
    strokeLinecap: "square"
  }), jsx("path", {
    d: "M14.5 9.5L17.5 6.5",
    strokeWidth: "1.5",
    strokeLinecap: "square"
  }), jsx("path", {
    d: "M12.0001 16V19",
    strokeWidth: "1.5"
  }), jsx("path", {
    d: "M8.00012 12L5.00012 12",
    strokeWidth: "1.5"
  }), jsx("path", {
    d: "M12.0001 8.00002L12.0001 4.99999",
    strokeWidth: "1.5"
  }), jsx("path", {
    d: "M16.0002 12L19.0002 12",
    strokeWidth: "1.5"
  }));
};