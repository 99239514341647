import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { isDOMElement } from '../../utils/parseDOM';

/**
 * @name fragment_mark
 * @description Indicates that the elements decorated with this mark belong to a "fragment" entity, which represents a collection of ADF nodes.
 * This entity can be referred to later by its `localId` attribute.
 */

var parseFragment = function parseFragment(maybeValue) {
  var _maybeValue$getAttrib;
  if (!isDOMElement(maybeValue)) {
    return false;
  }
  var name = (_maybeValue$getAttrib = maybeValue.getAttribute('data-name')) !== null && _maybeValue$getAttrib !== void 0 ? _maybeValue$getAttrib : undefined;
  var localId = maybeValue.getAttribute('data-localId');
  if (!localId) {
    return false;
  }
  return {
    localId: localId,
    name: name
  };
};
export var fragment = {
  inclusive: false,
  excludes: '',
  attrs: {
    localId: {
      default: ''
    },
    name: {
      default: null
    }
  },
  parseDOM: [{
    tag: '[data-mark-type="fragment"]',
    getAttrs: function getAttrs(maybeValue) {
      return parseFragment(maybeValue);
    }
  }],
  toDOM: function toDOM(mark, inline) {
    var wrapperStyle = inline ? 'span' : 'div';
    return [wrapperStyle, {
      'data-mark-type': 'fragment',
      'data-name': mark.attrs.name,
      'data-localId': mark.attrs.localId
    }];
  }
};
export var toJSON = function toJSON(mark) {
  return {
    type: mark.type.name,
    attrs: _objectSpread({
      localId: mark.attrs.localId
    }, mark.attrs.name ? {
      name: mark.attrs.name
    } : {})
  };
};