import React, { useEffect } from 'react';

import AnalyticsWebClient, {
  userType,
  tenantType,
} from '@atlassiansox/analytics-web-client';

import { uncurlyUuid } from './utils';

const setUserInfo = (analyticsClient: any, accountId?: string) => {
  if (accountId) {
    analyticsClient.setUserInfo(userType.ATLASSIAN_ACCOUNT, accountId);
  } else {
    analyticsClient.clearUserInfo();
  }
};

const setWorkspaceInfo = (
  analyticsClient: AnalyticsWebClient,
  workspaceId?: string
) => {
  if (workspaceId) {
    analyticsClient.setWorkspaceInfo(workspaceId);
    analyticsClient.setTenantInfo(tenantType.CLOUD_ID, workspaceId);
  } else {
    analyticsClient.clearWorkspaceInfo();
    analyticsClient.setTenantInfo(tenantType.NONE);
  }
};

type Props = {
  analyticsClient: AnalyticsWebClient;
  accountId?: string;
  workspaceId?: string;
};

/**
 * Component that manages the analytics client's registered user,
 * starting/stopping "UI viewed" events used for EMAU, and
 * setting the workspace ID for the analytics client.
 */
export const AnalyticsClientManager: React.FC<Props> = props => {
  const { analyticsClient, accountId, workspaceId } = props;

  let workspaceUuid = workspaceId;
  if (workspaceUuid) {
    // We want to support optional braces for this function. If uncurlyUuid
    // returns an empty string for no braces, assume the given string is a
    // valid UUID format.
    workspaceUuid = uncurlyUuid(workspaceUuid) || workspaceUuid;
  }

  useEffect(() => {
    analyticsClient.startUIViewedEvent();
    return () => {
      analyticsClient.stopUIViewedEvent();
    };
  }, [analyticsClient]);

  useEffect(() => {
    setUserInfo(analyticsClient, accountId);
  }, [analyticsClient, accountId]);

  useEffect(() => {
    setWorkspaceInfo(analyticsClient, workspaceUuid);
  }, [analyticsClient, workspaceUuid]);

  return null;
};
