import { FONT_STYLE } from '../groups';

/**
 * @name strike_mark
 */

export var strike = {
  inclusive: true,
  group: FONT_STYLE,
  parseDOM: [{
    tag: 'strike'
  }, {
    tag: 's'
  }, {
    tag: 'del'
  }, {
    style: 'text-decoration',
    getAttrs: function getAttrs(value) {
      return value === 'line-through' && null;
    }
  }],
  toDOM: function toDOM() {
    return ['s'];
  }
};