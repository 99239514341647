import { bind } from 'bind-event-listener';

/**
 * Returns a promise resolved when the page load event has been reached.
 */
export var waitForPageLoad = function waitForPageLoad() {
  if (document.readyState === 'complete') {
    return Promise.resolve();
  }
  return new Promise(function (resolve) {
    var unbind = bind(window, {
      type: 'load',
      listener: function listener() {
        resolve();
        unbind();
      }
    });
  });
};