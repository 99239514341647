import { inRange } from 'lodash-es';
/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { Task } from 'redux-saga';
import {
  all,
  take,
  select,
  call,
  put,
  spawn,
  race,
  fork,
  TakeEffect,
} from 'redux-saga/effects';

import { ChunkEntry } from '@atlassian/bitkit-diff';

import { CodeReviewConversation } from 'src/components/conversation-provider/types';
import { DiffType } from 'src/components/types/src/pull-request';
import { UPDATE_GLOBAL_SHOULD_IGNORE_WHITESPACE_SUCCESS } from 'src/redux/diff-settings';
import { LoadGlobal } from 'src/redux/global/actions';
import {
  FETCH_COMMENTS,
  FETCH_DIFF,
  LOAD_DIFFSTAT,
  FETCH_COMMENT_CONTEXT,
  ENTERED_CODE_REVIEW,
  REFRESH_CODE_REVIEW_DATA_FROM_POLL_RESULTS,
  LOAD_SSR_COMMENTS_CONTEXT,
} from 'src/redux/pull-request/actions';
import {
  getCurrentPullRequestUrlPieces,
  getIsSingleFileModeActive,
  getPullRequestRenderingLimits,
  getRenderedPullRequestDiff,
  getPullRequestDestinationHash,
  getPullRequestSourceHash,
  getCurrentPullRequestDiffType,
} from 'src/redux/pull-request/selectors';
import { isFileLevelComment } from 'src/redux/pull-request/utils/comments';
import { getConversations } from 'src/selectors/conversation-selectors';
import { Diff, PullRequestRenderingLimits } from 'src/types/pull-request';
import { hasRenderableLines } from 'src/utils/diff-classifications';
import authRequest from 'src/utils/fetch';
import {
  ContextLineRanges,
  validateContextLineRanges,
} from 'src/utils/validate-context-lines';

import urls from '../urls';

import { buildChunk } from './utils/build-a-chunk';
import { buildLineNumbers, parseSrcApiLines } from './utils/get-context-data';

type DiffAwareCommentFilter = (
  diffs: Diff[],
  isSingleFileModeActive: boolean,
  renderingLimits: PullRequestRenderingLimits | null
) => (conversation: CodeReviewConversation) => boolean;

type CommentWithClosestChunkMapper = (
  diffs: Diff[]
) => (conversation: CodeReviewConversation) => CommentWithClosestChunk;

type CommentWithClosestChunk = {
  conversation: CodeReviewConversation;
  chunk?: ChunkEntry;
};

const isIncompleteConversation = (conversation: CodeReviewConversation) =>
  !(conversation.meta.from !== null && conversation.meta.to !== null);

const none = (array: any[], predicate: (x: any) => boolean) =>
  !array.some(predicate);

const containsConversation =
  (conversation: CodeReviewConversation) => (chunk: ChunkEntry) => {
    // FROM | TO are the terms for the gutter column line numbers
    const fromStart = chunk.oldStart;
    const fromEnd = chunk.oldStart + chunk.oldLines - 1;
    const toStart = chunk.newStart;
    const toEnd = chunk.newStart + chunk.newLines - 1;

    const { from: convoFrom, to: convoTo } = conversation.meta;

    // This is redundant if the consumer has already checked this but this function doesn't know
    const isFileOrGlobalComment = convoFrom === null && convoTo === null;
    if (isFileOrGlobalComment) {
      return false;
    }

    const isFromLineInChunk =
      !!fromStart &&
      convoFrom !== null &&
      inRange(convoFrom, fromStart, fromEnd + 1);
    const isToLineInChunk =
      !!toStart && convoTo !== null && inRange(convoTo, toStart, toEnd + 1);

    return isFromLineInChunk || isToLineInChunk;
  };

const needsRendered: DiffAwareCommentFilter =
  (diffs, diffRenderingFlags, renderingLimits) => conversation => {
    const { path, outdated } = conversation.meta;
    const relevantDiff = diffs.find(diff => diff.to === path);
    if (!relevantDiff) {
      return false;
    }

    const { chunks } = relevantDiff;

    return (
      hasRenderableLines(relevantDiff, diffRenderingFlags, renderingLimits) &&
      !outdated &&
      !isFileLevelComment(conversation) &&
      none(chunks, containsConversation(conversation))
    );
  };

const getCommentWithClosestChunk: CommentWithClosestChunkMapper =
  diffs => conversation => {
    const { path } = conversation.meta;
    const relevantDiff = diffs.find(diff => diff.to === path)!;
    const { chunks } = relevantDiff;

    let minDistToChunk = Number.MAX_SAFE_INTEGER;
    let closestChunk: ChunkEntry;

    // find closest chunk above the convo if any
    chunks.forEach(chunk => {
      const { from: convoFrom, to: convoTo } = conversation.meta;
      // incomplete convo will have either a non-null "from" or "to" field
      if (convoFrom !== null) {
        const fromEnd = chunk.oldStart + chunk.oldLines - 1;
        if (convoFrom > fromEnd) {
          const currDist = Math.abs(fromEnd - convoFrom);
          if (currDist < minDistToChunk) {
            minDistToChunk = currDist;
            closestChunk = chunk;
          }
        }
      } else if (convoTo !== null) {
        const toEnd = chunk.newStart + chunk.newLines - 1;
        if (convoTo > toEnd) {
          const currDist = Math.abs(toEnd - convoTo);
          if (currDist < minDistToChunk) {
            minDistToChunk = currDist;
            closestChunk = chunk;
          }
        }
      }
    });

    return {
      conversation,
      // @ts-ignore closestChunk will be non-null
      chunk: closestChunk,
    };
  };

function* fetchContextLines(
  path: string,
  linesRange: ContextLineRanges,
  retries = 2
): Generator<any, void, any> {
  if (retries <= 0) {
    // Fail-safe because recursion
    return;
  }

  const sourceHash: string = yield select(getPullRequestSourceHash);
  const destinationHash: string = yield select(getPullRequestDestinationHash);

  const { owner, slug } = yield select(getCurrentPullRequestUrlPieces);

  const topicDiffType: DiffType = yield select(getCurrentPullRequestDiffType);
  const topicDiff = topicDiffType === DiffType.TOPIC_DIFF;

  const diffs: Diff[] = yield select(getRenderedPullRequestDiff);
  const relevantDiff = diffs.find(diff => diff.to === path);
  const prevFilepath = relevantDiff && relevantDiff.from;

  try {
    validateContextLineRanges(linesRange);
  } catch (e) {
    // Only logging these in Sentry for now
  }

  const url = topicDiff
    ? urls.api.internal.contextFromSrcTopicDiff(
        {
          // lookup the source hash in the destination repo
          // to avoid issues with repo permissions
          owner,
          slug,
          sourceHash,
          filepath: path,
        },
        linesRange as ContextLineRanges
      )
    : urls.api.internal.contextFromSrcPreviewMerge(
        {
          owner,
          slug,
          destinationHash,
          filepath: prevFilepath || path,
        },
        linesRange as ContextLineRanges
      );

  const response: Response = yield call(fetch, authRequest(url));

  const json =
    response.status === 200
      ? // @ts-ignore
        yield response.json()
      : // @ts-ignore
        yield Promise.resolve({ lines: [] });

  const lines = (json.data && parseSrcApiLines(json.data)) || [];

  const { startingFrom, startingTo } = linesRange as ContextLineRanges;
  const fromLineNumbers = buildLineNumbers(lines.length, startingFrom);
  const toLineNumbers = buildLineNumbers(lines.length, startingTo);

  const decodedLines = lines.map((line: string, index: number) => ({
    content: ` ${line}`, // diff content starts with +, - or ' '
    from_line: fromLineNumbers[index],
    to_line: toLineNumbers[index],
  }));

  yield put({
    type: FETCH_COMMENT_CONTEXT.SUCCESS,
    payload: {
      path,
      newChunk: buildChunk(decodedLines),
    },
  });
}

// These conversations will necessarily be in the "context lines" area
// So we can assume the delta between their FROM & TO line numbers
// is true of their neighboring line-number pairs.
// i.e. if this convo is on 4 | 5, then we know 3 | 4 and 5 | 6 should be
// safe to request so long as they don't cross into chunks we already render
// which are chunks this could contain diff changes.
export function* fetchConversationContext(
  conversationWithChunk: CommentWithClosestChunk
) {
  const EXTRA_LINES = 1; // line ranges are inclusive
  const { from, to, path } = conversationWithChunk.conversation.meta;
  let initialLineNums;

  // "incomplete" comment that needs special handling
  if (isIncompleteConversation(conversationWithChunk.conversation)) {
    // initial line numbers inferred from closest chunk above if any
    const { chunk } = conversationWithChunk;
    let offset = 0;
    if (chunk) {
      // chunk above
      const {
        oldStart: fromStart,
        newStart: toStart,
        oldLines: oldLines,
        newLines: newLines,
      } = chunk;
      offset = toStart - fromStart + (newLines - oldLines);
    }
    // @ts-ignore
    const correctedFrom = from === null ? to + -1 * offset : from;
    // @ts-ignore
    const correctedTo = to === null ? from + offset : to;

    initialLineNums = {
      startingFrom: Math.max(1, correctedFrom - EXTRA_LINES),
      startingTo: Math.max(1, correctedTo - EXTRA_LINES),
      endingFrom: correctedFrom + EXTRA_LINES,
      endingTo: correctedTo + EXTRA_LINES,
    };
  } else {
    initialLineNums = {
      // @ts-ignore FROM will be non null
      startingFrom: Math.max(1, from - EXTRA_LINES),
      // @ts-ignore TO will be non null
      startingTo: Math.max(1, to - EXTRA_LINES),
      // @ts-ignore FROM will be non null
      endingFrom: from + EXTRA_LINES,
      // @ts-ignore TO will be non null
      endingTo: to + EXTRA_LINES,
    };
  }

  yield* fetchContextLines(
    // @ts-ignore PATH will be non null
    path,
    initialLineNums
  );
}

function* userEntersCodeReviewPage(effects: TakeEffect[]) {
  yield take(ENTERED_CODE_REVIEW);
  yield all(effects);
}

function* userTogglesWhitespace(effects: TakeEffect[]) {
  yield take(UPDATE_GLOBAL_SHOULD_IGNORE_WHITESPACE_SUCCESS);
  yield all(effects);
}

function* userRefreshsPullRequestDiff(effects: TakeEffect[]) {
  yield take(REFRESH_CODE_REVIEW_DATA_FROM_POLL_RESULTS);
  yield all(effects);
}

export function* fetchCommentsContext() {
  const diffs: Diff[] = yield select(getRenderedPullRequestDiff);
  const conversations: CodeReviewConversation[] = yield select(
    getConversations
  );

  const isSingleFileModeActive: boolean = yield select(
    getIsSingleFileModeActive
  );
  const renderingLimits: PullRequestRenderingLimits = yield select(
    getPullRequestRenderingLimits
  );

  const conversationsToRender = conversations
    .filter(needsRendered(diffs, isSingleFileModeActive, renderingLimits))
    .map(getCommentWithClosestChunk(diffs));
  const contextTasks = conversationsToRender.map(conversationToRender =>
    spawn(fetchConversationContext, conversationToRender)
  );

  yield all(contextTasks);
}

export function* commentsContextSaga() {
  let task: Task | undefined;
  // wait for features to be set in redux
  yield take([LoadGlobal.SUCCESS, LOAD_SSR_COMMENTS_CONTEXT]);

  const effects = [take(FETCH_DIFF.SUCCESS), take(LOAD_DIFFSTAT.SUCCESS)];
  while (true) {
    yield race([
      call(
        userEntersCodeReviewPage,
        [take(FETCH_COMMENTS.SUCCESS)].concat(effects)
      ),
      call(userTogglesWhitespace, effects),
      call(userRefreshsPullRequestDiff, effects),
    ]);

    if (task !== undefined && task.isRunning()) {
      yield task.cancel();
    }

    task = yield fork(fetchCommentsContext);
  }
}
