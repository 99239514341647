/**
 * @name hardBreak_node
 */

export var hardBreak = {
  inline: true,
  group: 'inline',
  selectable: false,
  parseDOM: [{
    tag: 'br'
  }],
  toDOM: function toDOM() {
    return ['br'];
  }
};