export const SCREEN: 'Screen' = 'Screen';
export const DRAWER: 'Drawer' = 'Drawer';
export const MODAL: 'Modal' = 'Modal';
export const INLINE_DIALOG: 'InlineDialog' = 'InlineDialog';
export const DROPDOWN: 'Dropdown' = 'Dropdown';

export const UI_EVENT_TYPE: 'UI' = 'UI';
export const TRACK_EVENT_TYPE: 'TRACK' = 'TRACK';
export const SCREEN_EVENT_TYPE: 'SCREEN' = 'SCREEN';
export const OPERATIONAL_EVENT_TYPE: 'OPERATIONAL' = 'OPERATIONAL';

export const ANALYTICS_BRIDGE_CHANNEL = 'analyticsBridge';
