// http://developer.mozilla.org/en-US/docs/Web/API/PerformanceNavigation/type
var getDeprecatedNavigationType = function getDeprecatedNavigationType() {
  var type = window.performance.navigation.type;
  return type === 2 ? 'back_forward' : type === 1 ? 'reload' : 'navigate';
};
export var getNavigationType = function getNavigationType() {
  var _window$performance$g;
  return ((_window$performance$g = window.performance.getEntriesByType('navigation')[0]) === null || _window$performance$g === void 0 ? void 0 : _window$performance$g.type) || getDeprecatedNavigationType();
};
export var getActivationStart = function getActivationStart() {
  var _window$performance$g2;
  return ((_window$performance$g2 = window.performance.getEntriesByType('navigation')[0]) === null || _window$performance$g2 === void 0 ? void 0 : _window$performance$g2.activationStart) || 0;
};