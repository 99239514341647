import React from 'react';

import createSettingsHOC, {
  SharedProps,
} from 'src/components/settings/create-settings-hoc';
import { useParams } from 'src/router/hooks';
import urls from 'src/sections/repository/urls';
import { getSelectedMenuItem } from 'src/selectors/repository-selectors';

import RepoAdminBreadcrumbs from '../components/breadcrumbs';

const Breadcrumbs: React.FC<SharedProps> = () => {
  const { repositoryOwner, repositorySlug } = useParams<{
    repositoryOwner: string;
    repositorySlug: string;
  }>();
  const settingsHref = urls.ui.admin(
    repositoryOwner || '',
    repositorySlug || ''
  );
  return <RepoAdminBreadcrumbs settingsHref={settingsHref} />;
};

export default createSettingsHOC(getSelectedMenuItem, {
  breadcrumbs: Breadcrumbs,
});
