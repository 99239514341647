import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import AnalyticsWebClient, { originType } from '@atlassiansox/analytics-web-client';
import { Choreographer } from '../Choreographer';
/**
 * class to be used for subclassing individual choreographer plugin classes. Internally,
 * these can do whatever is needed for the message service being wrapped, so long as they adhere
 * to the IChoreographerPlugin contract for communicating with the parent choreographer API.
 */

export class ChoreographerPlugin {
  /**
   * Instantiates a plugin to be used with the main choreographer API. Requires a productId string
   * to be used for segmenting registered messageIds in other parts of the API, which ensures uniqueness
   * between individual products for tracking purposes.
   *
   * @param productId The name of the product for which this plugin will be managing communications with the choreographer API.
   * @param env The environment type for the product, to be used for analytics events.
   * @param metaMetricsData An object containing additional metadata to be sent along with every message request.
   * @returns A new instance of this plugin to use for integrating a 1P or 3P product with the choreographer API.
   */
  constructor(productId, env, metaMetricsData = {}) {
    this.state = {
      isDisabled: false,
      isDisplayingMessage: false,
      metaMetricsData,
      productId,
      unregisterPlugin: () => {}
    };
    this.analyticsClient = this.createAnalyticsClient(env);
    /**
     * These finalizers are callbacks to be invoked after a valid start or stop request in the Choreographer core.
     * Declaring them in the constructor because it's the same onStart/onStop pair that will be passed ot all of the
     * .on, .onStart, and .onStop function registrations.
     *
     * This behavior used to be managed by a decorator function that would auto-set the isDisplayingMessage value
     * whenever its start or stop callbacks were invoked. However, those start and stop callbacks are optional from
     * this layer, so if a consumer did *not* register a stop callback, the display state would never be cleared here
     * in the plugin, and would continue blocking messages.
     *
     * We're now passing these onStart and onStop callbacks as part of the message registration, so that any time a
     * message is started or stopped at the core layer, we can fire these callbacks to update the plugin's local state,
     * whether or not an actual start or stop callback was registered by the consumer.
     */
    this.finalizers = {
      onStart: () => this.blockDisplayState(),
      onStop: () => this.clearDisplayState()
    };
    this.state.unregisterPlugin = this.registerPlugin(productId);
  }

  /**
   * Public method to clean up this plugin instance, which will handle unregistering it from the choreographer API.
   */
  destroy() {
    this.state.unregisterPlugin();
  }

  /**
   * Determines whether this plugin is currently displaying a message on the screen. By default, is a simple boolean
   * toggle that gets flipped back and forth, but it could also be a function that checks the DOM for a given message
   * container to be present, or checks with some external API to tell it whether a message is being displayed, or
   * anything else. The logic is up to the consumer to implement, if something custom is desired.
   *
   * @returns A boolean that represents whether this plugin is currently displaying a message on the screen.
   */
  isDisplayingMessage() {
    return this.state.isDisplayingMessage;
  }

  /**
   * Sets the internal isDisplayingMessage state to true, to denote that this plugin is currently blocking the UI.
   */
  blockDisplayState() {
    this.state.isDisplayingMessage = true;
  }

  /**
   * Sets the internal isDisplayingMessage state to false, to denote that this plugin is no longer blocking the UI.
   */
  clearDisplayState() {
    this.state.isDisplayingMessage = false;
  }

  /**
   * Disables the plugin from being able to issue startMessage requests. By default, this is a simple boolean toggle
   * that gets flipped back and forth, but it could also be a call to an external API to disable some functionality
   * via its own public interface. The logic is up to the consumer to implement, if something custom is desired.
   */
  disable() {
    this.state.isDisabled = true;
  }

  /**
   * Enables the plugin from being able to issue startMessage requests. By default, this is a simple boolean toggle
   * that gets flipped back and forth, but it could also be a call to an external API to disable some functionality
   * via its own public interface. The logic is up to the consumer to implement, if something custom is desired.
   */
  enable() {
    this.state.isDisabled = false;
  }

  /**
   * Makes a request to start a message through the choreographer API. Will immediately return false if this plugin
   * is currently in a disabled state, and will otherwise return the ultimate result of the startMessage request.
   *
   * @param messageId The messageId for the message to be started through the choreographer API.
   * @param additionalData An object containing additional metadata to be sent along with the message start request
   * @returns A Promise that resolves to a MessageDeliveryStatus value, to denote the ultimate status of the attempted message start operation.
   */
  async startMessage(messageId, additionalData = {}) {
    return Choreographer.getInstance().startMessage(this.state.productId, messageId, this.analyticsClient, _objectSpread(_objectSpread({}, this.state.metaMetricsData), additionalData), this);
  }

  /**
   * Makes a request to stop a message through the choreographer API. Will return the ultimate result of the stopMessage request.
   *
   * @param messageId The messageId for the message to be stopped through the choreographer API.
   * @param additionalData An object containing additional metadata to be sent along with the message stop request
   * @returns A Promise that resolves to a MessageDeliveryStatus value, to denote the ultimate status of the attempted message stop operation.
   */
  async stopMessage(messageId, additionalData = {}) {
    return Choreographer.getInstance().stopMessage(this.state.productId, messageId, this.analyticsClient, _objectSpread(_objectSpread({}, this.state.metaMetricsData), additionalData));
  }

  /**
   * Subscribes callbacks to be used for starting and stopping message requests through the choreographer, keyed by a unique (to this plugin instance) messageId.
   *
   * @param messageId Unique identifier within the context of all of this plugin's message entries, to be used as a key for this message subscription.
   * @param options An object containing start and stop callback functions, to be invoked upon successful requests to startMessage and stopMessage, respectively.
   */
  on(messageId, options = {}) {
    return Choreographer.getInstance().on(this.state.productId, messageId, options, this.finalizers);
  }

  /**
   * Subscribes a callback to be used for starting message requests through the choreographer, keyed by a unique (to this plugin instance) messageId.
   *
   * @param messageId Unique identifier within the context of all of this plugin's message entries, to be used as a key for this message subscription.
   * @param startCallback A callback to be invoked upon successful requests to startMessage.
   */
  onStart(messageId, startCallback) {
    return Choreographer.getInstance().onStart(this.state.productId, messageId, startCallback, this.finalizers);
  }

  /**
   * Subscribes a callback to be used for stopping message requests through the choreographer, keyed by a unique (to this plugin instance) messageId.
   *
   * @param messageId Unique identifier within the context of all of this plugin's message entries, to be used as a key for this message subscription.
   * @param stopCallback A callback to be invoked upon successful requests to stopMessage.
   */
  onStop(messageId, stopCallback) {
    return Choreographer.getInstance().onStop(this.state.productId, messageId, stopCallback, this.finalizers);
  }

  /**
   * Unsubscribes a message's start and stop message callbacks from the choreographer, keyed by a unique (to this plugin instance) messageId.
   *
   * @param messageId Unique identifier within the context of all of this plugin's message entries, to be used as a key for unsubscribing this message subscription.
   */
  off(messageId) {
    return Choreographer.getInstance().off(this.state.productId, messageId);
  }

  /**
   * Registers this plugin instance with the choreographer API. Allows the choreographer to enable and disable it as needed to ensure individual message experiences on screen.
   *
   * @param productId The name of the product for which this plugin will be managing communications with the choreographer API.
   * @returns A callback to be used to unregister this plugin with the choreographer instance, for cleanup purposes, if necessary.
   */
  registerPlugin(productId) {
    return Choreographer.getInstance().registerPlugin(productId, this);
  }

  /**
   * Creates a new instance of the Analytics Web Client for a plugin to use for sending analytics events.
   *
   * @param env environment type used to create the analytics client
   * @returns AnalyticsWebClient instance
   */
  createAnalyticsClient(env) {
    const analyticsClient = new AnalyticsWebClient({
      env: env,
      product: 'postOffice',
      origin: originType.WEB
    });
    return analyticsClient;
  }
}