import styled from '@emotion/styled';
import { gridSize } from '@atlaskit/theme/constants';
import { appLabelTextColor } from './constants';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var ReportingLinesSection = styled.div({
  // Minor left margin to align better with existing icon fields
  marginLeft: "var(--ds-space-050, 4px)",
  marginTop: "var(--ds-space-100, 8px)"
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var ReportingLinesHeading = styled.h3({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  color: appLabelTextColor,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  fontSize: "".concat(gridSize() * 1.5, "px"),
  fontWeight: 600,
  marginBottom: "var(--ds-space-100, 8px)"
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var ManagerSection = styled.div({
  display: 'flex',
  alignItems: 'center',
  margin: "var(--ds-space-050, 4px)".concat(" ", "var(--ds-space-050, 4px)")
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var ManagerName = styled.span({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  fontSize: "".concat(gridSize() * 1.5, "px"),
  marginLeft: "var(--ds-space-100, 8px)"
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var OffsetWrapper = styled.div({
  marginTop: "var(--ds-space-050, 4px)",
  // Offset left margin so the avatar aligns with the heading
  marginLeft: "var(--ds-space-negative-050, -4px)"
});