import qs from 'qs';

import { uncurlyUuid } from '@atlassian/bitkit-analytics';

const urls = {
  api: {
    v10: {},
    v20: {},
    gateway: {
      repoSearch: () => '/gateway/api/xpsearch-aggregator',
      aisAvailableProducts: () => '/gateway/api/ais/available-products',
    },
    internal: {
      compassBannerEmailNotification: (workspaceSlug: string) =>
        `/!api/internal/workspaces/${workspaceSlug}/compass-banner-email-notification`,
      getCompassCloudIdForWorkspace: (workspaceSlug: string): string =>
        `/!api/internal/workspaces/${workspaceSlug}/compass/workspace-connection`,
    },
  },
  external: {
    apiDocumentation: () => 'https://confluence.atlassian.com/x/IYBGDQ',
    blog: () => 'https://blog.bitbucket.org',
    documentation: () => 'https://confluence.atlassian.com/display/BITBUCKET',
    gettingGitRight: () => 'https://www.atlassian.com/git',
    privacyPolicy: () => 'https://www.atlassian.com/legal/privacy-policy',
    atlassianResearchGroup: () => 'https://www.atlassian.com/research-group',
    status: () => 'https://status.bitbucket.org',
    termsOfService: () => 'https://www.atlassian.com/legal/customer-agreement',
    noticeOfCollectionInternal: () =>
      'https://hello.atlassian.net/wiki/spaces/PMP/pages/139161942',
    noticeOfCollectionExternal: () =>
      'https://www.atlassian.com/legal/privacy-policy#additional-disclosures-for-ca-residents',
    tutorials: () => 'https://confluence.atlassian.com/x/Q4sFLQ',
    atlassianAccountSync: (
      aaLoginUrl: string,
      destinationUrl: string,
      destinationAccountEmail: string,
      confirmationRequired = false
    ) =>
      `${aaLoginUrl}?${qs.stringify({
        application: 'bitbucket',
        continue: destinationUrl,
        login_hint: destinationAccountEmail,
        ...(!confirmationRequired && { prompt: 'none' }),
      })}`,
  },
  ui: {
    integrations: (workspaceSlug?: string) =>
      workspaceSlug
        ? `/${workspaceSlug}/workspace/settings/addon-directory/`
        : `/account/admin/addon-directory/`,
    labs: () => `/account/settings/features/`,
    login: () => '/account/signin/',
    plans: () => '/plans',
    root: () => '/',
    settings: () => `/account/settings/`,
    signup: () => '/account/signup/',
    support: () => '/support',
    allWorkspaces: () => '/account/workspaces/',
    search: (query?: string): string =>
      query ? `/search?q=${query}` : '/search',
    repositories: (search?: string): string =>
      `/dashboard/repositories${search ? `?${qs.stringify({ search })}` : ''}`,
    adminHub: (
      adminHubBaseUrl: string,
      orgId: string,
      workspaceUuid: string,
      path: string
    ) => {
      const workspaceAri = `ari:cloud:bitbucket::workspace/${uncurlyUuid(
        workspaceUuid
      )}`;
      const params = {
        product: workspaceAri,
        referrerName: 'Bitbucket',
      };
      return `${adminHubBaseUrl}/o/${orgId}/${path}?${qs.stringify(params)}`;
    },
  },
};

export default urls;
