/* eslint frontbucket-patterns/no-new-sagas: "warn" */
// eslint-disable-next-line frontbucket-patterns/no-new-sagas
import qs from 'qs';
import {
  all,
  put,
  select,
  call,
  takeLatest,
  debounce,
} from 'redux-saga/effects';

import {
  Branch,
  Commit,
  Pipeline,
  PipelineTarget,
} from 'src/components/pipelines/models';
import { getCurrentRepository } from 'src/selectors/repository-selectors';
import authRequest, { jsonHeaders } from 'src/utils/fetch';

import {
  PIPELINE_CREATORS_PAGE_SIZE,
  PIPELINE_DEFINITIONS_PAGE_SIZE,
  PIPELINE_PAGE_SIZE,
  DELAY_PIPELINE_UPDATED,
  SELECTED_BRANCH_REGEX,
} from '../../constants';
import envBaseUrl from '../../utils/env-base-url';
import {
  capturePipelinesExceptionWithTags,
  createErrorMessage,
  isIgnoredErrorForSentry,
} from '../../utils/sentry';
import {
  PIPELINE_UPDATED,
  REQUEST_COMMIT_PULL_REQUESTS,
  REQUEST_CREATE_PIPELINE,
  REQUEST_CURRENT_PIPELINE,
  REQUEST_PIPELINES,
  REQUEST_PIPELINE_CREATORS,
  REQUEST_PIPELINE_DEFINITIONS,
  REQUEST_START_STEP,
  REQUEST_STOP_PIPELINE,
  REQUEST_UPDATE_PIPELINE,
  REQUEST_RESUME_STAGE_REDEPLOY,
} from '../actions/pipelines';
import { getCurrentPipeline } from '../selectors/pipelines';

export const getRepositoriesUrl = (
  owner = '',
  queryParams: any = {}
): string => {
  return `${envBaseUrl('/!api/2.0')}/repositories/${owner}?${qs.stringify(
    queryParams
  )}`;
};

export const getPipelineUrl = (
  repositoryFullSlug: string,
  pipelineRef?: string,
  queryParams: any = {}
): string => {
  const allQueryParams = {
    fields: '+target.commit.message,+target.commit.summary.html,+target.*,+*',
    ...queryParams,
  };
  return `${envBaseUrl()}/repositories/${repositoryFullSlug}/pipelines/${
    pipelineRef
      ? `${pipelineRef}?${qs.stringify(allQueryParams, { allowDots: true })}`
      : ''
  }`;
};

export const getPipelineTarget = (
  pipeline: Pipeline
): { target: PipelineTarget } => {
  const isRefPipeline = !!pipeline.refName;
  const isCustomPipeline = !!pipeline.customName;

  const commit = {
    message: pipeline.message,
    hash: pipeline.revision,
    type: 'commit',
  };

  const type = isRefPipeline ? 'pipeline_ref_target' : 'pipeline_commit_target';

  const refName = isRefPipeline ? { ref_name: pipeline.refName } : null;
  const refType = isRefPipeline ? { ref_type: pipeline.refType } : null;

  let selector = {};
  if (isCustomPipeline) {
    selector = {
      selector: { type: 'custom', pattern: pipeline.selectorPattern },
    };
  } else if (pipeline.isManual && !pipeline.isPullRequestPipeline) {
    // support for default custom pipelines
    selector = {
      selector: {
        type: pipeline.selectorType,
        pattern: pipeline.selectorPattern,
      },
    };
  } else if (pipeline.selectorType === 'pull-requests') {
    return { target: pipeline.target };
  }

  return {
    target: {
      commit: new Commit(commit),
      type,
      ...refName,
      ...refType,
      ...selector,
    },
  };
};

export const getStopPipelineUrl = (
  repositoryFullSlug: string,
  pipelineRef: string
): string => {
  return `${envBaseUrl(
    '/!api/2.0'
  )}/repositories/${repositoryFullSlug}/pipelines/${pipelineRef}/stopPipeline`;
};

export const getPipelinesUrl = (
  repositoryFullSlug: string,
  queryParams: any = {}
): string => {
  const allQueryParams = {
    page: 1,
    pagelen: PIPELINE_PAGE_SIZE,
    sort: '-created_on',
    fields:
      '+values.target.commit.message,' +
      '+values.target.selector.type' +
      '+values.target.selector.pattern' +
      '+values.target.commit.summary.html,' +
      '+values.target.*,+values.*,+page,+size',
    ...queryParams,
  };
  return `${envBaseUrl(
    '/!api/2.0'
  )}/repositories/${repositoryFullSlug}/pipelines/?${qs.stringify(
    allQueryParams,
    { allowDots: true, arrayFormat: 'repeat' }
  )}`;
};

export const getPipelineDefinitionsUrl = (
  repositoryFullSlug: string,
  ref: string,
  queryParams: any = {}
): string => {
  const allQueryParams = {
    page: 1,
    pagelen: PIPELINE_DEFINITIONS_PAGE_SIZE,
    revision: ref,
    ...queryParams,
  };
  return `${envBaseUrl(
    '/!api/internal',
    'pipelines',
    true
  )}/repositories/${repositoryFullSlug}/pipeline_definitions?${qs.stringify(
    allQueryParams
  )}`;
};

export const getCommitPullRequestsUrl = (
  repositoryFullSlug: string,
  revision: string
): string => {
  return `${envBaseUrl(
    '/!api/2.0'
  )}/repositories/${repositoryFullSlug}/commit/${revision}/pullrequests`;
};

export function* requestPipelinesSaga(action: {
  meta: { queryParams: object };
}): any {
  const { full_name } = yield select(getCurrentRepository);
  try {
    const url = getPipelinesUrl(full_name, action.meta?.queryParams);
    const res: Response = yield call(fetch, authRequest(url));
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const data = yield call([res, 'json']);
    yield put({
      type: REQUEST_PIPELINES.SUCCESS,
      meta: action.meta,
      payload: data,
    });
  } catch (e) {
    if (!isIgnoredErrorForSentry(e)) {
      capturePipelinesExceptionWithTags(e, {
        segment: REQUEST_PIPELINES.ERROR,
      });
    }
    yield put({
      type: REQUEST_PIPELINES.ERROR,
      meta: action.meta,
      payload: e,
    });
  }
}

export function* requestPipelineUpdatedSaga(action: {
  pipelineUuid: string;
}): any {
  const pipelineUuid = action?.pipelineUuid;
  const { full_name } = yield select(getCurrentRepository);
  const pipeline = yield select(getCurrentPipeline);

  if (pipeline.uuid && pipeline.uuid !== pipelineUuid) {
    return;
  }
  const branchMatches = location.pathname.match(SELECTED_BRANCH_REGEX);
  const selectedBranch =
    branchMatches && branchMatches[1]
      ? new Branch({ name: branchMatches[1] })
      : undefined;

  try {
    const url = getPipelineUrl(full_name, action?.pipelineUuid);
    const res: Response = yield call(fetch, authRequest(url));
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const data = yield call([res, 'json']);
    yield put({
      type: REQUEST_UPDATE_PIPELINE.SUCCESS,
      meta: {
        pipelineUuid,
      },
      payload: {
        selectedBranch: decodeURIComponent(selectedBranch?.name || ''),
        data,
      },
    });
  } catch (e) {
    if (!isIgnoredErrorForSentry(e)) {
      capturePipelinesExceptionWithTags(e, {
        segment: REQUEST_UPDATE_PIPELINE.ERROR,
      });
    }
    yield put({
      type: REQUEST_UPDATE_PIPELINE.ERROR,
      meta: { pipelineUuid },
      payload: e,
    });
  }
}

export function* requestPipelineCreatorsSaga(): any {
  const { full_name } = yield select(getCurrentRepository);
  try {
    const url = getPipelinesUrl(full_name, {
      pagelen: PIPELINE_CREATORS_PAGE_SIZE,
      fields: '-values.*,+values.creator,+values.trigger',
    });
    const res: Response = yield call(fetch, authRequest(url));
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const data = yield call([res, 'json']);

    yield put({
      type: REQUEST_PIPELINE_CREATORS.SUCCESS,
      payload: data,
    });
  } catch (e) {
    if (!isIgnoredErrorForSentry(e)) {
      capturePipelinesExceptionWithTags(e, {
        segment: REQUEST_PIPELINE_CREATORS.ERROR,
      });
    }
    yield put({
      type: REQUEST_PIPELINE_CREATORS.ERROR,
      payload: e,
    });
  }
}

export function* requestPipelineDefinitionsSaga(action: {
  meta: { ref: string };
}): any {
  try {
    const { full_name } = yield select(getCurrentRepository);
    let items: any = [];
    let morePagesAvailable = true;
    let currentPage = 0;
    while (morePagesAvailable) {
      currentPage++;
      try {
        const url = getPipelineDefinitionsUrl(full_name, action.meta.ref, {
          pagelen: PIPELINE_DEFINITIONS_PAGE_SIZE,
          page: currentPage,
        });
        const res: Response = yield call(fetch, authRequest(url));
        if (!res.ok) {
          throw new Error(yield createErrorMessage(res));
        }
        const data = yield call([res, 'json']);
        const { values, size } = data;
        items = items.concat(values);
        morePagesAvailable =
          size > currentPage * PIPELINE_DEFINITIONS_PAGE_SIZE;
      } catch (ignore) {
        morePagesAvailable = false;
      }
    }

    yield put({
      type: REQUEST_PIPELINE_DEFINITIONS.SUCCESS,
      meta: action.meta,
      payload: {
        values: items,
      },
    });
  } catch (e) {
    if (!isIgnoredErrorForSentry(e)) {
      capturePipelinesExceptionWithTags(e, {
        segment: REQUEST_PIPELINE_DEFINITIONS.ERROR,
      });
    }
    yield put({
      type: REQUEST_PIPELINE_DEFINITIONS.ERROR,
      meta: action.meta,
      payload: e,
    });
  }
}

export function* requestCurrentPipelineSaga(action: {
  meta: { pipelineUuid: string };
}): any {
  const { full_name } = yield select(getCurrentRepository);
  const { uuid } = yield select(getCurrentPipeline);
  try {
    const url = getPipelineUrl(full_name, action.meta.pipelineUuid || uuid);
    const res: Response = yield call(fetch, authRequest(url));
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }

    const data = yield call([res, 'json']);
    yield put({
      type: REQUEST_CURRENT_PIPELINE.SUCCESS,
      meta: action.meta,
      payload: data,
    });
  } catch (e) {
    if (!isIgnoredErrorForSentry(e)) {
      capturePipelinesExceptionWithTags(e, {
        segment: REQUEST_CURRENT_PIPELINE.ERROR,
      });
    }
    yield put({
      type: REQUEST_CURRENT_PIPELINE.ERROR,
      payload: e,
    });
  }
}

export function* requestCreatePipelineSaga(action: {
  meta: { targetPipeline: any };
}): any {
  const { full_name } = yield select(getCurrentRepository);
  try {
    const url = getPipelineUrl(full_name);
    const res: Response = yield call(
      fetch,
      authRequest(url, {
        method: 'POST',
        headers: jsonHeaders,
        body: JSON.stringify(action.meta.targetPipeline),
      })
    );
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const data = yield call([res, 'json']);

    yield put({
      type: REQUEST_CREATE_PIPELINE.SUCCESS,
      meta: action.meta,
      payload: data,
    });
  } catch (e) {
    if (!isIgnoredErrorForSentry(e)) {
      capturePipelinesExceptionWithTags(e, {
        segment: REQUEST_CREATE_PIPELINE.ERROR,
      });
    }
    yield put({
      type: REQUEST_CREATE_PIPELINE.ERROR,
      meta: action.meta,
      payload: e,
    });
  }
}

export function* requestStopPipelineSaga(action: {
  meta: { pipelineUuid: string };
}): any {
  const { full_name } = yield select(getCurrentRepository);
  try {
    const url = getStopPipelineUrl(full_name, action.meta.pipelineUuid);
    const res: Response = yield call(
      fetch,
      authRequest(url, {
        method: 'POST',
        headers: jsonHeaders,
      })
    );
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    yield put({
      type: REQUEST_STOP_PIPELINE.SUCCESS,
      meta: action.meta,
    });
  } catch (e) {
    if (!isIgnoredErrorForSentry(e)) {
      capturePipelinesExceptionWithTags(e, {
        segment: REQUEST_STOP_PIPELINE.ERROR,
      });
    }
    yield put({
      type: REQUEST_STOP_PIPELINE.ERROR,
      meta: action.meta,
      payload: e,
    });
  }
}

export function* requestCommitPullRequestsSaga(action: {
  meta: { revision: string };
}): any {
  const { full_name } = yield select(getCurrentRepository);
  try {
    const url = getCommitPullRequestsUrl(full_name, action.meta.revision);
    const res: Response = yield call(fetch, authRequest(url));
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const data = yield call([res, 'json']);

    yield put({
      type: REQUEST_COMMIT_PULL_REQUESTS.SUCCESS,
      meta: action.meta,
      payload: data,
    });
  } catch (e) {
    if (!isIgnoredErrorForSentry(e)) {
      capturePipelinesExceptionWithTags(e, {
        segment: REQUEST_COMMIT_PULL_REQUESTS.ERROR,
      });
    }
    yield put({
      type: REQUEST_COMMIT_PULL_REQUESTS.ERROR,
      meta: action.meta,
      payload: e,
    });
  }
}

export default function* (): Generator {
  yield all([
    takeLatest(REQUEST_START_STEP.SUCCESS, requestCurrentPipelineSaga),
    takeLatest(
      REQUEST_RESUME_STAGE_REDEPLOY.SUCCESS,
      requestCurrentPipelineSaga
    ),
    takeLatest(REQUEST_CURRENT_PIPELINE.REQUEST, requestCurrentPipelineSaga),
    takeLatest(REQUEST_PIPELINES.REQUEST, requestPipelinesSaga),
    takeLatest(REQUEST_CREATE_PIPELINE.REQUEST, requestCreatePipelineSaga),
    takeLatest(REQUEST_STOP_PIPELINE.REQUEST, requestStopPipelineSaga),
    takeLatest(REQUEST_PIPELINE_CREATORS.REQUEST, requestPipelineCreatorsSaga),
    takeLatest(
      REQUEST_COMMIT_PULL_REQUESTS.REQUEST,
      requestCommitPullRequestsSaga
    ),
    takeLatest(
      REQUEST_PIPELINE_DEFINITIONS.REQUEST,
      requestPipelineDefinitionsSaga
    ),
    debounce(
      DELAY_PIPELINE_UPDATED,
      PIPELINE_UPDATED,
      requestPipelineUpdatedSaga
    ),
  ]);
}
