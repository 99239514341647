import React, { FC } from 'react';

import { defineMessages } from 'react-intl';

import Button from '@atlaskit/button/standard-button';
import { ErrorMessage } from '@atlaskit/form';

import { useAnalytics } from 'src/components/settings/analytics';
import { BULK_SELECTION_LIMIT } from 'src/constants/settings';
import { useIntl } from 'src/hooks/intl';

import { useActions, usePermissionsContext } from './provider';
import { BulkActionsHeader } from './styled';

const messages = defineMessages({
  selectedItemsText: {
    id: 'frontbucket.settings.permissions.selectedItemsLabel',
    description:
      'Text for the count of selected rows that appears if at least 1 row is selected',
    defaultMessage:
      '{count} {count, plural, one {item} other {items}} selected',
  },
  selectedItemsMaxedOutError: {
    id: 'frontbucket.settings.permissions.selectedItemsMaxedOutError',
    description:
      'Text to show if the user has selected more than the maximum allows number of rows',
    defaultMessage:
      'Reduce the number of selected items to no more than {max}.',
  },
  clearAll: {
    id: 'frontbucket.settings.permissions.link.clearAll',
    description: 'Label for the "clear all" link button',
    defaultMessage: 'clear all',
  },
  editPermissionsButton: {
    id: 'frontbucket.settings.permissions.editPermissionsButton',
    description: 'Label for the "Edit permissions" button',
    defaultMessage: 'Edit permissions',
  },
  remove: {
    id: 'frontbucket.settings.permissions.actions.remove',
    description: 'Remove user/group from the list button label',
    defaultMessage: 'Remove',
  },
});

type Props = { checkedCount: number };

export const BulkActions: FC<Props> = ({ checkedCount, children }) => {
  const { formatMessage } = useIntl();
  const { publishButtonClickEvent } = useAnalytics();
  const { setState, setActiveModal } = useActions();
  const [{ context }] = usePermissionsContext();

  return (
    <BulkActionsHeader>
      {children}
      <strong>
        {formatMessage(messages.selectedItemsText, {
          count: checkedCount,
        })}
      </strong>
      <Button
        appearance="subtle-link"
        onClick={() => {
          setState({ checkedRows: [] });
          publishButtonClickEvent('ClearAll');
        }}
        spacing="compact"
        testId="bulk-action--clear-all"
      >
        {formatMessage(messages.clearAll)}
      </Button>
      {context !== 'workspace' && (
        <Button
          onClick={() => {
            setActiveModal('BulkChangePrivilegesModal');
            publishButtonClickEvent('BulkEdit');
          }}
          spacing="compact"
          isDisabled={checkedCount > BULK_SELECTION_LIMIT}
          testId="bulk-action--edit"
        >
          {formatMessage(messages.editPermissionsButton)}
        </Button>
      )}
      <Button
        onClick={() => {
          setActiveModal('RemoveAccessModal');
          publishButtonClickEvent('BulkRemove');
        }}
        spacing="compact"
        isDisabled={checkedCount > BULK_SELECTION_LIMIT}
        testId="bulk-action--remove"
      >
        {formatMessage(messages.remove)}
      </Button>
      {checkedCount > BULK_SELECTION_LIMIT && (
        <ErrorMessage>
          {formatMessage(messages.selectedItemsMaxedOutError, {
            max: BULK_SELECTION_LIMIT,
          })}
        </ErrorMessage>
      )}
    </BulkActionsHeader>
  );
};
