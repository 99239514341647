import { AppData } from 'src/app/data';

import { RouteFeatures } from '../routes/types';

export const getRouteFeaturesFromAppData = (
  appData: AppData
): RouteFeatures => ({
  isWorkspaceSettingsIndexEnabled:
    appData.features['spa-ws-settings--settings-index'],
  isRepoAdminAliasesEnabled:
    appData.features['spa-repo-settings--username-aliases'],
  isRepoAdminComplianceEnabled:
    appData.features['spa-repo-settings--compliance'],
});
