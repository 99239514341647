// @ts-ignore TODO: fix noImplicitAny error here
import ConnectHostPrev from 'atlassian-connect-js';
// @ts-ignore TODO: fix noImplicitAny error here
import ConnectHostNext from 'atlassian-connect-js-next';

import assignLinkHandlers from '../../plugin/links';
import { ConnectModuleLinkData } from '../../types';
import { flatMerge, getInitialOrBucketState } from '../../utils';
import { getSharedState } from '../sharedState';

let isACJSNextEnabled = false;

try {
  const acjsNextFeature =
    !!getInitialOrBucketState().global.features['acjs-next'];
  if (acjsNextFeature) {
    isACJSNextEnabled = true;
  }
} catch (_error) {
  // TODO: BBCEEP-3380 Implement error monitoring
}

let ConnectHost: any;

if (isACJSNextEnabled) {
  ConnectHost = ConnectHostNext;
} else {
  ConnectHost = ConnectHostPrev;
}

let cmdOrCtrl = false;

export function setConnectLinkMetaKey(isSelected: boolean) {
  cmdOrCtrl = isSelected;
}

export function handleScrollEvent() {
  const inlineDialogProvider =
    ConnectHost.frameworkAdaptor.getProviderByModuleName('inlineDialog');
  if (inlineDialogProvider && inlineDialogProvider.isOpen()) {
    inlineDialogProvider.close();
  }
}

export function handleConnectLink(linkData: Partial<ConnectModuleLinkData>) {
  if (
    !linkData ||
    !linkData.href ||
    !(
      linkData.href.startsWith('http://') ||
      linkData.href.startsWith('https://')
    )
  ) {
    return;
  }
  if (cmdOrCtrl) {
    // Passing the opener object would allow the destination to modify Bitbucket's window object,
    // so we should not pass it through to the new window.
    window.open(linkData.href, undefined, 'noopener');
  } else {
    window.location.href = linkData.href;
  }
  cmdOrCtrl = false;
}

export function createDialog(linkData: ConnectModuleLinkData) {
  const data: Partial<ConnectModuleLinkData> = flatMerge(
    getSharedState(),
    linkData
  );
  if (data.href && cmdOrCtrl) {
    handleConnectLink(data);
    return;
  }
  const dialogProvider =
    ConnectHost.frameworkAdaptor.getProviderByModuleName('dialog');
  if (dialogProvider) {
    if (dialogProvider.isOpen()) {
      const extension = dialogProvider.getActiveDialogExtension();
      if (!extension) {
        throw new Error('Extension require to close connect dialog.');
      }
      // close existing dialog before opening a new one
      ConnectHost.dialog.close(extension.addon_key, extension);
      // A slight delay is needed to prevent atlassian-connect-js throwing a dialog not found exception.
      // There is no way to hook into the connect 'dialog.close' event on the host side. That would be preferred.
      setTimeout(() => dialogProvider.createDialogFromLinker(data), 100);
      return;
    }
    dialogProvider.createDialogFromLinker(data);
  }
}

export function createInlineDialog(linkData: ConnectModuleLinkData) {
  const data: Partial<ConnectModuleLinkData> = flatMerge(
    getSharedState(),
    linkData
  );
  if (data.href && cmdOrCtrl) {
    handleConnectLink(data);
    return;
  }
  const inlineDialogProvider =
    ConnectHost.frameworkAdaptor.getProviderByModuleName('inlineDialog');
  if (inlineDialogProvider) {
    if (!data.extensionId) {
      inlineDialogProvider.createGlobal(data);
      return;
    }
    const iframe = document.getElementById(data.extensionId);
    if (!iframe) {
      return;
    }
    const pos = iframe.getBoundingClientRect();
    const { top, left } = data.position || { top: 0, left: 0 };
    inlineDialogProvider.createGlobal({
      ...data,
      position: {
        ...data.position,
        top: pos.top + top,
        left: pos.left + left,
      },
    });
  }
}

// This sets up linkers locally within frontbucket
// Otherwise, handlers are called over the bridge
assignLinkHandlers(
  {
    links: {
      setConnectLinkMetaKey,
      handleConnectLink,
      createDialog,
      createInlineDialog,
    },
  },
  true
);
