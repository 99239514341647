import {
	type PluginDimensions as AtlaskitPluginDimensions,
	type PluginEnv as AtlaskitPluginEnv,
	type PluginVariant as AtlaskitPluginVariant,
	type DependencyMap,
	ExternalModuleName,
	type ManifestMap,
	type ModuleUriMapper,
	PluginName,
	type RemoteModuleConfig,
	type RemotePlugin,
} from '@atlassian/remote-module-loader/types';
import memoizeOne from 'memoize-one';
import { version as REACT_VERSION } from 'react';

export type PluginEnv = AtlaskitPluginEnv;
export type PluginVariant = AtlaskitPluginVariant;

export interface PluginDimensions extends AtlaskitPluginDimensions {
	readonly placementId: string;
}

const VARIANT_LATEST: PluginVariant = 'latest';
const VARIANT_STABLE: PluginVariant = 'stable';

class RemotePostOfficePlugin implements RemotePlugin {
	public readonly name = PluginName.PostOffice;
	public readonly placementId: string;

	private readonly _dimensions: AtlaskitPluginDimensions;
	private readonly _manifestMap: ManifestMap;

	constructor(
		{ placementId, ...baseDimensions }: PluginDimensions,
		overrides?: { moduleUri: string },
	) {
		this.placementId = placementId;
		this._dimensions = baseDimensions;
		this._manifestMap = new Map([
			[
				'local',
				new Map([
					[
						VARIANT_LATEST,
						{
							moduleName: MODULE_NAME,
							moduleUri: `${overrides?.moduleUri ?? 'http://localhost:9009/'}${placementId}`,
							externals: EXTERNALS,
						},
					],
				]),
			],
			[
				'test',
				new Map([
					[
						VARIANT_LATEST,
						{
							moduleName: MODULE_NAME,
							moduleUri: `${overrides?.moduleUri ?? 'http://localhost:9009/'}${placementId}`,
							externals: EXTERNALS,
						},
					],
				]),
			],
			[
				'staging',
				new Map([
					[
						VARIANT_LATEST,
						{
							moduleName: MODULE_NAME,
							moduleUri: `${MODULE_URI_STG}/${placementId}`,
							externals: EXTERNALS,
						},
					],
					[
						VARIANT_STABLE,
						{
							moduleName: MODULE_NAME,
							moduleUri: `${MODULE_URI_STG}/${placementId}`,
							externals: EXTERNALS,
						},
					],
				]),
			],
			[
				'production',
				new Map([
					[
						VARIANT_LATEST,
						{
							moduleName: MODULE_NAME,
							moduleUri: `${MODULE_URI_PROD}/${placementId}`,
							externals: EXTERNALS,
						},
					],
					[
						VARIANT_STABLE,
						{
							moduleName: MODULE_NAME,
							moduleUri: `${MODULE_URI_PROD}/${placementId}`,
							externals: EXTERNALS,
						},
					],
				]),
			],
		]);
	}

	get dimensions(): AtlaskitPluginDimensions {
		return this._dimensions;
	}

	get manifestMap(): ManifestMap {
		return this._manifestMap;
	}

	get bifrostOverrideKey() {
		return `${this.placementId}-bifrost-override`;
	}

	public moduleUriMapper = memoizeOne<ModuleUriMapper>((uri, variant = 'latest', computeHash) => {
		// The goal here is to take a version string of the form "18.1.2"
		// and get only the major version part.
		// eslint-disable-next-line @typescript-eslint/no-magic-numbers
		const reactMajorVersion = REACT_VERSION.split('.')[0] as string;

		// We want to hash the major version of React installed, alongside the chosen variant
		const hashedDependencies = ['react', 'react-dom'];
		const hashedValues = hashedDependencies.reduce<DependencyMap[]>(
			(mappings, dependency) => mappings.concat([{ name: dependency, version: reactMajorVersion }]),
			[],
		);

		const hash = computeHash(variant, hashedValues);

		if (process.env['NODE_ENV'] === 'development') {
			// eslint-disable-next-line no-console
			console.debug(
				`[@post-office/remote-module-loader-plugin]: Computed hash for plugin [${
					this.constructor.name
				}] with variant [${variant}] and values ${JSON.stringify(hashedValues)}.`,
			);
		}

		return `${uri}.${hash}.js`;
	});
}

const MODULE_NAME = '@atlassian/post-office-frontend';
const MODULE_URI_STG = 'https://post-office-rml-frontend.stg-east.frontend.public.atl-paas.net';
const MODULE_URI_PROD = 'https://post-office-rml-frontend.prod-east.frontend.public.atl-paas.net';

export type Externals = ExternalModuleName | '@atlassiansox/cross-flow-support';

const EXTERNALS: RemoteModuleConfig<Externals>['externals'] = [
	{ name: ExternalModuleName.React },
	{ name: ExternalModuleName.ReactDOM },
	{ name: ExternalModuleName.ReactIntlNext },
	{ name: ExternalModuleName.EmotionReact },
	{ name: ExternalModuleName.FeatureGateJsClientPublic },
	{
		module: '@atlassiansox/cross-flow-support',
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		import: require('@atlassiansox/cross-flow-support'),
		isDefault: false,
	},
	// Long term we believe that we will want to include additional
	// dependencies to this list (such as heavy weight Atlaskit
	// components) but we want to wait until we know which components
	// are worth excluding.
];

export default RemotePostOfficePlugin;
