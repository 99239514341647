export const FeatureKeys = {
  isExampleFlag: 'example-flag',
  bbogDiscoverExperiment: 'bb.og.discover',
  pipelinesArtifactPreviewSizeLimit: 'pipelines-artifact-preview-size-limit',
  useExperimentApiRecommendations: 'use-experiment-api-recommendations',
  isNudgeReviewersEnabled: 'pr-nudge-reviewers',
  isProjectSettingsInheritanceProviderEnabled:
    'project-settings-inheritance-provider',
  enableRunPipelineFromBranchPage: 'run-pipelines-from-single-branch-page',
  pipelinesMaxStepDuration: 'pipelines-max-step-duration',
  pipelinesMaximumAllowedStepSizeForCloudRunners:
    'pipelines-maximum-allowed-step-size-for-cloud-runners',
  isDeploymentsFilteringEnabled: 'deployments-filtering',
  isPipelinesManualStepsInStagesEnabled:
    'pipelines-allow-manual-steps-in-stages',
  isPipelinesEditorInFrontbucketEnabled: 'pipelines-editor-ui-in-frontbucket',
  pipelinesEditorDarkModeEnabled:
    'bitbucket-pipelines-editor-dark-mode-enabled',
  isPipelinesSettingsInFrontbucketEnabled:
    'pipelines-settings-ui-in-frontbucket',
  isBuildLinkRedirectionFixEnabled: 'build-link-redirection-fix',
  isPipelinesRunnersSettingsInFrontbucketEnabled:
    'pipelines-runners-settings-ui-in-frontbucket',
  incrementalRequestEnabled: 'bitbucket-pipelines-incremental-log-request',
  isSagaLinkifyDisabled: 'bitbucket-pipelines-disable-saga-linkify',
  isPipelinesDeploymentsSettingsInFrontbucketEnabled:
    'pipelines-deployments-settings-ui-in-frontbucket',
  scrollingFixEnabled:
    'bitbucket-pipelines-fix-scrolling-effect-when-streaming',
  isPipelinesSecuredPrefixedVariablesEnabled:
    'pipelines-secured-prefixed-variables',
  isPipelinesMacOSTartRunnerEnabled: 'pipelines-mac-os-tart-runner',
  isPipelinesRunnerUuidDisplayEnabled:
    'pipelines-enable-runner-table-uuid-display',
  isRunPipelineModalInFrontbucket:
    'pipelines-run-pipeline-modal-in-frontbucket',
  isStepMetricsEnabled: 'pipelines-step-metrics-enabled',
  isPipelineTypeHandleSpecialChars:
    'pipelines-pipeline-type-filter-handle-special-chars',
  isPrDependenciesEnabled: 'pr-dependencies',
  isPrReviewGroupsEnabled: 'pr-review-groups',
  isPipelinesStickyEnvParamEnabled: 'pipelines-sticky-env-parameter',
  isPipelineListSidebarEnabled: 'enable-pipeline-list-sidebar',
  isPipelineRunsHistoryEnabled: 'enable-pipeline-runs-history',
  isPipelinesPageLoadOptimisationEnabled:
    'enable-pipelines-page-load-optimisation',
  enableSendingDefaulVariableValues:
    'enable-sending-default-variables-for-run-pipeline-modal',
  sumCreateWorkspacePreChangeEnabled:
    'ADMINX-1261-workspaces-list-sum-pre-change',
  enableAddingVariablesFix: 'adding-variables-in-editor-fix',
  useUpdatedStepDurationFormat: 'pipelines-update-stage-step-duration-format',
  enableImprovedErrorMapping: 'pipelines-improved-error-mapping',
  enableCustomCronSupport: 'bitbucket-pipelines-custom-cron-pattern-support',
  enablePersistPipelineListFilters: 'pipelines-persist-pipeline-list-filters',
  isDescriptionEnabled: 'bitbucket-pipelines-variable-description',
  logLineWrapped: 'platform.pipelines.log-line-wrapped',
  descriptivePrUpdateFlag: 'descriptive-pr-update-flag',
  darkModePOC: 'dark-mode-poc',
  enableHandleAllowanceResponse: 'handle-allowance-returning-404',
  extensibleMergeChecksEnabled: 'extensible-merge-checks-enabled',
  enableDiscoverySdk: 'bitbucket.app-recommendations.discovery-sdk',
  enableDiscoverySdkIAllLocales:
    'bitbucket.app-recommendations.discovery-sdk.all-locale',
  clearPrFilter: 'clear-pr-filter',
  updatedStepList: 'updated-pipelines-step-list',
  enableStashCommitLink: 'pipelines-add-stash-commit-link',
  showShadowRepositoryConfiguration: 'show-shadow-repository-configuration',
  cookieConsentBannerEnabled: 'platform.moonjelly.browser-storage-controls',
  postOfficeInAppSwitcher: 'post-office-in-bitbucket-switcher',
  markFilesNewFeatures: 'mark-files-new-features',
  compassXFlowUI: 'bbc-compass-x-flow-ui',
  compassEditableEmptyState: 'bbc-compass-new-empty-state-side-card',
  compassXpSearch: 'compass-in-cross-product-search',
  compassXpSearchNonAdmin: 'compass-in-cross-product-search-non-admin',
  disableCompassBitbucketRepositoryCard:
    'disable-compass-bitbucket-repository-card',
  usePRServiceIR: 'ir-use-pr-service',
  isDefaultDisableFlagsForPdvsEnabled: 'default-disable-flags-for-pdvs',
  enableAutoreviewFeedbackUi: 'autoreview-feedback-ui_0ya4n',
  enableChoreographer: 'post-office_enable_bitbucket_choreographer_98iep',
  editorDeprecateDangerousPlugins: 'editor.deprecate-dangerous-plugins',
  staleBranchesFilter: 'stale-branches-filter',
  isNavThemeSelectorEnabled: 'enable-navigation-color-theme-picker',
} as const;

export type FeatureKeyType = (typeof FeatureKeys)[keyof typeof FeatureKeys];

export const StatsigFeatureKeys = {
  bbcNewNav: 'bbc_new_nav',
  isPipelinesStepFilterEnabled: 'enable_pipelines_step_filter',
  prCreateDeleteBranchDefault: 'bbc-frontbucket-default-delete-branch',
  useConsolidatedForgeRenderer: 'forge-ui-use-consolidated-renderer',
  useCustomMergeCheckResultEnforcement:
    'use-custom-merge-check-result-enforcement',
} as const;

export type StatsigFeatureKeyType =
  (typeof StatsigFeatureKeys)[keyof typeof StatsigFeatureKeys];
