/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useModal } from './hooks';
import { keylineHeight, padding } from './internal/constants';
var footerStyles = css({
  display: 'flex',
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
  padding: padding,
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
  paddingTop: "".concat(padding - keylineHeight, "px"),
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: "var(--ds-space-100, 8px)"
});
/**
 * __Modal footer__
 *
 * A modal footer often contains a primary action and the ability to cancel and close the dialog, though can contain any React element.
 *
 * - [Examples](https://atlassian.design/components/modal-dialog/examples#modal-footer)
 * - [Code](https://atlassian.design/components/modal-dialog/code#modal-footer-props)
 * - [Usage](https://atlassian.design/components/modal-dialog/usage)
 */
var ModalFooter = function ModalFooter(props) {
  var children = props.children,
    userDefinedTestId = props.testId;
  var _useModal = useModal(),
    modalTestId = _useModal.testId;
  var testId = userDefinedTestId || modalTestId && "".concat(modalTestId, "--footer");
  return jsx("div", {
    css: footerStyles,
    "data-testid": testId
  }, children);
};
export default ModalFooter;