import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * Handle controlled & uncontrolled component state
 *
 * ```js
 *  const [uncontrolledState, setUncontrolledState] = useControlled(
 *    controlledValue,
 *    () => defaultValue,
 *  );
 * ```
 *
 * @param propValue
 * @param getDefaultPropValue
 */
export default function useControlled(propValue) {
  var getDefaultPropValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {
    return propValue;
  };
  var isControlled = propValue !== undefined;
  var _useState = useState(getDefaultPropValue),
    _useState2 = _slicedToArray(_useState, 2),
    valueState = _useState2[0],
    setValue = _useState2[1];
  var isControlledRef = useRef(isControlled);
  useEffect(function () {
    isControlledRef.current = isControlled;
  }, [isControlled]);
  var value = isControlled ? propValue : valueState;
  var setValueIfUncontrolled = useCallback(function (newValue) {
    if (!isControlledRef.current) {
      setValue(newValue);
    }
  }, []);
  return [value, setValueIfUncontrolled];
}