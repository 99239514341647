import { bind } from 'bind-event-listener';
import { setConfig } from './utils/constants';
import { Logger } from './utils/logger';
import { getNetworkIdleObservable } from './utils/network-idle-observable';
import { getVisuallyCompleteCalculator } from './utils/visually-complete-calculator';
var calculator;
var whenActivated = function whenActivated(callback) {
  if (document.prerendering) {
    bind(window, {
      type: 'prerenderingchange',
      listener: callback,
      options: {
        capture: true
      }
    });
  } else {
    callback();
  }
};

/**
 *  Start ttvc and begin monitoring network activity and visual changes.
 */
export var initTTVC = function initTTVC(options) {
  // apply options
  setConfig(options);
  Logger.info('init()');
  calculator = getVisuallyCompleteCalculator();
  whenActivated(function () {
    void calculator.start();

    // restart measurement for SPA navigation
    bind(window, {
      type: 'locationchange',
      listener: function listener(event) {
        return void calculator.start(event.timeStamp);
      }
    });

    // restart measurement on back/forward cache page restoration
    bind(window, {
      type: 'pageshow',
      listener: function listener(event) {
        // abort if this is the initial pageload
        if (!event.persisted) {
          return;
        }
        void calculator.start(event.timeStamp, true);
      }
    });
  });
};

/**
 * Subscribe to notifications about TTVC metrics.
 *
 * NOTE: init() must be called before registering a TTVC subscriber.
 *
 * @example
 * const unsubscribe = onTTVC(ms => console.log(ms));
 *
 * @param successCallback Triggered once for each navigation instance when TTVC was successfully captured.
 * @param [errorCallback] Triggered when TTVC failed to capture
 * @returns A function that unsubscribes the callback from this metric.
 */
export var onTTVC = function onTTVC(successCallback, errorCallback) {
  var _calculator;
  return (_calculator = calculator) === null || _calculator === void 0 ? void 0 : _calculator.onTTVC(successCallback, errorCallback);
};

/**
 * Begin measuring a new navigation.
 *
 * Call this method to notify ttvc that a new client-side navigation has begun.
 *
 * *DO NOT* call `start()` on initial pageload.
 *
 * If you don't have access to the TTVC library in your product code, you can
 * trigger the same behaviour by dispatching a custom 'locationchange' event
 * (See README.md for an example of usage).
 */
export var startTTVC = function startTTVC() {
  var _calculator2;
  return (_calculator2 = calculator) === null || _calculator2 === void 0 ? void 0 : _calculator2.start(performance.now());
};
export var getLastVisibleChangeTimestamp = function getLastVisibleChangeTimestamp() {
  var _calculator3;
  return (_calculator3 = calculator) === null || _calculator3 === void 0 ? void 0 : _calculator3.getLastVisibleChangeTimestamp();
};

/**
 * Abort the current TTVC measurement.
 *
 * This method is provided as an escape hatch. Consider using it to notify
 * @dropbox/ttvc that a user interaction has occurred and continuing the
 * measurement may produce an invalid result.
 *
 * @param eventType The type of event that triggered the cancellation. This will be logged to the error callback.
 */
export var cancelTTVC = function cancelTTVC(eventType) {
  var _calculator4;
  return (_calculator4 = calculator) === null || _calculator4 === void 0 ? void 0 : _calculator4.cancel(eventType);
};

/**
 * Call this to notify ttvc that an AJAX request has just begun.
 *
 * Instrument your site's AJAX requests with `incrementAjaxCount` and
 * `decrementAjaxCount` to ensure that ttvc is not reported early.
 *
 * For the most accurate results, `decrementAjaxCount` should be called
 * **exactly once** for each `incrementAjaxCount`.
 */
export var incrementAjaxCount = function incrementAjaxCount() {
  var networkIdleObservable = getNetworkIdleObservable();
  networkIdleObservable.incrementAjaxCount();
};

/**
 * Call this to notify ttvc that an AJAX request has just resolved.
 *
 * Instrument your site's AJAX requests with `incrementAjaxCount` and
 * `decrementAjaxCount` to ensure that ttvc is not reported early.
 *
 * For the most accurate results, `decrementAjaxCount` should be called
 * **exactly once** for each `incrementAjaxCount`.
 */
export var decrementAjaxCount = function decrementAjaxCount() {
  var networkIdleObservable = getNetworkIdleObservable();
  networkIdleObservable.decrementAjaxCount();
};