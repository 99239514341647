import _extends from "@babel/runtime/helpers/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
var _templateObject, _templateObject2;
/** @jsx jsx */
import { useCallback, useRef, useState } from 'react';
import { css, jsx } from '@emotion/core';
import { Transition } from 'react-transition-group';
import Button from '@atlaskit/button/custom-theme-button';
import { Checkbox } from '@atlaskit/checkbox';
import Form, { CheckboxField, Field, FormFooter } from '@atlaskit/form';
import Textarea from '@atlaskit/textarea';
import { fontSize } from '@atlaskit/theme/constants';
import FeedbackScoreButtons from './FeedbackScoreButtons';
var getExpandedHeight = function getExpandedHeight(ref, state) {
  if (!ref.current) {
    return '0';
  }
  switch (state) {
    case 'entering':
      return "".concat(ref.current.scrollHeight, "px");
    case 'entered':
      // needed for TextField auto height expand
      return "none";
    default:
      return '0';
  }
};
var transitionDuration = 200;
export default (function (_ref) {
  var question = _ref.question,
    statement = _ref.statement,
    textPlaceholder = _ref.textPlaceholder,
    textLabel = _ref.textLabel,
    onSubmit = _ref.onSubmit;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    expanded = _useState2[0],
    setExpanded = _useState2[1];
  var _useState3 = useState(false),
    _useState4 = _slicedToArray(_useState3, 2),
    canContactDefault = _useState4[0],
    setCanContactDefault = _useState4[1];
  var hasAutoFilledCanContactRef = useRef(false);
  var expandedAreaRef = useRef(null);
  var onScoreSelect = useCallback(function () {
    setExpanded(true);
  }, [setExpanded]);

  // On the first type the user types some feedback we auto select
  // the option for allowing feedback. This automatic selection only
  // happens once. After that it is up to the user to control
  var onFeedbackChange = useCallback(function () {
    if (hasAutoFilledCanContactRef.current) {
      return;
    }
    hasAutoFilledCanContactRef.current = true;
    setCanContactDefault(true);
  }, []);
  return jsx("section", {
    "aria-labelledby": "contextualSurveyQuestion"
  }, jsx("h1", {
    id: "contextualSurveyQuestion",
    css: css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n          font-size: ", "px;\n          font-weight: 600;\n        "])), fontSize())
  }, question), statement && jsx("p", {
    id: "contextualSurveyStatement"
  }, statement), jsx(Form, {
    onSubmit: onSubmit
  }, function (_ref2) {
    var formProps = _ref2.formProps,
      submitting = _ref2.submitting;
    return jsx("form", formProps, jsx(Field, {
      name: "feedbackScore",
      isDisabled: submitting,
      isRequired: true
    }, function (_ref3) {
      var fieldProps = _ref3.fieldProps;
      return jsx(FeedbackScoreButtons, _extends({}, fieldProps, {
        onChange: function onChange(score) {
          fieldProps.onChange(score);
          onScoreSelect();
        }
      }));
    }), jsx(Transition, {
      in: expanded,
      timeout: transitionDuration,
      mountOnEnter: true
    }, function (state) {
      return jsx("div", {
        css: css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n                    transition: max-height ", "ms ease-in-out;\n                    overflow: hidden;\n                    max-height: ", ";\n                  "])), transitionDuration, getExpandedHeight(expandedAreaRef, state)),
        ref: expandedAreaRef
      }, jsx(Field, {
        name: "writtenFeedback",
        defaultValue: "",
        isDisabled: submitting
      }, function (_ref4) {
        var fieldProps = _ref4.fieldProps;
        return jsx(Textarea, _extends({}, fieldProps, {
          "aria-label": textLabel,
          placeholder: textPlaceholder,
          onChange: function onChange(event) {
            fieldProps.onChange(event);
            onFeedbackChange();
          }
        }));
      }), jsx(CheckboxField, {
        name: "canContact",
        isDisabled: submitting,
        defaultIsChecked: canContactDefault
      }, function (_ref5) {
        var fieldProps = _ref5.fieldProps;
        return jsx(Checkbox, _extends({}, fieldProps, {
          label: "Atlassian can contact me about this feedback"
        }));
      }), jsx(FormFooter, null, jsx(Button, {
        type: "submit",
        appearance: "primary",
        isLoading: submitting
      }, "Submit")));
    }));
  }));
});