import defaults from '../defaults';

const namedMutator = (storeState, actionName) => function () {
  storeState.mutator.actionName = actionName;
  return storeState.mutator(...arguments);
};

const warnings = new WeakMap();
export const bindAction = function bindAction(storeState, actionFn, actionKey, getContainerProps, boundActions) {
  if (getContainerProps === void 0) {
    getContainerProps = () => {};
  }

  if (boundActions === void 0) {
    boundActions = {};
  }

  // Setting mutator name so we can log action name for better debuggability
  const dispatch = function dispatch(thunkFn, actionName) {
    if (actionName === void 0) {
      actionName = `${actionKey}.dispatch`;
    }

    return thunkFn({
      setState: defaults.devtools ? namedMutator(storeState, actionName) : storeState.mutator,
      getState: storeState.getState,

      get actions() {
        if (!warnings.has(actionFn)) {
          warnings.set(actionFn, console.warn(`react-sweet-state 'actions' property has been deprecated and will be removed in the next mayor. ` + `Please check action '${actionName}' of Store '${storeState.key}' and use 'dispatch' instead`));
        }

        return boundActions;
      },

      dispatch
    }, getContainerProps());
  };

  return function () {
    return dispatch(actionFn(...arguments), actionKey);
  };
};
export const bindActions = function bindActions(actions, storeState, getContainerProps, boundActions) {
  if (getContainerProps === void 0) {
    getContainerProps = () => ({});
  }

  if (boundActions === void 0) {
    boundActions = null;
  }

  return Object.keys(actions).reduce((acc, k) => {
    acc[k] = bindAction(storeState, actions[k], k, getContainerProps, boundActions || acc);
    return acc;
  }, {});
};