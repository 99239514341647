/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import * as Sentry from '@sentry/browser';
import { select, call, put, take } from 'redux-saga/effects';

import { Repository } from 'src/components/types';
import { commit as commitSchema } from 'src/redux/pull-request/schemas';
import { PR_UPDATE_COMMITS_STATUS } from 'src/redux/repo-commits/actions';
import { getRepoCommitsState } from 'src/redux/repo-commits/selectors';
import { createCommitStatusMapper } from 'src/redux/repository/utils';
import { LoadRepositoryPage } from 'src/sections/repository/actions';
import { getCurrentRepository } from 'src/selectors/repository-selectors';
import urls from 'src/urls/commit';
import authRequest from 'src/utils/fetch';

export const createRequest = (
  fullName: string,
  // @ts-ignore TODO: fix noImplicitAny error here
  commits: { hash }[]
): Request => {
  const url = urls.api.internal.commitsBuildStatuses(fullName);

  return authRequest(url, {
    method: 'POST',
    body: commits.map(commit => `commits=${commit.hash}`).join('&'),
    headers: {
      'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
  });
};

export function* fetchCommitsStatusesSaga() {
  const { commits } = yield select(getRepoCommitsState);

  if (!commits || !commits.length) {
    return;
  }

  let repository: Repository = yield select(getCurrentRepository);

  if (!repository) {
    yield take(LoadRepositoryPage.SUCCESS);
    repository = yield select(getCurrentRepository);
  }

  const { full_name: fullName } = repository;

  try {
    const request = createRequest(fullName, commits);

    const response: Response = yield call(fetch, request);

    if (response.ok) {
      // @ts-ignore
      const statuses = yield response.json();

      // Statuses is a dictionary mapping commit SHAs to statuses
      if (Object.keys(statuses).length === 0) {
        return;
      }

      const addStatus = createCommitStatusMapper(statuses);

      yield put({
        type: PR_UPDATE_COMMITS_STATUS.SUCCESS,
        payload: commits.map(addStatus),
        meta: {
          schema: [commitSchema],
        },
      });
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}
