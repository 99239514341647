export var bulletListSelector = '.ak-ul';
export var bulletList = {
  group: 'block',
  content: 'listItem+',
  selectable: false,
  parseDOM: [{
    tag: 'ul'
  }],
  marks: 'unsupportedMark unsupportedNodeAttribute',
  toDOM: function toDOM() {
    var attrs = {
      class: bulletListSelector.substr(1)
    };
    return ['ul', attrs, 0];
  }
};