/**
 * @name heading_node
 */

/**
 * @name heading_with_no_marks_node
 */

// Check `paragraph` node for why we are doing things like this
/**
 * @name heading_with_alignment_node
 */

/**
 * @name heading_with_indentation_node
 */

var getAttrs = function getAttrs(level) {
  return function (domNode) {
    var _heading$attrs, _heading$attrs$localI;
    return {
      level: level,
      localId: domNode.getAttribute('data-local-id') || ((_heading$attrs = heading.attrs) === null || _heading$attrs === void 0 ? void 0 : (_heading$attrs$localI = _heading$attrs.localId) === null || _heading$attrs$localI === void 0 ? void 0 : _heading$attrs$localI.default)
    };
  };
};
export var headingStage0 = {
  attrs: {
    level: {
      default: 1
    },
    localId: {
      default: null
    }
  },
  content: "inline*",
  group: 'block',
  defining: true,
  selectable: false,
  parseDOM: [{
    tag: 'h1',
    getAttrs: getAttrs(1)
  }, {
    tag: 'h2',
    getAttrs: getAttrs(2)
  }, {
    tag: 'h3',
    getAttrs: getAttrs(3)
  }, {
    tag: 'h4',
    getAttrs: getAttrs(4)
  }, {
    tag: 'h5',
    getAttrs: getAttrs(5)
  }, {
    tag: 'h6',
    getAttrs: getAttrs(6)
  }],
  toDOM: function toDOM(node) {
    var _node$attrs = node.attrs,
      level = _node$attrs.level,
      localId = _node$attrs.localId;
    var name = 'h' + level;
    var attrs = localId !== undefined && localId !== null ? [{
      'data-local-id': localId
    }] : [];
    return [name].concat(attrs, [0]);
  }
};
export var heading = {
  attrs: {
    level: {
      default: 1
    }
  },
  content: "inline*",
  group: 'block',
  defining: true,
  selectable: false,
  parseDOM: [{
    tag: 'h1',
    attrs: {
      level: 1
    }
  }, {
    tag: 'h2',
    attrs: {
      level: 2
    }
  }, {
    tag: 'h3',
    attrs: {
      level: 3
    }
  }, {
    tag: 'h4',
    attrs: {
      level: 4
    }
  }, {
    tag: 'h5',
    attrs: {
      level: 5
    }
  }, {
    tag: 'h6',
    attrs: {
      level: 6
    }
  }],
  toDOM: function toDOM(node) {
    return ['h' + node.attrs['level'], 0];
  }
};
export var headingNodeSpec = function headingNodeSpec() {
  var adfStage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'full';
  return adfStage === 'stage0' ? headingStage0 : heading;
};