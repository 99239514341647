import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useRef, useState } from 'react';

/**
 * Provides a boolean value for whether an element is focused.
 * The `bindFocus` function returned provides two event handlers:
 * - `onFocus` and `onBlur` which when triggered set `isFocused` accordingly.
 */
export default function useFocus() {
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    isFocused = _useState2[0],
    setIsFocused = _useState2[1];

  // ensure bindFocus has a stable ref
  var bindFocus = useRef({
    onFocus: function onFocus() {
      return setIsFocused(true);
    },
    onBlur: function onBlur() {
      return setIsFocused(false);
    }
  });
  useEffect(function () {
    // handle the case where a component might
    // unmount while being focused.
    return function () {
      return setIsFocused(false);
    };
  }, []);
  return {
    isFocused: isFocused,
    bindFocus: bindFocus.current
  };
}