import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
export var orderedListSelector = '.ak-ol';
export var orderedList = {
  group: 'block',
  attrs: {
    order: {
      default: 1
    }
  },
  content: 'listItem+',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  selectable: false,
  parseDOM: [{
    tag: 'ol'
  }],
  toDOM: function toDOM() {
    var attrs = {
      class: orderedListSelector.substr(1)
    };
    return ['ol', attrs, 0];
  }
};

// resolve "start" to a safe, 0+ integer, otherwise return undefined
// Note: Any changes to this function should also be made to "resolveOrder"
// in packages/editor/editor-common/src/utils/list.ts
var resolveStart = function resolveStart(start) {
  var num = Number(start);
  if (Number.isNaN(num)) {
    return;
  }
  if (num < 0) {
    return;
  }
  return Math.floor(Math.max(num, 0));
};
export var orderedListWithOrder = _objectSpread(_objectSpread({}, orderedList), {}, {
  parseDOM: [{
    tag: 'ol',
    getAttrs: function getAttrs(domNode) {
      var dom = domNode;
      var startDOMAttr = dom.getAttribute('start');
      if (startDOMAttr) {
        var start = resolveStart(startDOMAttr);
        if (typeof start === 'number') {
          return {
            order: start
          };
        }
      }
      return null;
    }
  }],
  toDOM: function toDOM(node) {
    var _node$attrs;
    var start = resolveStart(node === null || node === void 0 ? void 0 : (_node$attrs = node.attrs) === null || _node$attrs === void 0 ? void 0 : _node$attrs.order);
    var attrs = {
      start: typeof start === 'number' ? String(start) : undefined,
      class: orderedListSelector.substr(1)
    };
    return ['ol', attrs, 0];
  }
});