// File has been copied to packages/editor/editor-plugin-ai/src/provider/prosemirror-transformer/utils/list.ts
// If changes are made to this file, please make the same update in the linked file.

export var DEFAULT_ORDER = 1;

// resolve "order" to a safe, 0+ integer, otherwise return undefined
// Note: Any changes to this function should also be made to "resolveStart"
// in packages/editor/adf-schema/src/schema/nodes/ordered-list.ts
export var resolveOrder = function resolveOrder(order) {
  var num = Number(order);
  if (Number.isNaN(num)) {
    return;
  }
  if (num < 0) {
    return;
  }
  return Math.floor(Math.max(num, 0));
};
export var getOrderFromOrderedListNode = function getOrderFromOrderedListNode(orderedListNode) {
  var _orderedListNode$attr, _resolveOrder;
  var order = orderedListNode === null || orderedListNode === void 0 || (_orderedListNode$attr = orderedListNode.attrs) === null || _orderedListNode$attr === void 0 ? void 0 : _orderedListNode$attr.order;
  return (_resolveOrder = resolveOrder(order)) !== null && _resolveOrder !== void 0 ? _resolveOrder : DEFAULT_ORDER;
};
export var getItemCounterDigitsSize = function getItemCounterDigitsSize(options) {
  var _resolveOrder2, _String;
  var order = (_resolveOrder2 = resolveOrder(options.order)) !== null && _resolveOrder2 !== void 0 ? _resolveOrder2 : DEFAULT_ORDER;
  var itemsCount = typeof options.itemsCount === 'number' ? options.itemsCount : 0;
  var largestCounter = order + (itemsCount - 1);
  return (_String = String(largestCounter)) === null || _String === void 0 || (_String = _String.split('.')) === null || _String === void 0 || (_String = _String[0]) === null || _String === void 0 ? void 0 : _String.length;
};
export function isListNode(node) {
  return Boolean(node && node.type && ['orderedList', 'bulletList'].includes(node.type.name));
}
export function isParagraphNode(node) {
  return Boolean(node && node.type && 'paragraph' === node.type.name);
}
export function isListItemNode(node) {
  return Boolean(node && node.type && 'listItem' === node.type.name);
}
export function isBulletList(node) {
  return Boolean(node && node.type && 'bulletList' === node.type.name);
}
export function isOrderedList(node) {
  return Boolean(node && node.type && 'orderedList' === node.type.name);
}
export var isOrderedListContinuous = function isOrderedListContinuous(firstOrderedList, secondOrderedList) {
  if (!(isOrderedList(firstOrderedList) && isOrderedList(secondOrderedList))) {
    return false;
  }
  return getOrderFromOrderedListNode(firstOrderedList) + firstOrderedList.childCount === getOrderFromOrderedListNode(secondOrderedList);
};