import UFOLoadHold from '@atlassian/ufo-load-hold';
import UFOSegment from '@atlassian/ufo-segment';

export const UFOv2 = {
	/** Names for UFOv2 segments for Post Office */
	SegmentNames: {
		REMOTE_PLACEMENT: 'post-office-remote-loaded-placement',
		PLACEMENT: 'post-office-placement',
		MESSAGE: 'post-office-message',
	},
	/** Names for UFOv2 holds for Post Office */
	HoldNames: {
		REMOTE_PLACEMENT: 'post-office-remote-loaded-placement-loading',
		PLACEMENT: 'post-office-placement-loading',
		MESSAGE: 'post-office-message-loading',
	},
	UFOLoadHold,
	UFOSegment,
};
