export var image = {
  group: 'inline',
  inline: true,
  attrs: {
    src: {
      default: ''
    },
    alt: {
      default: ''
    },
    title: {
      default: null
    }
  },
  draggable: true,
  parseDOM: [{
    tag: 'img[src^="data:image/"]',
    ignore: true
  }, {
    tag: 'img[src]',
    getAttrs: function getAttrs(domNode) {
      var dom = domNode;
      return {
        src: dom.getAttribute('src'),
        alt: dom.getAttribute('alt'),
        title: dom.getAttribute('title')
      };
    }
  }],
  toDOM: function toDOM(node) {
    return ['img', node.attrs];
  }
};