import { defineMessages } from 'react-intl';

export default defineMessages({
  tryMe: {
    id: 'bitkit.navigation.containerNavigation.tryMe',
    description: 'Text for lozenge designating Pipelines discovery feature',
    defaultMessage: 'try me',
  },
  new: {
    id: 'bitkit.navigation.containerNavigation.new',
    description: 'Text for new lozenge',
    defaultMessage: 'New',
  },
});
