import _extends from "@babel/runtime/helpers/extends";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { components } from 'react-select';
import Spinner from '@atlaskit/spinner';
import SelectClearIcon from '@atlaskit/icon/glyph/select-clear';
import DownIcon from '@atlaskit/icon/glyph/chevron-down';
var iconContainerStyles = css({
  all: 'unset',
  outline: 'revert',
  display: 'flex',
  alignItems: 'center'
});
export var ClearIndicator = function ClearIndicator(props) {
  return jsx(components.ClearIndicator, _objectSpread(_objectSpread({}, props), {}, {
    innerProps: _objectSpread(_objectSpread({}, props.innerProps), {}, {
      'aria-hidden': 'false'
    })
  }), jsx("button", {
    css: iconContainerStyles,
    type: "button",
    tabIndex: -1
  }, jsx(SelectClearIcon, {
    size: "small",
    label: "clear"
  })));
};
export var DropdownIndicator = function DropdownIndicator(props) {
  return jsx(components.DropdownIndicator, props, jsx(DownIcon, {
    label: "open"
  }));
};
export var LoadingIndicator = function LoadingIndicator(props) {
  var loadingStyles = css(props.getStyles('loadingIndicator', props));
  return (
    // This *must* be constructed this way because this is being consumed by
    // `react-select` and we don't control what it wants.
    // eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
    jsx("div", _extends({
      css: loadingStyles
    }, props.innerProps), jsx(Spinner, {
      size: "small"
    }))
  );
};