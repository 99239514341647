import { get } from 'lodash-es';
import { createSelector } from 'reselect';

import { HORIZONTAL_GLOBAL_NAV_HEIGHT } from '@atlaskit/atlassian-navigation';

import { BANNER_HEIGHT } from 'src/constants/navigation';
import { GlobalState } from 'src/redux/global/reducer';
import {
  shouldDisplayFlag,
  shouldDisplayIncidentBanner,
} from 'src/sections/global/components/flags/site-message/site-message-store';
import { getGlobal } from 'src/selectors/state-slicing-selectors';
import { getCurrentUser } from 'src/selectors/user-selectors';
import { BucketState } from 'src/types/state';

export const getIsNavigationOpen = createSelector(
  getGlobal,
  (state: GlobalState) => state.isNavigationOpen
);

export const getIsSearchDrawerOpen = createSelector(
  getGlobal,
  (state: GlobalState) => state.isSearchDrawerOpen
);

export const getIsMobileHeaderActive = createSelector(
  getGlobal,
  (state: GlobalState) => state.isMobileHeaderActive
);

export const getMobileHeaderState = createSelector(
  getGlobal,
  (state: GlobalState) => state.mobileHeaderState
);

export const getUpToSmallBreakpointState = createSelector(
  getGlobal,
  (state: GlobalState) => state.isUpToSmallBreakpointActive
);

export const getIsOrgAdmin = createSelector(
  getGlobal,
  (state: GlobalState) => !!state.isOrgAdmin
);

export const getWorkspaceOrgId = createSelector(
  getGlobal,
  (state: GlobalState) => state.workspaceOrgId
);

export const getIsWorkspaceSumEnabled = createSelector(
  getGlobal,
  (state: GlobalState) => !!state.isWorkspaceSumEnabled
);

export const getIsWorkspaceAdmin = createSelector(
  getGlobal,
  (state: GlobalState) => !!state.isWorkspaceAdmin
);

export const siteMessagesForAppearance = (
  state: BucketState,
  targetAppearance: 'flag' | 'banner'
) => {
  const siteMessage = get(state, 'global.siteMessage');
  const currentUser = getCurrentUser(state);

  if (!siteMessage || !currentUser) {
    return null;
  }

  return (
    !getIsMobileHeaderActive(state) &&
    siteMessage.appearance === targetAppearance &&
    // Banners cannot be dismissed, so we only need to check the "should display" status for Flags.
    (siteMessage.appearance === 'banner' ||
      shouldDisplayFlag(currentUser.uuid, siteMessage.id)) &&
    // Must set this line last, so it returns the siteMessage itself
    siteMessage
  );
};

export const getStatusPageBanner = (state: BucketState) => {
  return get(state, 'global.siteMessageStatuspageIncident', null);
};

export const getIsWorkspaceBootstrapping = (state: BucketState) => {
  return get(state, 'global.workspaceBootstrapping', false);
};

// Note: If there's a context workspace and it's having its permissions bootstrapping is in progress,
// we will show that banner first. If not, and there is an incident, the statuspage banner will be shown.
// Finally if neither of the above are true, the highest priority site message will be shown.
export const getSiteMessageBanner = (state: BucketState) => {
  const isWorkspaceBootstrapping = getIsWorkspaceBootstrapping(state);
  const bootstrappingMessage = {
    id: 0,
    title: 'Bootstrap in progress',
    appearance: 'banner',
    text: 'Workspace setup in progress. Groups and users may not be fully available for multiple hours. This banner will disappear once the process completes, upon page refresh.',
    isIncident: false,
    linkType: 'refresh',
  };
  if (isWorkspaceBootstrapping) return bootstrappingMessage;

  const statuspageIncident = getStatusPageBanner(state);
  const currentUser = getCurrentUser(state);
  if (!currentUser && statuspageIncident) return statuspageIncident;
  const shouldDisplayIncident =
    statuspageIncident &&
    shouldDisplayIncidentBanner(currentUser!.uuid, statuspageIncident.id);
  if (shouldDisplayIncident) {
    return statuspageIncident;
  }
  return siteMessagesForAppearance(state, 'banner');
};

export const getIsSiteMessageActive = (state: BucketState) =>
  !!getSiteMessageBanner(state);

export const getSiteMessageFlag = (state: BucketState) =>
  siteMessagesForAppearance(state, 'flag');

export const getCombinedBannerAndHorizontalNavHeight = (
  state: BucketState,
  isBeingRenderedInsideMobileNav: boolean,
  consentBannerHeight: number
) => {
  // Note: the HORIZONTAL_GLOBAL_NAV_HEIGHT is only relevant if *not*
  // being rendered *inside* the mobile nav. This means that the calculation
  // is for a component rendered inside the mobile nav panel, it is not
  // always relevant even if the mobile nav is visible.
  const bannerHeight = getSiteMessageBanner(state)
    ? BANNER_HEIGHT + consentBannerHeight
    : consentBannerHeight;
  const horizontalNavHeight =
    !getIsMobileHeaderActive(state) && !isBeingRenderedInsideMobileNav
      ? HORIZONTAL_GLOBAL_NAV_HEIGHT
      : 0;
  return bannerHeight + horizontalNavHeight;
};

export const getIsPageVisible = createSelector(
  getGlobal,
  (state: GlobalState) => state.isPageVisible
);

export const getIsOffline = createSelector(
  getGlobal,
  (state: GlobalState) => state.isOffline
);

export const getRepositoryPageLoadingStatus = createSelector(
  getGlobal,
  (state: GlobalState) => state.repositoryPageLoadingStatus
);

export const getCurrentUserEmail = createSelector(
  getGlobal,
  (state: GlobalState) => state.currentUserEmail
);

export const getIsFeedbackModalOpen = createSelector(
  getGlobal,
  (state: GlobalState) => state.isFeedbackModalOpen
);
